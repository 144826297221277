import { INSTRUMENT_RELATIONSHIPS_SECTION_HASH } from 'app/modules/networkAnalysis/constants';
import {
  U21_NO_VALUE,
  U21Badge,
  U21Chip,
  U21Section,
  U21ShowMoreList,
  U21Table,
  U21TableBodyCellProps,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IconLock,
  IconTags,
  IconLockOpen,
  IconCreditCardOff,
} from '@u21/tabler-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  EntityNodeData,
  GraphResult,
  InstrumentNodeData,
  NetworkAnalysisGroups,
  NodeType,
} from 'app/modules/networkAnalysisRefresh/types';
import { selectEntityToInstrumentRelationshipConfigsByExternalId } from 'app/modules/relationships/selectors';
import { selectHasEditInstrumentActionPermission } from 'app/modules/session/selectors';
import { InstrumentActionStatus } from 'app/modules/instruments/constants';
import { useActionInstruments } from 'app/modules/instruments/queries/useActionInstruments';
import { useBulkApplyTagsToInstruments } from 'app/modules/instruments/queries/useBulkApplyTagsOnInstruments';
import { InstrumentActionModal } from 'app/modules/instruments/components/InstrumentActionModal';
import { BulkEditDataLabelsModal } from 'app/modules/dataLabels/BulkEditDataLabelsModal';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { CopyLinkSectionHash } from 'app/modules/networkAnalysisRefresh/components/CopyLinkSectionHash';

interface InstrumentRelationshipLinkTableRow extends InstrumentNodeData {
  rowId: string;
  relationship_config_external_id: string;
}

export const InstrumentRelationshipsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(
      INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
      sectionRef,
    );
  }, [handleLinkSectionMounted]);

  const linkedRelationshipsLength = useMemo(
    () => Object.keys(networkGroups.instrumentRelationships).length,
    [networkGroups.instrumentRelationships],
  );

  const tableData = useMemo(
    () =>
      data
        ? makeInstrumentRelationshipTableData(
            networkGroups,
            data?.graph_result?.nodes,
          )
        : [],
    [data, networkGroups],
  );

  const relationshipConfigsByExternalId = useSelector(
    selectEntityToInstrumentRelationshipConfigsByExternalId,
  );
  const reduxDispatch = useDispatch();
  const columns: U21TableColumn<InstrumentRelationshipLinkTableRow, any>[] =
    useMemo(
      () => [
        {
          id: 'relationship_config',
          accessor: (row) =>
            relationshipConfigsByExternalId[row.relationship_config_external_id]
              ?.name ?? row.relationship_config_external_id,
          Header: 'Relationship',

          disableSortBy: true,
        },
        ...INSTRUMENT_RELATIONSHIP_COLUMNS,
        {
          id: 'entities',
          Cell: ({
            row,
          }: U21TableBodyCellProps<InstrumentRelationshipLinkTableRow>) => {
            const entities = networkGroups.links[row.id]?.entities.reduce<
              EntityNodeData[]
            >((acc, e) => {
              const entity = data?.graph_result?.nodes[e];
              if (
                entity &&
                entity.node_type === NodeType.ENTITY &&
                !entity.is_base_node
              ) {
                acc.push(entity);
              }
              return acc;
            }, []);
            if (entities.length === 0) {
              return U21_NO_VALUE;
            }
            return (
              <U21ShowMoreList
                value={entities}
                displayFunc={(value) => {
                  return (
                    <U21Chip
                      onClick={(e) => {
                        e.stopPropagation();
                        reduxDispatch(
                          toggleSidebar({
                            type: SidebarComponentTypes.ENTITY,
                            data: {
                              id: 0,
                              externalId: value.external_id,
                            },
                          }),
                        );
                      }}
                    >
                      {value.display_name}
                    </U21Chip>
                  );
                }}
              />
            );
          },
          Header: 'Related entities',
        },
      ],
      [
        data?.graph_result?.nodes,
        networkGroups.links,
        reduxDispatch,
        relationshipConfigsByExternalId,
      ],
    );

  const [selectedInstruments, setSelectedInstruments] = useState<
    InstrumentRelationshipLinkTableRow[]
  >([]);
  const selectedIds = useMemo(
    () => selectedInstruments.map((i) => i.rowId),
    [selectedInstruments],
  );
  const instrumentExternalIds = useMemo(
    () => selectedInstruments.map((i) => i.external_id),
    [selectedInstruments],
  );
  const hasInstrumentActionsPermission = useSelector(
    selectHasEditInstrumentActionPermission,
  );
  const [desiredActionStatus, setDesiredActionStatus] =
    useState<InstrumentActionStatus>();
  const [editDataLabelsModalOpen, setEditDataLabelsModalOpen] = useState(false);
  const { isPending: isActionInstrumentsPending, mutate: actionInstrument } =
    useActionInstruments();
  const {
    isPending: isBulkEditInstrumentsPending,
    mutate: bulkApplyTagsToInstruments,
  } = useBulkApplyTagsToInstruments();

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedRelationshipsLength}>
            <span>Instrument relationships</span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(INSTRUMENT_RELATIONSHIPS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(
          INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
        )}
        action={
          <CopyLinkSectionHash hash={INSTRUMENT_RELATIONSHIPS_SECTION_HASH} />
        }
      >
        <U21Table<InstrumentRelationshipLinkTableRow>
          data={tableData}
          columns={columns}
          getRowID={({ rowId }: InstrumentRelationshipLinkTableRow) => rowId}
          selectable={hasInstrumentActionsPermission}
          selected={selectedIds}
          onRowSelect={(_, rows) => setSelectedInstruments(rows)}
          actions={
            hasInstrumentActionsPermission
              ? [
                  {
                    icon: <IconCreditCardOff />,
                    label: 'Deactivate',
                    onClick: () => {
                      setDesiredActionStatus(
                        InstrumentActionStatus.DEACTIVATED,
                      );
                    },
                    color: 'error',
                    loading: isActionInstrumentsPending,
                  },
                  {
                    icon: <IconLock />,
                    label: 'Lock',
                    onClick: () => {
                      setDesiredActionStatus(InstrumentActionStatus.LOCKED);
                    },
                    loading: isActionInstrumentsPending,
                  },
                  {
                    icon: <IconLockOpen />,
                    label: 'Unlock',
                    onClick: () => {
                      actionInstrument({
                        action_status: InstrumentActionStatus.UNLOCKED,
                        instrument_external_ids: instrumentExternalIds,
                      });
                      setSelectedInstruments([]);
                    },
                    loading: isActionInstrumentsPending,
                  },
                  {
                    icon: <IconTags />,
                    label: 'Edit Tags',
                    onClick: () => {
                      setEditDataLabelsModalOpen(true);
                    },
                    loading: isBulkEditInstrumentsPending,
                  },
                  // TODO SC-83987: support exports via external IDs
                  // {
                  //   icon: <IconTableExport />,
                  //   label: 'Export',
                  //   onClick: () => {
                  //     setExportModalOpen(true);
                  //   },
                  // },
                ]
              : undefined
          }
        />
      </U21Section>
      <InstrumentActionModal
        open={desiredActionStatus !== undefined}
        onClose={() => {
          setDesiredActionStatus(undefined);
          setSelectedInstruments([]);
        }}
        instrumentIds={instrumentExternalIds}
        desiredActionStatus={desiredActionStatus}
      />
      <BulkEditDataLabelsModal
        editObjectTags={async (
          tagIdsToAdd: (string | number)[],
          tagIdsToRemove: (string | number)[],
        ) => {
          await bulkApplyTagsToInstruments({
            instrument_external_ids: instrumentExternalIds,
            data_labels_to_add: tagIdsToAdd,
            data_labels_to_remove: tagIdsToRemove,
          });
          setSelectedInstruments([]);
        }}
        isEditLoading={isBulkEditInstrumentsPending}
        title="Edit Tags"
        open={editDataLabelsModalOpen}
        handleClose={() => {
          setEditDataLabelsModalOpen(false);
        }}
      />
      {/* TODO SC-83987: support exports via external IDs
      <ExportModal
        loading={isExportLoading}
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        title={
          <U21TitleCountLabel
            count={instrumentExternalIds.length}
            label="instruments"
          >
            Export Instruments
          </U21TitleCountLabel>
        }
        onExport={async (values) => {
          try {
            await exportInstruments({
              export_ids: instrumentExternalIds,
              receive_email: values.receive_email,
              use_csv: values.use_csv,
              filters: {},
            });
            setExportModalOpen(false);
            setSelectedInstruments([]);
          } catch {}
        }}
      /> */}
    </div>
  );
};

const makeInstrumentRelationshipTableData = (
  { instrumentRelationships }: NetworkAnalysisGroups,
  nodes: GraphResult['nodes'],
) =>
  Object.values(instrumentRelationships).reduce<
    InstrumentRelationshipLinkTableRow[]
  >((acc, instrumentRelationshipEdge) => {
    const { id, target, label } = instrumentRelationshipEdge;
    const targetInstrument = nodes[target];
    if (targetInstrument.node_type !== NodeType.INSTRUMENT) {
      return acc;
    }
    acc.push({
      ...targetInstrument,
      rowId: id,
      relationship_config_external_id: label,
    });
    return acc;
  }, []);

const INSTRUMENT_RELATIONSHIP_COLUMNS = [
  { id: 'id', accessor: 'external_id', Header: 'Instrument ID' },
  { id: 'type', accessor: 'type', Header: 'Instrument Type' },
  { id: 'name', accessor: 'name', Header: 'Instrument Subtype' },
  { id: 'source', accessor: 'source', Header: 'Source' },
  { id: 'status', accessor: 'status', Header: 'Status' },
];
