import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const TXN_EVENT_COMMON_ADVANCED_SYMBOLS: SymbolTable = Object.freeze({
  external_fee: {
    type: 'FIELD',
    field: 'external_fee',
    label: `Transaction / ${fromUpperToSentenceCase('external_fee')}`,
    model: 'txn_event',
    datatype: 'number',
    cardinality: 'many',
  },
  ip_is_bot: {
    type: 'FIELD',
    field: 'ip_is_bot',
    label: fromUpperToSentenceCase('ip_uses_bot'),
    model: 'txn_event',
    datatype: 'boolean',
    cardinality: 'many',
  },
  ip_is_proxy: {
    type: 'FIELD',
    field: 'ip_is_proxy',
    label: fromUpperToSentenceCase('ip_uses_proxy'),
    model: 'txn_event',
    datatype: 'boolean',
    cardinality: 'many',
  },
  ip_is_vpn: {
    type: 'FIELD',
    field: 'ip_is_vpn',
    label: fromUpperToSentenceCase('ip_uses_vpn'),
    model: 'txn_event',
    datatype: 'boolean',
    cardinality: 'many',
  },
  ip_is_tor: {
    type: 'FIELD',
    field: 'ip_is_tor',
    label: fromUpperToSentenceCase('ip_uses_tor'),
    model: 'txn_event',
    datatype: 'boolean',
    cardinality: 'many',
  },
  'geolocation.country': {
    type: 'FIELD',
    field: 'geolocation.country',
    label: 'Geolocation / Country',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.city': {
    type: 'FIELD',
    field: 'geolocation.city',
    label: 'Geolocation / City',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.postal_code': {
    type: 'FIELD',
    field: 'geolocation.postal_code',
    label: 'Geolocation / Postal code',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.longitude': {
    type: 'FIELD',
    field: 'geolocation.longitude',
    label: 'Geolocation / Longitude',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
  'geolocation.latitude': {
    type: 'FIELD',
    field: 'geolocation.latitude',
    label: 'Geolocation / Latitude',
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  },
});
