import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip } from 'app/shared/u21-ui/components';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import {
  useToggleEntitySidebar,
  useToggleSidebar,
} from 'app/modules/sidebar/hooks';

export type U21EntityChipProps = EntityByExternalIDProps | EntityByU21IDProps;

interface EntityByExternalIDProps {
  externalID: string;
  name: string;
}

interface EntityByU21IDProps {
  id: number | string;
  name: string;
}

export const U21EntityChip = (props: U21EntityChipProps) => {
  const { name } = props;
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const toggleSidebar = useToggleSidebar();
  const toggleEntitySidebar = useToggleEntitySidebar();
  return (
    <U21Chip
      onClick={
        hasReadEntitiesPermission
          ? (e) => {
              e.stopPropagation();
              if ('externalID' in props) {
                const { externalID } = props;
                toggleSidebar(
                  '', // TODO sc-81031 support cmd+click for entities by external id
                  {
                    type: SidebarComponentTypes.ENTITY,
                    data: {
                      id: '',
                      externalId: externalID,
                    },
                  },
                  e,
                );
              } else {
                const { id } = props;
                toggleEntitySidebar(id, e);
              }
            }
          : undefined
      }
    >
      {name}
    </U21Chip>
  );
};
