import { IconProps } from 'app/shared/components/Icons/models';

export const IconFlagX = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  ...rest
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.41895 4.16699V17.5003" />
      <path d="M16.085 4.16699V10.0003" />
      <path d="M4.41895 4.16666C5.19782 3.40321 6.24498 2.97559 7.33561 2.97559C8.42625 2.97559 9.47341 3.40321 10.2523 4.16666C11.0311 4.9301 12.0783 5.35773 13.1689 5.35773C14.2596 5.35773 15.3067 4.9301 16.0856 4.16666" />
      <path d="M4.41895 11.6667C5.19782 10.9032 6.24498 10.4756 7.33561 10.4756C8.42625 10.4756 9.47341 10.9032 10.2523 11.6667" />
      <path d="M12.7725 13.7715L16.8973 17.8963" />
      <path d="M12.7725 17.8955L16.8973 13.7707" />
    </g>
  </svg>
);
