import { GetRelationshipConfigsPayload } from 'app/modules/relationships/types';
import { U21NetworkGraphElementKeysToUpdate } from 'app/shared/components/Graphs/U21NetworkGraph';
import {
  BASE_CYTOSCAPE_OPTIONS,
  BASE_EDGE_STYLES,
  BASE_LABEL_STYLES,
  BASE_NODE_STYLES,
  CONCENTRIC_LAYOUT_OPTIONS,
} from 'app/shared/components/Graphs/constants';
import { CreditCard, User } from 'app/shared/components/Graphs/svgs';
import { CytoscapeOptions } from 'cytoscape';
import palette from 'vendor/material-minimal/palette';

export const DEFAULT_GET_RELATIONSHIP_CONFIGS_PAYLOAD: GetRelationshipConfigsPayload =
  {
    offset: 1,
    limit: 25,
    search: '',
    filters: [],
  };

export const GET_ALL_RELATIONSHIP_CONFIGS_PAYLOAD: GetRelationshipConfigsPayload =
  {
    ...DEFAULT_GET_RELATIONSHIP_CONFIGS_PAYLOAD,
    limit: 1000,
  };

export const RELATIONSHIP_CONFIG_MULTISELECT_PROPS = {
  label: 'Filter by relationship',
  placeholder: 'Select relationships...',
};

export const RELATIONSHIP_LAYOUT_OPTIONS = {
  ...CONCENTRIC_LAYOUT_OPTIONS,
  avoidOverlap: true,
  nodeDimensionsIncludeLabels: true,
};

export const RELATIONSHIP_ANALYSIS_CYTOSCAPE_OPTIONS: CytoscapeOptions = {
  ...BASE_CYTOSCAPE_OPTIONS,
  layout: RELATIONSHIP_LAYOUT_OPTIONS,
  userZoomingEnabled: false,
  style: [
    {
      selector: 'node',
      style: {
        ...BASE_NODE_STYLES,
        'background-color': (node) => {
          if (node.data('isBaseNode')) {
            return palette.light.error.main;
          }
          return node.data('nodeType') === 'entity'
            ? palette.light.primary.main
            : palette.light.info.main;
        },
        'border-color': (node) => {
          if (node.data('isBaseNode')) {
            return palette.light.error.light;
          }
          return node.data('nodeType') === 'entity'
            ? palette.light.primary.light
            : palette.light.info.light;
        },
        'background-image': (node) =>
          node.data('nodeType') === 'entity' ? User : CreditCard,
        content: (node) => node.data('label') ?? '',
      },
    },
    {
      selector: 'edge',
      style: {
        ...BASE_EDGE_STYLES,
        content: (edge) => edge.data('label') ?? '',
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle',
        'source-arrow-shape': (node) =>
          node.data('undirected') ? 'triangle' : 'none',
        'arrow-scale': 0.75,
      },
    },
    {
      selector: 'label',
      style: {
        ...BASE_LABEL_STYLES,
        'min-zoomed-font-size': 8,
        'text-max-width': '80px',
      },
    },
    {
      selector: 'edge[label]',
      style: {
        ...BASE_LABEL_STYLES,
        'text-background-color': palette.light.error.lighter,
      },
    },
  ],
};

export const RELATIONSHIP_ELEMENT_KEYS_TO_UPDATE: U21NetworkGraphElementKeysToUpdate =
  { nodes: ['degree'], edges: [] };
