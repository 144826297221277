// Models
import { RuleDetails } from 'app/modules/rules/models';

export enum BlacklistActionTypes {
  RETRIEVE_BLACKLISTS = '@@blacklists/RETRIEVE_BLACKLISTS',
  RETRIEVE_BLACKLISTS_SUCCESS = '@@blacklists/RETRIEVE_BLACKLISTS_SUCCESS',

  RETRIEVE_BLACKLIST = '@@blacklists/RETRIEVE_BLACKLIST',
  RETRIEVE_BLACKLIST_SUCCESS = '@@blacklists/RETRIEVE_BLACKLIST_SUCCESS',

  RETRIEVE_BLACKLIST_ITEMS = '@@blacklists/RETRIEVE_BLACKLIST_ITEMS',
  RETRIEVE_BLACKLIST_ITEMS_SUCCESS = '@@blacklists/RETRIEVE_BLACKLIST_ITEMS_SUCCESS',

  CREATE_BLACKLIST = '@@blacklists/CREATE_BLACKLIST',
  CREATE_BLACKLIST_SUCCESS = '@@blacklists/CREATE_BLACKLIST_SUCCESS',

  EDIT_BLACKLIST = '@@blacklists/EDIT_BLACKLIST',
  EDIT_BLACKLIST_SUCCESS = '@@blacklists/EDIT_BLACKLIST_SUCCESS',

  DEACTIVATE_BLACKLIST_ITEM = '@@blacklists/DEACTIVATE_BLACKLIST_ITEM',
  DEACTIVATE_BLACKLIST_ITEM_SUCCESS = '@@blacklists/DEACTIVATE_BLACKLIST_ITEM_SUCCESS',

  ADD_BLACKLIST_ITEM = '@@blacklists/ADD_BLACKLIST_ITEM',
  ADD_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_BLACKLIST_ITEM_SUCCESS',

  ADD_USER_BLACKLIST_ITEM = '@@blacklists/ADD_USER_BLACKLIST_ITEM',
  ADD_USER_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_USER_BLACKLIST_ITEM_SUCCESS',

  ADD_BUSINESS_BLACKLIST_ITEM = '@@blacklists/ADD_BUSINESS_BLACKLIST_ITEM',
  ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS',

  APPEND_FILE_TO_BLACKLIST = '@@blacklists/APPEND_FILE_TO_BLACKLIST',
  APPEND_FILE_TO_BLACKLIST_SUCCESS = '@@blacklists/APPEND_FILE_TO_BLACKLIST_SUCCESS',
}

export type BlacklistType =
  | 'STRING'
  | 'IP_INET'
  | 'IP_CIDR'
  | 'USER'
  | 'BUSINESS';

export type BlacklistStatus = 'ACTIVE' | 'INACTIVE';

export interface Blacklist {
  id: number;
  name: string;
  description: string;
  type: BlacklistType;
  use_as_whitelist: boolean;
  status: BlacklistStatus;
  u21_global: boolean;
  rules: RuleDetails[];
}

export interface BlacklistsState {
  blacklists: Blacklist[];
  blacklistsCount: number;
  blacklist: Blacklist;
  blacklistItems: BlacklistItem[];
  blacklistItemsCount: number;
  fileStatus: {
    status: string;
    message: string;
  };
}

export interface BlacklistItem {
  id: number;
  blacklist_id: number;
  value: string;
  deactivated_at: string | null;
}

export type FinCEN314aScanType = 'PERSON' | 'BUSINESS';

type FinCEN314aPersonScanMatchFieldName = 'DOB' | 'FULL_NAME' | 'SSN';

export interface FinCEN314aPersonScanMatchField {
  field: FinCEN314aPersonScanMatchFieldName;
  threshold: number;
}

export interface FinCEN314aPersonScanMatchSettings {
  alert_queue?: number;
  match_fields: FinCEN314aPersonScanMatchField[];
}

type FinCEN314aBusinessScanMatchFieldName = 'NAME';

export interface FinCEN314aBusinessScanMatchField {
  field: FinCEN314aBusinessScanMatchFieldName;
  threshold: number;
}

export interface FinCEN314aBusinessScanMatchSettings {
  alert_queue?: number;
  match_fields: FinCEN314aBusinessScanMatchField[];
}

export interface FinCEN314aScanConfig {
  person: FinCEN314aPersonScanMatchSettings;
  business: FinCEN314aBusinessScanMatchSettings;
}
