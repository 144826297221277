import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
// Models
import { TransactionsActionTypes } from 'app/modules/transactions/models';

export const retrieveActionEvent = (payload: number | string) => {
  return createAction(TransactionsActionTypes.RETRIEVE_ACTION_EVENT, payload);
};

export const retrieveActionEventSuccess = (result) => {
  return createAction(
    TransactionsActionTypes.RETRIEVE_ACTION_EVENT_SUCCESS,
    result,
  );
};

const TransactionsActionsList = {
  retrieveActionEventSuccess,
};
type TransactionsActions = ActionsUnion<typeof TransactionsActionsList>;

export default TransactionsActions;
