import { useSelector } from 'react-redux';

// Components
import { IconArrowsLeftRight } from '@u21/tabler-icons';

import { U21Chip } from 'app/shared/u21-ui/components';

// Models
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { useToggleTransactionSidebar } from 'app/modules/sidebar/hooks';

export const EventChip = ({ eventID }: { eventID: number }) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const toggleTransactionSidebar = useToggleTransactionSidebar();

  return (
    <U21Chip
      icon={<IconArrowsLeftRight />}
      onClick={
        hasReadEventsPermission
          ? (e) => {
              e.stopPropagation();
              toggleTransactionSidebar(eventID, e);
            }
          : undefined
      }
    >
      {`Event #${eventID}`}
    </U21Chip>
  );
};
