import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from 'app/shared/providers/AuthProvider';

// components
import {
  U21Avatar,
  U21Divider,
  U21Typography,
  U21Spacer,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import AccountImpersonateDropdown from 'app/modules/navigator/components/AccountImpersonateDropdown';
import { AccountImpersonationModal } from 'app/modules/navigator/components/AccountImpersonationModal';
import { AccountSection } from 'app/modules/navigator/components/accountPopoverSections/AccountSection';
import { OrgSwitcherSection } from 'app/modules/navigator/components/accountPopoverSections/OrgSwitcherSection';
import { retrieveOrgSwitcher } from 'app/modules/orgSwitcher/sliceOrgSwitcher';

// Selectors
import { selectAgent, selectIsUnit21User } from 'app/modules/session/selectors';
import { selectOrgSwitcher } from 'app/modules/orgSwitcher/selectors';

// Utils
import { getA11yClickProps } from 'app/shared/utils/a11y';
import { getAgentDisplayName } from 'app/modules/agents/utils';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import styled from 'styled-components';
import { getIsOrgSwitcherAgentSet } from 'app/modules/session/helpers';

const buildTimestamp = getFromProcessEnv('REACT_APP_BUILD_TIMESTAMP');
const buildHash = getFromProcessEnv('REACT_APP_HEAD_HASH');

export default function AccountPopover() {
  const dispatch = useDispatch();

  const agent = useSelector(selectAgent);
  const orgSwitcher = useSelector(selectOrgSwitcher);
  const isUnit21User = useSelector(selectIsUnit21User);

  const { authClient: auth } = useAuth();

  const { org_display_name: orgDisplayName, picture } = agent;
  const name = getAgentDisplayName(agent);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openImpersonationModal, setOpenImpersonationModal] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(retrieveOrgSwitcher());
  }, [agent, dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isOrgSwitcherAgentSet = getIsOrgSwitcherAgentSet();

  const orgSwitcherEnabled =
    isOrgSwitcherAgentSet ||
    (orgSwitcher.is_enabled && orgSwitcher.orgs.length > 0);

  return (
    <>
      <U21MenuLayout
        onClose={handleClose}
        open={open}
        trigger={
          <Container
            ref={anchorRef}
            {...getA11yClickProps(handleOpen)}
            horizontal
            spacing={2}
          >
            <U21Avatar name={name} src={picture} />
            <div>
              <NoWrapTypography variant="subtitle2">{name}</NoWrapTypography>
              <NoWrapTypography color="text.secondary" variant="body2">
                {orgDisplayName}
              </NoWrapTypography>
            </div>
          </Container>
        }
      >
        <MenuContainer>
          {orgSwitcherEnabled && (
            <>
              <OrgSwitcherSection
                auth={auth}
                orgSwitcher={orgSwitcher}
                closePopover={handleClose}
              />
              <U21Divider horizontal />
            </>
          )}
          <AccountSection agent={agent} closePopover={handleClose} />
          <AccountImpersonateDropdown
            auth={auth}
            closePopover={handleClose}
            onOpenImpersonationModal={() => setOpenImpersonationModal(true)}
          />

          {isUnit21User && buildTimestamp && (
            <>
              <U21Divider horizontal />
              <BuildInfo color="text.secondary" variant="body2">
                {`${buildHash.slice(0, 16)} built at ${buildTimestamp}`}
              </BuildInfo>
            </>
          )}
        </MenuContainer>
      </U21MenuLayout>
      <AccountImpersonationModal
        open={openImpersonationModal}
        handleClose={() => setOpenImpersonationModal(false)}
        auth={auth}
      />
    </>
  );
}

const MenuContainer = styled.div`
  width: 250px;
`;

const Container = styled(U21Spacer)`
  display: flex;
  padding: 8px;
  cursor: pointer;

  :hover {
    border-radius: 8px;
    background: ${(props) => props.theme.palette.action.hover};
  }
`;

const NoWrapTypography = styled(U21Typography)`
  white-space: nowrap;
`;

const BuildInfo = styled(U21Typography)`
  padding: 8px 16px;
`;
