import { CoreSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/CoreSourceChip';
import { useInternalSourceDetail } from 'app/modules/investigations/components/AiInvestigation/hooks';
import { AiInvestigationInternalSource } from 'app/modules/investigations/types/responses';
import {
  useToggleActionEventSidebar,
  useToggleAlertSidebar,
  useToggleEntitySidebar,
  useToggleInstrumentSidebar,
  useToggleTransactionSidebar,
} from 'app/modules/sidebar/hooks';
import { SyntheticEvent, useCallback } from 'react';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationInternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const InternalSourceChip = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  const { icon, label, id } = useInternalSourceDetail(sourceData);

  const toggleActionEventSidebar = useToggleActionEventSidebar();
  const toggleAlertSidebar = useToggleAlertSidebar();
  const toggleEntitySidebar = useToggleEntitySidebar();
  const toggleInstrumentSidebar = useToggleInstrumentSidebar();
  const toggleTransactionSidebar = useToggleTransactionSidebar();

  const toggleSidebar = useCallback(
    (
      u21Id: number | string,
      e: KeyboardEvent | MouseEvent | SyntheticEvent,
    ) => {
      const u21IdNum = typeof u21Id === 'string' ? parseInt(u21Id, 10) : u21Id;

      switch (sourceData.data_type) {
        case 'ACTION_EVENT':
          return toggleActionEventSidebar(u21IdNum, e);
        case 'ALERT':
          return toggleAlertSidebar(u21IdNum, e);
        case 'ENTITY':
          return toggleEntitySidebar(u21IdNum, e);
        case 'INSTRUMENT':
          return toggleInstrumentSidebar(u21IdNum, e);
        case 'TRANSACTION':
          return toggleTransactionSidebar(u21IdNum, e);
        default:
          return () => {};
      }
    },
    [
      sourceData.data_type,
      toggleActionEventSidebar,
      toggleAlertSidebar,
      toggleEntitySidebar,
      toggleInstrumentSidebar,
      toggleTransactionSidebar,
    ],
  );

  return (
    <CoreSourceChip
      sourceNumber={sourceNumber}
      icon={icon}
      label={label}
      onClick={id ? (e) => toggleSidebar(id, e) : undefined}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
    />
  );
};
