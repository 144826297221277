import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import { selectNetworkAnalysisRequestIsQueued } from 'app/modules/networkAnalysis/selectors';
import { removeQueuedRequest } from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import { selectNetworkAnalysisOnDeltaLake } from 'app/shared/featureFlags/selectors';
import { post } from 'app/shared/utils/fetchr';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface NetworkAnalysisStatusResponse {
  status:
    | 'QUEUED'
    | 'IN_PROGRESS'
    | 'FINISHED_SUCCESSFULLY'
    | 'FINISHED_WITH_ERRORS';
  queued_at: string;
  started_at: string;
  finished_at: string;
}

const getNetworkAnalysisStatus = (entityExternalId: string) =>
  post<NetworkAnalysisStatusResponse>(`/entities/network-analysis/status`, {
    entity_external_id: entityExternalId,
  });

const getInstrumentNetworkAnalysisStatus = (instrumentExternalId: string) =>
  post<NetworkAnalysisStatusResponse>(`/instruments/network-analysis/status`, {
    instrument_external_id: instrumentExternalId,
  });

export const usePollNetworkAnalysisStatus = (
  externalId: string,
  baseObjectType: BaseObjectType,
) => {
  // check redux to see if the entity ID is in our set of queued network analysis requests
  const enabled = useSelector((state) =>
    selectNetworkAnalysisRequestIsQueued(state, externalId),
  );

  const query = useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisStatusKey(
      externalId,
      baseObjectType,
    ),
    queryFn: () =>
      baseObjectType === BaseObjectType.ENTITY
        ? getNetworkAnalysisStatus(externalId)
        : getInstrumentNetworkAnalysisStatus(externalId),
    refetchInterval: (q) =>
      !(q.state.status === 'error') &&
      (enabled ||
      !q.state.data?.status ||
      q.state.data?.status === 'QUEUED' ||
      q.state.data?.status === 'IN_PROGRESS'
        ? 1500
        : undefined), // refetch every 1.5 seconds
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const linkAnalysisOnDeltaLake = useSelector(selectNetworkAnalysisOnDeltaLake);

  useEffect(() => {
    if (
      (query.data?.status === 'FINISHED_SUCCESSFULLY' ||
        query.data?.status === 'FINISHED_WITH_ERRORS') &&
      !query.isFetching &&
      enabled
    ) {
      // invalidate the network analysis query so that the UI will update
      queryClient.invalidateQueries({
        queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisKey(
          externalId,
          baseObjectType,
        ),
      });
      // remove the entity ID from the set of queued network analysis requests to disable polling
      dispatch(removeQueuedRequest(externalId));
    }
  }, [
    query.data,
    enabled,
    queryClient,
    dispatch,
    externalId,
    baseObjectType,
    query.isFetching,
    linkAnalysisOnDeltaLake,
  ]);
  return query;
};
