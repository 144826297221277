import { alpha } from '@mui/system/colorManipulator';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - custom colors
// - removed button text transform
// - set DefaultTheme to ThemeOptions
// - removed secondary color
// - add COLORS
// - add table action colors

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

declare module '@mui/private-theming/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    colors: Record<
      ColorColorSchema,
      {
        main: string;
        dark: string;
        contrastText: string;
      }
    >;
    gradients: GradientsPaletteOptions;
  }
  interface PaletteOptions {
    gradients?: GradientsPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

export type ColorSchema = 'primary' | 'info' | 'success' | 'warning' | 'error';

export type ColorColorSchema = keyof typeof COLORS;

// SETUP COLORS
const PRIMARY = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const INFO = {
  lighter: '#D3EDEE',
  light: '#6BC2C7',
  main: '#43AAB1',
  dark: '#328185',
  darker: '#225559',
};
const SUCCESS = {
  lighter: '#EFFFBE',
  light: '#C3EB45',
  main: '#A3D211',
  dark: '#7CA00E',
  darker: '#495F06',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFD6C4',
  light: '#F9A287',
  main: '#F46059',
  dark: '#C54640',
  darker: '#87231F',
};

const GREY = {
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#121212',
  500_4: alpha('#919EAB', 0.04),
  500_8: alpha('#919EAB', 0.08),
  500_10: alpha('#919EAB', 0.1),
  '500_8_solid': '#F7F7F8',
  500_12: alpha('#919EAB', 0.12),
  '500_12_solid': '#F2F3F5',
  500_16: alpha('#919EAB', 0.16),
  '500_16_solid': '#EEEFF1',
  500_24: alpha('#919EAB', 0.24),
  '500_24_solid': '#E4E8EB',
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const COLORS = {
  red: {
    main: '#F46059',
    dark: '#C54640',
    contrastText: '#fff',
  },
  orange: {
    main: '#FF8A00',
    dark: '#D27404',
    contrastText: GREY[800],
  },
  yellow: {
    main: '#FFC107',
    dark: '#B78103',
    contrastText: GREY[800],
  },
  green: {
    main: '#A3D211',
    dark: '#7CA00E',
    contrastText: GREY[800],
  },
  olive: {
    main: '#495F06',
    dark: '#2B3802',
    contrastText: '#fff',
  },
  teal: {
    main: '#43AAB1',
    dark: '#328185',
    contrastText: '#fff',
  },
  blue: {
    main: '#1890FF',
    dark: '#0C53B7',
    contrastText: '#fff',
  },
  purple: {
    main: '#9C3CAC',
    dark: '#792187',
    contrastText: '#fff',
  },
  brown: {
    main: '#7A4F01',
    dark: '#593A00',
    contrastText: '#fff',
  },
  grey: {
    main: '#637381',
    dark: '#454F5B',
    contrastText: '#fff',
  },
  black: {
    main: '#212B36',
    dark: '#161C24',
    contrastText: '#fff',
  },
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};
export const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  colors: COLORS,
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
      tableHeader: GREY[300],
    },
    action: {
      active: GREY[600],
      ...COMMON.action,
      hover: GREY[300],
      tableHover: '#F7FBFF', // 4% primary against background.paper
      tableHighlight: '#EFF6FF', // 8% primary against background.paper
      tableSelect: '#E6F1FF', // 12% primary against background.paper
    },
    button: { opacity: 1 },
  },
  dark: {
    ...COMMON,
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[900],
      default: alpha(GREY[1000], 0.95),
      neutral: GREY[1000],
      tableHeader: GREY[1000],
    },
    action: {
      active: GREY[500],
      ...COMMON.action,
      tableHover: '#17202C', // 4% primary against background.paper
      tableHighlight: '#172535', // 8% primary against background.paper
      tableSelect: '#182A3F', // 12% primary against background.paper
    },
    button: { opacity: 0.8 },
  },
};

export default palette;
