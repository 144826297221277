import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { CustomDataSidebarDefinition } from 'app/modules/sidebar/models';
import { isEmpty } from 'lodash';
import {
  U21Button,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { useState } from 'react';

interface Props {
  data: CustomDataSidebarDefinition['data'];
}

export const SidebarFinCENCTRCustomEntity = ({
  data: { customData, customDataLabels },
}: Props) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);
  return (
    <U21SideMenu
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      title="Entity Information"
    >
      {isEmpty(customData) ? (
        <U21Typography variant="caption" color="text.secondary">
          No entity information
        </U21Typography>
      ) : (
        <U21Spacer>
          {Object.keys(customData).map((i) => {
            return (
              <SidebarDataRow
                key={i}
                showEmptyFields={showEmptyFields}
                label={customDataLabels[i]?.label ?? i}
              >
                {customData[i]}
              </SidebarDataRow>
            );
          })}
        </U21Spacer>
      )}
    </U21SideMenu>
  );
};
