import { post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { selectDLEnabledForAlertTxnAnalysis } from 'app/shared/featureFlags/selectors';
import { useEffect, useMemo, useRef } from 'react';
import {
  DataLoaderState,
  DataLoaderStatus,
  DataLoaderJobType,
} from 'app/modules/investigations/types/responses';

export enum ColdStorageLoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  LOADING_TIMEOUT = 'LOADING_TIMEOUT', // We are still loading but it's been more than the threshold
}

const LOADING_TIME_THRESHOLD_IN_SECONDS = 60 * 5; // 5 minutes

const getLoadingFromColdStorageState = (
  alertId: number,
  beState: DataLoaderState<'ALERT'> | undefined,
  hasDLEnabledForAlertTxnAnalysis: boolean,
): {
  [key in DataLoaderJobType]: ColdStorageLoadingStatus;
} => {
  const loadingState: {
    [key in DataLoaderJobType]: ColdStorageLoadingStatus;
  } = {
    txn_event: ColdStorageLoadingStatus.LOADED,
    instrument: ColdStorageLoadingStatus.LOADED,
  };

  const alertBEState = beState?.article_ids[alertId];
  if (hasDLEnabledForAlertTxnAnalysis && alertBEState) {
    Object.keys(alertBEState).forEach((key: DataLoaderJobType) => {
      if (
        alertBEState[key].seconds_since_execution !== null &&
        [DataLoaderStatus.LOADING, DataLoaderStatus.TO_QUEUE].includes(
          alertBEState[key].status,
        )
      ) {
        // Update the loading state to Loading based on the threshold
        loadingState[key] =
          alertBEState[key].seconds_since_execution <
          LOADING_TIME_THRESHOLD_IN_SECONDS
            ? ColdStorageLoadingStatus.LOADING
            : ColdStorageLoadingStatus.LOADING_TIMEOUT;
      }
    });
  }
  return loadingState;
};

export const useGetAlert = (
  id: number,
  options: Pick<
    UseQueryOptions<InvestigationsAlertResponse<false>>,
    'enabled'
  > = {},
) => {
  const { enabled = true } = options;
  const hasDLEnabledForAlertTxnAnalysis = useSelector(
    selectDLEnabledForAlertTxnAnalysis,
  );
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const dataUpdateCountRef = useRef(0);
  const query = useQuery({
    refetchInterval: ({ state: { data } }) => {
      /*
        We might want to move the refetch interval to the hook definition once we are sure the new cache tables work correctly, for now only using it on this tab
        Make sure we got only 1 alert ID, if it's loading from cold storage refetch after waiting some time as long as we don't run out of retries
      */
      const COLD_STORAGE_WAIT_TIME = 2 * 60 * 1000; // 2 minutes
      const COLD_STORAGE_MAX_RETRIES = 3;
      if (data?.alerts.length === 1) {
        const alertId = data?.alerts[0].id;
        const loadingStatus = getLoadingFromColdStorageState(
          alertId,
          data?.data_loader,
          hasDLEnabledForAlertTxnAnalysis,
        );
        const cgdoStillLoading = Object.values(loadingStatus).some((status) =>
          [
            ColdStorageLoadingStatus.LOADING,
            ColdStorageLoadingStatus.LOADING_TIMEOUT,
          ].includes(status),
        );

        // Retry if we haven't hit the max retries and the CGDO is still loading
        if (
          dataUpdateCountRef.current < COLD_STORAGE_MAX_RETRIES &&
          cgdoStillLoading
        ) {
          return COLD_STORAGE_WAIT_TIME;
        }
      }
      // Do not retry by default
      return false;
    },
    enabled: hasReadAlertsPermission && enabled,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ALERT_QUERY_KEYS.getAlert(id),
    queryFn: () => {
      dataUpdateCountRef.current += 1;
      return post<InvestigationsAlertResponse<false>>(
        '/investigations/retrieve/alert',
        {
          short: false,
          object_type: 'ALERT',
          object_ids: [id],
        } satisfies InvestigationsPayload,
      );
    },
  });

  const coldStorageLoading = useMemo(
    () =>
      getLoadingFromColdStorageState(
        id,
        query.data?.data_loader,
        hasDLEnabledForAlertTxnAnalysis,
      ),
    [id, query.data?.data_loader, hasDLEnabledForAlertTxnAnalysis],
  );

  useEffect(() => {
    // Reset update count when the alert ID changes
    dataUpdateCountRef.current = 0;
  }, [id]);

  return {
    ...query,
    alert: query.data?.alerts[0],
    coldStorageLoading,
  };
};
