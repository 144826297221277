import { SentenceWithSources } from 'app/modules/investigations/components/AiInvestigation/SentenceWithSources';
import { AiInvestigationParagraphWithSource } from 'app/modules/investigations/models';
import {
  AiInvestigationExternalSource,
  AiInvestigationInternalSource,
} from 'app/modules/investigations/types/responses';
import { U21Typography } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface OwnProps {
  paragraphWithSource: AiInvestigationParagraphWithSource[];
  sourceNumberToExternalSourceMap: Map<number, AiInvestigationExternalSource>;
  sourceNumberToInternalSourceMap: Map<number, AiInvestigationInternalSource>;
  isSourcesHovered: boolean;
  currentSources: number[];
  setCurrentSources: (sourceNumbers: number[]) => void;
}

export const AiInvestigationParagraph = ({
  paragraphWithSource,
  sourceNumberToExternalSourceMap,
  sourceNumberToInternalSourceMap,
  isSourcesHovered,
  currentSources,
  setCurrentSources,
}: OwnProps) => {
  return (
    <StyledTypography component="div" variant="body2">
      {paragraphWithSource.map((paragraph) => {
        if (paragraph.sources.length === 0) {
          return paragraph.sentence;
        }

        return (
          <SentenceWithSources
            key={paragraph.key}
            paragraphWithSource={paragraph}
            currentSources={currentSources}
            internalSourceData={sourceNumberToInternalSourceMap}
            externalSourceData={sourceNumberToExternalSourceMap}
            isSourcesHovered={isSourcesHovered}
            setCurrentSources={setCurrentSources}
          />
        );
      })}
    </StyledTypography>
  );
};

const StyledTypography = styled(U21Typography)`
  white-space: pre-wrap;
`;
