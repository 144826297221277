import {
  U21Button,
  U21Chip,
  U21Section,
  U21Spacer,
  U21Tooltip,
  U21Typography,
  U21TypographyIcon,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LinkedEntitiesTable } from 'app/modules/networkAnalysisRefresh/components/LinkedEntitiesTable';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconCircleCheck, IconCopy, IconLink } from '@u21/tabler-icons';
import { truncate } from 'lodash';
import { LinkGroupItem } from 'app/modules/networkAnalysisRefresh/types';
import {
  NODE_OR_CONNECTION_TYPE_TO_ICON,
  NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME,
} from 'app/modules/networkAnalysisRefresh/constants';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';

interface LinkSectionProps {
  link: LinkGroupItem;
}

export const LinkSection = ({ link }: LinkSectionProps) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const {
    networkGroups,
    expandedSections,
    setExpandedSections,
    handleLinkSectionMounted,
  } = useNetworkAnalysisContext();

  useEffect(() => {
    return handleLinkSectionMounted(link.id, sectionRef);
  }, [handleLinkSectionMounted, link]);

  const entities = useMemo(
    () => link.entities.map((entityId) => networkGroups.entities[entityId]),
    [networkGroups, link],
  );

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Spacer horizontal align="center">
            <U21TypographyIcon variant="h4">
              {NODE_OR_CONNECTION_TYPE_TO_ICON[link.node_type]}
            </U21TypographyIcon>
            <span>
              {NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME[link.node_type]}
            </span>
            :{' '}
            <StyledTypography variant="h4">
              {truncate(link.label, { length: 60 })}
            </StyledTypography>
            <U21Chip
              color={entities.length > 5 ? 'error' : 'warning'}
              variant="ghost"
            >
              {pluralize('Entity', entities.length, true)}
            </U21Chip>
          </U21Spacer>
        }
        collapsible
        collapsed={!expandedSections.has(link.id)}
        onToggleCollapse={(c) =>
          setExpandedSections((prev) => {
            const newSet = new Set(prev);
            if (c) {
              newSet.delete(link.id);
            } else {
              newSet.add(link.id);
            }
            return newSet;
          })
        }
        action={
          // TODO: support opening instrument sidebar via external id
          // link.node_type === NodeType.INSTRUMENT ? (
          //   <U21Spacer horizontal wrap>
          //     <U21Button
          //       startIcon={NODE_OR_CONNECTION_TYPE_TO_ICON[link.node_type]}
          //       onClick={() =>
          //         reduxDispatch(
          //           toggleSidebar({
          //             type: SidebarComponentTypes.INSTRUMENT,
          //             data: {
          //               id: 0,
          //               externalId: link.instrument_external_id,
          //             },
          //           }),
          //         )
          //       }
          //     >
          //       View Summary
          //     </U21Button>
          //     <CopyLinkHashButton linkId={link.id} />
          //   </U21Spacer>
          <CopyLinkHashButton linkId={link.id} />
        }
      >
        <LinkedEntitiesTable entities={entities} />
      </U21Section>
    </div>
  );
};

const CopyLinkHashButton = ({ linkId }: { linkId: string }) => {
  const [copied, setCopied] = useState(false);
  const { pathname, search } = useLocation();
  const history = useHistory();
  return (
    <U21Tooltip
      tooltip={
        copied ? (
          <U21Typography
            color="inherit"
            icon={<StyledIconCircleCheck />}
            variant="inherit"
          >
            Copied!
          </U21Typography>
        ) : (
          <U21Typography color="inherit" icon={<IconCopy />} variant="inherit">
            Copy section URL
          </U21Typography>
        )
      }
    >
      <span>
        <CopyToClipboard
          onCopy={() => {
            history.replace(`${pathname}${search}#${linkId}`);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
          text={`${window.location.origin}${pathname}${search}#${linkId}`}
        >
          <U21Button
            icon={<IconLink />}
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-label="Copy link"
          />
        </CopyToClipboard>
      </span>
    </U21Tooltip>
  );
};

export const StyledTypography = styled(U21Typography)`
  text-transform: none;
`;

export const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${(props) => props.theme.palette.success.main};
`;
