import { GetInstrumentsResponse } from 'app/modules/instruments/responses';

export const DEFAULT_GET_INSTRUMENTS_RESPONSE: GetInstrumentsResponse = {
  count: null,
  txn_instruments: [],
};

export type InstrumentDetailsTabType = {
  key: string;
  hidden: boolean;
  label: string;
};

export const INSTRUMENT_DETAILS_DEFAULT_TABS: Record<
  string,
  InstrumentDetailsTabType
> = {
  transactions: {
    key: 'transactions',
    hidden: false,
    label: 'Transactions',
  },
  risk: {
    key: 'risk',
    hidden: false,
    label: 'Risk',
  },
  'rule-whitelist': {
    key: 'rule-whitelist',
    hidden: false,
    label: 'Rule Silencing',
  },
  relationships: {
    key: 'relationships',
    hidden: false,
    label: 'Relationships',
  },
  'consortium-hits': {
    key: 'consortium-hits',
    hidden: false,
    label: 'Consortium Hits',
  },
  'network-analysis': {
    key: 'network-analysis',
    hidden: false,
    label: 'Network Analysis',
  },
} as const;

export enum InstrumentActionStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  DEACTIVATED = 'DEACTIVATED',
}
