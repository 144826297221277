import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import {
  BaseObjectType,
  NetworkAnalysisRefreshResponse,
} from 'app/modules/networkAnalysisRefresh/types';

const getEntityNetworkAnalysis = async (entityExternalID: string) =>
  post<NetworkAnalysisRefreshResponse>(`/entities/network-analysis`, {
    entity_external_id: entityExternalID,
  });

const getInstrumentNetworkAnalysis = async (instrumentExternalID: string) =>
  post<NetworkAnalysisRefreshResponse>(`/instruments/network-analysis`, {
    instrument_external_id: instrumentExternalID,
  });

export const useGetNetworkAnalysis = (
  externalId: string,
  baseObjectType: BaseObjectType,
) => {
  return useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisKey(
      externalId,
      baseObjectType,
    ),
    queryFn: () =>
      baseObjectType === BaseObjectType.ENTITY
        ? getEntityNetworkAnalysis(externalId)
        : getInstrumentNetworkAnalysis(externalId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
};
