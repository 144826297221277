import { ACTION_EVENT_QUERY_KEYS } from 'app/modules/actionEvents/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

export const useGetActionEventDetail = (id?: number) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  return useQuery({
    queryFn: () => get<ShortActionEventResponse>(`/action-events/${id}`),
    queryKey: ACTION_EVENT_QUERY_KEYS.getActionEventDetail(id!), // id will never be undefined since query is only enabled when id is defined
    enabled: id !== undefined && hasReadEventsPermission && id > 0,
  });
};
