import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const ACTION_EVENT_DROPDOWN_SYMBOLS: SymbolTable = Object.freeze({
  // NOTE: builderType === 'dropdown'
  txn_instrument_id: {
    type: 'text',
    text: 'Instrument ID',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'txn_instrument_id',
        label: 'txn_instrument_id',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'entity.first_esigned_event': {
    type: 'text',
    text: 'Entity / First esigned event',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'entity.first_esigned_event',
        label: 'entity.first_esigned_event',
        model: 'action_event',
        datatype: 'datetime',
        cardinality: 'many',
      },
    },
  },
  'geolocation.country': {
    type: 'text',
    text: 'Geolocation / Country',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.country',
        label: 'geolocation.country',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.city': {
    type: 'text',
    text: 'Geolocation / City',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.city',
        label: 'geolocation.city',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.postal_code': {
    type: 'text',
    text: 'Geolocation / Postal code',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.postal_code',
        label: 'geolocation.postal_code',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.longitude': {
    type: 'text',
    text: 'Geolocation / Longitude',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.longitude',
        label: 'geolocation.longitude',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  'geolocation.latitude': {
    type: 'text',
    text: 'Geolocation / Latitude',
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'geolocation.latitude',
        label: 'geolocation.latitude',
        model: 'action_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  },
  ip_is_bot: {
    type: 'text',
    text: 'IP uses bot',
    value: {
      operandType: 'boolean',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'ip_is_bot',
        label: 'ip_is_bot',
        model: 'action_event',
        datatype: 'boolean',
        cardinality: 'many',
      },
    },
  },
  ip_is_tor: {
    type: 'text',
    text: 'IP uses Tor',
    value: {
      operandType: 'boolean',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'ip_is_tor',
        label: 'ip_is_tor',
        model: 'action_event',
        datatype: 'boolean',
        cardinality: 'many',
      },
    },
  },
  ip_is_vpn: {
    type: 'text',
    text: 'IP uses VPN',
    value: {
      operandType: 'boolean',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'ip_is_vpn',
        label: 'ip_is_vpn',
        model: 'action_event',
        datatype: 'boolean',
        cardinality: 'many',
      },
    },
  },
  ip_is_proxy: {
    type: 'text',
    text: 'IP uses proxy',
    value: {
      operandType: 'boolean',
      unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
      value: {
        type: 'FIELD',
        field: 'ip_is_proxy',
        label: 'ip_is_proxy',
        model: 'action_event',
        datatype: 'boolean',
        cardinality: 'many',
      },
    },
  },
});
