import {
  ConcentricLayoutOptions,
  Css,
  CytoscapeOptions,
  EdgeSingular,
  GridLayoutOptions,
  NodeSingular,
} from 'cytoscape';

export const CONCENTRIC_LAYOUT_OPTIONS: ConcentricLayoutOptions = {
  name: 'concentric',
  // @ts-ignore this is valid cytoscape logic but types doesn't know that ¯\_(ツ)_/¯
  concentric: (node) => node.data('degree'),
  levelWidth: () => 1,
  animate: true,
  animationDuration: 1000,
  animationEasing: 'ease-out-quint',
  nodeDimensionsIncludeLabels: true,
  avoidOverlap: true,
  // TODO: figure out how to get 'spring' animation to work
  // animationEasing: 'spring',
};

export const GRID_LAYOUT_OPTIONS: GridLayoutOptions = {
  name: 'grid',
  animate: true,
  nodeDimensionsIncludeLabels: true,
  avoidOverlap: true,
  condense: true,
  sort: (a, b) => b.data('weight') - a.data('weight'),
};

export const BASE_CYTOSCAPE_OPTIONS: CytoscapeOptions = {
  minZoom: 0.15,
  layout: CONCENTRIC_LAYOUT_OPTIONS,
  userZoomingEnabled: false,
};

export const BASE_NODE_STYLES = {
  width: '32px',
  height: '32px',
  'border-width': '2px',
};

export const BASE_EDGE_STYLES = {
  'curve-style': 'haystack' as const,
  'haystack-radius': 0,
  width: 2,
};

export const BASE_LABEL_STYLES: Partial<
  Css.Labels<NodeSingular | EdgeSingular>
> = {
  'font-size': '6px',
  'text-wrap': 'ellipsis',
  'text-max-width': '64px',
  'text-background-color': 'white',
  'text-background-opacity': 1,
  'text-background-padding': '2px',
  'text-background-shape': 'roundrectangle',
  'min-zoomed-font-size': 6,
} as const;

export const MIN_SPACING_FACTOR = 1;
export const MAX_SPACING_FACTOR = 5;
