import { NetworkAnalysisLegacy } from 'app/modules/networkAnalysis/components/NetworkAnalysisLegacy';
import { NetworkAnalysis } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysis';
import { NetworkAnalysisProps } from 'app/modules/networkAnalysis/types';
import { useSelector } from 'react-redux';
import { selectNetworkAnalysisOnDeltaLake } from 'app/shared/featureFlags/selectors';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';

export const NetworkAnalysisWrapper = ({ entity }: NetworkAnalysisProps) => {
  const linkAnalysisOnDeltaLake = useSelector(selectNetworkAnalysisOnDeltaLake);

  return linkAnalysisOnDeltaLake ? (
    <NetworkAnalysis
      baseObject={entity}
      baseObjectType={BaseObjectType.ENTITY}
    />
  ) : (
    <NetworkAnalysisLegacy entity={entity} />
  );
};
