import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { selectDLEnabledForAlertHistogram } from 'app/shared/featureFlags/selectors';
import { EventHistogram } from 'app/modules/histogram/models';
import { GetAlertHistogramPayload } from 'app/modules/alerts/requests';

export const useGetHistogram = (payload: GetAlertHistogramPayload) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasDLHistogramEnabled = useSelector(selectDLEnabledForAlertHistogram);

  return useQuery({
    queryKey: ALERT_QUERY_KEYS.getHistogram(payload),
    queryFn: () => post<EventHistogram>('/alerts/cached/histogram', payload),
    meta: { errorMessage: 'Failed to load histogram.' },
    enabled: hasReadAlertsPermission && hasDLHistogramEnabled,
    staleTime: Infinity,
  });
};
