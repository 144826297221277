import { U21EncryptedFormField } from 'app/shared/u21-ui/components';
import { useEncryptedFieldProps } from 'app/shared/hooks/useEncryptedFieldProps';
import styled from 'styled-components';
import { NARRATIVE_CONTENT_FIELD } from 'app/modules/narratives/schema';

export interface ArticleInfo {
  isEncrypted: boolean;
  id: number;
  type: 'alert' | 'case';
}

interface OwnProps {
  id: number;
  readOnly: boolean;
  articleInfo: ArticleInfo;
}

export const EncryptedNarrativeDetail = ({
  id,
  readOnly,
  articleInfo,
}: OwnProps) => {
  const encryptedFieldProps = useEncryptedFieldProps(
    articleInfo.type,
    articleInfo.id.toString(),
  );

  return (
    <StyledU21EncryptedFormField
      name={NARRATIVE_CONTENT_FIELD.name}
      type="TEXTAREA"
      fieldProps={{
        id: id.toString(),
        inputProps: { readOnly },
        encryptedFieldProps,
      }}
    />
  );
};

const StyledU21EncryptedFormField = styled(U21EncryptedFormField)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;
