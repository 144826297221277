import { ReactNode } from 'react';

// Components
import { Sidebar } from 'app/modules/sidebar/components/Sidebar';
import TopBar from 'app/modules/navigator/components/TopBar';
import Leftnav from 'app/modules/navigator/components/Leftnav';

// Style
import styled, { css } from 'styled-components';
import { isNewDesignEnabled } from 'vendor/material-minimal/featureFlag';

interface Props {
  children: ReactNode;
}

export const GridLayout = ({ children }: Props) => {
  return (
    <Container>
      <Leftnav />
      <TopBar />
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    'LeftNav TopBar TopBar'
    'LeftNav Content RightSlider';
  height: 100vh;
  width: 100vw;
  /* do not use fixed height in pdf exports */
  @media print {
    height: auto;
    width: auto;
  }
`;

const Content = styled.div`
  grid-area: Content;
  ${() =>
    isNewDesignEnabled()
      ? css`
          overflow: hidden;
        `
      : css`
          overflow-y: auto;
          overflow-x: hidden;
        `}
`;
