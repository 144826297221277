// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import { EventState, EventActionTypes } from 'app/shared/events/models';

// Actions
import { EventsActions } from 'app/shared/events/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<EventState> = {
  actionEvents: {
    count: 0,
    action_events: [],
  },
};

deepFreeze(initialState);

const reducer: Reducer<EventState> = (
  state = initialState,
  action: EventsActions,
) => {
  return produce(state, (draft: Draft<EventState>) => {
    switch (action.type) {
      case EventActionTypes.RETRIEVE_ACTION_EVENTS_SUCCESS:
        draft.actionEvents = action.payload;
        return;
    }
  });
};

export { reducer as eventsReducer, initialState };
