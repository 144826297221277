// Helper for creating props for encrypted fields
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { U21EncryptedFieldProps } from 'app/shared/u21-ui/components';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAgentOrgID } from 'app/modules/session/selectors';

export const ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN = (() => {
  const url = getFromProcessEnv('TOKENIZATION_SERVICE_URL');
  if (!url) return 'http://localhost:8001';

  // Add protocol if missing
  return url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `https://${url}`;
})();

// Define outside of the function to avoid re-renders
export const authTokenFetcher = () => {
  return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) ?? '';
};

// Define outside of the function to avoid re-renders
// Will keep the same reference across renders
const encryptedFieldPropsValue = {
  iframeHost: ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN,
  authTokenFetcher,
};

export const useEncryptedFieldProps = (
  fieldType: 'alert' | 'case' | 'sar',
  id: U21EncryptedFieldProps['id'],
): U21EncryptedFieldProps => {
  const orgId = useSelector(selectAgentOrgID);
  return useMemo(() => {
    return { ...encryptedFieldPropsValue, orgId, fieldType, id };
  }, [orgId, fieldType, id]);
};
