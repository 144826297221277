import { FC, ReactNode } from 'react';

// Components
import {
  U21Button,
  U21CopyButton,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconExternalLink } from '@u21/tabler-icons';
import styled from 'styled-components';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';

export interface DataProps {
  label: ReactNode;
  value: ReactNode;
  id: number | string;
  route?: string;
}

interface OwnProps {
  list: DataProps[];
}

export const SidebarExternalLinkList: FC<OwnProps> = ({ list }) => {
  if (!list.length) {
    return null;
  }
  return (
    <U21Spacer>
      {list.map((item) => {
        return (
          <SidebarDataRow
            key={item.id}
            fullWidth
            label={item.label}
            showEmptyFields
          >
            <StyledU21Spacer align="start" horizontal>
              <StyledU21Typography ellipsis variant="body2">
                {item.value}
              </StyledU21Typography>

              {typeof item.value === 'string' && (
                <U21CopyButton text={item.value} />
              )}

              {item.route && (
                <div>
                  <U21Button variant="outlined" size="small" to={item.route}>
                    <IconExternalLink />
                  </U21Button>
                </div>
              )}
            </StyledU21Spacer>
          </SidebarDataRow>
        );
      })}
    </U21Spacer>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  flex: 1;
  justify-content: space-between;
`;

const StyledU21Typography = styled(U21Typography)`
  flex: 1;
`;
