import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import { addQueuedRequest } from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import { useDispatch } from 'react-redux';

const queueEntityNetworkAnalysis = (payload: string) =>
  post<{ entity_external_id: string }>(`/entities/network-analysis/queue`, {
    entity_external_id: payload,
  });

const queueInstrumentNetworkAnalysis = (payload: string) =>
  post<{ instrument_external_id: string }>(
    `/instruments/network-analysis/queue`,
    {
      instrument_external_id: payload,
    },
  );

export const useQueueNetworkAnalysis = (
  externalId: string,
  baseObjectType: BaseObjectType,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation<
    { instrument_external_id: string } | { entity_external_id: string },
    Error,
    string
  >({
    mutationFn:
      baseObjectType === BaseObjectType.INSTRUMENT
        ? queueInstrumentNetworkAnalysis
        : queueEntityNetworkAnalysis,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisStatusKey(
          externalId,
          baseObjectType,
        ),
      });
      dispatch(addQueuedRequest(externalId));
    },
    onError: () => {
      dispatch(
        sendErrorToast('Failed to queue network analysis. Please try again.'),
      );
    },
  });
};
