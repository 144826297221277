// Components
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { RULE_GRAPH_CYTOSCAPE_OPTIONS } from 'app/modules/detectionModels/constants';
import {
  U21NetworkGraph,
  U21NetworkGraphElements,
} from 'app/shared/components/Graphs/U21NetworkGraph';
import { U21Section } from 'app/shared/u21-ui/components';
import { EventObject } from 'cytoscape';

// Selectors
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

interface Props {
  graph: U21NetworkGraphElements;
  subheader?: string;
}

export const RuleGraph = ({ graph, subheader }: Props) => {
  const dispatch = useDispatch();

  const fullSubheader = subheader
    ? `Press and hold CTRL to zoom\n${subheader}`
    : 'Press and hold CTRL to zoom';

  const handleGraphClick = (e: EventObject) => {
    const { entityId, instrumentId } = e.target.data();
    if (entityId) {
      dispatch(
        toggleSidebar({
          data: { id: 0, externalId: entityId },
          type: SidebarComponentTypes.ENTITY,
        }),
      );
    } else if (instrumentId) {
      dispatch(
        toggleSidebar({
          data: { id: instrumentId },
          type: SidebarComponentTypes.INSTRUMENT,
        }),
      );
    }
  };

  return (
    <U21Section
      title="Rule Graph"
      subheader={<StyledSpan>{fullSubheader}</StyledSpan>}
    >
      <U21NetworkGraph
        elements={graph}
        cytoscapeOptions={RULE_GRAPH_CYTOSCAPE_OPTIONS}
        handleGraphClick={handleGraphClick}
        height={500}
      />
    </U21Section>
  );
};

const StyledSpan = styled.span`
  white-space: pre;
`;
