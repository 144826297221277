import {
  forwardRef,
  Fragment,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import useForkRef from '@mui/utils/useForkRef';
import useId from '@mui/utils/useId';
import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import {
  ClearButton,
  Loading,
  StartIconContainer,
  StyledTextField,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import { DebouncedInput } from 'app/shared/u21-ui/components/input/text-field/DebouncedInput';
import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import { InputAdornment } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import {
  INPUT_TYPES,
  INVALID_KEYS,
  TYPES,
  U21TextFieldProps,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';

export const U21TextField = forwardRef<HTMLDivElement, U21TextFieldProps>(
  (props: U21TextFieldProps, ref) => {
    const {
      autoFocus = false,
      clearable = true,
      delay,
      disabled,
      endIcon,
      error,
      focused,
      id: idProp,
      InputLabelProps = {},
      inputProps = {},
      InputProps = {},
      inputRef,
      label,
      loading = false,
      minRows = 2,
      onChange,
      onBlur,
      onKeyDown,
      onKeyPress,
      placeholder,
      required,
      responsiveLength,
      size = 'small',
      startIcon,
      textTransform,
      type = TYPES.TEXT,
      value,
      valueTransform,
      name,
      ...rest
    } = props;
    const id = useId(idProp);
    const isTextArea = type === TYPES.TEXTAREA;
    const isNumber = type === TYPES.NUMBER || type === TYPES.INTEGER;
    const hasClearIcon =
      clearable && !disabled && (value === 0 || Boolean(value)) && !isTextArea;

    const [showPassword, setShowPassword] = useState(false);

    const internalInputRef = useRef<HTMLInputElement>(null);
    const combinedInputRef = useForkRef(inputRef, internalInputRef);

    const defaultEndAdornment = useMemo(() => {
      const adornments = [
        hasClearIcon && (
          <ClearButton
            key="clear"
            onClick={(e) => {
              onChange(undefined, e);
              internalInputRef.current?.focus();
            }}
          />
        ),
        loading && <Loading key="loading" loading />,
        type === TYPES.PASSWORD && (
          <AdornmentButton
            key="reveal"
            aria-label={showPassword ? 'Hide Password' : 'Show Password'}
            icon={
              showPassword ? <IconEyeOff size={20} /> : <IconEye size={20} />
            }
            onClick={() => setShowPassword(!showPassword)}
          />
        ),
        <Fragment key="end-icon">{endIcon}</Fragment>,
      ].filter(Boolean);
      if (adornments.length > 0) {
        return <InputAdornment position="end">{adornments}</InputAdornment>;
      }
      return undefined;
    }, [
      endIcon,
      hasClearIcon,
      loading,
      onChange,
      setShowPassword,
      showPassword,
      type,
    ]);

    const defaultStartAdornment = useMemo(() => {
      if (startIcon) {
        return (
          <InputAdornment position="start">
            <StartIconContainer $disabled={disabled}>
              {startIcon}
            </StartIconContainer>
          </InputAdornment>
        );
      }
      return undefined;
    }, [disabled, startIcon]);

    const [key, forceUpdate] = useReducer((x) => x + 1, 0);

    return (
      <StyledTextField
        $loading={loading}
        $responsiveLength={responsiveLength}
        $textarea={isTextArea}
        $textTransform={textTransform}
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        focused={focused}
        fullWidth={!responsiveLength}
        id={id}
        inputProps={{
          delay,
          element: type === TYPES.TEXTAREA ? TextareaAutosize : undefined,
          inputKey: key,
          responsiveLength,
          ...(type === TYPES.TEXTAREA ? { minRows } : {}),
          ...inputProps,
          onWheel: (e) => {
            if (isNumber) {
              e.currentTarget.blur();
            }
            inputProps.onWheel?.(e);
          },
        }}
        InputLabelProps={{ htmlFor: id, id: `${id}-label`, ...InputLabelProps }}
        InputProps={{
          ...InputProps,
          inputComponent: DebouncedInput,
          endAdornment: InputProps.endAdornment || defaultEndAdornment,
          startAdornment: InputProps.startAdornment || defaultStartAdornment,
        }}
        inputRef={combinedInputRef}
        label={label}
        multiline={isTextArea}
        name={name}
        onBlur={(e) => {
          onBlur?.(e);
          if (valueTransform) {
            const transformedValue = valueTransform(e.target.value);
            // force update is needed if the value isn't changed so react doesn't
            // rerender causing the input element showing an outdated value
            if (value === transformedValue && e.target.value !== value) {
              forceUpdate();
            }
            onChange(transformedValue, e);
          }
        }}
        onChange={(e) => {
          const { value: newValue } = e.target;

          if (isNumber) {
            onChange(newValue ? Number(newValue) : undefined, e);
            return;
          }
          if (textTransform === 'uppercase') {
            onChange(newValue.toUpperCase(), e);
          } else if (textTransform === 'lowercase') {
            onChange(newValue.toLowerCase(), e);
          } else {
            onChange(newValue, e);
          }
        }}
        onKeyPress={(e) => {
          if (
            (INVALID_KEYS.includes(e.key) && isNumber) ||
            (e.key === '.' && type === TYPES.INTEGER)
          ) {
            e.preventDefault();
          }
          onKeyPress?.(e);
        }}
        onKeyDown={(e) => {
          if (isNumber && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
            e.preventDefault();
          }
          onKeyDown?.(e);
        }}
        ref={ref}
        required={required}
        placeholder={placeholder}
        size={size}
        type={
          INPUT_TYPES[
            type === TYPES.PASSWORD && showPassword ? TYPES.TEXT : type
          ]
        }
        value={value ?? ''}
        // use ref value instead because select + datepickers don't use value prop
        // title is not needed for textarea
        title={isTextArea ? undefined : internalInputRef.current?.value}
        {...getDOMProps(rest)}
      />
    );
  },
);
