// Components
import { Label, SemanticCOLORS, SearchResultProps } from 'semantic-ui-react';
import { U21Typography } from 'app/shared/u21-ui/components';

// Constants
import { ACTIVE_STATUSES, INACTIVE_STATUSES } from 'app/shared/constants';

// Styles
import styles from 'app/modules/search/styles/TopSearchBarResult.module.scss';
import styled from 'styled-components';

const TopSearchBarResult = ({
  title,
  description = '',
  icon,
  label,
}: SearchResultProps) => {
  let labelColor: SemanticCOLORS = 'grey';
  if (label && ACTIVE_STATUSES.includes(label)) {
    labelColor = 'green';
  }
  if (label && INACTIVE_STATUSES.includes(label)) {
    labelColor = 'red';
  }

  return (
    <Container className={styles.resultRenderer}>
      <div className={styles.container}>
        <StyledU21Typography variant="body2">{title}</StyledU21Typography>
        <StyledU21Typography color="text.secondary" variant="caption">
          {description}
        </StyledU21Typography>
      </div>
      <div className={styles.labels}>
        {label && (
          <Label color={labelColor} className={styles.match}>
            {label}
          </Label>
        )}
        {icon}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const StyledU21Typography = styled(U21Typography)`
  white-space: pre-wrap;
`;

export default TopSearchBarResult;
