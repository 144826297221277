import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

// Components
import {
  U21Select,
  U21Section,
  U21Loading,
  U21NoData,
} from 'app/shared/u21-ui/components';
import { IconAlertTriangle } from '@u21/tabler-icons';

// Helpers
import { formatEntityOptions } from 'app/modules/search/helpers';

// Selectors
import { selectAlertAssociatedEntities } from 'app/modules/alerts/selectors';
import { selectEntityIdsWithPIIOverlap } from 'app/modules/entities/selectors';
import { NetworkAnalysisWrapper as NetworkAnalysisComponent } from 'app/modules/networkAnalysis/components/NetworkAnalysisWrapper';

export const NetworkAnalysis = () => {
  const alertAssociatedEntities = useSelector(selectAlertAssociatedEntities);
  const [selectedEntityId, setSelectedEntityId] = useState<
    number | string | undefined
  >(alertAssociatedEntities[0]?.id);
  const entityIdsWithPIIOverlap = useSelector(selectEntityIdsWithPIIOverlap);
  const hasMultipleEntities = alertAssociatedEntities.length > 1;

  const entityOptions = useMemo(
    () =>
      formatEntityOptions(alertAssociatedEntities).map((opt) =>
        entityIdsWithPIIOverlap.has(opt.value as number)
          ? {
              ...opt,
              icon: <IconAlertTriangle />,
            }
          : opt,
      ),
    [alertAssociatedEntities, entityIdsWithPIIOverlap],
  );

  useEffect(() => {
    if (alertAssociatedEntities.length) {
      setSelectedEntityId(alertAssociatedEntities[0].id);
    }
  }, [alertAssociatedEntities]);

  const selectedEntity = useMemo(
    () => alertAssociatedEntities.find((e) => e.id === selectedEntityId),
    [alertAssociatedEntities, selectedEntityId],
  );

  if (!selectedEntityId) {
    return (
      <U21Section>
        <U21Loading loading variant="graphic" />
      </U21Section>
    );
  }

  if (!selectedEntity) {
    return <U21NoData />;
  }

  return (
    <>
      {hasMultipleEntities && (
        <SelectContainer>
          <U21Select
            label="Network analysis for entity"
            clearable={false}
            onChange={(entityId: number | string) => {
              if (entityId) {
                setSelectedEntityId(entityId);
              }
            }}
            options={entityOptions}
            value={selectedEntityId}
          />
        </SelectContainer>
      )}
      <NetworkAnalysisComponent entity={selectedEntity} />
    </>
  );
};

const SelectContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `};
`;
