import { ExternalSourceAnnotation } from 'app/modules/investigations/components/AiInvestigation/Annotation/ExternalSourceAnnotation';
import { InternalSourceAnnotation } from 'app/modules/investigations/components/AiInvestigation/Annotation/InternalSourceAnnotation';
import {
  AiInvestigationExternalSource,
  AiInvestigationInternalSource,
} from 'app/modules/investigations/types/responses';
import { alpha } from '@mui/system/colorManipulator';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { AiInvestigationParagraphWithSource } from 'app/modules/investigations/models';

interface OwnProps {
  paragraphWithSource: AiInvestigationParagraphWithSource;
  internalSourceData: Map<number, AiInvestigationInternalSource>;
  externalSourceData: Map<number, AiInvestigationExternalSource>;
  isSourcesHovered: boolean;
  currentSources: number[];
  setCurrentSources: (sourceNumbers: number[]) => void;
}

export const SentenceWithSources = ({
  paragraphWithSource,
  internalSourceData,
  externalSourceData,
  isSourcesHovered,
  currentSources,
  setCurrentSources,
}: OwnProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const { sentence, sources } = paragraphWithSource;

  const currentSourcesSet = useMemo(
    () => new Set(currentSources),
    [currentSources],
  );

  return (
    <>
      <StyledSpan
        onMouseEnter={() => {
          setIsHovering(true);
          setCurrentSources(sources);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          setCurrentSources([]);
        }}
        $isCurrHover={
          (isSourcesHovered || isHovering) &&
          sources.some((value) => currentSourcesSet.has(value))
        }
      >
        {sentence}
      </StyledSpan>

      {sources.map((sourceNumber) => {
        const internalSource = internalSourceData.get(sourceNumber);
        const externalSource = externalSourceData.get(sourceNumber);

        if (internalSource === undefined && externalSource === undefined) {
          return null;
        }

        if (internalSource) {
          return (
            <InternalSourceAnnotation
              sourceNumber={sourceNumber}
              sourceData={internalSource}
              key={sourceNumber}
              isHovering={
                (isSourcesHovered || isHovering) &&
                currentSources.includes(sourceNumber)
              }
              setIsHovering={(isCurrentlyHovering) => {
                setIsHovering(isCurrentlyHovering);
                setCurrentSources(isCurrentlyHovering ? [sourceNumber] : []);
              }}
            />
          );
        }

        return (
          <ExternalSourceAnnotation
            sourceNumber={sourceNumber}
            sourceData={externalSource!} // Either internal source or external source will be defined. Hence if internal source is undefined, external source will be defined.
            key={sourceNumber}
            isHovering={
              (isSourcesHovered || isHovering) &&
              currentSources.includes(sourceNumber)
            }
            setIsHovering={(isCurrentlyHovering) => {
              setIsHovering(isCurrentlyHovering);
              setCurrentSources(isCurrentlyHovering ? [sourceNumber] : []);
            }}
          />
        );
      })}
    </>
  );
};

const StyledSpan = styled.span<{ $isCurrHover: boolean }>`
  background-color: ${(props) =>
    props.$isCurrHover
      ? alpha(props.theme.palette.colors.purple.main, 0.08)
      : 'inherit'};
`;
