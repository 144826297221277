import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { IconFlag, IconLayoutColumns } from '@u21/tabler-icons';

import {
  U21Section,
  U21TitleCountLabel,
  U21Button,
  U21Loading,
  U21Table,
  U21_NO_VALUE,
  U21ChipColor,
  U21TablePreference,
  U21TableState,
  U21TableAction,
  U21TableColumnTypes,
  U21ButtonColor,
  U21LoadError,
} from 'app/shared/u21-ui/components';

// Models
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';
import { selectTxnTableConfig } from 'app/shared/CustomConfig/selectors';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';
import { selectCanResolveTransactions } from 'app/modules/transactions/selectors';
import {
  selectAlertComponentActionTriggers,
  selectHasReadEventsPermission,
} from 'app/modules/session/selectors';

// Actions
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';
import { useChangeAlertComponentStatus } from 'app/modules/alerts/queries/useChangeAlertComponentStatus';
import { useGetAlertTransactions } from 'app/modules/alerts/queries/useGetAlertTransactions';

// Utils
import {
  createTableColumnConfig,
  createPaginationPayload,
} from 'app/shared/utils/table';
import {
  TRANSACTION_COLUMN_CONFIG,
  TransactionTableColumn,
} from 'app/modules/transactions/columns';
import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { DEFAULT_TABLE_PREFRENCES } from 'app/shared/u21-ui/components/display/table/constants';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { useQueryClient } from '@tanstack/react-query';
import { selectSidebarTransactionID } from 'app/modules/sidebar/selectors';
import { useToggleTransactionSidebar } from 'app/modules/sidebar/hooks';

export const FlaggedTransactionsTable = () => {
  const dispatch = useDispatch();
  const transactionTableConfig = useSelector(selectTxnTableConfig);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const canResolveTransactions = useSelector(selectCanResolveTransactions);
  const sidebarTransactionID = useSelector(selectSidebarTransactionID);

  const toggleTransactionSidebar = useToggleTransactionSidebar();

  const newColumns = [
    ...createTableColumnConfig<ShortTxnResponse>(
      transactionTableConfig,
      TRANSACTION_COLUMN_CONFIG,
    ),
    ...(canResolveTransactions ? [EVENT_RESOLUTION_COLUMN] : []),
  ];

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.ALERT_TRANSACTION_TABLE_PREFERENCES,
      DEFAULT_TABLE_PREFRENCES,
    );

  const { id, total_events: totalEvents } = useSelector(selectAlert);
  const { showCurrentAlert } = useSelector(selectLinkAnalysisFilters);

  const [selected, setSelected] = useState<(number | string)[]>([]);
  const [tableState, setTableState] = useState<U21TableState>({
    page: 1,
    pageSize: tablePreferences.pageSize,
    sortBy: tablePreferences.sortBy,
  });

  useEffect(() => {
    setSelected([]);
  }, [showCurrentAlert]);

  const payload = useMemo(
    () => createPaginationPayload(tableState),
    [tableState],
  );

  const highlighted = useMemo(
    () => (sidebarTransactionID ? [sidebarTransactionID] : undefined),
    [sidebarTransactionID],
  );

  const {
    data: txns = [],
    isError,
    isLoading: transactionsLoading,
    isRefetching,
    refetch,
  } = useGetAlertTransactions(id, payload);

  const {
    isPending: changeAlertComponentStatusPending,
    mutateAsync: changeAlertComponentStatus,
  } = useChangeAlertComponentStatus();

  const canResolveCurrentTransactions =
    canResolveTransactions && showCurrentAlert;

  const actionTriggers = useSelector(selectAlertComponentActionTriggers);

  const queryClient = useQueryClient();

  const actions = useMemo<U21TableAction[]>(() => {
    return actionTriggers.map((trigger) => ({
      label: trigger.label,
      onClick: async () => {
        try {
          await changeAlertComponentStatus({
            alertId: id,
            events: selected,
            action_trigger_id: trigger.id,
          });
          queryClient.invalidateQueries({
            queryKey: ALERT_QUERY_KEYS.getAlertTransactions(id, payload, true),
          });
          queryClient.invalidateQueries({
            queryKey: ALERT_QUERY_KEYS.getAlertTransactions(id, payload, false),
          });
          setSelected([]);
        } catch {}
      },
      color: ACTION_BUTTON_COLORS[trigger.color],
      variant: 'contained',
      loading: changeAlertComponentStatusPending,
    }));
  }, [
    actionTriggers,
    changeAlertComponentStatus,
    changeAlertComponentStatusPending,
    id,
    payload,
    queryClient,
    selected,
  ]);

  if (id <= 0) {
    return <U21Loading loading />;
  }

  return (
    <U21Section
      titleIcon={<IconFlag />}
      action={
        <U21Button
          onClick={() => {
            dispatch(
              toggleTableConfigSidebar({
                tableConfigType: TableConfigType.TXN_TABLE,
              }),
            );
          }}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        <U21TitleCountLabel count={totalEvents} label="transaction">
          Flagged Transactions
        </U21TitleCountLabel>
      }
    >
      {isError ? (
        <U21LoadError label="transactions" onTryAgain={() => refetch()} />
      ) : (
        <U21Table
          actions={canResolveCurrentTransactions ? actions : []}
          columns={newColumns}
          count={totalEvents}
          data={txns}
          defaultColumnWidths={tablePreferences.columnWidths}
          defaultPageSize={tablePreferences.pageSize}
          defaultSortBy={tablePreferences.sortBy}
          highlighted={highlighted}
          label="transaction"
          loading={transactionsLoading}
          manualPagination
          onPreferenceChange={(value) => setTablePreferences(value)}
          onRefresh={() => refetch()}
          onRowClick={
            hasReadEventsPermission
              ? (txnId: number | string, _, e) => {
                  toggleTransactionSidebar(txnId, e);
                }
              : undefined
          }
          onRowSelect={(ids) => setSelected(ids)}
          onStateChange={setTableState}
          refreshLoading={isRefetching}
          selectable={canResolveCurrentTransactions}
          selected={selected}
        />
      )}
    </U21Section>
  );
};

const EVENT_RESOLUTION_COLUMN: TransactionTableColumn = {
  id: 'event_resolution_field',
  type: U21TableColumnTypes.LABEL,
  accessor: (row: ShortTxnResponse) => getEventResolution(row.resolution),
  cellProps: (row: ShortTxnResponse) => {
    return { color: getEventResolutionColor(row.resolution) };
  },
  Header: 'Result',
};

const getEventResolution = (resolution: string | undefined): string => {
  switch (resolution) {
    case 'FALSE_POSITIVE':
      return 'Rejected';
    case 'TRUE_POSITIVE':
      return 'Accepted';
    case undefined:
      return U21_NO_VALUE;
    default:
      return 'Unresolved';
  }
};

const getEventResolutionColor = (
  resolution: string | undefined,
): U21ChipColor => {
  switch (resolution) {
    case 'FALSE_POSITIVE':
      return 'error';
    case 'TRUE_POSITIVE':
      return 'success';
    default:
      return 'default';
  }
};

const ACTION_BUTTON_COLORS: Record<string, U21ButtonColor> = {
  green: 'success',
  red: 'error',
};
