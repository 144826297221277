import { IconProps } from 'app/shared/components/Icons/models';

export const IconParentChild = ({
  size = 20,
  color = 'currentColor',
  stroke = 1,
  ...rest
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={stroke}
    >
      <path d="M4.87516 6.49996C6.07178 6.49996 7.04183 5.52991 7.04183 4.33329C7.04183 3.13668 6.07178 2.16663 4.87516 2.16663C3.67855 2.16663 2.7085 3.13668 2.7085 4.33329C2.7085 5.52991 3.67855 6.49996 4.87516 6.49996Z" />
      <path d="M9.47917 8.12496C10.2271 8.12496 10.8333 7.51868 10.8333 6.77079C10.8333 6.02291 10.2271 5.41663 9.47917 5.41663C8.73128 5.41663 8.125 6.02291 8.125 6.77079C8.125 7.51868 8.73128 8.12496 9.47917 8.12496Z" />
      <path d="M1.625 11.375V10.2917C1.625 9.71703 1.85327 9.16593 2.2596 8.7596C2.66593 8.35327 3.21703 8.125 3.79167 8.125H5.95833C6.53297 8.125 7.08407 8.35327 7.4904 8.7596C7.89673 9.16593 8.125 9.71703 8.125 10.2917V11.375" />
      <path d="M11.9168 11.6459V10.6343C11.915 10.3073 11.8077 9.99015 11.6118 9.73223C11.4158 9.4743 11.1422 9.29011 10.8335 9.20837" />
    </g>
  </svg>
);
