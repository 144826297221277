import { getDOMProps } from 'app/shared/utils/react';
import { isValidElement, ReactElement, ReactNode, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import styled from 'styled-components';

import { Redirect, Route, Switch } from 'react-router-dom';
import { Tab } from '@mui/material';
import { U21Divider } from 'app/shared/u21-ui/components/display/U21Divider';
import {
  U21TabItem,
  U21Tabs,
  U21TabsProps,
} from 'app/shared/u21-ui/components/layout/U21Tabs';

export interface U21TabRouterItem
  extends Omit<U21TabItem<string>, 'link' | 'value'> {
  component: ReactNode;
  exact?: boolean;
  path: string;
  routePath?: string;
}

export interface U21TabRouterProps
  extends Omit<U21TabsProps<string>, 'defaultValue' | 'tabs' | 'value'> {
  defaultRoute?: string;
  tabs: (U21TabRouterItem | ReactElement)[];
}

export const U21TabRouter = (props: U21TabRouterProps) => {
  const { defaultRoute, onTabChange, tabs, action, onTabsCustomize, ...rest } =
    props;
  const { pathname, search } = useLocation();

  // filter out non-tabs (ReactElement) and disabled tabs
  const validTabs = useMemo(
    () =>
      tabs.filter((i): i is U21TabRouterItem => {
        if (isValidElement<any>(i)) {
          return false;
        }
        return !i.disabled;
      }),
    [tabs],
  );

  const validShownTabs = useMemo(
    () => validTabs.filter((i) => !i.hidden),
    [validTabs],
  );

  // if no default route, find first visible enabled tab
  // if no visible enabled tab, find first hidden enabled tab
  const defaultPath =
    defaultRoute || validShownTabs[0]?.path || validTabs[0]?.path;

  const currentTabValue = useMemo(() => {
    const foundedTab = validTabs.find((i) => {
      const { exact = true, path } = i;
      return matchPath(pathname, { path, exact });
    });
    return foundedTab?.path;
  }, [pathname, validTabs]);

  const tabsTabs = useMemo(
    () =>
      tabs.map<U21TabItem<string> | ReactElement>((i) => {
        if (isValidElement<any>(i)) {
          return i;
        }
        const { component, exact, path, routePath, ...restTab } = i;
        return {
          ...restTab,
          search,
          value: path,
          link: true,
        };
      }),
    [search, tabs],
  );

  return (
    <>
      <U21Tabs
        action={action}
        onTabChange={onTabChange}
        onTabsCustomize={onTabsCustomize}
        tabs={tabsTabs}
        value={currentTabValue || defaultPath}
        {...getDOMProps(rest)}
      />
      <Switch>
        {validTabs.map((i) => {
          const { component, path, routePath, exact = true } = i;
          return (
            <Route key={path} exact={exact} path={routePath || path}>
              {component}
            </Route>
          );
        })}
        {defaultPath && (
          <Route>
            <Redirect to={defaultPath} />
          </Route>
        )}
      </Switch>
    </>
  );
};

const DividerTab = styled(Tab)`
  min-width: 0;

  &&& {
    margin: 0 12px;
  }
  .MuiTab-wrapper {
    height: 100%;
  }
`;

// Tabs children must be Tab so wrap divider in a Tab as a hack
U21TabRouter.Divider = () => (
  <DividerTab icon={<U21Divider />} disabled role="separator" />
);
