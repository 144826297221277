// Components
import { IconSwitchHorizontal, IconUsers } from '@u21/tabler-icons';

import {
  U21TableColumn,
  U21TableBodyCellProps,
  U21Chip,
} from 'app/shared/u21-ui/components';

// Types
import {
  RelationshipConfigResponse,
  EntityToEntityRelationshipConfigResponse,
} from 'app/modules/relationships/types';

// Models
import {
  DegreeType,
  RelatedEntityDirectedness,
} from 'app/modules/relationships/models';
import { IconParentChild } from 'app/shared/components/Icons/IconParentChild';
import { ReactElement } from 'react';
import {
  MergedRelatedEntityResponse,
  MergedRelatedInstrumentResponse,
} from 'app/modules/relationships/types/responses';

type RelationshipConfigTableColumn = U21TableColumn<
  RelationshipConfigResponse,
  any
>;

const NAME_COLUMN: RelationshipConfigTableColumn = {
  id: 'name',
  accessor: 'name',
  Header: 'Name',
};

const DESCRIPTION_COLUMN: RelationshipConfigTableColumn = {
  id: 'description',
  accessor: 'description',
  Header: 'Description',
};

const RELATIONSHIP_ID_COLUMN: RelationshipConfigTableColumn = {
  id: 'relationship_id',
  accessor: 'external_id',
  Header: 'Relationship ID',
  disableSortBy: true,
};

const CREATED_AT_COLUMN: RelationshipConfigTableColumn = {
  id: 'created_at',
  accessor: 'created_at',
  type: 'datetime',
  Header: 'Created at',
};

const UPDATED_AT_COLUMN: RelationshipConfigTableColumn = {
  id: 'updated_at',
  accessor: 'updated_at',
  type: 'datetime',
  Header: 'Updated at',
};

const DEGREE_COLUMN: RelationshipConfigTableColumn = {
  id: 'degree',
  accessor: ({ degree }) =>
    degree === DegreeType.MANY ? 'One-to-many' : 'One-to-one',
  Header: 'Degree',
  disableSortBy: true,
  Cell: ({
    value,
  }: U21TableBodyCellProps<RelationshipConfigResponse, string>) => (
    <U21Chip>{value}</U21Chip>
  ),
};

const DIRECTED_COLUMN: RelationshipConfigTableColumn = {
  id: 'direction',
  accessor: (row) =>
    (row as EntityToEntityRelationshipConfigResponse).directed
      ? 'Directed'
      : 'Undirected',
  Header: 'Direction',
  disableSortBy: true,
  Cell: ({
    value,
  }: U21TableBodyCellProps<RelationshipConfigResponse, string>) => (
    <U21Chip>{value}</U21Chip>
  ),
};

export const RELATIONSHIP_CONFIG_COLUMN_CONFIG: Record<
  string,
  RelationshipConfigTableColumn
> = {
  [NAME_COLUMN.id]: NAME_COLUMN,
  [DESCRIPTION_COLUMN.id]: DESCRIPTION_COLUMN,
  [RELATIONSHIP_ID_COLUMN.id]: RELATIONSHIP_ID_COLUMN,
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [UPDATED_AT_COLUMN.id]: UPDATED_AT_COLUMN,
  [DEGREE_COLUMN.id]: DEGREE_COLUMN,
  [DIRECTED_COLUMN.id]: DIRECTED_COLUMN,
};

export const RELATIONSHIP_LEFTHAND_TABLE_COLUMN: U21TableColumn<
  MergedRelatedEntityResponse | MergedRelatedInstrumentResponse,
  any
>[] = [
  {
    id: 'relationship',
    accessor: '__relationship.name',
    Header: 'Relationship',
    Cell: ({
      value,
      row,
    }: U21TableBodyCellProps<
      MergedRelatedEntityResponse | MergedRelatedInstrumentResponse,
      string
    >) => <U21Chip tooltip={row.__relationship.description}>{value}</U21Chip>,
    disableSortBy: true,
  },
];

export const DIRECTEDNESS_TO_LABEL_MAP: Record<
  RelatedEntityDirectedness,
  ReactElement
> = {
  [RelatedEntityDirectedness.TO]: (
    <U21Chip icon={<IconParentChild />}>Child</U21Chip>
  ),
  [RelatedEntityDirectedness.FROM]: (
    <U21Chip icon={<IconUsers />}>Parent</U21Chip>
  ),
  [RelatedEntityDirectedness.UNDIRECTED]: (
    <U21Chip icon={<IconSwitchHorizontal />}>Undirected</U21Chip>
  ),
};

const DIRECTEDNESS_COLUMN: U21TableColumn<
  MergedRelatedEntityResponse,
  RelatedEntityDirectedness
> = {
  id: 'directedness',
  accessor: 'directedness',
  Header: 'Direction',
  Cell: ({
    value,
  }: U21TableBodyCellProps<
    MergedRelatedEntityResponse,
    RelatedEntityDirectedness
  >) => {
    return DIRECTEDNESS_TO_LABEL_MAP[value] ?? null;
  },
  disableSortBy: true,
};

export const ENTITY_RELATIONSHIP_LEFTHAND_TABLE_COLUMNS: U21TableColumn<
  MergedRelatedEntityResponse,
  any
>[] = [
  ...(RELATIONSHIP_LEFTHAND_TABLE_COLUMN as U21TableColumn<
    MergedRelatedEntityResponse,
    any
  >[]),
  DIRECTEDNESS_COLUMN,
];
