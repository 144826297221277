import React, { FC, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { formatDatetime, formatDateToNow } from 'app/shared/utils/date';
import { useAppDispatch } from 'app/store/storeHooks';

// Models
import { Narrative } from 'app/modules/narratives/models';
import { InvestigationType } from 'app/modules/investigations/models';
import { QueueType } from 'app/modules/queues/models';

// Components
import {
  U21Avatar,
  U21Button,
  U21Form,
  U21FormFieldCustom,
  U21Grid,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconClipboardList, IconFilePlus, IconTrash } from '@u21/tabler-icons';
import { DeleteNarrativeModal } from 'app/modules/narratives/components/DeleteNarrativeModal';

// Thunks/Queries
import { useAddNarrativeToRecordMutation } from 'app/modules/narratives/queries/useAddNarrativeToRecord';
import {
  deleteNarrativeThunk,
  editNarrativeThunk,
} from 'app/modules/narratives/sliceNarratives';
import { useGetDefaultQueue } from 'app/modules/queues/queries/useGetDefaultQueue';

// Helpers
import { byAgentNameHelper } from 'app/modules/narratives/utils';
import {
  createFormInitialValues,
  generateFormFieldProps,
} from 'app/shared/utils/form';
import { NARRATIVE_CONTENT_FIELD } from 'app/modules/narratives/schema';
import {
  ArticleInfo,
  EncryptedNarrativeDetail,
} from 'app/modules/narratives/components/EncryptedNarrativeDetail';
import { U21RichTextEditor } from 'app/shared/components/RichTextEditor/U21RichTextEditor';
import { RichTextEditorCommentType } from 'app/shared/components/RichTextEditor/enums';
import { editCommentNarrativeThunk } from 'app/shared/components/RichTextEditor/sliceRichTextEditor';
import { NARRATIVE_QUERY_KEYS } from 'app/modules/narratives/queries/keys';
import { useQueryClient } from '@tanstack/react-query';
import { useGetAlert } from 'app/modules/alerts/queries/useGetAlert';
import { useGetCase } from 'app/modules/cases/queries/useGetCase';

export interface NarrativeProps {
  isSidePanel?: boolean;
  narrative: Narrative;
  readOnly: boolean;
  investigationType: InvestigationType;
}

interface NarrativeFormValues {
  narrative: string;
}

export const NarrativeDetails: FC<NarrativeProps> = ({
  isSidePanel,
  narrative,
  readOnly,
  investigationType,
}) => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const defaultQueue = useGetDefaultQueue(
    investigationType === InvestigationType.ALERT
      ? QueueType.ALERT_QUEUE
      : QueueType.CASE_QUEUE,
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const {
    isPending,
    mutate: addNarrativeToRecord,
    data: recordedNarrative,
  } = useAddNarrativeToRecordMutation();

  const {
    article_id: articleID,
    id,
    created_by: createdBy,
    edited_by: editedBy,
    created_at: createdAt,
    updated_at: updatedAt,
    queue,
    added_to_record_by: addedToRecordBy,
  } = useMemo(() => {
    // If a narrative had been successfully recorded, the data in the recorded version will be returned
    if (recordedNarrative) {
      return recordedNarrative;
    }

    return narrative;
  }, [narrative, recordedNarrative]);

  const isReadOnly = useMemo<boolean>(
    () => readOnly || addedToRecordBy.id > 0,
    [addedToRecordBy.id, readOnly],
  );

  const { alert } = useGetAlert(articleID, {
    enabled: investigationType === InvestigationType.ALERT,
  });
  const { case: c } = useGetCase(articleID, {
    enabled: investigationType === InvestigationType.CASE,
  });

  const articleInfo = useMemo<ArticleInfo>(() => {
    if (investigationType === InvestigationType.ALERT) {
      return {
        isEncrypted: alert?.is_encrypted || false,
        id: alert?.id || -1,
        type: 'alert',
      };
    }
    return {
      isEncrypted: c?.is_encrypted || false,
      id: c?.id || -1,
      type: 'case',
    };
  }, [alert, c, investigationType]);

  const initialValues = useMemo(() => {
    // If a narrative had been successfully recorded, the data in the recorded version will be rendered
    if (recordedNarrative) {
      return createFormInitialValues<NarrativeFormValues>(
        [NARRATIVE_CONTENT_FIELD],
        recordedNarrative,
      );
    }

    return createFormInitialValues<NarrativeFormValues>(
      [NARRATIVE_CONTENT_FIELD],
      narrative,
    );
  }, [narrative, recordedNarrative]);

  const narrativeCreatedBy = `Created ${byAgentNameHelper(
    createdBy,
  )} on ${formatDatetime(new Date(createdAt))}`;

  const narrativeEditedBy = `Edited ${byAgentNameHelper(
    editedBy,
  )} on ${formatDatetime(new Date(updatedAt))}`;

  const narrativeAddedToRecordBy = `Added to record ${byAgentNameHelper(
    addedToRecordBy,
  )} on ${formatDatetime(new Date(updatedAt))}`;

  return (
    <div>
      {isSidePanel ? (
        <NarrativeHeaderSidePanel>
          <HeaderContent>
            <U21Typography
              ellipsis
              icon={<IconClipboardList />}
              variant="subtitle1"
            >
              {queue?.title || defaultQueue?.title || ''}
            </U21Typography>
            {!isReadOnly && (
              <U21Button
                aria-label="Delete Narrative"
                icon={<IconTrash />}
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </HeaderContent>
          <StyledU21Grid minWidth={200}>
            <TimestampContent>
              <U21Typography variant="body2">
                {`Created ${byAgentNameHelper(createdBy)}`.trim()}
              </U21Typography>
              <DateTypography
                color="text.secondary"
                tooltip={formatDatetime(createdAt)}
                variant="caption"
              >
                {formatDateToNow(createdAt, { addSuffix: true })}
              </DateTypography>
            </TimestampContent>
            <TimestampContent>
              {addedToRecordBy.id > 0 ? (
                <U21Typography variant="body2">
                  {`Added to record ${byAgentNameHelper(addedToRecordBy)}`.trim()}
                </U21Typography>
              ) : (
                <U21Typography variant="body2">
                  {`Edited ${byAgentNameHelper(editedBy)}`.trim()}
                </U21Typography>
              )}
              <DateTypography
                color="text.secondary"
                tooltip={formatDatetime(updatedAt)}
                variant="caption"
              >
                {formatDateToNow(updatedAt, { addSuffix: true })}
              </DateTypography>
            </TimestampContent>
          </StyledU21Grid>
        </NarrativeHeaderSidePanel>
      ) : (
        <NarrativeHeader>
          <U21Spacer align="start" horizontal>
            <U21Avatar name={createdBy.full_name} src={createdBy.picture} />
            <U21Spacer>
              <IconStyledTypography variant="subtitle1" color="text.secondary">
                {narrativeCreatedBy}
                <StyledIconClipboardList />
                <StyledTypography variant="subtitle1" color="text.secondary">
                  {queue?.title || defaultQueue?.title || ''}
                </StyledTypography>
              </IconStyledTypography>
              <StyledTypography variant="subtitle2" color="text.secondary">
                {addedToRecordBy.id > 0
                  ? narrativeAddedToRecordBy
                  : narrativeEditedBy}
              </StyledTypography>
            </U21Spacer>
          </U21Spacer>
          {!isReadOnly && (
            <U21Button
              aria-label="Delete Narrative"
              icon={<IconTrash />}
              onClick={() => setShowDeleteModal(true)}
            />
          )}
        </NarrativeHeader>
      )}
      <StyledDiv $readOnly={isReadOnly}>
        <U21Form
          initialValues={initialValues}
          onSubmit={(values) => {
            if (isReadOnly) {
              // Note: This will be fired when comments are added to an existing narrative
              dispatch(
                editCommentNarrativeThunk({
                  parentId: id,
                  parentType: RichTextEditorCommentType.NARRATIVE,
                  narrative: values.narrative,
                }),
              );
            } else {
              dispatch(
                editNarrativeThunk({
                  narrative_id: id,
                  narrative: values.narrative || '',
                }),
              );
            }
          }}
          autoSave
          // We want the changes to be immediately saved for comments that are added to an existing narrative
          autoSaveDelay={isReadOnly ? 0 : 3000}
        >
          {(formProps) => {
            const { values } = formProps;

            return (
              <U21Spacer spacing={0}>
                {articleInfo.isEncrypted ? (
                  <EncryptedNarrativeDetail
                    id={id}
                    readOnly={isReadOnly}
                    articleInfo={articleInfo}
                  />
                ) : (
                  <U21FormFieldCustom
                    disabled={isReadOnly}
                    {...generateFormFieldProps(NARRATIVE_CONTENT_FIELD)}
                  >
                    <U21RichTextEditor
                      isCommentShown
                      parent={{ id, type: RichTextEditorCommentType.NARRATIVE }}
                      onChange={() => {}}
                    />
                  </U21FormFieldCustom>
                )}
                {!isReadOnly && (
                  <NarrativeFooter $isEncrypted={articleInfo.isEncrypted}>
                    <U21Button
                      startIcon={<IconFilePlus />}
                      onClick={() => {
                        addNarrativeToRecord({
                          narrative_id: id,
                          narrative: values.narrative,
                          add_to_record: true,
                        });
                      }}
                      color="primary"
                      variant="contained"
                      disabled={isPending}
                      tooltip="Narratives that have been added to record can no longer be edited"
                    >
                      Add to record
                    </U21Button>
                  </NarrativeFooter>
                )}
              </U21Spacer>
            );
          }}
        </U21Form>
      </StyledDiv>
      <DeleteNarrativeModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleSubmit={async () => {
          try {
            await dispatch(deleteNarrativeThunk(id)).unwrap();
            setShowDeleteModal(false);
            queryClient.invalidateQueries({
              queryKey: NARRATIVE_QUERY_KEYS.getNarratives({
                article_id: articleID,
              }),
            });
          } catch {}
        }}
      />
    </div>
  );
};

const NarrativeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px 8px 0 0;
  background-color: ${(props) => props.theme.palette.grey[500_16]};

  ${(props) => {
    const greyStyle = `1px solid ${props.theme.palette.grey[400]}`;
    return css`
      border-top: ${greyStyle};
      border-left: ${greyStyle};
      border-right: ${greyStyle};
    `;
  }}
`;

const NarrativeHeaderSidePanel = styled.div`
  padding: 16px;
  border-radius: 8px 8px 0 0;
  background-color: ${(props) => props.theme.palette.background.neutral};
  ${(props) => {
    const greyStyle = `1px solid ${props.theme.palette.grey[400]}`;
    return css`
      border-top: ${greyStyle};
      border-left: ${greyStyle};
      border-right: ${greyStyle};
    `;
  }}
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledU21Grid = styled(U21Grid)`
  gap: 0 8px;
`;

const TimestampContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4px;
`;

const DateTypography = styled(U21Typography)`
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const StyledDiv = styled.div<{ $readOnly: boolean }>`
  ${(props) => {
    if (props.$readOnly) {
      return css`
        border: 1px solid ${props.theme.palette.grey[400]};
      `;
    }
    return css``;
  }}
`;

const StyledTypography = styled(U21Typography)`
  margin-top: 0 !important;
`;

const IconStyledTypography = styled(U21Typography)`
  .MuiTypography-root {
    display: flex;
    align-items: center;
  }
`;

const StyledIconClipboardList = styled(IconClipboardList)`
  margin-left: 32px;
`;

const NarrativeFooter = styled.div<{ $isEncrypted: boolean }>`
  display: flex;
  justify-content: end;
  padding: 12px 16px;

  ${(props) => {
    const greyStyle = `1px solid ${props.theme.palette.grey[400]}`;

    if (!props.$isEncrypted) {
      return css`
        border-bottom: ${greyStyle};
        border-left: ${greyStyle};
        border-right: ${greyStyle};
        border-radius: 0 0 8px 8px;
      `;
    }
    return css``;
  }}
`;
