import { CHIP_PINK } from 'app/modules/investigations/components/AiInvestigation/constants';
import {
  U21Chip,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';

interface OwnProps {
  sourceNumber: number;
  label: string;
  icon?: ReactElement;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
  onClick?: (e: SyntheticEvent) => void;
}

export const CoreSourceChip = ({
  sourceNumber,
  label,
  icon,
  isHovering,
  setIsHovering,
  onClick,
}: OwnProps) => {
  return (
    <StyledSpacer horizontal>
      <StyledNumberChip
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        $isCurrHover={isHovering}
        rounded
        variant="outlined"
        aria-label={`${label} sources redirect index chip`}
      >
        <StyledTypography variant="caption" color="text.secondary">
          {sourceNumber}
        </StyledTypography>
      </StyledNumberChip>

      <StyledContentChip
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        $isCurrHover={isHovering}
        onClick={onClick}
        variant="ghost"
        icon={icon}
        aria-label={`${label} sources redirect chip`}
      >
        {label}
      </StyledContentChip>
    </StyledSpacer>
  );
};

const StyledSpacer = styled(U21Spacer)`
  margin: 8px;
  margin-left: 4px;
`;

const StyledNumberChip = styled(U21Chip)<{ $isCurrHover: boolean }>`
  cursor: ${(props) => (props.$isCurrHover ? 'pointer' : 'inherit')};
  background-color: ${(props) => (props.$isCurrHover ? CHIP_PINK : 'white')};
`;

const StyledContentChip = styled(U21Chip)<{ $isCurrHover: boolean }>`
  background-color: ${(props) =>
    props.$isCurrHover ? props.theme.palette.action.hover : 'inherit'};
`;

const StyledTypography = styled(U21Typography)`
  .MuiTypography-root {
    font-size: 10px;
  }
`;
