import {
  ConsortiumHitResponse,
  ConsortiumMatchDict,
} from 'app/modules/entitiesRefresh/responses';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import {
  U21Section,
  U21Table,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { BASE_CONSORTIUM_COLUMNS } from 'app/modules/entitiesRefresh/columns';
import { U21EntityChip } from 'app/shared/u21-ui/components/dashboard';

type ConsortiumMatchWithEntity = ConsortiumMatchDict & {
  entity_external_id: string;
};

interface MyProps {
  consortiumHits: ConsortiumHitResponse[];
}

export const EntitiesConsortiumHitsTable: React.FC<MyProps> = ({
  consortiumHits,
}) => {
  const formatAmount = useSelector(selectFormatAmount);

  const allMatches: ConsortiumMatchWithEntity[] = consortiumHits
    .map((hit) =>
      // Only include the entity_external_id if the hit was found, if not there's nothing to display
      hit.found
        ? hit.matches.map((match) => ({
            entity_external_id: hit.entity_external_id,
            ...match,
          }))
        : [],
    )
    .flat();

  return (
    <U21Section title="Consortium hits">
      <U21Table
        columns={[
          {
            id: 'entity_external_id',
            Header: 'Entity',
            accessor: (row) => (
              <U21EntityChip
                externalID={row.entity_external_id}
                name={row.entity_external_id}
              />
            ),
          },
          ...(BASE_CONSORTIUM_COLUMNS as U21TableColumn<any, any>[]),
          {
            id: 'blocked_amount',
            accessor: (row) =>
              row.blocked_amount
                ? formatAmount({ amount: row.blocked_amount })
                : '',
            Header: 'Blocked amount',
          },
        ]}
        data={allMatches}
      />
    </U21Section>
  );
};
