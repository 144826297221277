import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const CONSORTIUM_SUBTYPE_BLOCKED_REASONS = 'blocked_reasons';
export const CONSORTIUM_BLOCKED_ENTITIES_VALUE = 'TRUE_POSITIVE_BLOCKED';

export const LOOKBACK_OPTIONS = [
  {
    key: '1 day',
    value: '1d',
    text: '1 day',
    minutes: 1440,
  },
  {
    key: '1 week',
    value: '1w',
    text: '1 week',
    minutes: 10_080,
  },
];
export const BLOCKED_REASON_ANY = 'any';
const BLOCKED_REASONS_OPTION_ANY = { value: BLOCKED_REASON_ANY, text: 'Any' };

export const BLOCKED_REASON_OTHER = 'Other';
export const BLOCKED_REASONS_OPTION_OTHER = {
  value: BLOCKED_REASON_OTHER,
  text: 'Other',
};

// TODO: We should get these from the disposition_type_group_option table where is_deleted is FALSE
export const BASE_BLOCKED_REASONS_OPTIONS: U21SelectOptionProps[] = [
  { value: 'Account Takeover', text: 'Account takeover' },
  { value: 'ACH Fraud', text: 'ACH fraud' },
  { value: 'Card Scam', text: 'Card scam' },
  { value: 'Chargeback', text: 'Chargeback' },
  { value: 'Check Fraud', text: 'Check fraud' },
  { value: 'Elder Abuse', text: 'Elder abuse' },
  { value: 'Fraud Victim', text: 'Fraud victim' },
  { value: 'Friendly Fraud', text: 'Friendly fraud' },
  { value: 'Money Mule', text: 'Money mule' },
  { value: 'Promotion Abuse', text: 'Promotion abuse' },
  { value: 'Social Scam', text: 'Social scam' },
  { value: 'Stolen Identity', text: 'Stolen identity' },
  { value: 'Wire Fraud', text: 'Wire fraud' },
  { value: 'Money Laundering', text: 'Money laundering' },
];

// Used in consortium rules
export const BLOCKED_REASONS_OPTIONS_WITH_ANY: U21SelectOptionProps[] = [
  BLOCKED_REASONS_OPTION_ANY,
  ...BASE_BLOCKED_REASONS_OPTIONS,
];

// Used in actioning an instrument
export const BLOCKED_REASONS_OPTIONS_WITH_OTHER: U21SelectOptionProps[] = [
  ...BASE_BLOCKED_REASONS_OPTIONS,
  BLOCKED_REASONS_OPTION_OTHER,
];

export enum ConsortiumScenarioFormFields {
  BLOCKED_REASONS = 'blockedReasons',
  EXCLUDE_BLOCKED_ENTITIES = 'excludeBlockedEntities',
  DYNAMIC_RULE_CONDITION = 'dynamicRuleCondition',
  EXECUTION_FREQUENCY = 'executionFrequency',
}
