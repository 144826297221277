import {
  EditDocumentModal,
  Props as EditDocumentModalProps,
} from 'app/modules/attachmentsRefresh/components/EditDocumentModal';
import {
  U21MenuItemProps,
  U21Spacer,
  U21Table,
  U21TableColumn,
  U21TableColumnTypes,
  U21TablePreference,
} from 'app/shared/u21-ui/components';
import { DocumentUpload } from 'app/modules/attachmentsRefresh/components/DocumentUpload';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import {
  selectHasGDriveEnabled,
  selectHasS3Enabled,
} from 'app/modules/attachments/selectors';
import {
  parseOutFilename,
  toReadableMediaType,
} from 'app/modules/attachmentsRefresh/utils';
import { STORAGE_RESOURCE_LABELS } from 'app/modules/attachmentsRefresh/constants';
import { IconDownload, IconEdit, IconTrash } from '@u21/tabler-icons';
import { downloadAttachmentThunk } from 'app/modules/attachmentsRefresh/sliceAttachments';
import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { GoogleDriveDoc } from 'app/modules/uploads/models';

interface Props {
  addDocumentLoading?: boolean;
  documents: FullAttachmentResponse[];
  id: number | string;
  onEditDocumentSuccess?: EditDocumentModalProps['onSuccess'];
  onAddGDrive?: (files: GoogleDriveDoc[]) => void;
  onDeleteDocument?: (documentID: number) => void;
  onUploadGDrive?: (files: File[]) => void;
  onUploadS3?: (files: File[]) => void;
  readOnly?: boolean;
}

export const Documents = (props: Props) => {
  const {
    addDocumentLoading,
    documents,
    id,
    onAddGDrive,
    onDeleteDocument,
    onUploadGDrive,
    onUploadS3,
    onEditDocumentSuccess,
    readOnly = false,
  } = props;
  const dispatch = useDispatch();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editDoc, setEditDoc] = useState<FullAttachmentResponse>();

  const hasGDriveEnabled = useSelector(selectHasGDriveEnabled);
  const hasS3Enabled = useSelector(selectHasS3Enabled);
  const hasMultipleStorageResourceEnabled =
    hasGDriveEnabled && onUploadGDrive && hasS3Enabled && onUploadS3;

  const columns = useMemo(() => {
    const columnsBuilder: U21TableColumn<FullAttachmentResponse, any>[] = [
      {
        type: U21TableColumnTypes.TEXT,
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        width: 75,
      },
      {
        type: U21TableColumnTypes.TEXT,
        id: 'name',
        accessor: (row) => parseOutFilename(row.name)[0],
        Header: 'Name',
      },
      {
        type: U21TableColumnTypes.TEXT,
        id: 'description',
        accessor: 'description',
        Header: 'Description',
      },
      {
        type: U21TableColumnTypes.LABEL,
        id: 'media_type',
        accessor: (row) => toReadableMediaType(row.media_type),
        Header: 'Media type',
      },
      {
        type: U21TableColumnTypes.LABEL,
        id: 'mime_type',
        accessor: 'mime_type',
        Header: 'File type',
      },
      {
        type: U21TableColumnTypes.DATETIME,
        id: 'time',
        accessor: 'created_at',
        Header: 'Uploaded at',
      },
    ];

    if (hasMultipleStorageResourceEnabled) {
      columnsBuilder.push({
        type: U21TableColumnTypes.LABEL,
        id: 'storage',
        accessor: (row) => STORAGE_RESOURCE_LABELS[row.storage_resource],
        Header: 'Storage location',
      });
    }

    columnsBuilder.push({
      id: 'actions',
      type: U21TableColumnTypes.ACTIONS,
      cellProps: (row) => {
        const items: U21MenuItemProps[] = [];
        if (row.storage_resource !== 'GDRIVE') {
          items.push({
            icon: <IconDownload />,
            text: 'Download',
            onClick: () => {
              dispatch(
                downloadAttachmentThunk({
                  id: row.id,
                  fileName: row.name,
                }),
              );
            },
          });
        }
        if (!readOnly) {
          items.push({
            icon: <IconEdit />,
            text: 'Edit',
            onClick: () => {
              setEditDoc(row);
              setOpenEditModal(true);
            },
          });
          if (onDeleteDocument) {
            items.push({
              icon: <IconTrash />,
              text: 'Delete',
              onClick: () => onDeleteDocument(row.id),
              color: 'error',
            });
          }
        }
        return { items };
      },
    });

    return columnsBuilder;
  }, [dispatch, hasMultipleStorageResourceEnabled, onDeleteDocument, readOnly]);

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.DOCUMENTS_TABLE_PREFERENCES,
    );
  return (
    <U21Spacer spacing={2}>
      {!readOnly && (
        <DocumentUpload
          currentDocumentCount={documents.length}
          onPickGDrive={onAddGDrive}
          onUploadS3={onUploadS3}
          onUploadGDrive={onUploadGDrive}
          loading={addDocumentLoading}
        />
      )}
      {documents.length > 0 && (
        <U21Table
          columns={columns}
          data={documents}
          defaultColumnWidths={tablePreferences?.columnWidths}
          defaultPageSize={tablePreferences?.pageSize}
          defaultSortBy={tablePreferences?.sortBy}
          onPreferenceChange={setTablePreferences}
          onRowClick={(_, row: FullAttachmentResponse) => {
            setEditDoc(row);
            setOpenEditModal(true);
          }}
        />
      )}
      <EditDocumentModal
        file={editDoc}
        id={id}
        onClose={() => setOpenEditModal(false)}
        onExited={() => setEditDoc(undefined)}
        onSuccess={onEditDocumentSuccess}
        open={openEditModal}
        readOnly={readOnly}
      />
    </U21Spacer>
  );
};
