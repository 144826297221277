import { post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';

export const useGetCase = (
  id: number,
  options: Pick<
    UseQueryOptions<InvestigationsCaseResponse<false>>,
    'enabled'
  > = {},
) => {
  const { enabled = true } = options;
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const query = useQuery({
    enabled: enabled && hasReadCasesPermission,
    queryKey: CASE_QUERY_KEYS.getCase(id),
    queryFn: () =>
      post<InvestigationsCaseResponse<false>>('/investigations/retrieve/case', {
        short: false,
        object_type: 'CASE',
        object_ids: [id],
      } satisfies InvestigationsPayload),
  });
  return {
    ...query,
    case: query.data?.cases[0],
  };
};
