import { useSelector } from 'react-redux';

import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { selectTransactionDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { SelectFormatAmountPayload } from 'app/modules/orgSettings/models';
import { AmountKeys } from 'app/modules/dataSettings/types';
import { useCallback } from 'react';

export const useFormatAmountWithDataSettingsPrecision = (
  amountKey?: AmountKeys,
) => {
  const formatAmount = useSelector(selectFormatAmount);
  const txnNativeDataSettings = useSelector(
    selectTransactionDataSettingsByNativeKey,
  );

  const precision = amountKey
    ? txnNativeDataSettings?.[amountKey]?.rendering_options?.precision
    : undefined;

  return useCallback(
    (payload: SelectFormatAmountPayload) =>
      formatAmount({ ...payload, ...(precision ? { precision } : {}) }),
    [formatAmount, precision],
  );
};
