import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';

import {
  forwardRef,
  HTMLProps,
  MutableRefObject,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import styled from 'styled-components';

import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';

export interface U21CheckboxProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'label' | 'onChange'> {
  checked?: boolean;
  description?: string;
  disabled?: boolean;
  ellipsis?: boolean;
  indeterminate?: boolean;
  inputProps?: CheckboxProps['inputProps'];
  label?: ReactNode;
  onChange: (checked: boolean, e: SyntheticEvent) => void;
  ref?: RefObject<HTMLButtonElement>;
  required?: boolean;
}

export const U21Checkbox = forwardRef<HTMLButtonElement, U21CheckboxProps>(
  (props, ref) => {
    const {
      checked = false,
      description,
      disabled,
      ellipsis,
      indeterminate,
      inputProps = {},
      label,
      onChange,
      required,
      ...rest
    } = props;

    const checkboxProps = {
      checked,
      disabled,
      indeterminate: checked ? undefined : indeterminate,
      onChange: (e) => {
        trackEvent(AnalyticsEvents.U21CHECKBOX_ON_CLICK, props, {}, e);
        onChange(e.target.checked, e);
      },
      inputProps,
    };
    if (!label) {
      return (
        <Checkbox
          ref={ref as MutableRefObject<HTMLButtonElement>}
          {...checkboxProps}
          {...getDOMProps(rest)}
        />
      );
    }

    return (
      <StyledFormControlLabel
        control={<Checkbox {...checkboxProps} />}
        disableTypography
        label={
          <LabelContainer>
            {typeof label === 'string' ? (
              <U21Spacer horizontal spacing={0.25}>
                <U21Typography
                  color={disabled ? 'text.disabled' : 'text.primary'}
                  ellipsis={ellipsis}
                  variant="body2"
                >
                  {label}
                </U21Typography>
                {required && <Asterisk>*</Asterisk>}
              </U21Spacer>
            ) : (
              label
            )}
            <U21Typography
              color="text.disabled"
              ellipsis={ellipsis}
              variant="body2"
            >
              {description}
            </U21Typography>
          </LabelContainer>
        }
        ref={ref}
        {...getDOMProps(rest)}
      />
    );
  },
);

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 32px;
  width: fit-content;
  // account for -11px margin-left default mui style
  max-width: calc(100% + 11px);

  .MuiFormControlLabel-label {
    margin: 4px 0;
  }
`;

const LabelContainer = styled.div`
  overflow: hidden;
  margin: 4px 0;
  ${(props) => props.theme.typography.body2};
`;

const Asterisk = styled.span`
  color: ${(props) => props.theme.palette.error.main};
`;
