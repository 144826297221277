// Models
import {
  CustomChecklistContent,
  CustomChecklistDefinition,
  CustomChecklistFieldDefinition,
  CustomChecklistState,
  CustomChecklistValidationFieldsTypes,
  CustomChecklistValidationResult,
  CustomChecklistValidations,
} from 'app/shared/customChecklist/models';
import { DateRangeValue } from 'app/shared/u21-ui/components';

import { setIn } from 'final-form';

// Custom Checklist Content has DateRanges which may be specified as an object
// with startDate and endDate attributes. Date Range Picker needs this transformed
// into a two value array.
// Used in CustomChecklistContent when creating Date Range Picker Field
export const dateRangeFieldValueTransformation = (
  fieldValue,
): DateRangeValue => {
  if (!Array.isArray(fieldValue))
    return [
      fieldValue?.startDate ? new Date(fieldValue.startDate) : null,
      fieldValue?.endDate ? new Date(fieldValue.endDate) : null,
    ];
  return [
    fieldValue?.[0] ? new Date(fieldValue[0]) : null,
    fieldValue?.[1] ? new Date(fieldValue[1]) : null,
  ];
};

// U21Form will return field values as deeply nested
// Custom Checklist Content is kept as a flat object with path values
// Used to transform U21Form output into Custom Checklist Content
export const flattenFormValues = (
  root: Record<string, any> = {},
  parent?: string,
): CustomChecklistContent =>
  Object.keys(root).reduce((acc, key) => {
    const propName = parent ? `${parent}.${key}` : key;
    const value = root[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      return { ...acc, ...flattenFormValues(value, propName) };
    }
    acc[propName] = value;
    return acc;
  }, {});

export const getValidatedCustomChecklist = (
  customChecklist: CustomChecklistState,
) => {
  const validations = {};
  const isValidated = validateChecklist(
    customChecklist.content,
    customChecklist.definition,
    [],
    validations,
  );
  return { ...customChecklist, isValidated, validations };
};

// Flattens Custom Checklist definition
// Used to determine U21FormFields
export const getChecklistFields = (
  checklistItems: CustomChecklistDefinition[],
  path: string[] = [],
): CustomChecklistFieldDefinition[] => {
  return checklistItems.reduce<CustomChecklistFieldDefinition[]>(
    (acc, checklistItem) => {
      const { items, key, type } = checklistItem;
      if (items) acc.push(...getChecklistFields(items, [...path, key]));
      else acc.push({ name: toKey(path, key), type });

      return acc;
    },
    [],
  );
};

// Takes a flattened Custom Checklist definition, and Custom Checklist Content
// and uses the field names from the definition to access the values in the content
// creates a deeply nested object,
// Used in CustomChecklistContainer to create U21Form initalValues
export const prepareInitialValues = (
  content?: CustomChecklistContent,
  definition?: CustomChecklistFieldDefinition[],
): Record<string, any> =>
  Object.entries(content || {})
    .filter(([, value]) => value !== undefined)
    .reduce((acc, [key, value]) => {
      const fieldDefinition = definition?.find((field) => field.name === key);
      if (fieldDefinition?.type === 'date_range') {
        return setIn(acc, key, dateRangeFieldValueTransformation(value));
      }
      if (fieldDefinition?.type === 'multi_select' && typeof value === 'string')
        return setIn(acc, key, [value]);
      return setIn(acc, key, value);
    }, {});

const toKey = (path: string[], key: string): string => {
  return [...path, key].join('.');
};

export const validateChecklist = (
  data: CustomChecklistContent,
  sections?: CustomChecklistDefinition[],
  path: string[] = [],
  results: CustomChecklistValidationResult = {},
): boolean =>
  (sections || []).reduce(
    (accValid: boolean, section: CustomChecklistDefinition) => {
      const { key, items, validations, enabled_by: enabledBy } = section;
      const sectionPath = [...path, key];
      const sectionKey = sectionPath.join('.');
      const sectionResults: string[] = [];

      const sectionValid = (validations || []).reduce(
        (acc: boolean, validation: CustomChecklistValidations) => {
          const { fields, message } = validation;

          if (typeof fields === 'string') {
            let setCount = 0;
            let unsetCount = 0;
            let allRequiredSet = true;

            items?.forEach((item: CustomChecklistDefinition) => {
              const {
                key: itemKey,
                items: itemItems,
                required,
                enabled_by: itemEnabledBy,
                type,
              } = item;

              // skip any section and textarea that is enabled by a checkbox
              if (!itemItems && !itemEnabledBy && type !== 'label') {
                const value = data[toKey(sectionPath, itemKey)];

                allRequiredSet =
                  allRequiredSet && (!required || (required && !!value));
                setCount += value ? 1 : 0;
                unsetCount += value ? 0 : 1;
              }
            });

            if (
              (fields === CustomChecklistValidationFieldsTypes.ALL &&
                unsetCount > 0) ||
              (fields === CustomChecklistValidationFieldsTypes.ANY &&
                setCount === 0) ||
              (fields === CustomChecklistValidationFieldsTypes.NONE &&
                setCount > 0) ||
              (fields === CustomChecklistValidationFieldsTypes.REQUIRED &&
                !allRequiredSet)
            ) {
              if (message) {
                sectionResults.push(message);
              }
              return false;
            }
          }

          if (
            Array.isArray(fields) &&
            !fields.reduce(
              (valid, field) => valid && data[toKey(sectionPath, field)],
              true,
            )
          ) {
            if (message) {
              sectionResults.push(message);
            }
            return false;
          }

          // 1. no empty field/checkbox with "validations" property
          // 2. i.e. empty textarea enabled by a parent checkbox
          if (
            !fields &&
            !data[sectionKey] &&
            (!enabledBy || data[toKey(path, enabledBy)])
          ) {
            if (message) {
              sectionResults.push(message);
            }
            return false;
          }

          return acc;
        },
        true,
      );

      if (sectionResults.length) {
        // this function relies on mutating the parameter :(
        // eslint-disable-next-line no-param-reassign
        results[sectionKey] = sectionResults;
      }
      return (
        validateChecklist(data, items, sectionPath, results) &&
        sectionValid &&
        accValid
      );
    },
    true,
  );
