import { CHIP_PINK } from 'app/modules/investigations/components/AiInvestigation/constants';
import { U21Chip, U21Typography } from 'app/shared/u21-ui/components';
import { ReactElement } from 'react';
import styled from 'styled-components';

interface OwnProps {
  sourceNumber: number;
  label: string;
  icon?: ReactElement;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const CoreSourceAnnotation = ({
  sourceNumber,
  label,
  icon,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  return (
    <StyledChip
      $isCurrHover={isHovering}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      rounded
      variant="outlined"
      tooltip={
        <U21Typography icon={icon} color="inherit" variant="inherit">
          {label}
        </U21Typography>
      }
      aria-label={`${sourceNumber} annotation chip`}
    >
      <StyledTypography variant="caption" color="text.secondary">
        {sourceNumber}
      </StyledTypography>
    </StyledChip>
  );
};

const StyledChip = styled(U21Chip)<{ $isCurrHover: boolean }>`
  height: 14px;
  padding: 4px;
  margin-left: 2px;

  background-color: ${(props) => (props.$isCurrHover ? CHIP_PINK : 'white')};

  .MuiChip-label {
    padding: 0px;
  }
`;

const StyledTypography = styled(U21Typography)`
  .MuiTypography-root {
    font-size: 10px;
  }
`;
