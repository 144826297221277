import {
  NetworkAnalysisStatusResponse,
  usePollNetworkAnalysisStatus,
} from 'app/modules/networkAnalysisRefresh/queries/usePollNetworkAnalysisStatus';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import {
  U21Chip,
  U21Loading,
  U21Progress,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { formatDatetime } from 'app/shared/utils/date';

const READABLE_STATUS: Record<NetworkAnalysisStatusResponse['status'], string> =
  {
    FINISHED_WITH_ERRORS: 'Failed',
    QUEUED: 'Queued',
    IN_PROGRESS: 'In progress',
    FINISHED_SUCCESSFULLY: 'Completed',
  };

const STATUS_COLOR: Record<
  NetworkAnalysisStatusResponse['status'],
  'error' | 'info' | 'success'
> = {
  FINISHED_WITH_ERRORS: 'error',
  QUEUED: 'info',
  IN_PROGRESS: 'info',
  FINISHED_SUCCESSFULLY: 'success',
};

const STATUS_TO_TIMESTAMP_KEY: Record<
  NetworkAnalysisStatusResponse['status'],
  keyof NetworkAnalysisStatusResponse
> = {
  FINISHED_WITH_ERRORS: 'finished_at',
  QUEUED: 'queued_at',
  IN_PROGRESS: 'started_at',
  FINISHED_SUCCESSFULLY: 'finished_at',
};

export const NetworkAnalysisStatus = ({
  externalId,
  baseObjectType,
}: {
  externalId: string;
  baseObjectType: BaseObjectType;
}) => {
  const { data } = usePollNetworkAnalysisStatus(externalId, baseObjectType);

  if (!data) {
    return null;
  }
  return (
    <U21Spacer spacing={2}>
      <U21Spacer horizontal align="center">
        <U21Chip color={STATUS_COLOR[data.status]}>
          {READABLE_STATUS[data.status]}
        </U21Chip>
        <U21Typography>
          as of{' '}
          {formatDatetime(new Date(data[STATUS_TO_TIMESTAMP_KEY[data.status]]))}
        </U21Typography>
      </U21Spacer>
      {data.status === 'FINISHED_SUCCESSFULLY' ||
      data.status === 'FINISHED_WITH_ERRORS' ? (
        <U21Progress
          color={STATUS_COLOR[data.status]}
          value={data.status === 'FINISHED_SUCCESSFULLY' ? 100 : 25}
        />
      ) : (
        <U21Loading
          color={STATUS_COLOR[data.status]}
          delay={0}
          loading
          variant="linear"
        />
      )}
    </U21Spacer>
  );
};
