import { useForm, useFormState, UseFormStateParams } from 'react-final-form';
import { U21FormAPI } from 'app/shared/u21-ui/components/form/models';

// wrapper for useForm but defaults TFormValues to unknown for strict typing
// and uses our custom U21FormAPI type
export const useU21Form = <
  TFormValues = unknown,
  TInitialFormValues = Partial<TFormValues>,
>(): U21FormAPI<TFormValues, TInitialFormValues> => {
  return useForm<TFormValues, TInitialFormValues>() as unknown as U21FormAPI<
    TFormValues,
    TInitialFormValues
  >;
};

// wrapper for useFormState but defaults TFormValues to unknown for strict typing
export const useU21FormState = <
  TFormValues = unknown,
  TInitialFormValues = Partial<TFormValues>,
>(
  params?: UseFormStateParams<TFormValues, TInitialFormValues>,
) => {
  return useFormState<TFormValues, TInitialFormValues>(params);
};
