import { ToastType } from 'app/shared/toasts/models';
import { U21AlertProps } from 'app/shared/u21-ui/components';

export const TOAST_TYPE_TO_ALERT_MAP: Record<
  ToastType,
  U21AlertProps['severity']
> = {
  Error: 'error',
  Success: 'success',
  Info: 'info',
};
