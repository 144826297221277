import { TableInstance } from 'react-table';
import { U21TableProps } from 'app/shared/u21-ui/components/display/table/models';
import { U21Themes } from 'vendor/material-minimal/models';

import { EMPTY_COUNT } from 'app/shared/u21-ui/components/display/table/constants';

import {
  getDisabledSelectedRows,
  getSelectableRows,
} from 'app/shared/u21-ui/components/display/table/utils';
import { useMemo } from 'react';
import { useTheme } from '@mui/styles';
import numeral from 'numeral';
import styled, { css } from 'styled-components';
import pluralize from 'pluralize';

import { Collapse } from '@mui/material';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

interface Props<T extends object, F> {
  tableProps: TableInstance<T>;
  userProps: U21TableProps<T, F>;
}

export const U21TableSelectAllBar = <T extends object, F>(
  props: Props<T, F>,
) => {
  const { tableProps, userProps } = props;
  const { page, rows } = tableProps;
  const {
    data,
    disabled,
    count = data.length,
    label = 'item',
    manualPagination,
    onRowSelect,
    onSelectAllButtonPressed,
    selectable,
    selected = [],
    selectAllButtonPressed,
  } = userProps;

  const theme = useTheme();

  const areAllRowsSelected = useMemo(() => {
    if (manualPagination) {
      return selectAllButtonPressed;
    }
    const selectedSet = new Set(selected);
    const selectableRows = getSelectableRows<T>(rows, selected, disabled);
    return selectableRows.every((i) => selectedSet.has(i.id));
  }, [disabled, manualPagination, rows, selected, selectAllButtonPressed]);

  const pageSelectedCount = useMemo(() => {
    if (areAllRowsSelected) {
      return manualPagination ? count : selected.length;
    }
    const selectedSet = new Set(selected);
    return page.filter((i) => selectedSet.has(i.id)).length;
  }, [areAllRowsSelected, count, manualPagination, page, selected]);

  const hasOnSelectAllButtonPressed = Boolean(onSelectAllButtonPressed);
  const shouldShowSelectAllBar = useMemo(() => {
    // don't show if not selectable
    if (!selectable) {
      return false;
    }

    // show if everything selectable on the page is selected
    const selectedSet = new Set(selected);
    const selectableRows = getSelectableRows<T>(page, selected, disabled);
    if (selectableRows.length === 0 || selectedSet.size === 0) {
      return false;
    }

    if (manualPagination) {
      return (
        hasOnSelectAllButtonPressed &&
        selectableRows.every((i) => selectedSet.has(i.id))
      );
    }
    return selectableRows.every((i) => selectedSet.has(i.id));
  }, [
    disabled,
    hasOnSelectAllButtonPressed,
    manualPagination,
    page,
    selectable,
    selected,
  ]);

  return (
    <Collapse in={shouldShowSelectAllBar} mountOnEnter unmountOnExit>
      <StyledSelectedBar>
        <StyledU21Typography
          color={
            theme.palette.mode === U21Themes.LIGHT
              ? 'info.darker'
              : 'info.lighter'
          }
          variant="body2"
        >
          {`All ${
            pageSelectedCount === EMPTY_COUNT
              ? ''
              : `${numeral(pageSelectedCount).format()} `
            // if pageSelectedCount = null, then assume plural
          }${pluralize(label, pageSelectedCount ?? 2)} on ${
            areAllRowsSelected ? 'all pages' : 'this page'
          } are selected.`}
        </StyledU21Typography>
        <U21Button
          color="info"
          onClick={(e) => {
            if (areAllRowsSelected) {
              if (manualPagination) {
                onSelectAllButtonPressed?.(false);
              }
              const disabledSelectedRows = getDisabledSelectedRows(
                rows,
                selected,
                disabled,
              );
              onRowSelect?.(
                disabledSelectedRows.map((i) => i.id),
                disabledSelectedRows.map((i) => i.original),
                e,
              );
            } else if (manualPagination) {
              onSelectAllButtonPressed?.(true);
            } else {
              const selectableRows = getSelectableRows(
                rows,
                selected,
                disabled,
              );
              onRowSelect?.(
                selectableRows.map((i) => i.id),
                selectableRows.map((i) => i.original),
                e,
              );
            }
          }}
          variant="ghost"
        >
          {areAllRowsSelected
            ? 'Clear Selection'
            : `Click here to select all ${
                count === EMPTY_COUNT ? '' : `${numeral(count).format()} `
                // use plural if count = null
              }${pluralize(label, count || 2)}`}
        </U21Button>
      </StyledSelectedBar>
    </Collapse>
  );
};

const StyledSelectedBar = styled.div`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  ${(props) => {
    const { theme } = props;
    return css`
      background-color: ${theme.palette.mode === U21Themes.LIGHT
        ? theme.palette.info.lighter
        : theme.palette.info.darker};
    `;
  }}
`;

const StyledU21Typography = styled(U21Typography)`
  margin-right: 8px;
`;
