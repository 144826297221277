import { useSelector } from 'react-redux';

// Components
import { U21Typography } from 'app/shared/u21-ui/components';
import { NewAlertAmount } from 'app/modules/alerts/components/NewAlertAmount';

// Models
import { AlertSummary } from 'app/modules/alerts/models';
import { AlertType } from 'app/modules/alerts/types/alert';

// Selectors
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { selectAlertTableAmountColumnSkeletonLoading } from 'app/shared/featureFlags/selectors';

interface OwnProps {
  alert: AlertSummary;
}

export const AlertAmount = ({
  alert: {
    id: alertId,
    amount_volume: amount, // Remove with skeleton feature flag
    currency,
    alert_type: alertType,
  },
}: OwnProps) => {
  const formatAmount = useSelector(selectFormatAmount);
  const shouldUseSkeletonLoading = useSelector(
    selectAlertTableAmountColumnSkeletonLoading,
  );
  if (alertType === AlertType.KYC) {
    return null;
  }
  /* Old Path */
  if (!shouldUseSkeletonLoading) {
    return (
      <U21Typography color="text.secondary" variant="body2">
        {formatAmount({ amount, currencyCodeProps: currency })}
      </U21Typography>
    );
  }
  /* New Path */
  return <NewAlertAmount alertId={alertId} currency={currency} />;
};
