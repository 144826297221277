// Types
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Models
import { EventHistogram } from 'app/modules/histogram/models';
import { ActionEventDetails } from 'app/shared/events/models';

export enum TransactionsActionTypes {
  RETRIEVE_ACTION_EVENT = '@@transactions/RETRIEVE_ACTION_EVENT',
  RETRIEVE_ACTION_EVENT_SUCCESS = '@@transactions/RETRIEVE_ACTION_EVENT_SUCCESS',
}

export interface HistogramData extends EventHistogram {
  updated_at?: string;
}

export interface PaginatedTxns {
  count: number;
  txns: ShortTxnResponse[];
}
export interface TransactionsState {
  actionEvent: ActionEventDetails;
}
