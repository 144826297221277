import {
  ReactNode,
  useEffect,
  useState,
  useMemo,
  SyntheticEvent,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

// Components
import { U21NoData } from 'app/shared/u21-ui/components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  U21PaginationTableFooter,
  PageLimitValue,
} from 'app/shared/u21-ui/components/U21PaginationTableFooter';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { U21RenderTableCell } from 'app/shared/u21-ui/components/tables/U21RenderTableCell';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

// Models
import {
  SortDirection,
  RowId,
  SelectableCheckboxProps,
  TableConfig,
  PaginationPayload,
} from 'app/shared/pagination/models';
import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { SarFiltersRequest } from 'app/modules/fincenSar/models';

// Constants
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';

// Helpers
import { useDebounceValue } from 'app/shared/hooks/useDebounce';

export const INITIAL_SORT_DIRECTION: SortDirection = 'descending';
interface StrictRowProps {
  id: RowId;
}

interface AllState {
  pagination: {
    limit: PageLimitValue;
    offset: number;
    sortDirection: SortDirection;
    sortColumnKey: string;
  };
}

const UNSELECTED_ROW_ID = -1;

type AllowedTableFilters = SarFiltersRequest | Record<string, any>;

interface OwnProps<T extends StrictRowProps> {
  // Required
  rows: T[];
  config: TableConfig[];
  update: (payload: PaginationPayload, tableConfig: TableConfig[]) => void;
  totalCount: number | null; // null count means too many to count
  filters?: AllowedTableFilters;
  // Optional
  loading?: boolean;
  dataType?: string; // one of 'alerts' | 'cases' | 'entities' | etc...
  onRowClick?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: T,
  ) => void;
  renderCellValue?: (
    config: TableConfig,
    row: T,
    rowIx: number,
  ) => React.ReactNode;
  selectableCheckboxProps?: SelectableCheckboxProps; // to add selectable checkbox on left column
  disableFooter?: boolean;
  disablePointer?: boolean;
  actionButtons?: any[];
  actionItems?: any[];
  noDataComponent?: React.ReactNode;
  stickyHeader?: boolean;
  // For when we want to control the offset from outside the PaginationTable component
  offset?: number;

  // Whether rows can be selectable
  selectable?: boolean;

  // Pending to migrate
  // deleteOnClick?: (rowId: string | number) => void;
  // disableFooterReload?: boolean;
  // rowMenuItems?: RowMenuItem[];
  // fullPageTable?: boolean;
  // printView?: boolean;
  // endOfRowIcon?: EndOfRowIcon | EndRowIconFunc;
  // tableConfigType?: TableConfigType;

  maxHeight?: number;
}

const DEFAULT_FILTERS = {};

/**
 * @deprecated Use U21Table instead
 */
function U21PaginationTable<T extends StrictRowProps>({
  rows,
  config,
  update,
  totalCount = 0,
  dataType,
  onRowClick,
  renderCellValue,
  selectableCheckboxProps,
  loading = false,
  noDataComponent = <U21NoData />,
  disableFooter = false,
  disablePointer = false,
  filters = DEFAULT_FILTERS,
  actionButtons,
  actionItems,
  stickyHeader = false,
  offset,
  selectable = false,
  maxHeight,
}: OwnProps<T>) {
  const [currentPagination, setCurrentPagination] = useState<
    AllState['pagination']
  >({
    limit: DEFAULT_PAGINATION_LIMIT,
    offset: DEFAULT_OFFSET,
    sortColumnKey: '',
    sortDirection: INITIAL_SORT_DIRECTION,
  });

  // used for when a row is clicked on and shows a preview
  const [selectedRowId, setSelectedRowId] = useState<RowId>(UNSELECTED_ROW_ID);
  const selectedRows: Set<RowId> = useMemo(
    () => new Set(selectableCheckboxProps?.selected),
    [selectableCheckboxProps?.selected],
  );
  const disabledRows: Set<RowId> = useMemo(
    () => new Set(selectableCheckboxProps?.disabled),
    [selectableCheckboxProps?.disabled],
  );
  const tableConfig = config.filter((col) => !col.hidden);
  const prevFilters = useRef<AllowedTableFilters>({});
  const debounceFilters = useDebounceValue(filters, 1000);

  const callUpdate = () => {
    update(
      {
        offset: currentPagination.offset,
        limit: currentPagination.limit,
        sort_column: currentPagination.sortColumnKey,
        sort_direction: currentPagination.sortDirection,
      },
      config,
    );
  };
  const callUpdateRef = useRef(callUpdate);
  callUpdateRef.current = callUpdate;
  useEffect(() => {
    if (debounceFilters) {
      const diff = {};
      Object.keys(debounceFilters).forEach((newFilterKey) => {
        if (
          debounceFilters[newFilterKey] !== prevFilters.current[newFilterKey]
        ) {
          diff[newFilterKey] = debounceFilters[newFilterKey];
        }
      });

      // If there was a diff and there were some previous filters stored
      if (
        Object.keys(diff).length > 0 &&
        Object.keys(prevFilters.current).length !== 0
      ) {
        trackEvent(AnalyticsEvents.U21FILTERS_CHANGED, {}, {}, diff);
      }

      if (Object.keys(diff).length > 0) {
        prevFilters.current = debounceFilters;
      }
    }
    callUpdateRef.current();
  }, [debounceFilters, currentPagination]);

  useEffect(() => {
    if (offset) {
      setCurrentPagination((prevPagination) => ({
        ...prevPagination,
        offset,
      }));
    }
  }, [offset]);

  // To update the state, accepts partial values
  const updatePagination = (
    partialPayload: Partial<AllState['pagination']>,
  ) => {
    setCurrentPagination({ ...currentPagination, ...partialPayload });
  };

  const handleSort = (columnKey: string) => () => {
    let newSortDirection: SortDirection = INITIAL_SORT_DIRECTION;

    // IF the column is the same just toggle direction
    if (columnKey === currentPagination.sortColumnKey) {
      newSortDirection =
        currentPagination.sortDirection === 'descending'
          ? 'ascending'
          : 'descending';
    }

    const newPagination: AllState['pagination'] = {
      ...currentPagination,
      offset: DEFAULT_OFFSET,
      sortColumnKey: columnKey,
      sortDirection: newSortDirection,
    };

    const colConfig = tableConfig.find((i) => i.key === columnKey);
    trackEvent(
      AnalyticsEvents.U21SORT_CHANGED,
      {},
      {},
      {
        toAscending: newSortDirection === 'ascending',
        columnKey,
        column: colConfig?.label,
      },
    );
    setCurrentPagination(newPagination);
  };

  const toggleRowSelection = (row: T) => {
    // already selected, toggle off
    if (row.id === selectedRowId) {
      setSelectedRowId(UNSELECTED_ROW_ID);
      return;
    }
    // toggle on
    setSelectedRowId(row.id);
  };

  // ======= Selection ======
  const handleRowClick =
    (row: T) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      event.stopPropagation();
      if (onRowClick) {
        onRowClick(event, row);
      }
      if (selectable) {
        toggleRowSelection(row);
      }
    };
  const onSelectAllClick = () => {
    if (selectableCheckboxProps) {
      if (selectedRows.size === rows.length) {
        // Deselect all
        selectableCheckboxProps.handleSelected([]);
      } else {
        // Select all
        selectableCheckboxProps.handleSelected(rows.map((row) => row.id));
      }
    }
  };

  const onCheckboxClick =
    (row: T) => (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (selectableCheckboxProps) {
        const selectedCopy = new Set([...selectedRows]);
        if (selectedRows.has(row.id)) {
          // Deselect
          selectedCopy.delete(row.id);
        } else {
          // Select
          selectedCopy.add(row.id);
        }
        selectableCheckboxProps.handleSelected([...selectedCopy]);
      }
    };

  // ======= Render ======
  const renderCell = (conf: TableConfig, row: T, rowIx: number): ReactNode => {
    if (renderCellValue) {
      return renderCellValue(conf, row, rowIx);
    }

    return (
      <U21RenderTableCell
        actionButtons={actionButtons}
        actionItems={actionItems}
        config={conf}
        row={row}
      />
    );
  };

  const renderHeader = () => {
    return (
      <StyledTableHead>
        <TableRow>
          {selectableCheckboxProps && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={
                  selectedRows.size > 0 && rows.length > selectedRows.size
                }
                checked={rows.length === selectedRows.size}
                onClick={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all',
                }}
                disabled={loading}
              />
            </TableCell>
          )}
          {tableConfig.map((columnConfig) => {
            const isColumnSortable =
              columnConfig.sortable !== false && !loading;
            const columnLabel = columnConfig.label;
            const sortKey = columnConfig.key;
            const isSorted =
              isColumnSortable && currentPagination.sortColumnKey === sortKey;
            return (
              <TableCell key={`${dataType || 'table'}-list-header-${sortKey}`}>
                {isColumnSortable ? (
                  <TableSortLabel
                    active={isSorted}
                    direction={
                      currentPagination.sortDirection === 'ascending'
                        ? 'asc'
                        : 'desc'
                    }
                    onClick={isColumnSortable ? handleSort(sortKey) : emptyFn}
                  >
                    {columnLabel}
                  </TableSortLabel>
                ) : (
                  columnLabel
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </StyledTableHead>
    );
  };

  const renderTable = () => {
    return (
      <Paper>
        <StyledTableContainer component={Paper} $height={maxHeight}>
          <Table aria-label="simple table" stickyHeader={stickyHeader}>
            {renderHeader()}
            <TableBody>
              {rows.map((row, rowIx) => {
                const isItemSelected = row.id === selectedRowId;
                const isCheckboxSelected = selectedRows.has(row.id);
                const isCheckboxDisabled = disabledRows.has(row.id);
                const rowKey = `${row.id}-${rowIx}`;
                return (
                  <StyledTableRow
                    hover
                    onClick={handleRowClick(row)}
                    key={rowKey}
                    role="checkbox"
                    aria-checked={isItemSelected || isCheckboxSelected}
                    tabIndex={-1}
                    selected={isItemSelected || isCheckboxSelected}
                    $cursor={disablePointer ? 'initial' : 'pointer'}
                  >
                    {selectableCheckboxProps && (
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          onClick={onCheckboxClick(row)}
                          color="primary"
                          checked={isCheckboxSelected}
                          disabled={isCheckboxDisabled}
                          inputProps={{
                            'aria-labelledby': `checkbox-${rowKey}`,
                          }}
                        />
                      </StyledTableCell>
                    )}
                    {tableConfig.map((columnConfig) => (
                      <StyledTableCell
                        $maxWidth={columnConfig.maxWidth}
                        key={`${row.id}-${columnConfig.key}`}
                      >
                        {renderCell(columnConfig, row, rowIx)}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
    );
  };

  return (
    <div>
      {loading && (
        <LoadingContainer>
          <U21Loading loading />
        </LoadingContainer>
      )}

      {!loading &&
        (rows.length ? renderTable() : (noDataComponent ?? <U21NoData />))}
      {!disableFooter && rows.length ? (
        <U21PaginationTableFooter
          onUpdate={updatePagination}
          count={totalCount}
          offset={currentPagination.offset}
          limit={currentPagination.limit}
          currentRows={rows.length}
        />
      ) : null}
    </div>
  );
}

// ====== Styles =====
const StyledTableContainer = styled(TableContainer)<{
  component: typeof Paper;
  $height?: number;
}>`
  width: 100%;
  ${(p) => {
    if (p.$height) {
      return css`
        height: ${p.$height}px;
      `;
    }
    return css``;
  }}
  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;

const StyledTableRow = styled(TableRow)<{ $cursor: 'pointer' | 'initial' }>`
  cursor: ${(p) => p.$cursor};

  /* cannot do border-radius on the row itself */
  & > td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const StyledTableCell = styled(TableCell)<{ $maxWidth?: number }>`
  ${(props) =>
    Boolean(props.$maxWidth) &&
    css`
      max-width: ${props.$maxWidth}px;
    `}
  padding: 5px 10px;
  height: 56px;
  white-space: pre;
  text-wrap: wrap;
`;

const StyledTableHead = styled(TableHead)`
  th:first-of-type,
  th:last-of-type {
    box-shadow: none;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 500px;
`;

export default U21PaginationTable;
