import {
  DateRange,
  U21HistogramDateRange,
  U21HistogramApexChartSeries,
  U21HistogramSeries,
} from 'app/shared/u21-ui/components/charts/models';

import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfYear,
  subDays,
} from 'date-fns';
import { formatDate, formatDatetime } from 'app/shared/utils/date';

export const calculateDateRange = (
  series: U21HistogramSeries,
): U21HistogramDateRange => {
  const data = series.flatMap((i) => i.data);
  if (!data.length) {
    return [undefined, undefined];
  }
  return data.reduce<DateRange>(
    (acc, i) => {
      const [min, max] = acc;
      let newMin = min;
      let newMax = max;
      const epoch = new Date(i.x);
      if (epoch > max) {
        newMax = epoch;
      }
      if (epoch < min) {
        newMin = epoch;
      }
      return [newMin, newMax];
    },
    [new Date(data[0].x), new Date(data[0].x)],
  );
};

interface Config {
  bucketSize: number;
  timezone?: string | null;
}

export const tooltipFormatter = (config: Config) => {
  const { timezone, bucketSize } = config;
  return (timestamp: number) => {
    // bucketSize > 1 day means don't need to use show time
    if (bucketSize > 24 * 60 * 60 * 1000) {
      return `${formatDate(timestamp, undefined, { timezone })} - ${formatDate(subDays(new Date(timestamp + bucketSize), 1), undefined, { timezone })}`;
    } else if (bucketSize === 24 * 60 * 60 * 1000) {
      // only show 1 date if bucket is 1 day
      return formatDate(timestamp, undefined, { timezone });
    }
    if (timezone) {
      return `${formatDatetime(timestamp, undefined, {
        timezone,
        showTimezone: true,
      })} - ${formatDatetime(timestamp + bucketSize, undefined, {
        timezone,
        showTimezone: true,
      })}`;
    }

    return `${formatDatetime(timestamp)} - ${formatDatetime(timestamp + bucketSize)}`;
  };
};

export const xaxisFormatter = (config: Config) => {
  const { timezone, bucketSize } = config;
  return (timestamp: number) => {
    // bucketSize >= 1 day means don't need to use show time
    if (bucketSize >= 24 * 60 * 60 * 1000) {
      return formatDate(timestamp, undefined, { timezone });
    }
    if (timezone) {
      return formatDatetime(timestamp, undefined, {
        timezone,
        showTimezone: true,
      });
    }

    return formatDatetime(timestamp);
  };
};

export const aggregateTimeseries = (
  series: U21HistogramSeries,
  dateRange: U21HistogramDateRange,
): { bucketSize: number; series: U21HistogramApexChartSeries } => {
  const [min, max] = dateRange;
  if (!min || !max) {
    return { series: [], bucketSize: 0 };
  }

  const end = startOfDay(addDays(max, 1));
  const days = differenceInDays(end, startOfDay(min));

  let bucketSize: number = 60 * 60 * 1000; // 1 hr
  let offsetStartFunc: (date: Date) => Date = startOfHour;
  let offsetEndFunc: (date: Date) => Date = endOfHour;
  if (days === 1) {
    // 1 - 2 days = 1 hr aggregation
    bucketSize = 60 * 60 * 1000; // 1 hr
    offsetStartFunc = startOfHour;
  } else if (days === 2) {
    // 2 - 3 days = 2 hr aggregation
    bucketSize = 2 * 60 * 60 * 1000;
    offsetStartFunc = startOfHour;
    offsetEndFunc = endOfHour;
  } else if (days === 3) {
    // 3 - 4 days = 3 hr aggregation
    bucketSize = 3 * 60 * 60 * 1000;
    offsetStartFunc = startOfHour;
    offsetEndFunc = endOfHour;
  } else if (days === 4) {
    // 4 - 5 days = 4 hr aggregation
    bucketSize = 4 * 60 * 60 * 1000;
    offsetStartFunc = startOfHour;
    offsetEndFunc = endOfHour;
  } else if (days < 12) {
    // 5 - 12 days = 6 hr aggregation
    bucketSize = 6 * 60 * 60 * 1000;
    offsetStartFunc = startOfHour;
    offsetEndFunc = endOfHour;
  } else if (days < 24) {
    // 12 - 24 days = 12 hr aggregation
    bucketSize = 12 * 60 * 60 * 1000;
    offsetStartFunc = startOfHour;
    offsetEndFunc = endOfHour;
  } else if (days < 60) {
    // 24 - 60 days = 1 day aggregation
    bucketSize = 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 90) {
    // 2 - 3 months = 2 day aggregation
    bucketSize = 2 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 120) {
    // 3 - 4 months = 3 day aggregation
    bucketSize = 3 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 240) {
    // 4 - 8 months = 5 day aggregation
    bucketSize = 5 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 365) {
    // 8 - 12 months = 1 week aggregation
    bucketSize = 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 2 * 365) {
    // 1 - 2 years = 2 week aggregation
    bucketSize = 2 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfDay;
    offsetEndFunc = endOfDay;
  } else if (days < 4 * 365) {
    // 2 - 4 years = 4 week aggregation
    bucketSize = 4 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfMonth;
    offsetEndFunc = endOfMonth;
  } else if (days < 8 * 365) {
    // 4 - 8 years = 8 week aggregation
    bucketSize = 8 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfMonth;
    offsetEndFunc = endOfMonth;
  } else if (days < 16 * 365) {
    // 8 - 16 years = 16 week aggregation
    bucketSize = 16 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfMonth;
    offsetEndFunc = endOfMonth;
  } else if (days < 32 * 365) {
    // 16 - 32 years = 32 week aggregation
    bucketSize = 32 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfMonth;
    offsetEndFunc = endOfMonth;
  } else if (days < 64 * 365) {
    // 32 - 64 years = 1 year aggregation
    bucketSize = 52 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfYear;
    offsetEndFunc = endOfYear;
  } else if (days < 128 * 365) {
    // 64 - 128 years = 2 year aggregation
    bucketSize = 2 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfYear;
    offsetEndFunc = endOfYear;
  } else if (days < 200 * 365) {
    // 128 - 200 years = 5 year aggregation
    bucketSize = 5 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfYear;
    offsetEndFunc = endOfYear;
  } else if (days < 300 * 365) {
    // 200 - 300 years = 10 year aggregation
    bucketSize = 10 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfYear;
    offsetEndFunc = endOfYear;
  } else {
    // 300 years + = 20 year aggregation
    bucketSize = 20 * 52 * 7 * 24 * 60 * 60 * 1000;
    offsetStartFunc = startOfYear;
    offsetEndFunc = endOfYear;
  }

  return {
    bucketSize,
    series: series.map((i) => {
      if (!i.data.length) {
        return { ...i, data: [] };
      }

      const bucketStartTimestamp = offsetStartFunc(min).getTime();
      const bucketEndTimestamp = offsetEndFunc(end).getTime();

      // Pre-calculate the number of buckets needed
      const totalBuckets =
        Math.ceil((bucketEndTimestamp - bucketStartTimestamp) / bucketSize) + 1;
      const buckets = new Array(totalBuckets).fill(0);

      // Calculate bucket indices for each data point
      for (let index = 0; index < i.data.length; index++) {
        const { x, y } = i.data[index];
        const timestamp = new Date(x).getTime();
        const bucketIndex = Math.floor(
          (timestamp - bucketStartTimestamp) / bucketSize,
        );
        if (bucketIndex >= 0 && bucketIndex < totalBuckets) {
          buckets[bucketIndex] += y;
        }
      }

      // Convert buckets to final format
      const data = buckets.map((value, index) => ({
        x: bucketStartTimestamp + index * bucketSize,
        // https://github.com/apexcharts/apexcharts.js/issues/4911
        y: Math.abs(value) < 1e-6 ? 0 : value,
      }));

      return { ...i, data };
    }),
  };
};
