import { Filter, FilterOption } from 'app/modules/filters/models';

import { hasPermissionChecker } from 'app/modules/permissions/utils';
import { isEqual } from 'lodash';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { selectSessionAgentPermissions } from 'app/modules/session/selectors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FilterBuilder } from 'app/modules/filters/components/FilterBuilder';
import { FilterAppliedChip } from 'app/modules/filters/components/FilterAppliedChip';
import { FilterPinButton } from 'app/modules/filters/components/FilterPinButton';
import { FilterPinnedChip } from 'app/modules/filters/components/FilterPinnedChip';
import { IconChevronDown, IconChevronUp, IconFilter } from '@u21/tabler-icons';
import {
  U21Button,
  U21Divider,
  U21MenuLayout,
  U21MenuList,
  U21MenuListOptionProps,
  U21Spacer,
} from 'app/shared/u21-ui/components';
import { selectDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';

interface Props {
  defaultFilters?: Filter[];
  disabled?: boolean;
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
  onPinChange?: (pinned: string[]) => void;
  options: FilterOption[];
  pinned?: string[];
}

const DEFAULT_FILTERS = [];
const DEFAULT_PINNED = [];

export const Filters = (props: Props) => {
  const {
    defaultFilters = DEFAULT_FILTERS,
    disabled,
    filters,
    onChange,
    onPinChange,
    options: optionsProp,
    pinned = DEFAULT_PINNED,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>();

  const featureFlags = useSelector(selectFeatureFlags);
  const userPermissions = useSelector(selectSessionAgentPermissions);
  const dataSettingsByKey = useSelector(selectDataSettingsByNativeKey);

  const options = useMemo(
    () =>
      optionsProp.map((i) => {
        const { key, unit21DataClassifier } = i;
        if (unit21DataClassifier) {
          const dataSetting = dataSettingsByKey[unit21DataClassifier][key];
          if (dataSetting) {
            return {
              ...i,
              description: dataSetting.description,
              label: keyPathToLabel(dataSetting),
            };
          }
        }
        return i;
      }),
    [dataSettingsByKey, optionsProp],
  );

  const optionMap = useMemo(
    () =>
      options.reduce<Record<FilterOption['key'], FilterOption>>(
        (acc, i) => ({ ...acc, [i.key]: i }),
        {},
      ),
    [options],
  );

  // filter out invalid pinned keys
  const pinnedSet = useMemo(
    () => new Set(pinned.filter((i) => optionMap[i])),
    [optionMap, pinned],
  );

  const filterKeyMap = useMemo(
    () =>
      filters.reduce(
        (acc, i) => ({
          ...acc,
          [i.key]: i,
        }),
        {},
      ),
    [filters],
  );

  const onPinChangeWrapper = useCallback(
    (key) =>
      onPinChange
        ? () => {
            if (pinnedSet.has(key)) {
              onPinChange?.([...pinnedSet].filter((j) => j !== key));
            } else {
              onPinChange?.([...pinnedSet, key]);
            }
          }
        : undefined,
    [onPinChange, pinnedSet],
  );

  const filterOptions = useMemo(() => {
    return options.reduce<U21MenuListOptionProps[]>((acc, i) => {
      const { featureFlag, label, key, permissions, description } = i;
      if (filterKeyMap[key]) {
        return acc;
      }
      if (featureFlag && !featureFlags[featureFlag]) {
        return acc;
      }
      if (!hasPermissionChecker(userPermissions, permissions)) {
        return acc;
      }
      acc.push({
        description,
        onClick: () => setSelectedFilterKey(key),
        rightIcon: (
          <FilterPinButton
            onPinChange={onPinChangeWrapper(key)}
            pinned={pinnedSet.has(key)}
          />
        ),
        text: label,
        typographyProps: { ellipsis: true },
      });
      return acc;
    }, []);
  }, [
    featureFlags,
    filterKeyMap,
    onPinChangeWrapper,
    options,
    pinnedSet,
    userPermissions,
  ]);

  const chipFilterKeys = useMemo(
    () => [...new Set([...pinnedSet, ...Object.keys(filterKeyMap)])],
    [filterKeyMap, pinnedSet],
  );

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // remove filters with no options
  useEffect(() => {
    const newFilters = filters.filter((i) => optionMap[i.key]);
    if (newFilters.length < filters.length) {
      onChangeRef.current(newFilters);
    }
  }, [filters, optionMap]);

  // warn if required filter doesn't exist on dev
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const defaultFiltersKeys = new Set(defaultFilters.map((i) => i.key));
      const missingDefaultFilters = options.filter((i) => {
        return i.required && !defaultFiltersKeys.has(i.key);
      });
      if (missingDefaultFilters.length) {
        throw new Error(
          `Missing default filters for required options: ${new Intl.ListFormat('en-US').format(missingDefaultFilters.map((i) => i.key))}`,
        );
      }
    }
  }, [defaultFilters, options]);

  return (
    <U21Spacer wrap>
      <U21MenuLayout
        onClose={() => setOpen(false)}
        onExited={() => setSelectedFilterKey(undefined)}
        open={open}
        trigger={
          <U21Button
            disabled={disabled}
            color="primary"
            endIcon={open ? <IconChevronUp /> : <IconChevronDown />}
            onClick={() => setOpen(true)}
            size="small"
            startIcon={<IconFilter />}
          >
            Filter
          </U21Button>
        }
      >
        <MenuContent>
          {selectedFilterKey ? (
            <FilterBuilder
              onClose={() => setOpen(false)}
              onPinChange={onPinChangeWrapper(selectedFilterKey)}
              onReset={() => setSelectedFilterKey(undefined)}
              onSubmit={(operatorAndValue) => {
                const newFilter = {
                  ...operatorAndValue,
                  key: selectedFilterKey,
                };
                onChange([...filters, newFilter]);
                setOpen(false);
              }}
              option={optionMap[selectedFilterKey]}
              pinned={pinnedSet.has(selectedFilterKey)}
            />
          ) : (
            <U21Spacer>
              <U21MenuList options={filterOptions} />
              <U21Divider horizontal />
              <FooterContainer>
                {!isEqual(defaultFilters, filters) && (
                  <U21Button
                    onClick={() => {
                      onChange(defaultFilters);
                      setOpen(false);
                    }}
                    size="small"
                    variant="ghost"
                  >
                    Reset Filters
                  </U21Button>
                )}
                <StyledU21Button onClick={() => setOpen(false)} size="small">
                  Cancel
                </StyledU21Button>
              </FooterContainer>
            </U21Spacer>
          )}
        </MenuContent>
      </U21MenuLayout>
      {chipFilterKeys.map((i) => {
        const option = optionMap[i];
        // ignore if option doesn't exist. will be removed w/ a useEffect
        if (!option) {
          return null;
        }
        if (filterKeyMap[i]) {
          const { key, ...value } = filterKeyMap[i];
          return (
            <FilterAppliedChip
              disabled={disabled}
              key={key}
              onDelete={() => onChange(filters.filter((j) => j.key !== key))}
              onUpdate={(updatedFilter) =>
                onChange(
                  filters.map((j) =>
                    j.key === key ? { ...j, ...updatedFilter } : j,
                  ),
                )
              }
              operatorAndValue={value}
              option={option}
              pinned={pinnedSet.has(i)}
              onPinChange={onPinChangeWrapper(i)}
            />
          );
        }
        return (
          <FilterPinnedChip
            disabled={disabled}
            key={i}
            onSubmit={(operatorAndValue) => {
              const newFilter = {
                ...operatorAndValue,
                key: i,
              };
              onChange([...filters, newFilter]);
            }}
            onPinChange={onPinChangeWrapper(i)}
            option={option}
          />
        );
      })}
    </U21Spacer>
  );
};

const MenuContent = styled.div`
  min-width: 300px;
  max-width: 600px;
`;

const FooterContainer = styled.div`
  display: flex;
`;

const StyledU21Button = styled(U21Button)`
  margin-left: auto;
`;
