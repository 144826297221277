import { useDispatch, useSelector } from 'react-redux';
import { selectHasRequeueAlertPermission } from 'app/modules/alerts/selectors';
import { useGetAlert } from 'app/modules/alerts/queries/useGetAlert';
import {
  U21Chip,
  U21ChipProps,
  U21MenuLayout,
} from 'app/shared/u21-ui/components';
import { useState } from 'react';
import { useRequeueAlert } from 'app/modules/alerts/queries/useRequeueAlert';
import { IconCircleDashed, IconClipboardList } from '@u21/tabler-icons';
import { QueueType } from 'app/modules/queues/models';
import { ChangeQueueMenuList } from 'app/modules/queues/components/ChangeQueueMenuList';
import { post } from 'app/shared/utils/fetchr';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { updateAlertCache } from 'app/modules/alerts/actions';

interface Props {
  id: number;
}

export const Requeue = (props: Props) => {
  const { id } = props;

  const dispatch = useDispatch();
  const hasRequeueAlertPermission = useSelector(
    selectHasRequeueAlertPermission,
  );

  const [open, setOpen] = useState(false);

  const { alert } = useGetAlert(id);
  const { mutateAsync: requeueAlert } = useRequeueAlert([id]);

  if (!alert) {
    return null;
  }

  const chipProps: U21ChipProps = {
    children: alert['queue.title'] || 'No queue',
    color: 'primary',
    icon: alert.queue_id ? <IconClipboardList /> : <IconCircleDashed />,
    variant: 'ghost',
  };

  if (
    !hasRequeueAlertPermission ||
    alert.status === 'CLOSED' ||
    alert.status === 'IN_REVIEW'
  ) {
    return <U21Chip {...chipProps} />;
  }

  return (
    <U21MenuLayout
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      trigger={<U21Chip {...chipProps} onClick={() => setOpen(true)} />}
      open={open}
    >
      <ChangeQueueMenuList
        onChange={async (queueID) => {
          try {
            await requeueAlert(queueID);
            // retrieve latest alert info to update the table data w/ new queue
            // unfortunately the investigation/action API does not return the latest data
            // so need to manually trigger another API to get it
            // this will no longer be needed once the alert table uses hooks
            const response = await post<InvestigationsAlertResponse<false>>(
              '/investigations/retrieve/alert',
              {
                short: false,
                object_type: 'ALERT',
                object_ids: [id],
              } satisfies InvestigationsPayload,
            );
            if (response.alerts[0]) {
              dispatch(updateAlertCache(response.alerts[0]));
            }
          } catch {}
          setOpen(false);
        }}
        queueType={QueueType.ALERT_QUEUE}
      />
    </U21MenuLayout>
  );
};
