import { IconWorld } from '@u21/tabler-icons';
import { CoreSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/CoreSourceChip';
import { AiInvestigationExternalSource } from 'app/modules/investigations/types/responses';
import { U21ExternalLinkModal } from 'app/shared/u21-ui/components';
import { useState } from 'react';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationExternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const ExternalSourceChip = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  const { url, header } = sourceData;

  const [externalLinkModalOpen, setExternalLinkModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <CoreSourceChip
        sourceNumber={sourceNumber}
        icon={<IconWorld />}
        label={header ? `${header} (${url})` : `Web Search (${url})`}
        onClick={() => setExternalLinkModalOpen(true)}
        isHovering={isHovering}
        setIsHovering={setIsHovering}
      />

      <U21ExternalLinkModal
        onClose={() => setExternalLinkModalOpen(false)}
        open={externalLinkModalOpen}
        url={url}
      />
    </>
  );
};
