// Constants
import { HISTORY_LIMIT } from 'app/modules/navigator/constants';
import { HistoryItem } from 'app/modules/navigator/models';

export const popRoute = (
  history: HistoryItem[],
  route: string,
  key?: string,
): HistoryItem[] => {
  const index = history.findIndex((i) => i.key === key);

  // when forward button is pressed, pop is triggered but should push route
  if (index === -1) {
    return pushRoute(history, route, key);
  }

  // Math.max protects against 0 index
  const newHistory = history.slice(Math.max(index, 1));

  // there should always be a history
  if (newHistory.length) {
    return newHistory;
  }
  return [{ route: `${window.location.pathname}${window.location.search}` }];
};

export const pushRoute = (
  history: HistoryItem[],
  route: string,
  key?: string,
): HistoryItem[] => {
  // if new route = last route, update the key
  if (route === history[0].route) {
    return [{ key, route }, ...history.slice(1)];
  }
  return history.length < HISTORY_LIMIT
    ? [{ key, route }, ...history]
    : [{ key, route }, ...history.slice(0, -1)];
};

export const replaceRoute = (
  history: HistoryItem[],
  route: string,
  key?: string,
): HistoryItem[] => {
  const newHistory = history.slice(1);
  // filter out duplicate routes in recent history
  const index = newHistory.findIndex((i) => i.route !== route);
  return [{ key, route }, ...newHistory.slice(index)];
};
