import { PinType } from 'app/shared/u21-ui/components/display/table/hooks/models';
import { TableInstance } from 'react-table';
import {
  U21TableColumnTypes,
  U21TableProps,
} from 'app/shared/u21-ui/components/display/table/models';

import { PINNED_SHADOW_STYLE } from 'app/shared/u21-ui/components/display/table/styles';
import { SELECT_COLUMN_CHECKBOX } from 'app/shared/u21-ui/components/display/table/constants';

import { isRenderable } from 'app/shared/utils/react';
import { Fragment, isValidElement, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';

import { Collapse } from '@mui/material';
import { IconDots } from '@u21/tabler-icons';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import { U21Chip } from 'app/shared/u21-ui/components/display/U21Chip';
import { U21MenuButton } from 'app/shared/u21-ui/components/display/U21MenuButton';
import { U21ShowMoreList } from 'app/shared/u21-ui/components/display/U21ShowMoreList';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21DataDisplay } from 'app/shared/u21-ui/components/display/U21DataDisplay';
import { isEmpty } from 'lodash';
import { U21NoValue } from 'app/shared/u21-ui/components/display/U21NoValue';

interface Props<T extends object, F> {
  tableProps: TableInstance<T>;
  userProps: U21TableProps<T, F>;
}

const EMPTY_ARRAY = [];

export const U21TableBody = <T extends object, F>(props: Props<T, F>) => {
  const { tableProps, userProps } = props;
  const {
    firstRightPinnedColumn,
    getTableBodyProps,
    isScrollable,
    lastLeftPinnedColumn,
    page,
    prepareRow,
    visibleColumns,
  } = tableProps;
  const {
    highlighted = EMPTY_ARRAY,
    isRowClickable,
    onRowClick,
    selected = EMPTY_ARRAY,
  } = userProps;

  const highlightedSet = useMemo(() => new Set(highlighted), [highlighted]);
  const selectedSet = useMemo(() => new Set(selected), [selected]);

  const firstColumnID = useMemo(() => {
    return visibleColumns.find((i) => i.id !== SELECT_COLUMN_CHECKBOX.id)?.id;
  }, [visibleColumns]);

  const { palette } = useTheme();

  // typescript throws an error since it thinks SubComponent is undefined when rendering
  const SubComponent = userProps.SubComponent!;
  const hasSubComponent = Boolean(SubComponent);

  return (
    <Body {...getTableBodyProps()}>
      {page.map((i) => {
        prepareRow(i);
        const {
          cells,
          getRowProps,
          id,
          isExpanded,
          original,
          toggleRowExpanded,
        } = i;
        const onRowClickExpanded = hasSubComponent
          ? () => toggleRowExpanded(!isExpanded)
          : undefined;
        const clickable = isRowClickable
          ? isRowClickable(original)
          : Boolean(onRowClick) || Boolean(onRowClickExpanded);
        const isHighlighted = highlightedSet.has(id);
        const isSelected = selectedSet.has(id);

        const { key, ...restRowProps } = getRowProps();

        return (
          <Fragment key={key}>
            <BodyRow
              $clickable={clickable}
              $highlighted={isExpanded || isHighlighted}
              $selected={isSelected}
              aria-selected={isSelected}
              onClick={
                clickable
                  ? (e) => {
                      const selection = document.getSelection()?.toString();
                      if (!selection) {
                        if (onRowClick) {
                          onRowClick(id, original, e);
                        } else if (onRowClickExpanded) {
                          onRowClickExpanded();
                        }
                      }
                    }
                  : undefined
              }
              {...restRowProps}
            >
              {cells.map((j) => {
                const {
                  column: {
                    id: columnID,
                    Cell,
                    cellProps: cellPropsProp,
                    pinType,
                    type,
                  },
                  getCellProps,
                  value,
                } = j;

                const { key: cellContainerKey, ...cellContainerProps } =
                  getCellProps();
                return (
                  <BodyCell
                    $highlighted={isExpanded || isHighlighted}
                    $pinType={pinType}
                    $selected={isSelected}
                    $showShadow={
                      (columnID === lastLeftPinnedColumn ||
                        columnID === firstRightPinnedColumn) &&
                      isScrollable
                    }
                    key={cellContainerKey}
                    {...cellContainerProps}
                  >
                    {(() => {
                      if (Cell) {
                        return (
                          <Cell
                            id={id}
                            row={original}
                            rowProps={i}
                            tableProps={tableProps}
                            userProps={userProps}
                            value={value}
                          />
                        );
                      }

                      const bold = firstColumnID === columnID;
                      const cellProps =
                        typeof cellPropsProp === 'function'
                          ? cellPropsProp(original)
                          : cellPropsProp;

                      switch (type) {
                        case U21TableColumnTypes.ACTIONS: {
                          const { items, ...restCellProps } = cellProps;
                          return (
                            <U21MenuButton
                              alignRight
                              buttonProps={{
                                onClick: (e) => e.stopPropagation(),
                                size: 'small',
                              }}
                              onClose={(e) => e.stopPropagation()}
                              items={items.map((k) => {
                                if (isValidElement(k)) {
                                  return k;
                                }
                                return {
                                  ...k,
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    k.onClick?.(e);
                                  },
                                };
                              })}
                              {...restCellProps}
                            >
                              <IconDots />
                            </U21MenuButton>
                          );
                        }

                        case U21TableColumnTypes.BUTTON: {
                          const { onClick, ...restCellProps } = cellProps;
                          return (
                            <U21Button
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick?.();
                              }}
                              {...restCellProps}
                            />
                          );
                        }

                        case U21TableColumnTypes.DATE:
                          return (
                            <U21DataDisplay
                              componentProps={{
                                color: bold
                                  ? palette.text.primary
                                  : palette.text.secondary,
                                variant: bold ? 'subtitle2' : 'body2',
                                ...cellProps,
                              }}
                              variant="DATE"
                              value={value}
                            />
                          );

                        case U21TableColumnTypes.DATETIME:
                          return (
                            <U21DataDisplay
                              componentProps={{
                                color: bold
                                  ? palette.text.primary
                                  : palette.text.secondary,
                                variant: bold ? 'subtitle2' : 'body2',
                                ...cellProps,
                              }}
                              variant="DATE_TIME"
                              value={value}
                            />
                          );

                        case U21TableColumnTypes.LABEL:
                          if (!isRenderable(value)) {
                            return <U21NoValue />;
                          }
                          return <U21Chip {...cellProps}>{value}</U21Chip>;

                        case U21TableColumnTypes.BOOLEAN:
                          if (typeof value !== 'boolean') {
                            return <U21NoValue />;
                          }
                          return (
                            <U21DataDisplay variant="BOOLEAN" value={value} />
                          );

                        case U21TableColumnTypes.LIST:
                          if (isEmpty(value)) {
                            return <U21NoValue />;
                          }
                          return (
                            <StyledU21ShowMoreList
                              onLessClick={(e) => e.stopPropagation()}
                              onMoreClick={(e) => e.stopPropagation()}
                              value={value}
                              {...cellProps}
                            />
                          );

                        default: {
                          if (!isRenderable(value)) {
                            return <U21NoValue />;
                          }

                          return (
                            <U21Typography
                              color={
                                bold
                                  ? palette.text.primary
                                  : palette.text.secondary
                              }
                              variant={bold ? 'subtitle2' : 'body2'}
                              {...cellProps}
                            >
                              {value}
                            </U21Typography>
                          );
                        }
                      }
                    })()}
                  </BodyCell>
                );
              })}
            </BodyRow>
            {hasSubComponent && (
              <Collapse in={isExpanded} mountOnEnter unmountOnExit>
                <SubComponentContainer>
                  <SubComponent
                    id={id}
                    row={original}
                    rowProps={i}
                    tableProps={tableProps}
                    userProps={userProps}
                  />
                </SubComponentContainer>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </Body>
  );
};

const SELECTED_STYLE = css<{ $selected?: boolean }>`
  ${(props) =>
    props.$selected &&
    css`
      background-color: ${props.theme.palette.action.tableSelect};
      :hover {
        background-color: ${props.theme.palette.action.tableSelect};
      }
    `}
`;

const HIGHLIGHTED_STYLE = css<{ $highlighted: boolean }>`
  ${(props) =>
    props.$highlighted &&
    css`
      background-color: ${props.theme.palette.action.tableHighlight};
      :hover {
        background-color: ${props.theme.palette.action.tableHighlight};
      }
    `}
`;

const BodyCell = styled.div<{
  $highlighted: boolean;
  $pinType: PinType;
  $selected?: boolean;
  $showShadow: boolean;
}>`
  min-height: 42px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  overflow: hidden;

  ${PINNED_SHADOW_STYLE}

  background-color: ${(props) => props.theme.palette.background.paper};

  ${HIGHLIGHTED_STYLE}
  ${SELECTED_STYLE}
`;

const BodyRow = styled.div<{
  $clickable: boolean;
  $highlighted: boolean;
  $selected?: boolean;
}>`
  ${HIGHLIGHTED_STYLE}
  ${SELECTED_STYLE}

  ${(props) =>
    props.$clickable &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.$clickable &&
    !props.$highlighted &&
    !props.$selected &&
    css`
      :hover {
        background-color: ${props.theme.palette.action.tableHover};
        & > ${BodyCell} {
          background-color: ${props.theme.palette.action.tableHover};
        }
      }
    `}
`;

const Body = styled.div`
  & > ${BodyRow}:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledU21ShowMoreList = styled(U21ShowMoreList)`
  overflow: hidden;
`;

const SubComponentContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
