import { TableConfigActionPayload } from 'app/shared/CustomConfig/models';
import {
  SidebarComponentTypes,
  SidebarDefinition,
} from 'app/modules/sidebar/models';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
  definition?: SidebarDefinition;
  usePopup: boolean;
}

const initialState: SidebarState = {
  usePopup: false,
};

export const SIDEBAR_SLICE_NAME = 'sidebar';

export const slice = u21CreateSlice({
  initialState,
  name: SIDEBAR_SLICE_NAME,
  reducers: {
    hideSidebar: (draft) => {
      delete draft.definition;
    },
    toggleSidebar: (draft, action: PayloadAction<SidebarDefinition>) => {
      if (action.payload?.data === draft.definition?.data) {
        delete draft.definition;
      } else {
        draft.definition = action.payload;
      }
    },
    toggleTableConfigSidebar: (
      draft,
      action: PayloadAction<TableConfigActionPayload>,
    ) => {
      if (action.payload.tableConfigType === draft.definition?.data) {
        draft.definition = undefined;
      } else {
        draft.definition = {
          type: SidebarComponentTypes.TABLE_CONFIG,
          data: action.payload.tableConfigType,
        };
      }
    },
    toggleUsePopupSidebar: (draft, action: PayloadAction<boolean>) => {
      draft.usePopup = action.payload;
    },
  },
});

export const {
  hideSidebar,
  toggleSidebar,
  toggleTableConfigSidebar,
  toggleUsePopupSidebar,
} = slice.actions;
export default slice.reducer;
