import { FC, HTMLProps, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { TextFieldProps } from '@mui/material';

// Components
import { IconArrowRight } from '@u21/tabler-icons';
import { U21InputGroup } from 'app/shared/u21-ui/components/display/U21InputGroup';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { getDOMProps } from 'app/shared/utils/react';

type RangeValue = number | undefined;
export type U21NumberRangeValue = [RangeValue, RangeValue];

export interface U21NumberRangeProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  autoFocus?: boolean;
  value: U21NumberRangeValue;
  onChange: (value: U21NumberRangeValue) => void;
  clearable?: boolean;
  disabled?: boolean;
  endPlaceholder?: string;
  error?: boolean;
  id?: string;
  label?: string | undefined;
  maxInputProps?: TextFieldProps['inputProps'];
  minInputProps?: TextFieldProps['inputProps'];
  required?: boolean;
  startPlaceholder?: string;
  startIcon?: ReactElement;
}

export const U21NumberRange: FC<U21NumberRangeProps> = ({
  autoFocus,
  clearable = true,
  disabled,
  endPlaceholder = 'Max',
  error: errorProp,
  id,
  label = '',
  maxInputProps = {},
  minInputProps = {},
  onChange,
  required,
  startPlaceholder = 'Min',
  value,
  startIcon,
  ...rest
}) => {
  const [min, max] = value;

  const handleOnMinChange = (val) => {
    onChange([val, max ?? undefined]);
  };

  const handleOnMaxChange = (val) => {
    onChange([min ?? undefined, val]);
  };

  const error = errorProp || (min ?? -Infinity) > (max ?? Infinity);

  return (
    <StyledU21InputGroup
      borders={false}
      error={error}
      disabled={disabled}
      {...getDOMProps(rest)}
    >
      <StyledU21TextField
        autoFocus={autoFocus}
        onChange={handleOnMinChange}
        placeholder={startPlaceholder}
        type="number"
        error={error}
        disabled={disabled}
        value={min}
        label={label}
        startIcon={startIcon}
        clearable={clearable}
        required={required}
        id={id}
        InputLabelProps={{ shrink: true }}
        inputProps={minInputProps}
      />
      <StyledIconArrowRight />
      <StyledU21TextField
        onChange={handleOnMaxChange}
        placeholder={endPlaceholder}
        type="number"
        error={error}
        disabled={disabled}
        value={max}
        clearable={clearable}
        id={id}
        inputProps={maxInputProps}
      />
    </StyledU21InputGroup>
  );
};

const StyledU21InputGroup = styled(U21InputGroup)`
  position: relative;
  width: 100%;

  :hover {
    .MuiTextField-clearIndicator {
      visibility: visible;
    }
  }
`;

const StyledU21TextField = styled(U21TextField)<{
  $open?: boolean;
  $right?: boolean;
}>`
  .MuiInputBase-input {
    text-align: center;
    ${(props) =>
      props.$right
        ? css`
            padding-left: 6px;
          `
        : css`
            padding-right: 6px;
          `}
  }

  ${(props) =>
    !props.disabled &&
    css`
      .MuiInputBase-root {
        cursor: pointer;

        .MuiInputBase-input {
          cursor: pointer;
        }
      }
    `}
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.palette.grey[600]};
`;
