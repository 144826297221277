import { CoreSourceAnnotation } from 'app/modules/investigations/components/AiInvestigation/Annotation/CoreSourceAnnotation';
import { useInternalSourceDetail } from 'app/modules/investigations/components/AiInvestigation/hooks';
import { AiInvestigationInternalSource } from 'app/modules/investigations/types/responses';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationInternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const InternalSourceAnnotation = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  const { icon, label } = useInternalSourceDetail(sourceData);

  return (
    <CoreSourceAnnotation
      sourceNumber={sourceNumber}
      label={label}
      icon={icon}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
    />
  );
};
