import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  U21Button,
  U21Checkbox,
  U21Chip,
  U21Page,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { NotificationsTable } from 'app/modules/notifications/components/NotificationsTable';
import { IconBell, IconChecks, IconSettings } from '@u21/tabler-icons';

// Selectors
import {
  selectUnreadNotificationsCount,
  selectLoadingMarkAllNotificationsRead,
} from 'app/modules/notifications/selectors';

// Thunks
import { markAllNotificationsReadThunk } from 'app/modules/notifications/sliceNotifications';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { selectAgent } from 'app/modules/session/selectors';

export const NotificationsPage = () => {
  const dispatch = useDispatch();
  const notificationsUnreadCount = useSelector(selectUnreadNotificationsCount);
  const loadingMarkAllNotificationsRead = useSelector(
    selectLoadingMarkAllNotificationsRead,
  );
  const { id } = useSelector(selectAgent);

  const [unreadOnly, setUnreadOnly] = useState<boolean>(false);

  const handleMarkAllOnClick = () => {
    dispatch(markAllNotificationsReadThunk());
  };

  return (
    <U21Page
      action={
        <U21Button
          aria-label="Settings"
          to={`${ROUTES_MAP.agentsIdTab.path.replace(':id', String(id)).replace(':tab', 'notifications')}`}
        >
          <IconSettings />
        </U21Button>
      }
      title="Notifications"
      titleIcon={<IconBell />}
    >
      <U21Section
        title={
          <U21Spacer horizontal>
            <U21Typography variant="h4">Notifications List</U21Typography>
            <U21Chip>{notificationsUnreadCount} unread notifications</U21Chip>
          </U21Spacer>
        }
        action={
          <U21Spacer horizontal>
            <U21Checkbox
              label="Only show unread notifications"
              onChange={setUnreadOnly}
              checked={unreadOnly}
            />
            <U21Button
              startIcon={<IconChecks />}
              onClick={handleMarkAllOnClick}
              loading={loadingMarkAllNotificationsRead}
            >
              Mark all as read
            </U21Button>
          </U21Spacer>
        }
      >
        <NotificationsTable unreadOnly={unreadOnly} />
      </U21Section>
    </U21Page>
  );
};
