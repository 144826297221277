import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import {
  U21Chip,
  U21Table,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  EntityGroupItem,
  GraphResult,
  NetworkAnalysisGroups,
} from 'app/modules/networkAnalysisRefresh/types';
import pluralize from 'pluralize';
import { NODE_OR_CONNECTION_TYPE_TO_ICON } from 'app/modules/networkAnalysisRefresh/constants';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';

interface Props {
  entities: EntityGroupItem[];
}

export const LinkedEntitiesTable = ({ entities }: Props) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { data, networkGroups } = useNetworkAnalysisContext();
  const columns = useMemo(() => {
    const cols = getColumns();
    if (data) {
      cols.push(
        getLinksColumn(
          data?.graph_result?.nodes,
          networkGroups,
          history,
          pathname,
          search,
        ),
      );
    }
    return cols;
  }, [data, networkGroups, history, pathname, search]);

  return (
    <U21Table
      data={entities}
      columns={columns}
      onRowClick={(id, row) =>
        dispatch(
          toggleSidebar({
            type: SidebarComponentTypes.ENTITY,
            data: { id: 0, externalId: row.external_id },
          }),
        )
      }
      defaultPageSize={10}
    />
  );
};

export const getColumns = <
  T extends EntityGroupItem = EntityGroupItem,
>(): U21TableColumn<T, any>[] => [
  { id: 'external_id', Header: 'External ID', accessor: 'external_id' },
  { id: 'name', Header: 'Name', accessor: 'display_name' },
  { id: 'type', Header: 'Type', accessor: 'type' },
  { id: 'subtype', Header: 'Subtype', accessor: 'internal_entity_type' },
  { id: 'status', Header: 'Status', accessor: 'status' },
  {
    id: 'link count',
    Header: 'Link count',
    accessor: (row) => pluralize('Link', row.links.length, true),
    cellProps: (row) => ({
      color: row.links.length > 3 ? 'error' : 'warning',
    }),
  },
];

export const getLinksColumn = (
  nodes: GraphResult['nodes'],
  groups: NetworkAnalysisGroups,
  history,
  pathname: string,
  search: string,
) => {
  return {
    id: 'links',
    accessor: 'links',
    Header: 'Links',
    type: 'list' as const,
    cellProps: {
      displayFunc: (linkId: string) => (
        <U21Chip
          icon={NODE_OR_CONNECTION_TYPE_TO_ICON[nodes[linkId].node_type]}
          key={linkId}
          onClick={(e) => {
            e.stopPropagation();
            history.replace(`${pathname}${search}#${linkId}`);
          }}
          disabled={!groups.links[linkId]}
        >
          {nodes[linkId].label}
        </U21Chip>
      ),
    },
    disableSortBy: true,
  };
};
