import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';

interface BulkActionInstrumentsPayload {
  instrument_external_ids: string[];
  data_labels_to_add: (string | number)[];
  data_labels_to_remove: (string | number)[];
}

const bulkApplyDataLabelsToInstruments = (
  payload: BulkActionInstrumentsPayload,
): Promise<BulkActionInstrumentsPayload> =>
  post(`/instruments/bulk-apply/data-labels`, payload);

export const useBulkApplyTagsToInstruments = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: BulkActionInstrumentsPayload) =>
      bulkApplyDataLabelsToInstruments(payload),
    onError: (_, payload) => {
      const { instrument_external_ids: instrumentIds } = payload;
      dispatch(
        sendErrorToast(
          `Failed to edit instrument(s): ${new Intl.ListFormat('en-US').format(instrumentIds)}`,
        ),
      );
    },
    onSuccess: () => {
      dispatch(sendSuccessToast('Successfully edited instrument(s).'));
    },
  });
};
