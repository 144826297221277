import { HistoryItem } from 'app/modules/navigator/models';

const IMPERSONATE_AS = 'impersonate_as';
const IMPERSONATE_LOG_ID = 'impersonate_log_id';
const ORIGINAL_AGENT = 'original_agent';
const ROUTING_HISTORY = 'ROUTING_HISTORY';

const ORG_SWITCHER_AGENT_ID = 'org_switcher_agent_id';
const ORG_SWITCHER_LOG_ID = 'org_switcher_log_id';

export const setImpersonateId = (impersonateId: number): void =>
  sessionStorage.setItem(IMPERSONATE_AS, String(impersonateId));

export const getImpersonateId = () =>
  Number(sessionStorage.getItem(IMPERSONATE_AS));

export const setImpersonateLogId = (impersonateLogId: number): void =>
  sessionStorage.setItem(IMPERSONATE_LOG_ID, String(impersonateLogId));

export const getImpersonateLogId = () =>
  Number(sessionStorage.getItem(IMPERSONATE_LOG_ID));

export const setOrgSwitcherAgentId = (orgSwitcherAgentId: number): void =>
  sessionStorage.setItem(ORG_SWITCHER_AGENT_ID, String(orgSwitcherAgentId));

export const getOrgSwitcherAgentId = () =>
  Number(sessionStorage.getItem(ORG_SWITCHER_AGENT_ID));

export const setOrgSwitcherLogId = (logId: number): void =>
  sessionStorage.setItem(ORG_SWITCHER_LOG_ID, String(logId));

export const getOrgSwitcherLogId = (): number | null =>
  Number(sessionStorage.getItem(ORG_SWITCHER_LOG_ID));

export const setOriginalAgentId = (agentId: number): void =>
  sessionStorage.setItem(ORIGINAL_AGENT, String(agentId));

export const getOriginalAgentId = () =>
  Number(sessionStorage.getItem(ORIGINAL_AGENT));
export const clearSessionStorage = () => sessionStorage.clear();

export const getRoutingHistory = (): HistoryItem[] => {
  try {
    const history = sessionStorage.getItem(ROUTING_HISTORY);
    if (!history) {
      return [];
    }
    const parsed = JSON.parse(history);
    // validate history
    if (parsed.every((i) => typeof i.route === 'string')) {
      return parsed;
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const setRoutingHistory = (history: HistoryItem[]): void => {
  sessionStorage.setItem(ROUTING_HISTORY, JSON.stringify(history));
};

export const clearRoutingHistory = () => {
  sessionStorage.removeItem(ROUTING_HISTORY);
};
