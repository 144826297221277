import { LinkSection } from 'app/modules/networkAnalysisRefresh/components/LinkSection';
import { TransactionsSection } from 'app/modules/networkAnalysisRefresh/components/TransactionsSection';
import { isEmpty } from 'lodash';
import { EntityRelationshipsSection } from 'app/modules/networkAnalysisRefresh/components/EntityRelationshipsSection';
import { InstrumentRelationshipsSection } from 'app/modules/networkAnalysisRefresh/components/InstrumentRelationshipsSection';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';

export const NetworkLinks = () => {
  const { data, networkGroups } = useNetworkAnalysisContext();
  if (!data) {
    return null;
  }
  return (
    <>
      {!isEmpty(networkGroups.entityRelationships) && (
        <EntityRelationshipsSection />
      )}
      {!isEmpty(networkGroups.instrumentRelationships) && (
        <InstrumentRelationshipsSection />
      )}
      {!isEmpty(networkGroups.transactions) && <TransactionsSection />}
      {Object.values(networkGroups.links).map((link) => (
        <LinkSection key={link.id} link={link} />
      ))}
    </>
  );
};
