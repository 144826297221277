import { TRANSACTIONS_SECTION_HASH } from 'app/modules/networkAnalysis/constants';
import { U21Badge, U21Section, U21Table } from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch } from 'react-redux';
import {
  EntityGroupItem,
  NetworkAnalysisGroups,
} from 'app/modules/networkAnalysisRefresh/types';
import { SelectFormatAmountPayload } from 'app/modules/orgSettings/models';
import { useFormatAmountWithDataSettingsPrecision } from 'app/modules/dataSettings/hooks/useFormatAmountWithDataSettingsPrecision';
import {
  getColumns,
  getLinksColumn,
} from 'app/modules/networkAnalysisRefresh/components/LinkedEntitiesTable';
import palette from 'vendor/material-minimal/palette';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { CopyLinkSectionHash } from 'app/modules/networkAnalysisRefresh/components/CopyLinkSectionHash';

interface TransactionLinkTableRow extends EntityGroupItem {
  amount: string;
  amountColor: string;
  rowId: string;
}

export const TransactionsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(TRANSACTIONS_SECTION_HASH, sectionRef);
  }, [handleLinkSectionMounted]);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const linkedTransactionsLength = useMemo(
    () => Object.keys(networkGroups.transactions).length,
    [networkGroups.transactions],
  );

  const formatAmount = useFormatAmountWithDataSettingsPrecision();

  const tableData = useMemo(
    () => makeTransactionsLinksTableData(networkGroups, formatAmount),
    [networkGroups, formatAmount],
  );

  const reduxDispatch = useDispatch();

  const columns = useMemo(() => {
    const cols = [
      {
        id: 'amount',
        Header: 'Amount',
        accessor: 'amount',
        cellProps: ({ amountColor }: TransactionLinkTableRow) => ({
          color: amountColor,
        }),
      },
      ...getColumns<TransactionLinkTableRow>(),
    ];
    if (data) {
      cols.push(
        getLinksColumn(
          data?.graph_result?.nodes,
          networkGroups,
          history,
          pathname,
          search,
        ),
      );
    }
    return cols;
  }, [data, networkGroups, history, pathname, search]);

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedTransactionsLength}>
            <span>Transactions</span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(TRANSACTIONS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(TRANSACTIONS_SECTION_HASH)}
        action={<CopyLinkSectionHash hash={TRANSACTIONS_SECTION_HASH} />}
      >
        <U21Table<TransactionLinkTableRow>
          data={tableData}
          columns={columns}
          onRowClick={(_, { external_id: extId }) =>
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id: 0,
                  externalId: extId,
                },
              }),
            )
          }
          getRowID={({ rowId }: TransactionLinkTableRow) => rowId}
        />
      </U21Section>
    </div>
  );
};

const makeTransactionsLinksTableData = (
  { transactions, entities }: NetworkAnalysisGroups,
  formatAmount: (payload: SelectFormatAmountPayload) => string,
) =>
  Object.values(transactions).reduce<TransactionLinkTableRow[]>(
    (acc, txnEdge) => {
      const {
        id,
        source,
        target,
        transaction_data: { amount, currency, flow },
      } = txnEdge;
      const sourceEntity = entities[source];
      const targetEntity = entities[target];
      const formattedAmount = `${flow === 'OUTBOUND' ? '-' : ''}${formatAmount({
        amount,
        currencyCodeProps: currency ?? undefined,
      })}`;
      let amountColor =
        flow === 'OUTBOUND'
          ? palette.light.error.main
          : palette.light.success.main;
      if (flow === 'OTHER') {
        amountColor = palette.light.text.secondary;
      }
      acc.push({
        ...(sourceEntity.is_base_node ? targetEntity : sourceEntity),
        amount: formattedAmount,
        rowId: id,
        amountColor,
      });
      return acc;
    },
    [],
  );
