import {
  U21Alert,
  U21Button,
  U21Chip,
  U21Loading,
  U21NoData,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filters } from 'app/modules/networkAnalysisRefresh/components/Filters';
import { resetSelectedNodesAndEdges } from 'app/modules/networkAnalysis/helpers';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { useHistory, useLocation } from 'react-router';
import {
  LINK_ANALYSIS_LINK_TYPES,
  TRANSACTIONS_SECTION_HASH,
  ELEMENT_KEYS_TO_UPDATE,
  GRAPH_ALERT_HINTS,
  ENTITY_RELATIONSHIPS_SECTION_HASH,
  INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
} from 'app/modules/networkAnalysis/constants';
import {
  U21NetworkGraph,
  U21NetworkGraphProps,
} from 'app/shared/components/Graphs/U21NetworkGraph';
import { Core, EdgeSingular, EventObject, NodeSingular } from 'cytoscape';
import {
  IconAffiliate,
  IconCircleDot,
  IconGridDots,
  IconSpace,
  IconSpaceOff,
} from '@u21/tabler-icons';
import { NetworkLinks } from 'app/modules/networkAnalysisRefresh/components/NetworkLinks';
import {
  CONCENTRIC_LAYOUT_OPTIONS,
  GRID_LAYOUT_OPTIONS,
} from 'app/shared/components/Graphs/constants';
import { useQueueNetworkAnalysis } from 'app/modules/networkAnalysisRefresh/queries/useQueueNetworkAnalysis';
import { NetworkAnalysisStatus } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysisStatus';
import {
  selectNodes,
  getBaseNodeId,
  getCytoscapeOptions,
} from 'app/modules/networkAnalysisRefresh/helpers';
import {
  BaseObjectType,
  ConnectionType,
  EntityNodeData,
  NodeType,
} from 'app/modules/networkAnalysisRefresh/types';
import pluralize from 'pluralize';
import styled from 'styled-components';
import {
  ShortEntityResponse,
  FullEntityResponse,
} from 'app/modules/entities/types';
import {
  FullTxnInstrumentResponse,
  ShortTxnInstrumentResponse,
} from 'app/modules/txnInstruments/types';
import {
  NetworkAnalysisProvider,
  useNetworkAnalysisContext,
} from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { usePollNetworkAnalysisStatus } from 'app/modules/networkAnalysisRefresh/queries/usePollNetworkAnalysisStatus';

interface EntityNetworkAnalysisProps {
  baseObject: ShortEntityResponse | FullEntityResponse;
  baseObjectType: BaseObjectType.ENTITY;
}

interface InstrumentNetworkAnalysisProps {
  baseObject: ShortTxnInstrumentResponse | FullTxnInstrumentResponse;
  baseObjectType: BaseObjectType.INSTRUMENT;
}

const NetworkAnalysisInner = () => {
  const {
    externalId,
    baseObjectType,
    data,
    isLoading,
    elements,
    totals: {
      totalObjects,
      totalLinks,
      totalTransactions,
      totalEntityRelationships,
      totalInstrumentRelationships,
    },
    setExpandedSections,
    hashReferences,
  } = useNetworkAnalysisContext();

  const { hash, pathname, search } = useLocation();
  const { mutate: queueNetworkAnalysis, isPending: isQueuePending } =
    useQueueNetworkAnalysis(externalId, baseObjectType);
  const { data: statusData } = usePollNetworkAnalysisStatus(
    externalId,
    baseObjectType,
  );

  const reduxDispatch = useDispatch();
  const history = useHistory();

  const [spaceNodes, setSpaceNodes] = useState<boolean>(
    Object.keys(data?.graph_result?.nodes ?? {}).length < 30,
  );
  const [isGridLayout, setIsGridLayout] = useState<boolean>(false);

  const baseNodeId = useMemo(() => getBaseNodeId(data), [data]);

  const handleGraphClick: U21NetworkGraphProps['handleGraphClick'] =
    useCallback(
      (e: EventObject, cy: Core | null) => {
        const group = e.target?.group?.();
        const isMetaClick = e.originalEvent.ctrlKey || e.originalEvent.metaKey;
        const urlBase = `${pathname}${search}`;
        if (group === 'nodes') {
          const node: NodeSingular = e.target;
          const { id, node_type: type, ...rest } = node.data();
          resetSelectedNodesAndEdges(cy);
          selectNodes(node, baseNodeId);
          // cmd/ctrl click should open sidebar or scroll to related link section
          if (isMetaClick) {
            if (
              baseObjectType === BaseObjectType.ENTITY &&
              type === NodeType.ENTITY
            ) {
              const entityNodeData: EntityNodeData = rest;
              reduxDispatch(
                toggleSidebar({
                  type: SidebarComponentTypes.ENTITY,
                  data: {
                    id: 0,
                    externalId: entityNodeData.external_id,
                  },
                }),
              );
            } else if (
              baseObjectType === BaseObjectType.INSTRUMENT &&
              type === NodeType.INSTRUMENT
            ) {
              // TODO SC-84032: implement instrument sidebar
            } else {
              const linkHash =
                node.data('type') === LINK_ANALYSIS_LINK_TYPES.TRANSACTION
                  ? TRANSACTIONS_SECTION_HASH
                  : id;
              history.replace(`${urlBase}#${linkHash}`);
            }
          }
        }
        // if target is a transaction or relationship edge, highlight it and its nodes
        else if (group === 'edges') {
          resetSelectedNodesAndEdges(cy);
          const edge: EdgeSingular = e.target;
          edge.data('selected', true);
          for (const el of edge.connectedNodes()) {
            el.data('selected', true);
          }
          if (isMetaClick) {
            const edgeType = edge.data('edge_type');
            if (edgeType === ConnectionType.TRANSACTION) {
              history.replace(`${urlBase}#${TRANSACTIONS_SECTION_HASH}`);
            } else if (edgeType === ConnectionType.ENTITY_RELATIONSHIP) {
              history.replace(
                `${urlBase}#${ENTITY_RELATIONSHIPS_SECTION_HASH}`,
              );
            } else if (
              baseObjectType === BaseObjectType.ENTITY &&
              edgeType === NodeType.INSTRUMENT
            ) {
              history.replace(
                `${urlBase}#${INSTRUMENT_RELATIONSHIPS_SECTION_HASH}`,
              );
            } else if (edgeType === ConnectionType.INSTRUMENT_RELATIONSHIP) {
              // TODO SC-83543: implement instrument relationship click
            } else if (
              baseObjectType === BaseObjectType.INSTRUMENT &&
              edgeType === NodeType.ENTITY
            ) {
              // TODO SC-83543: implement entity relationship click
            }
          }
        }
      },
      [pathname, search, baseNodeId, baseObjectType, reduxDispatch, history],
    );

  /**
   * Effect to scroll to url-hash link element
   */
  useEffect(() => {
    if (hash) {
      const parsedHash = hash.slice(1).replaceAll('%20', ' ');
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        newSet.add(parsedHash);
        return newSet;
      });
      const sectionToScroll = hashReferences[parsedHash];
      setTimeout(() => {
        if (sectionToScroll && sectionToScroll.current) {
          sectionToScroll.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }, [hash, hashReferences, setExpandedSections]);

  const [showHints, setShowHints] = useState<boolean>(true);
  const objectHasLinks: boolean = useMemo(() => {
    return !!data && Object.keys(data.graph_result.nodes).length > 0;
  }, [data]);

  const layoutOptions = useMemo(
    () => ({
      ...(isGridLayout ? GRID_LAYOUT_OPTIONS : CONCENTRIC_LAYOUT_OPTIONS),
      nodeDimensionsIncludeLabels: spaceNodes,
    }),
    [spaceNodes, isGridLayout],
  );

  const graphActions: U21NetworkGraphProps['actions'] = useMemo(
    () => [
      {
        key: 'spaceNodes',
        children: spaceNodes ? <IconSpaceOff /> : <IconSpace />,
        tooltip: spaceNodes
          ? 'Remove additional spacing between nodes'
          : 'Add additional spacing between nodes',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setSpaceNodes((prev) => !prev),
      },
      {
        key: 'alterLayout',
        children: isGridLayout ? <IconCircleDot /> : <IconGridDots />,
        tooltip: isGridLayout ? 'Use concentric layout' : 'Use grid layout',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setIsGridLayout((prev) => !prev),
      },
    ],
    [spaceNodes, isGridLayout],
  );

  const cytoscapeOptions = useMemo(
    () => getCytoscapeOptions(baseObjectType),
    [baseObjectType],
  );

  return (
    <U21Spacer spacing={4}>
      <U21Section
        title={
          <U21Spacer horizontal align="center">
            <span>Network Analysis</span>
            {objectHasLinks && (
              <>
                <U21Chip>{pluralize('Link', totalLinks, true)}</U21Chip>
                <StyledChip>
                  {pluralize(
                    'Entity relationship',
                    totalEntityRelationships,
                    true,
                  )}
                </StyledChip>
                <StyledChip>
                  {pluralize(
                    'Instrument relationship',
                    totalInstrumentRelationships,
                    true,
                  )}
                </StyledChip>
                <U21Chip>
                  {pluralize('Transaction', totalTransactions, true)}
                </U21Chip>
                <StyledChip>
                  {pluralize(`Linked ${baseObjectType}`, totalObjects, true)}
                </StyledChip>
              </>
            )}
          </U21Spacer>
        }
        titleIcon={<IconAffiliate />}
        action={
          <U21Button
            onClick={() => queueNetworkAnalysis(externalId)}
            loading={isQueuePending}
            disabled={
              statusData?.status === 'QUEUED' ||
              statusData?.status === 'IN_PROGRESS'
            }
            tooltip={
              statusData?.status === 'QUEUED'
                ? 'Analysis already queued'
                : 'Analysis already in progress'
            }
          >
            Run New Analysis
          </U21Button>
        }
      >
        <U21Spacer spacing={2}>
          <NetworkAnalysisStatus
            externalId={externalId}
            baseObjectType={baseObjectType}
          />
          {!!data && objectHasLinks && <Filters />}
          {isLoading ? (
            <U21Loading loading />
          ) : (
            <>
              {(() => {
                if (objectHasLinks) {
                  return (
                    <U21Alert
                      hidden={!showHints}
                      severity="info"
                      title="How to use this chart:"
                      onClose={() => {
                        setShowHints(false);
                      }}
                    >
                      {GRAPH_ALERT_HINTS.map((hint) => (
                        <U21Typography key={hint} variant="body2">
                          <li>{hint}</li>
                        </U21Typography>
                      ))}
                    </U21Alert>
                  );
                }
                if (data) {
                  return (
                    <U21Alert severity="info">
                      This {baseObjectType} has no connections. Please run a new
                      analysis if this is unexpected.
                    </U21Alert>
                  );
                }
                return null;
              })()}
              {data?.graph_result ? (
                <U21NetworkGraph
                  cytoscapeOptions={cytoscapeOptions}
                  elements={elements}
                  elementKeysToUpdate={ELEMENT_KEYS_TO_UPDATE}
                  handleGraphClick={handleGraphClick}
                  layoutOptions={layoutOptions}
                  actions={graphActions}
                />
              ) : (
                <U21NoData>
                  This {baseObjectType} has no results. Try running a new
                  analysis.
                </U21NoData>
              )}
            </>
          )}
        </U21Spacer>
      </U21Section>
      {/* TODO SC-83543: add network groups for instruments */}
      {!!data && baseObjectType === BaseObjectType.ENTITY && <NetworkLinks />}
    </U21Spacer>
  );
};

const StyledChip = styled(U21Chip)`
  text-transform: none;
`;

export const NetworkAnalysis = ({
  baseObject,
  baseObjectType,
}: EntityNetworkAnalysisProps | InstrumentNetworkAnalysisProps) => {
  return (
    <NetworkAnalysisProvider
      externalId={baseObject.external_id}
      baseObjectType={baseObjectType}
    >
      <NetworkAnalysisInner />
    </NetworkAnalysisProvider>
  );
};
