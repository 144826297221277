// Utils
import { consoleWarn } from 'app/shared/utils/console';
import { matchPath } from 'react-router-dom';
import getFromProcessEnv from 'app/shared/utils/getFromProcessEnv';
import permissions from 'app/shared/utils/permissions';
import { FeatureFlag } from 'app/shared/featureFlags/models';

// combine path strings without "//" bugs
// https://stackoverflow.com/questions/29855098/is-there-a-built-in-javascript-function-similar-to-os-path-join
export const pathJoin = (...args: Array<string | number>) => {
  return args
    .map((part, i) => {
      if (i === 0) {
        return String(part).trim().replace(/[/]*$/g, '');
      }
      return String(part)
        .trim()
        .replace(/(^[/]*|[/]*$)/g, '');
    })
    .filter((x) => x.length)
    .join('/');
};

export const openInNewTab = (path: string) => {
  const baseUrl = getFromProcessEnv('REACT_APP_AUTH0_LOGIN_REDIRECT_URL');
  // add base url to our shortened /example routes if not coming from a test
  const formattedPath =
    process.env.NODE_ENV !== 'test' ? pathJoin(baseUrl, path) : path;

  // temporarily remove routing history since window.open transfers it to the new tab
  // Note: cannot use imports from sessionStorage since there's an import cycle
  const history = sessionStorage.getItem('ROUTING_HISTORY');
  sessionStorage.removeItem('ROUTING_HISTORY');
  window.open(formattedPath);
  if (history) {
    sessionStorage.setItem('ROUTING_HISTORY', history);
  }
};

/**
 * @deprecated Use ROUTES or ROUTES_MAP for lumos routes instead
 * Define patronus routes directly in react-query hook instead
 */
const routes = {
  lumos: {
    // Home
    home: '/',

    // Unit21 Login
    agentLogin: '/agent-login',
    emailLogin: '/email-login',

    // Cases Routes
    cases: '/cases',
    casesId: '/cases/:id',
    casesIdTab: '/cases/:id/:tab?',
    casesIdAttachments: '/cases/:id/attachments',
    casesIdComments: '/cases/:id/comments',
    casesCreate: '/cases/create',
    pdfExportCasesId: '/pdf-export/cases/:caseId',

    // Dashboards Routes
    dashboards: '/dashboards',
    dashboardsId: '/dashboards/:id',

    // Agents Routes
    agentsId: '/agents/:id',
    agentsIdTab: '/agents/:id/:tab?',

    // Teams Routes
    teamsId: '/teams/:id',
    teamsIdTab: '/teams/:id/:tab?',

    // Alerts Routes
    alerts: '/alerts',
    alertsCreate: '/alerts/create',
    myAlerts: '/alerts/my-alerts',
    alertAdmin: '/alerts/admin',
    alertsId: '/alerts/:id',
    alertsIdTab: '/alerts/:id/:tab?',
    pdfExportAlertsAlertId: '/pdf-export/alerts/:alertId',

    // Entities Routes
    entitiesId: '/entities/:id',
    entitiesExternalId: '/unit21/entities/:entity-external-id',
    entitiesIdTab: '/entities/:id/:tab?',

    // Report filings
    filings: '/filings',

    // Report filings: SAR
    filingsSARs: '/filings/sars',
    filingsSar: '/filings/sar',
    filingsSarId: '/filings/sar/:id',

    // Report filings: goAML
    filingsGoAML: '/filings/go-aml',
    filingsGoAMLCreate: '/filings/go-aml/create',
    filingsGoAMLIdEdit: '/filings/go-aml/:id/edit',

    // Detection Modeling
    detectionModels: '/detection-models',
    detectionModelsValidating: '/detection-models/validating',
    detectionModelsShadow: '/detection-models/shadow',
    detectionModelsCreateSimple: '/detection-models/create/simple',
    detectionModelsCreateAdvancedEntity:
      '/detection-models/create/advanced/entity',
    detectionModelsCreateAdvancedInstrument:
      '/detection-models/create/advanced/instrument',
    detectionModelsValidationId: '/detection-models/validation/:id',
    detectionModelsId: '/detection-models/:id',
    detectionModelsIdTab: '/detection-models/:id/:tab?',
    pdfExportRulesRuleId: '/pdf-export/rules/:ruleId',

    // Txn Instruments Routes
    instruments: '/instruments',
    instrumentsId: '/instruments/:id',
    instrumentsIdTab: '/instruments/:id/:tab?',

    // Notifications Routes
    notifications: '/notifications',

    // Error Routes
    error: '/error',
    fourOhfour: '/404',

    // Webhook Routes
    webhooksId: '/webhooks/:id',

    // Data Explorer
    dataExplorer: '/data-explorer',
    dataExplorerEntities: '/data-explorer/entities',
    dataExplorerUploadFile: '/data-explorer/upload-file',
    dataExplorerInstruments: '/data-explorer/instruments',
    dataExplorerTransactions: '/data-explorer/transactions',
    dataExplorerActionEvents: '/data-explorer/action-events',
    dataExplorerActionEventsId: '/data-explorer/action-events/:id',
    dataExplorerTransactionsId: '/data-explorer/transactions/:id',
    dataExplorerDataMappingUI: '/data-explorer/data-mapping/:id',
    dataExplorerFileUploads: '/data-explorer/flat-file-ingestion',
  },
  patronus: {
    // Audit Trail API
    auditTrailId: '/audit-trail/:id',
    auditTrailList: '/audit-trail/list',

    // Orgs
    orgsScenarioConfigs: '/orgs/scenario-configs',
    orgsAPIConfig: '/orgs/api-config',
    orgsAutocomplete: '/orgs/autocomplete',
    orgsRelevantOrgsDetails: '/orgs/relevant-orgs/details',

    // Permissions
    permissionsAssociationTypeList:
      '/permissions/:associationType/:associationId/assigned',
    permissionsAssociationTypeUnassigned:
      '/permissions/:associationType/:associationId/unassigned',
    permissionsAssociationTypeAssociationId:
      '/permissions/:associationType/:associationId',

    apiKeysList: '/api-keys/list',
    apiKeysCreate: '/api-keys/create',
    apiKeysUpdatePermissions: '/api-keys/update-permissions',
    apiKeysDeactivateKeyPrefix: '/api-keys/deactivate/:keyPrefix',

    webhooksActionTriggers: '/webhooks/action-triggers',

    deadlinesConfigList: '/deadlines_config/list',
    deadlinesConfigId: '/deadlines_config/:id',
    deadlinesConfigCreate: '/deadlines_config/create',
    deadlinesEdit: '/deadlines/edit',
    goAMLTemplatesUpdate: '/go-aml/templates/update',
    goAMLTemplates: '/go-aml/templates/list',

    // Cases API
    casesCreate: '/cases/create',
    casesEdit: '/cases/edit/',
    casesEditId: '/cases/edit/:id',
    casesIdAttachmentsAttachmentId: '/cases/:id/attachments/:attachmentId',
    casesIdTriggerAction: '/cases/:id/trigger-action',
    casesIdAddFiles: '/cases/:id/add-files',
    casesSingleEmailXlsx: '/cases/single-email-xlsx',
    casesBulkXlsx: '/cases/bulk-xlsx',

    // Comments API
    comments: '/comments',

    // Entities API
    entitiesSearch: '/entities/search',
    entitiesIdPaginatedAlerts: '/entities/:entity-external-id/paginated-alerts',
    entitiesEditId: '/entities/edit/:id',
    entitiesLinkAnalysisId: '/entities/link-analysis/:id',
    entitiesLinkAnalysisMetadata: '/entities/link-analysis/metadata',
    entitiesQueueLinkAnalysisId: '/entities/link-analysis/queue/:id',
    entitiesPaginatedGloballyWhitelistedRules:
      '/entities/paginated-globally-whitelisted-entities',
    entitiesWhitelistGlobally: '/entities/whitelist-globally',
    entitiesUnwhitelistGlobally: '/entities/unwhitelist-globally',

    // Custom Checklist API
    customChecklist: '/custom-checklist',
    customChecklistSearch: '/custom-checklist/search',
    customChecklistId: '/custom-checklist/:id',
    customChecklistArticleIdSubmission:
      '/custom-checklist/article/:id/submission',

    // Investigation Checklist
    investigationChecklistList: '/custom-checklist/list',

    // Events API
    eventsEventId: '/events/:eventId',
    eventsSearch: '/events/search',

    // Txn Events
    txnEventsEvaluationLogEventId: '/txn-events/:eventId/evaluation_log',

    // Action Events
    actionEventsEventId: '/action-events/:eventId',
    actionEventsList: '/action-events/list',
    actionEventsSearch: '/action-events/search',

    // Notes API
    notesArticleId: '/notes/:articleId',
    notesArticleIdAdd: '/notes/:articleId/add',
    notesNoteId: '/notes/:noteId',

    // Uploads API
    uploadsDatafiles: '/uploads/datafiles',
    uploadsDatafilesList: '/uploads/datafiles/list',
    uploadsDatafilesDownload: '/uploads/datafiles/download',
    uploadsDatafilesDelete: '/uploads/datafiles/delete',
    uploadsDatafilesProcess: '/uploads/datafiles/:id/process',

    // Tags API
    tagsAdd: '/tags/add',
    tagsList: '/tags/list',

    // Rules API
    rulesList: '/rules/list',
    rulesBulkDeactivate: '/rules/bulk-deactivate',
    rulesWhitelistEntities: '/rules/whitelist-entities',
    rulesUnwhitelistEntities: '/rules/unwhitelist-entities',
    rulesPaginatedWhitelistedEntities: '/rules/paginated-whitelisted-entities',
    rulesSearch: '/rules/search',
    validationRulesIdDelete: `/validation-rules/:id/delete`,
    validationRulesBulkDeploy: '/validation-rules/bulk-deploy',
    ruleValidationAlertsId: '/rule-validation-alerts/:id',
    ruleValidationAlertsIdTransactions:
      '/rule-validation-alerts/:id/transactions',
    ruleValidationAlertsIdEntities: '/rule-validation-alerts/:id/entities',
    validationRulesPaginatedAlertsId: '/validation-rules/paginated-alerts/:id',
    validationRulesList: `/validation-rules/list`,
    shadowRulesIdDeploy: `/shadow-rules/:id/deploy`,
    rulesEditId: '/rules/edit/:id',
    rulesSingleEmailXlsx: '/rules/single-email-xlsx',
    rulesBulkXlsx: '/rules/bulk-xlsx',

    // Detection Models
    detectionModels: '/detection-models',
    detectionModelsPerformanceChart: '/detection-models/performance-chart',
    detectionModelsValidationId: '/detection-models/validation/:id',
    detectionModelsValidationIdMetrics:
      '/detection-models/validation/:id/metrics',
    detectionModelsValidate: '/detection-models/validate',
    detectionModelsRevalidateId: '/detection-models/revalidate/:id',
    detectionModelsIdActivate: '/detection-models/:id/activate',
    detectionModelId: '/detection-models/:id',
    detectionModelsTemplates: '/detection-models/templates/list',
    detectionModelsTest: '/detection-models/:id/test',

    // Alerts API
    alertsAlertId: '/alerts/:alertId',
    alertsList: '/alerts/list',
    alertsIdTransactions: '/alerts/:id/transactions',
    alertsIdActionEvents: '/alerts/:id/action-events',
    alertsIdAuditTrail: '/alerts/:id/audit-trail',
    alertsIdEntities: '/alerts/:id/entities',
    alertsIdInstruments: '/alerts/:id/instruments',
    alertsCreate: '/alerts/create',
    alertsIdComponentResolution: '/alerts/:id/component-resolution',
    alertsReassign: '/alerts/reassign',
    alertsSearch: '/alerts/search',
    alertsEditId: '/alerts/edit/:id',
    alertsIdAddFiles: '/alerts/:id/add-files',
    alertsIdAttachmentId: '/alerts/:id/attachments/:attachmentId',
    alertsTriggerAction: '/alerts/:id/trigger-action',
    alertsAssociatedCases: '/alerts/associate-cases',
    alertsSingleEmailXlsx: '/alerts/single-email-xlsx',
    alertsBulkXlsx: '/alerts/bulk-xlsx',
    alertsHistogram: '/alerts/histogram',

    // Queues API
    queuesList: '/queues/list',
    queues: '/queues',
    queuesMyAlertsStats: '/queues/my-alerts-stats',
    requeueAlerts: '/queues/requeue',
    unassignedAlerts: '/queues/get-unassigned',

    // Auth API
    agentsIdp: '/agents/idp', // get google IDP
    googleOauthRefresh: '/google-oauth/refresh',
    login: '/login',
    tokenPing: '/token-ping',
    logout: '/logout',

    // Agents API
    agentsAutocomplete: '/agents/autocomplete',
    agentsLogImpersonationStart: '/agents/log-impersonation-start',
    agentsLogImpersonationCancelled: '/agents/log-impersonation-cancelled',
    agentsExchangeAuthEmailToken: '/agents/exchange-auth-email-token',
    agentsRefreshJwt: '/agents/refresh-jwt',

    // Teams API
    teamsAutocomplete: '/teams/autocomplete',
    teamsSearch: '/teams/search',

    // Sars API
    sarsCreate: '/sars/create',
    sars: '/sars',
    sarsId: '/sars/:id',
    sarsList: '/sars/list',
    sarsArchive: '/sars/archive',
    sarsSingleEmailXlsx: '/sars/single-email-xlsx',
    sarsBulkXlsx: '/sars/bulk-xlsx',

    // GoAML API
    goAMLCreate: '/go-aml/create',
    goAMLIdEdit: '/go-aml/:id/edit',
    goAMLIdExport: '/go-aml/:id/export',
    goAMLValidate: '/go-aml/:id/validate',
    goAMLIdAttachmentAttachmentId: '/go-aml/:id/attachment/:attachmentId',
    goAMLIdAttachment: '/go-aml/:id/attachment',

    // Custom/Table Configs API
    customConfigs: '/custom-configs',
    customConfigsOrg: '/custom-configs/org',

    // Webhooks
    webhooksId: '/webhooks/:id',
    webhooksEditId: '/webhooks/edit/:id',
    webhooksList: '/webhooks/list',
    webhooksCreate: '/webhooks/create',
    webhooksAlertActionTriggersList: '/webhooks/alert/action-triggers/list',
    webhookActionTypesList: '/webhooks/action-types/list',
    webhooksDeleteId: '/webhooks/delete/:id',
    webhooksRotateId: '/webhooks/:id/rotate-key',
    webhooksIdCallbacks: '/webhooks/:id/callback-attempts',
    webhooksTestId: '/webhooks/test/:id',

    // Workflows
    workflows: '/workflows',
    workflowsId: '/workflows/:id',
    workflowsList: '/workflows/list',
    workflowsSubdispositionsList: '/workflows/subdispositions',

    // Insights API
    insightsChartDefinitionsList: '/insights/chart-definitions/list',
    insightsDashboardsList: '/insights/dashboards/list',
    insightsDashboardsId: '/insights/dashboards/:id',
    insightsChartsId: '/insights/charts/:id',
    insightsDashboardsCreate: '/insights/dashboards/create',
    insightsDashboardsEditId: '/insights/dashboards/edit/:id',
    insightsDashboardsDuplicateId: '/insights/dashboards/duplicate/:id',
    insightsChartsEditId: '/insights/charts/edit/:id',

    // Notifications API
    notificationsPaginated: '/notifications/paginated',
    notificationsRead: '/notifications/read',
    notificationsReadAll: '/notifications/read-all',

    // Blacklists API
    blacklistsId: '/blacklists/:id',
    blacklistsList: '/blacklists/list',
    blacklistsCreate: '/blacklists/create',
    blacklistsIdEdit: '/blacklists/:id/edit',
    blacklistsIdAddItem: '/blacklists/:id/add-item',
    blacklistsIdAddUser: '/blacklists/:id/add-user',
    blacklistsIdAddBusiness: '/blacklists/:id/add-business',
    blacklistsIdListItems: '/blacklists/:id/list-items',
    blacklistsIdAppendFile: '/blacklists/:id/append-file',
    blacklistsIdDeactivateItem: '/blacklists/:id/deactivate-item',

    // File Exports API
    fileExportsDownload: '/file-exports/download',
    fileExportsRuleValidationFileExportsId:
      '/file-exports/rule-validation-file-exports/:id',
  },

  external: {
    // External (interwebs)
    googleDrive: {
      filePrefix: 'https://drive.google.com/file/d/',
      folderPrefix: 'https://drive.google.com/drive/folders/',
    },
  },
};

export interface Route {
  exact?: boolean;
  id: string;
  name: string;
  path: string;
  permissions?: string[];
  anyPermissions?: string[];
  requiredFeatureFlags?: FeatureFlag[];
}

// this will be the new way to define routes
// additional keys (anything used in AppRouter) will be added
//
// Exact routes should come before non-exact routes if the non-exact route is part of the exact route path
export const ROUTES = [
  // Alerts
  { exact: true, id: 'alerts', name: 'Alerts', path: routes.lumos.alerts },
  {
    exact: true,
    id: 'myAlerts',
    name: 'My Alerts',
    path: routes.lumos.myAlerts,
    permissions: [permissions.readAlerts],
  },
  {
    exact: true,
    id: 'myAlertsEntities',
    name: 'My Alerts by Entities',
    path: '/alerts/my-alerts/entities',
    permissions: [permissions.readAlerts, permissions.readEntities],
  },

  {
    exact: true,
    id: 'alertAdmin',
    name: 'Admin Alerts',
    path: routes.lumos.alertAdmin,
    permissions: [permissions.readAlerts, permissions.readAssignments],
  },
  {
    exact: true,
    id: 'alertAdminEntities',
    name: 'Admin Alerts by Entities',
    path: '/alerts/admin/entities',
    permissions: [
      permissions.readAlerts,
      permissions.readAssignments,
      permissions.readEntities,
    ],
  },
  {
    exact: true,
    id: 'queuedAlerts',
    name: 'Queued Alerts',
    path: '/alerts/queued-alerts',
    permissions: [permissions.readAlerts],
  },
  {
    exact: true,
    id: 'queuedAlertsEntities',
    name: 'Queued Alerts by Entities',
    path: '/alerts/queued-alerts/entities',
    permissions: [permissions.readAlerts, permissions.readEntities],
  },
  {
    exact: true,
    id: 'alertsCreate',
    name: 'Create Alert',
    path: routes.lumos.alertsCreate,
  },
  { exact: true, id: 'alertsId', name: 'Alert', path: routes.lumos.alertsId },
  {
    exact: true,
    id: 'alertsIdTab',
    name: 'Alert',
    path: routes.lumos.alertsIdTab,
  },
  {
    exact: true,
    id: 'alertPDF',
    name: 'Alert PDF',
    path: routes.lumos.pdfExportAlertsAlertId,
  },

  // Cases
  {
    exact: true,
    id: 'myCases',
    name: 'My Cases',
    path: '/cases/my-cases',
    permissions: [permissions.readCases],
  },
  {
    exact: true,
    id: 'queuedCases',
    name: 'Queued Cases',
    path: '/cases/queued-cases',
    permissions: [permissions.readCases],
  },
  {
    exact: true,
    id: 'createCase',
    name: 'Create Case',
    path: '/cases/create-case',
    permissions: [permissions.createCases],
  },
  {
    id: 'adminCases',
    name: 'Admin Cases',
    path: '/cases/admin',
    permissions: [permissions.readCases, permissions.readAssignments],
  },
  {
    exact: true,
    id: 'cases',
    name: 'Cases',
    path: routes.lumos.cases,
    permissions: [permissions.readCases],
  },
  {
    exact: true,
    id: 'casesCreate',
    name: 'Create Case',
    path: routes.lumos.casesCreate,
    permissions: [permissions.readCases, permissions.createCases],
  },
  {
    exact: true,
    id: 'casesId',
    name: 'Case',
    path: routes.lumos.casesId,
    permissions: [permissions.readCases],
  },
  {
    exact: true,
    id: 'casesIdTab',
    name: 'Case',
    path: routes.lumos.casesIdTab,
  },
  {
    exact: true,
    id: 'pdfExportCasesId',
    name: 'Case PDF',
    path: routes.lumos.pdfExportCasesId,
    permissions: [permissions.readCases],
  },

  // Filings
  {
    exact: true,
    id: 'myFinCENSARs',
    name: 'My FinCEN SARs',
    path: '/filings/my-filings/fincen-sars',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'myFinCENCTRs',
    name: 'My FinCEN CTRs',
    path: '/filings/my-filings/fincen-ctrs',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'myGoAML',
    name: 'My goAML Filings',
    path: '/filings/my-filings/go-aml',
    permissions: [permissions.readSars],
  },
  {
    exact: false,
    id: 'myFilings',
    name: 'My Filings',
    path: '/filings/my-filings',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'queuedFinCENSARs',
    name: 'Queued FinCEN SARs',
    path: '/filings/queued-filings/fincen-sars',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'queuedFinCENCTRs',
    name: 'Queued FinCEN CTRs',
    path: '/filings/queued-filings/fincen-ctrs',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'queuedGoAML',
    name: 'Queued goAML Filings',
    path: '/filings/queued-filings/go-aml',
    permissions: [permissions.readSars],
  },
  {
    id: 'queuedFilings',
    name: 'Queued Filings',
    path: '/filings/queued-filings',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'adminFinCENSARs',
    name: 'Admin FinCEN SARs',
    path: '/filings/admin/fincen-sars',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'adminFinCENCTRs',
    name: 'Admin FinCEN CTRs',
    path: '/filings/admin/fincen-ctrs',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'adminGoAML',
    name: 'Admin goAML Filings',
    path: '/filings/admin/go-aml',
    permissions: [permissions.readSars],
  },
  {
    id: 'adminFilings',
    name: 'Admin Filings',
    path: '/filings/admin',
    permissions: [permissions.readSars, permissions.readAssignments],
  },
  {
    exact: true,
    id: 'filingsSarDownload',
    name: 'Download FinCEN SAR',
    path: '/filings/sar/:id/download',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'filingsSarId',
    name: 'FinCEN SAR',
    path: routes.lumos.filingsSarId,
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'filingsSarIdTab',
    name: 'FinCEN SAR',
    path: '/filings/sar/:id/:tab',
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'filingsGoAML',
    name: 'goAML filings',
    path: routes.lumos.filingsGoAML,
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'filingsGoAMLIdEdit',
    name: 'goAML filing',
    path: routes.lumos.filingsGoAMLIdEdit,
    permissions: [permissions.readSars],
  },
  {
    exact: true,
    id: 'filingsGoAMLCreate',
    name: 'Create goAML Filing',
    path: routes.lumos.filingsGoAMLCreate,
    permissions: [permissions.createSars],
  },

  // Report Filings: FinCEN CTR
  {
    exact: true,
    id: 'filingsFincenCtrEdit',
    name: 'FinCEN CTR',
    path: '/filings/fincen-ctr/:id/:tab?',
  },

  // Filings configuration
  {
    id: 'finCENConfiguration',
    name: 'FinCEN SAR Settings',
    path: '/filings/configuration/fincen',
    permissions: [permissions.editSarsConfig],
    exact: false,
  },
  {
    id: 'finCENConfigurationTab',
    name: 'FinCEN SAR Settings',
    path: '/filings/configuration/fincen/:tab',
    permissions: [permissions.editSarsConfig],
  },
  {
    id: 'goAMLConfiguration',
    name: 'goAML STR Settings',
    path: '/filings/configuration/goaml',
    permissions: [permissions.editSarsConfig],
  },
  {
    id: 'finCENCtrConfiguration',
    name: 'FinCEN CTR Settings',
    path: '/filings/configuration/fincenctr',
    permissions: [permissions.editSarsConfig],
  },
  {
    id: 'filingsConfiguration',
    name: 'Report Filings Settings',
    path: '/filings/configuration',
    permissions: [permissions.editSarsConfig],
    exact: false,
  },
  {
    exact: false,
    id: 'filings',
    name: 'Filings',
    path: routes.lumos.filings,
    permissions: [permissions.readSars],
  },

  // Insights
  {
    exact: true,
    id: 'dashboardsId',
    name: 'Dashboards',
    path: routes.lumos.dashboardsId,
    permissions: [permissions.readInsights],
  },
  {
    id: 'dashboards',
    name: 'Dashboards',
    path: routes.lumos.dashboards,
    permissions: [permissions.readInsights],
  },

  // Entities
  {
    exact: true,
    id: 'dataExplorerEntities',
    name: 'Entities',
    path: routes.lumos.dataExplorerEntities,
    permissions: [permissions.readEntities],
  },
  {
    exact: true,
    id: 'entitiesIdTab',
    name: 'Entity',
    path: routes.lumos.entitiesIdTab,
    permissions: [permissions.readEntities],
  },
  {
    id: 'entitiesId',
    name: 'Entity',
    path: routes.lumos.entitiesId,
    permissions: [permissions.readEntities],
  },

  // Instruments
  {
    exact: true,
    id: 'dataExplorerInstruments',
    name: 'Instruments',
    path: routes.lumos.dataExplorerInstruments,
    permissions: [permissions.readTxnInstruments],
  },
  {
    exact: true,
    id: 'instrumentsIdTab',
    name: 'Instrument',
    path: routes.lumos.instrumentsIdTab,
    permissions: [permissions.readTxnInstruments],
  },
  {
    id: 'instrumentsId',
    name: 'Instrument',
    path: routes.lumos.instrumentsId,
    permissions: [permissions.readTxnInstruments],
  },

  // Transactions
  {
    exact: true,
    id: 'dataExplorerTransactions',
    name: 'Transactions',
    path: routes.lumos.dataExplorerTransactions,
    permissions: [permissions.readEvents],
  },
  {
    exact: true,
    id: 'dataExplorerTransactionsId',
    name: 'Transaction',
    path: routes.lumos.dataExplorerTransactionsId,
    permissions: [permissions.readEvents],
  },

  // Actions
  {
    exact: true,
    id: 'dataExplorerActionEvents',
    name: 'Actions',
    path: routes.lumos.dataExplorerActionEvents,
    permissions: [permissions.readEvents],
  },
  {
    exact: true,
    id: 'dataExplorerActionEventsId',
    name: 'Actions',
    path: routes.lumos.dataExplorerActionEventsId,
    permissions: [permissions.readEvents],
  },

  // Upload file
  {
    exact: true,
    id: 'dataExplorerUploadFiles',
    name: 'Upload File',
    path: routes.lumos.dataExplorerUploadFile,
    permissions: [permissions.readDatafileUploads],
  },

  // Data explorer
  {
    id: 'dataExplorer',
    name: 'Data Explorer',
    path: routes.lumos.dataExplorer,
    anyPermissions: [
      permissions.readEntities,
      permissions.readTxnInstruments,
      permissions.readEvents,
      permissions.readDatafileUploads,
    ],
  },

  // Data Management Tab
  {
    exact: true,
    id: 'dataManagementFlatFileIngestion',
    name: 'Flat-File Ingestion',
    path: '/data-management/flat-file-ingestion',
    permissions: [permissions.readDDBConnections],
  },
  {
    exact: true,
    id: 'dataManagementDataMapping',
    name: 'Data Mapping',
    path: '/data-management/data-mapping/:id',
    permissions: [permissions.editDDBConnections],
  },
  {
    id: 'dataSettings',
    name: 'Data Settings',
    path: '/data-management/data-settings',
    permissions: [permissions.readCustomDataSettings],
  },
  {
    id: 'dataSettingsImportExport',
    name: 'Data Settings Import Export',
    path: '/data-management/export-import',
    permissions: [
      permissions.editCustomDataSettings,
      permissions.editDDBConnections,
    ],
  },
  {
    id: 'dataSettingsImport',
    name: 'Data Settings Import',
    path: '/data-management/export-import/import',
    permissions: [
      permissions.editCustomDataSettings,
      permissions.editDDBConnections,
    ],
  },
  {
    id: 'dataSettingsExport',
    name: 'Data Settings Export',
    path: '/data-management/export-import/export',
    permissions: [
      permissions.editCustomDataSettings,
      permissions.editDDBConnections,
    ],
  },
  {
    id: 'dataSettingsDownload',
    name: 'Download Data Settings',
    path: '/data-management/data-settings/download',
    permissions: [permissions.readCustomDataSettings],
  },
  {
    id: 'displaySettings',
    name: 'Display Settings',
    path: '/data-management/display-settings',
    anyPermissions: [
      permissions.editCustomDataSettings,
      permissions.readCensorshipConditions,
    ],
  },
  {
    id: 'tableSettings',
    name: 'Default Table Settings',
    path: '/data-management/display-settings/table-settings',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'tableSettingsClassifier',
    name: 'Default Table Settings',
    path: '/data-management/display-settings/table-settings/:classifier',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'summarySettings',
    name: 'Summary Settings',
    path: '/data-management/display-settings/summary-settings',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'summarySettingsClassifier',
    name: 'Summary Settings',
    path: '/data-management/display-settings/summary-settings/:classifier',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'watchlistSettings',
    name: 'Watchlist Settings',
    path: '/data-management/display-settings/watchlist-settings',
    permissions: [
      permissions.readCustomDataSettings,
      permissions.editCustomDataSettings,
    ],
  },
  {
    id: 'watchlistSettingsClassifier',
    name: 'Watchlist Settings',
    path: '/data-management/display-settings/watchlist-settings/:classifier',
    permissions: [
      permissions.readCustomDataSettings,
      permissions.editCustomDataSettings,
    ],
  },
  {
    id: 'censorship',
    name: 'Censorship',
    path: '/data-management/display-settings/censorship',
    permissions: [permissions.readCensorshipConditions],
  },
  {
    id: 'typeClassification',
    name: 'Type Classification',
    path: '/data-management/type-classification',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'relationships',
    name: 'Relationships',
    path: '/data-management/relationships',
    permissions: [permissions.editCustomDataSettings],
  },
  {
    id: 'relationshipConfigsId',
    name: 'Relationship',
    path: '/data-management/relationship-configs/:id',
  },
  {
    id: 'integrations',
    name: 'Integrations',
    path: '/data-management/integrations',
    anyPermissions: [permissions.readApiKeys, permissions.editStorage],
  },
  {
    id: 'apiKeys',
    name: 'API Keys',
    path: '/data-management/integrations/api-keys',
    permissions: [permissions.readApiKeys],
  },
  {
    id: 'externalAPIKeys',
    name: 'External API Keys',
    path: '/data-management/integrations/external-api-keys',
    permissions: [permissions.readApiKeys],
  },
  {
    id: 'googleDrive',
    name: 'Google Drive',
    path: '/data-management/integrations/google-drive',
    permissions: [permissions.editStorage],
  },
  {
    id: 'tags',
    name: 'Tags',
    path: '/data-management/tags',
  },
  {
    id: 'dataManagement',
    name: 'Data Management',
    path: '/data-management',
  },

  // Risk scores
  {
    exact: true,
    id: 'riskRatings',
    name: 'Risk Ratings',
    path: '/risk-ratings',
    permissions: [permissions.readRiskRatings],
  },
  {
    exact: true,
    id: 'riskRatingsId',
    name: 'Risk Model Details',
    path: '/risk-ratings/:id',
    permissions: [permissions.readRiskRatings],
  },

  // Detection models
  {
    exact: true,
    id: 'detectionModels',
    name: 'Detection Models',
    path: routes.lumos.detectionModels,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsValidating',
    name: 'Validating Models',
    path: routes.lumos.detectionModelsValidating,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsShadow',
    name: 'Shadow Models',
    path: routes.lumos.detectionModelsShadow,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsSimpleCreate',
    name: 'Create Scenario Rule',
    path: routes.lumos.detectionModelsCreateSimple,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsCreateAdvancedEntity',
    name: 'Create Entity Detection Model',
    path: routes.lumos.detectionModelsCreateAdvancedEntity,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsCreateAdvancedInstrument',
    name: 'Create Instrument Detection Model',
    path: routes.lumos.detectionModelsCreateAdvancedInstrument,
    permissions: [permissions.readRules],
  },
  {
    exact: false,
    id: 'detectionModelsCreateAdvanced',
    name: 'Create Detection Model',
    path: '/detection-models/create/advanced',
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'subSecondRuleCreate',
    name: 'Create Real-Time Rule',
    path: '/detection-models/create/sub-second/',
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsValidationId',
    name: 'Detection Model Validation',
    path: routes.lumos.detectionModelsValidationId,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelsIdTab',
    name: 'Detection Model',
    path: routes.lumos.detectionModelsIdTab,
    permissions: [permissions.readRules],
  },
  {
    id: 'detectionModelsId',
    name: 'Detection Model',
    path: routes.lumos.detectionModelsId,
    permissions: [permissions.readRules],
  },
  {
    exact: true,
    id: 'detectionModelPDF',
    name: 'Detection Model PDF',
    path: routes.lumos.pdfExportRulesRuleId,
    permissions: [permissions.readRules],
  },
  {
    id: 'rulesPdfExport',
    name: 'Rule PDF Export',
    path: '/pdf-export/rules/:ruleId',
  },

  // Lists
  {
    exact: true,
    id: 'lists',
    name: 'Lists',
    path: '/lists',
    anyPermissions: [permissions.readBlacklists, permissions.create314aScans],
  },
  // Lists: Matchlists
  {
    exact: true,
    id: 'matchlists',
    name: 'Matchlists',
    path: '/lists/matchlists',
    permissions: [permissions.readBlacklists],
  },
  {
    exact: true,
    id: 'matchlistsId',
    name: 'Matchlist',
    path: '/lists/matchlists/:id',
    permissions: [permissions.readBlacklists],
  },
  {
    exact: true,
    id: 'matchlistsIdTab',
    name: 'Matchlist',
    path: '/lists/matchlists/:id/:tab?',
    permissions: [permissions.readBlacklists],
  },
  // Lists: 314(a)
  {
    exact: true,
    id: 'lists314a',
    name: '314(a)',
    path: '/lists/314a',
    permissions: [permissions.create314aScans],
  },

  // Notifications
  {
    exact: true,
    id: 'notifications',
    name: 'Notifications',
    path: routes.lumos.notifications,
  },

  // Org Management
  {
    exact: true,
    id: 'orgCreate',
    name: 'Org Features',
    path: '/org-management/create',
    permissions: [permissions.editAdminDashboard, permissions.adminAPI],
  },
  {
    id: 'orgIDTab',
    name: 'Org Details',
    path: '/org-management/:id/:tab',
    permissions: [permissions.editAdminDashboard, permissions.adminAPI],
  },
  {
    id: 'orgID',
    name: 'Org Details',
    path: '/org-management/:id',
    permissions: [permissions.editAdminDashboard, permissions.adminAPI],
  },
  {
    id: 'orgManagement',
    name: 'Org Management',
    path: '/org-management',
    permissions: [permissions.editAdminDashboard, permissions.adminAPI],
  },

  // Rules admin
  {
    id: 'rulesAdminAlerts',
    name: 'Rules Admin Alerts',
    path: '/rules/admin/alerts',
    permissions: [permissions.editRulesAdminDashboard, permissions.adminAPI],
  },
  {
    id: 'rulesAdminMatchlists',
    name: 'Rules Admin Matchlists',
    path: '/rules/admin/matchlists',
    permissions: [permissions.editRulesAdminDashboard, permissions.adminAPI],
  },
  {
    id: 'rulesAdmin',
    name: 'Rules Admin',
    path: '/rules/admin',
    permissions: [permissions.editRulesAdminDashboard, permissions.adminAPI],
  },

  // User management
  {
    id: 'agentsId',
    name: 'Agent',
    path: routes.lumos.agentsId,
  },
  {
    exact: true,
    id: 'agentsIdTab',
    name: 'Agent',
    path: routes.lumos.agentsIdTab,
  },
  {
    id: 'agents',
    name: 'Agents',
    path: '/user-management/agents',
    permissions: [permissions.readAgents],
  },
  {
    id: 'teamsId',
    name: 'Team',
    path: routes.lumos.teamsId,
    permissions: [permissions.readTeams],
  },
  {
    exact: true,
    id: 'teamsIdTab',
    name: 'Team',
    path: routes.lumos.teamsIdTab,
    permissions: [permissions.readTeams],
  },
  {
    id: 'teams',
    name: 'Teams',
    path: '/user-management/teams',
    permissions: [permissions.readTeams],
  },
  {
    id: 'userManagement',
    name: 'User Management',
    path: '/user-management',
    anyPermissions: [permissions.readAgents, permissions.readTeams],
  },

  // Workflows
  // todo: rename to 'alertQueues' after old one is deleted, need DB migration
  {
    exact: true,
    id: 'workflowAlertQueues',
    name: 'Alert Queues',
    path: '/workflows/queues/alerts',
    permissions: [permissions.readAlerts, permissions.editQueues],
  },
  {
    exact: true,
    id: 'alertQueuesId',
    name: 'Alert Queue',
    path: '/queues/alerts/:id',
    permissions: [permissions.readAlerts, permissions.editQueues],
  },
  {
    exact: true,
    id: 'caseQueues',
    name: 'Case Queues',
    path: '/workflows/queues/cases',
    permissions: [permissions.readCases, permissions.editQueues],
  },
  {
    exact: true,
    id: 'caseQueuesId',
    name: 'Case Queue',
    path: '/queues/cases/:id',
    permissions: [permissions.readCases, permissions.editQueues],
  },
  {
    exact: true,
    id: 'sarQueues',
    name: 'Filing Queues',
    path: '/workflows/queues/filings',
    permissions: [permissions.readSars, permissions.editQueues],
  },
  {
    exact: true,
    id: 'sarQueuesId',
    name: 'Filing Queue',
    path: '/queues/filings/:id',
    permissions: [permissions.readSars, permissions.editQueues],
  },
  {
    exact: true,
    id: 'queues',
    name: 'Queues',
    path: '/workflows/queues',
    permissions: [permissions.editQueues],
  },
  {
    exact: true,
    id: 'alertWorkflowButtonCreate',
    name: 'Create Alert Workflow Button',
    path: '/workflows/buttons/alerts/templates/:templateId',
    permissions: [permissions.readAlerts, permissions.createWebhooks],
  },
  {
    exact: true,
    id: 'alertWorkflowButtonTemplates',
    name: 'Alert Workflow Button Templates',
    path: '/workflows/buttons/alerts/templates',
    permissions: [permissions.readAlerts, permissions.createWebhooks],
  },
  {
    exact: true,
    id: 'alertWorkflowButton',
    name: 'Alert Workflow Button',
    path: '/workflows/buttons/alerts/:id',
    permissions: [permissions.readAlerts, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'caseWorkflowButtonCreate',
    name: 'Create Case Workflow Button',
    path: '/workflows/buttons/cases/templates/:templateId',
    permissions: [permissions.readCases, permissions.createWebhooks],
  },
  {
    exact: true,
    id: 'caseWorkflowButton',
    name: 'Case Workflow Button',
    path: '/workflows/buttons/cases/:id',
    permissions: [permissions.readCases, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'alertWorkflowButtons',
    name: 'Alert Workflow Buttons',
    path: '/workflows/buttons/alerts',
    permissions: [permissions.readAlerts, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'caseWorkflowButtons',
    name: 'Case Workflow Buttons',
    path: '/workflows/buttons/cases',
    permissions: [permissions.readCases, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'workflowButtons',
    name: 'Workflow Buttons',
    path: '/workflows/buttons',
    permissions: [permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'alertInvestigationChecklists',
    name: 'Alert Investigation Checklists',
    path: '/workflows/investigation-checklists/alerts',
    permissions: [permissions.readAlerts, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'caseInvestigationChecklists',
    name: 'Case Investigation Checklists',
    path: '/workflows/investigation-checklists/cases',
    permissions: [permissions.readCases, permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'investigationChecklistsId',
    name: 'Investigation Checklist',
    path: '/investigation-checklists/:id',
    permissions: [permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'investigationChecklists',
    name: 'Investigation Checklists',
    path: '/workflows/investigation-checklists',
    permissions: [permissions.readWebhooks],
  },
  {
    id: 'alertNarrativeTemplates',
    name: 'Alert Narrative Templates',
    path: '/workflows/narrative-templates/alerts',
    permissions: [permissions.readAlerts, permissions.readWebhooks],
  },
  {
    id: 'caseNarrativeTemplates',
    name: 'Case Narrative Templates',
    path: '/workflows/narrative-templates/cases',
    permissions: [permissions.readCases, permissions.readWebhooks],
  },
  {
    id: 'narrativeTemplates',
    name: 'Narrative Templates',
    path: '/workflows/narrative-templates',
    permissions: [permissions.readWebhooks],
  },
  {
    id: 'webhooks',
    name: 'Webhooks',
    path: '/workflows/webhooks',
    permissions: [permissions.readWebhooks],
  },
  {
    id: 'webhooksId',
    name: 'Webhook',
    path: routes.lumos.webhooksId,
    permissions: [permissions.readWebhooks],
  },
  {
    id: 'deadlines',
    name: 'Deadlines',
    path: '/workflows/deadlines',
    permissions: [permissions.readDeadlinesConfig],
  },
  {
    exact: true,
    id: 'dispositions',
    name: 'Dispositions',
    path: '/workflows/dispositions',
    permissions: [permissions.readWebhooks],
  },
  {
    exact: true,
    id: 'quality',
    name: 'Quality',
    path: '/workflows/quality',
    permissions: [permissions.readQuality],
  },
  {
    id: 'workflows',
    name: 'Workflows',
    path: '/workflows',
    anyPermissions: [
      permissions.readAssignments,
      permissions.readWebhooks,
      permissions.readDeadlinesConfig,
    ],
  },

  // Quality Cycles
  {
    id: 'qualityId',
    name: 'Quality Cycles',
    path: '/quality/:id',
    permissions: [permissions.readQuality],
  },

  // Reporting
  {
    id: 'fileExports',
    name: 'File Exports',
    path: '/reporting/file-exports',
    anyPermissions: [
      permissions.readCases,
      permissions.readAlerts,
      permissions.readSars,
      permissions.readAssignments,
    ],
  },
  {
    id: 'reporting',
    name: 'Reporting',
    path: '/reporting',
    anyPermissions: [
      permissions.readCases,
      permissions.readAlerts,
      permissions.readSars,
      permissions.readAssignments,
    ],
  },

  // Misc
  { exact: true, id: 'home', name: 'Home', path: routes.lumos.home },
  {
    exact: true,
    id: 'agentLogin',
    name: 'Login',
    path: routes.lumos.agentLogin,
  },
  {
    exact: true,
    id: 'emailLogin',
    name: 'Login',
    path: routes.lumos.emailLogin,
  },
  {
    exact: true,
    id: 'error',
    name: 'Error',
    path: routes.lumos.error,
  },
  {
    id: 'fourOhFour',
    name: 'Not found',
    path: '/404',
  },
] as const satisfies DeepReadonly<Route[]>;

type RouteID = (typeof ROUTES)[number]['id'];
export const ROUTES_MAP: Record<RouteID, Route> = ROUTES.reduce(
  (acc, i) => ({
    ...acc,
    [i.id]: i,
  }),
  {} as Record<RouteID, Route>,
);

export const NOT_ALLOWED_BACK_ROUTES = {
  [ROUTES_MAP.agentLogin.path]: true,
  [ROUTES_MAP.emailLogin.path]: true,
  [ROUTES_MAP.home.path]: true,
  [ROUTES_MAP.error.path]: true,
  [ROUTES_MAP.fourOhFour.path]: true,
};

export const getRouteName = (route: string): string => {
  const found = ROUTES.find((i) => matchPath(route, i));
  if (!found) {
    consoleWarn('Route name not found!', route);
    return '';
  }
  return found.name;
};

export const HOME_ROUTES = [
  ROUTES_MAP.myAlerts,
  ROUTES_MAP.alertAdmin,
  ROUTES_MAP.dashboards,
  ROUTES_MAP.workflowAlertQueues,
];

export const NO_AUTH_ROUTES = new Set([
  ROUTES_MAP.agentLogin.path,
  ROUTES_MAP.emailLogin.path,
]);

export default routes;
