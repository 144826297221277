import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';

export const TXN_EVENT_SENDER_RECEIVER_ENTITY_DROPDOWN_SYMBOLS: SymbolTable =
  Object.freeze({
    'sender_entity.cumulative_transaction_sum': {
      type: 'text',
      text: 'Entity / Sender entity / Cumulative transaction sum',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_entity.cumulative_transaction_sum',
          label: 'sender_entity.cumulative_transaction_sum',
          model: 'txn_event',
          datatype: 'number',
          cardinality: 'many',
        },
      },
    },
    'receiver_entity.cumulative_transaction_sum': {
      type: 'text',
      text: 'Entity / Receiver entity / Cumulative transaction sum',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_entity.cumulative_transaction_sum',
          label: 'receiver_entity.cumulative_transaction_sum',
          model: 'txn_event',
          datatype: 'number',
          cardinality: 'many',
        },
      },
    },
    'sender_entity.first_esigned_event': {
      type: 'text',
      text: 'Entity / Sender entity / First esigned event',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_entity.first_esigned_event',
          label: 'sender_entity.first_esigned_event',
          model: 'txn_event',
          datatype: 'datetime',
          cardinality: 'many',
        },
      },
    },
    'receiver_entity.first_esigned_event': {
      type: 'text',
      text: 'Entity / Receiver entity / First esigned event',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_entity.first_esigned_event',
          label: 'receiver_entity.first_esigned_event',
          model: 'txn_event',
          datatype: 'datetime',
          cardinality: 'many',
        },
      },
    },
    'sender_entity.first_transaction': {
      type: 'text',
      text: 'Entity / Sender entity / First transaction',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_entity.first_transaction',
          label: 'sender_entity.first_transaction',
          model: 'txn_event',
          datatype: 'datetime',
          cardinality: 'many',
        },
      },
    },
    'receiver_entity.first_transaction': {
      type: 'text',
      text: 'Entity / Receiver entity / First transaction',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_entity.first_transaction',
          label: 'receiver_entity.first_transaction',
          model: 'txn_event',
          datatype: 'datetime',
          cardinality: 'many',
        },
      },
    },
    'sender_entity.phone_number.number': {
      type: 'text',
      text: 'Entity / Sender entity / Phone number / Number',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_entity.phone_number.number',
          label: 'sender_entity.phone_number.number',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    'receiver_entity.phone_number.number': {
      type: 'text',
      text: 'Entity / Receiver entity / Phone number / Number',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_entity.phone_number.number',
          label: 'receiver_entity.phone_number.number',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    'sender_entity.email_address.email_address': {
      type: 'text',
      text: 'Entity / Sender entity / Email address',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_entity.email_address.email_address',
          label: 'sender_entity.email_address.email_address',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    'receiver_entity.email_address.email_address': {
      type: 'text',
      text: 'Entity / Receiver entity / Email address',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_entity.email_address.email_address',
          label: 'receiver_entity.email_address.email_address',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    sender_id: {
      type: 'text',
      text: 'Transaction / Sender ID',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_id',
          label: 'sender_id',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    receiver_id: {
      type: 'text',
      text: 'Transaction / Receiver ID',
      value: {
        operandType: 'text',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_id',
          label: 'receiver_id',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
      },
    },
    sender_source: {
      type: 'text',
      text: 'Transaction / Sender source',
      value: {
        operandType: 'enum',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'sender_source',
          label: 'sender_source',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
        dropdownValues: ['internal', 'external'],
      },
    },
    receiver_source: {
      type: 'text',
      text: 'Transaction / Receiver source',
      value: {
        operandType: 'enum',
        unit21DataClassifier: Unit21DataClassifier.EVENT,
        value: {
          type: 'FIELD',
          field: 'receiver_source',
          label: 'receiver_source',
          model: 'txn_event',
          datatype: 'string',
          cardinality: 'many',
        },
        dropdownValues: ['internal', 'external'],
      },
    },
  });
