// Redux
import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';
import { produce, Draft } from 'immer';

// Models
import {
  EntitiesActionTypes,
  EntitiesState,
  EntityAlerts,
} from 'app/modules/entities/models';

// Actions
import { EntitiesActions } from 'app/modules/entities/actions';
import { AlertsActions } from 'app/modules/alerts/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import { EMPTY_ENTITY } from 'app/modules/entities/constants';

export const initialPaginatedEntityAlerts: EntityAlerts = {
  count: 0,
  alerts: [],
};

const initialState: Readonly<EntitiesState> = {
  details: EMPTY_ENTITY,
  linkAnalysisReport: null,
  attachments: [],
  entitiesLinkAnalysisMetadata: {
    metadata: [],
  },
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<EntitiesState> = (
  state = initialState,
  action: EntitiesActions | AlertsActions,
) => {
  return produce(state, (draft: Draft<EntitiesState>) => {
    switch (action.type) {
      case EntitiesActionTypes.RETRIEVE_ENTITY_SUCCESS:
        draft.details = action.payload;
        return;

      case EntitiesActionTypes.RETRIEVE_ENTITY_ALERTS_SUCCESS:
        draft.details.paginated_alerts = action.payload;
        return;

      case EntitiesActionTypes.RETRIEVE_ENTITY_LINK_ANALYSIS_SUCCESS:
      case EntitiesActionTypes.QUEUE_ENTITY_LINK_ANALYSIS_SUCCESS:
        draft.linkAnalysisReport = action.payload;
        return;

      case EntitiesActionTypes.CLEAR_ALL_ENTITY_STATE:
        // eslint-disable-next-line consistent-return
        return cloneDeep(initialState);

      case EntitiesActionTypes.RETRIEVE_ENTITIES_LINK_ANALYSIS_METADATA_SUCCESS:
        draft.entitiesLinkAnalysisMetadata = action.payload;
        return;
      case EntitiesActionTypes.RESET_ENTITIES_LINK_ANALYSIS_METADATA:
        draft.entitiesLinkAnalysisMetadata =
          initialState.entitiesLinkAnalysisMetadata;
        return;
    }
  });
};

export { reducer as entitiesReducer, initialState };
