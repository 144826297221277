// Models
import {
  CreateCaseLocationState,
  CreateCaseFormValues,
  GetCasesPayload,
} from 'app/modules/cases/models';
import {
  ArrayComparisonOperators,
  CustomDataFilters,
  SimpleComparisonOperators,
} from 'app/modules/filtersOld/models';
import { Filter } from 'app/modules/filters/models';
import { QueueDetailsResponse } from 'app/modules/queues/responses';

// Constants
import { CASE_CREATE_SCHEMA } from 'app/modules/cases/schemas';
import {
  CASE_FILTER_KEYS,
  NATIVE_CASE_FILTER_KEYS_SET,
} from 'app/modules/cases/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

// Utils
import { addDays, startOfDay } from 'date-fns';
import { createFormInitialValues } from 'app/shared/utils/form';
import { getBEDateFormat } from 'app/shared/utils/timeHelpers';
import moment from 'moment';
import { CaseActionTriggerModel } from 'app/modules/webhooks/models';
import { FullCaseResponse } from 'app/modules/casesOld/types';

export const createCaseCreateInitialValues = (
  state: CreateCaseLocationState = {},
  agentID?: number,
  defaultQueue?: QueueDetailsResponse,
) => {
  const { alerts, assignee, entities, events, actionEvents } = state;
  const formInitialValues =
    createFormInitialValues<CreateCaseFormValues>(CASE_CREATE_SCHEMA);
  if (alerts?.length) {
    if (alerts.length === 1) {
      const [alert] = alerts;
      formInitialValues.title = alert.title;
      formInitialValues.description = alert.description;
    } else {
      formInitialValues.title = 'Bulk resolution case';
      formInitialValues.description = `Case created to bulk resolve ${alerts.length} alerts`;
    }
    formInitialValues.alerts = alerts.map((i) => i.id);
    formInitialValues.tags = [
      ...new Set(
        alerts.reduce(
          (acc, i) => [...acc, ...(i.tags?.map((j) => j.id) || [])],
          [],
        ),
      ),
    ];
  }
  formInitialValues.assignee = assignee || agentID;
  if (defaultQueue) {
    formInitialValues.queue = defaultQueue.id;
  }

  // Use the queue from the location state if it exists
  // this will override any default queue given.
  if (state.queue) {
    formInitialValues.queue = state.queue;
  }

  if (events?.length) {
    formInitialValues.events = events;
  }

  if (actionEvents?.length) {
    formInitialValues.action_events = actionEvents;
  }

  if (entities?.length) {
    formInitialValues.entities = entities;
  }

  return formInitialValues;
};

const filterNumbers = (array: (number | string)[]): number[] => {
  return array.filter((i): i is number => typeof i === 'number');
};

const filterStrings = (array: (number | string)[]): string[] => {
  return array.filter((i): i is string => typeof i === 'string');
};

export const createCaseFilters = (filters: Filter[]): GetCasesPayload => {
  const { nativeFilters, customFilters } = filters.reduce<{
    nativeFilters: Filter[];
    customFilters: Filter[];
  }>(
    (acc, i) => {
      if (NATIVE_CASE_FILTER_KEYS_SET.has(i.key)) {
        acc.nativeFilters.push(i);
      } else {
        acc.customFilters.push(i);
      }
      return acc;
    },
    {
      nativeFilters: [],
      customFilters: [],
    },
  );

  const customDataFilters = customFilters.reduce<CustomDataFilters>(
    (acc, i) => {
      const CLASSIFIER_CUSTOM_DATA_KEY_MAPPING = {
        [Unit21DataClassifier.ENTITY]: 'entity',
        [Unit21DataClassifier.INSTRUMENT]: 'txn_instrument',
        [Unit21DataClassifier.EVENT]: 'event',
      };
      const { classifier, key } = JSON.parse(i.key);
      const customDataKey = CLASSIFIER_CUSTOM_DATA_KEY_MAPPING[classifier];
      if (!acc[customDataKey]) {
        acc[customDataKey] = [];
      }
      const { operator, value } = i;
      switch (operator) {
        case FILTER_OPERATOR.IS_GREATER_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.GT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_LESS_THAN_NUMBER:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.LT,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EXACT_TEXT:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_ONE_OF:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: ArrayComparisonOperators.NOT_IN,
            value,
          });
          break;

        case FILTER_OPERATOR.IS_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.EQ,
            value: null,
          });
          break;

        case FILTER_OPERATOR.IS_NOT_EMPTY:
          acc[customDataKey].push({
            key,
            nested: false,
            operator: SimpleComparisonOperators.NEQ,
            value: null,
          });
          break;

        default:
          break;
      }

      return acc;
    },
    {},
  );

  const nativeDataFilters = nativeFilters.reduce<GetCasesPayload>((acc, i) => {
    const { key, operator, value } = i;
    switch (key) {
      case CASE_FILTER_KEYS.AMOUNT: {
        if (operator === FILTER_OPERATOR.IS_GREATER_THAN_NUMBER && value > 0) {
          acc.minimum_amount = value;
        } else if (
          operator === FILTER_OPERATOR.IS_LESS_THAN_NUMBER &&
          value > 0
        ) {
          acc.maximum_amount = value;
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_NUMBER) {
          const [min, max] = value;
          if (min > 0) {
            acc.minimum_amount = min;
          }
          if (max > 0) {
            acc.maximum_amount = max;
          }
        }
        break;
      }

      case CASE_FILTER_KEYS.ASSIGNEE: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.assigned_to_id = filterNumbers(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.CREATED_AT: {
        // note: this API is using the legacy date time format so using the old formatter which requires moment
        if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
          acc.created_start_date = getBEDateFormat(value, moment.ISO_8601);
        } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
          acc.created_end_date = getBEDateFormat(
            startOfDay(addDays(new Date(value), 1)),
            moment.ISO_8601,
          );
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
          const [start, end] = value;
          acc.created_start_date = getBEDateFormat(start, moment.ISO_8601);
          acc.created_end_date = getBEDateFormat(
            startOfDay(addDays(new Date(end), 1)),
            moment.ISO_8601,
          );
        }
        break;
      }

      case CASE_FILTER_KEYS.DISPOSITION: {
        if (operator === FILTER_OPERATOR.IS) {
          acc.disposition = String(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.DISPOSITIONED_AT: {
        // note: this API is using the legacy date time format so using the old formatter which requires moment
        if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
          acc.disposition_start_date = getBEDateFormat(value, moment.ISO_8601);
        } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
          acc.disposition_end_date = getBEDateFormat(
            startOfDay(addDays(new Date(value), 1)),
            moment.ISO_8601,
          );
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
          const [start, end] = value;
          acc.disposition_start_date = getBEDateFormat(start, moment.ISO_8601);
          acc.disposition_end_date = getBEDateFormat(
            startOfDay(addDays(new Date(end), 1)),
            moment.ISO_8601,
          );
        }
        break;
      }

      case CASE_FILTER_KEYS.ENTITY: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.entity_ids = value;
        }
        break;
      }

      case CASE_FILTER_KEYS.ID_TITLE: {
        if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
          acc.phrase = value;
        }
        break;
      }

      case CASE_FILTER_KEYS.QUEUE: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.queue_ids = filterNumbers(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.SOURCE: {
        if (operator === FILTER_OPERATOR.IS) {
          acc.sources = [String(value)];
        }
        break;
      }

      case CASE_FILTER_KEYS.STATUS: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.statuses = filterStrings(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.SUBDISPOSITION: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.subdisposition_option_ids = filterNumbers(value);
        } else if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
          acc.is_not_subdisposition_option_ids = filterNumbers(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.TAG: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.tag_ids = filterNumbers(value);
        } else if (operator === FILTER_OPERATOR.IS_NOT_ONE_OF) {
          acc.is_not_tag_ids = filterNumbers(value);
        }
        break;
      }

      case CASE_FILTER_KEYS.WATCHERS: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.watcher_ids = filterNumbers(value);
        }
        break;
      }

      default:
        break;
    }
    return acc;
  }, {});

  return {
    ...nativeDataFilters,
    custom_data_filters: customDataFilters,
  };
};

export const filterCaseWorkflowButtons = (
  workflowButtons: CaseActionTriggerModel[],
  c: FullCaseResponse,
  agentTeams: number[],
  removeDisplayTagLogic?: boolean,
): CaseActionTriggerModel[] => {
  const caseTagIDSet = new Set(c.tags.map((i) => i.id));
  const agentTeamIDSet = new Set(agentTeams);
  return workflowButtons
    .filter((i) => {
      if (
        i.config.show_on_case_status !== null &&
        i.config.show_on_case_status !== c.status
      ) {
        return false;
      }
      if (i.queues.length && c.queue_id && !i.queues.includes(c.queue_id)) {
        return false;
      }
      return true;
    })
    .filter((i) => {
      if (!i.teams.length) {
        return true;
      }
      // show if workflow has a team the agent is a part of
      return i.teams.some((j) => agentTeamIDSet.has(j));
    })
    .filter((i) => {
      if (removeDisplayTagLogic) {
        return true;
      }
      // only show if case has all the workflow button display tags
      return i.display_tags.every((j) => caseTagIDSet.has(j));
    })
    .filter((i) => {
      // don't show if case has any of the workflow button masked tags
      return !i.masking_tags.some((j) => caseTagIDSet.has(j));
    });
};

export const getChecklistDisabledReason = (
  workflowButton: CaseActionTriggerModel,
  isChecklistValidated: boolean,
): string | undefined => {
  if (!workflowButton.config.enable_if_invalid && !isChecklistValidated) {
    return 'Complete checklist first';
  }
  return undefined;
};
