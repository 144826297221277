import { AUDIT_LIST_QUERY_KEYS } from 'app/modules/auditService/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  AuditTrailAssetType,
  AuditServiceEntry,
} from 'app/modules/auditService/types';

export interface AuditServiceResponse {
  rawAssetId: string;
  auditLogs: AuditServiceEntry[];
  rawAssetType: string;
}

const listAuditTrail = (
  assetType: AuditTrailAssetType,
  assetId: string,
  offset: number = 1,
  limit: number = 100,
): Promise<AuditServiceResponse> =>
  get(
    `/audit-service/${assetType}/${assetId}/list?offset=${offset}&limit=${limit}`,
  );

export const useAuditServiceList = (
  assetType: AuditTrailAssetType,
  assetId: string,
) => {
  return useInfiniteQuery({
    queryKey: AUDIT_LIST_QUERY_KEYS.list(assetType, assetId),
    queryFn: ({ pageParam: offset }) =>
      listAuditTrail(assetType, assetId, offset),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.auditLogs?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    meta: { errorMessage: 'Failed to get audit history.' },
    enabled: assetId !== '',
  });
};
