import { LockOpen } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { IconLockExclamation } from '@u21/tabler-icons';
import {
  authTokenFetcher,
  ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN,
} from 'app/shared/hooks/useEncryptedFieldProps';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { U21Chip } from 'app/shared/u21-ui/components/display/U21Chip';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { useEffect, useRef, useState, useCallback, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

interface IFramePostMessageRequest {
  type: 'DECRYPT_TOKEN';
  token: string;
  authToken: string;
  styles: string;
}

interface IFramePostMessageResponse {
  type: 'DECRYPTION_ERROR' | 'DECRYPTION_SUCCESS';
  token: string;
}

interface Props {
  token: string;
}

export const DataSettingDataDecrypted = forwardRef<HTMLDivElement, Props>(
  ({ token }, ref) => {
    const [decryptionStatus, setDecryptionStatus] = useState<
      'pending' | 'success' | 'error'
    >('pending');
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const dispatch = useDispatch();

    const styles = useGetIframeStyles();

    const decryptData = useCallback(() => {
      if (iframeLoaded && iframeRef.current) {
        const decryptionMessage: IFramePostMessageRequest = {
          type: 'DECRYPT_TOKEN',
          token,
          authToken: authTokenFetcher(),
          styles,
        };
        try {
          iframeRef.current.contentWindow?.postMessage(
            decryptionMessage,
            ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN,
          );
        } catch (error) {
          setDecryptionStatus('error');
          dispatch(sendErrorToast('Failed to decrypt data.'));
        }
      }
    }, [iframeLoaded, token, dispatch, styles]);

    // Send decryption message after iframe is loaded
    useEffect(() => {
      decryptData();
    }, [decryptData]);

    const handleMessage = useCallback(
      (event: MessageEvent<IFramePostMessageResponse>) => {
        if (
          event.origin !== ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN ||
          event.data.token !== token
        ) {
          return;
        }

        if (event.data.type === 'DECRYPTION_ERROR') {
          setDecryptionStatus('error');
          dispatch(sendErrorToast('Failed to decrypt data.'));
          return;
        }
        if (event.data.type === 'DECRYPTION_SUCCESS') {
          setDecryptionStatus('success');
        }
      },
      [dispatch, token],
    );

    useEffect(() => {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, [handleMessage]);

    return (
      <StyledChip
        icon={(() => {
          if (decryptionStatus === 'pending') {
            return <U21Loading color="primary" loading variant="spinner" />;
          }
          if (decryptionStatus === 'success') {
            return <LockOpen />;
          }
          return <IconLockExclamation />;
        })()}
        ref={ref}
        // TODO SC-83130: get retries working
        // tooltip={
        //   decryptionStatus === 'error'
        //     ? 'Failed to decrypt data. Click to retry.'
        //     : ''
        // }
        // onClick={
        //   decryptionStatus === 'error'
        //     ? (e) => {
        //         e.stopPropagation();
        //         setDecryptionStatus('pending');
        //         decryptData();
        //       }
        //     : undefined
        // }
      >
        {decryptionStatus === 'error' && 'Decryption failed'}
        <StyledIframe
          ref={iframeRef}
          title="Decrypted field"
          src={`${ENCRYPTED_FIELD_IFRAME_TARGET_ORIGIN}/decrypt-span/${token}`}
          onLoad={() => setIframeLoaded(true)}
          $decryptionFailed={decryptionStatus === 'error'}
        />
      </StyledChip>
    );
  },
);

const StyledChip = styled(U21Chip)`
  display: flex;
  align-items: center;
  .MuiChip-label {
    height: 100%;
  }
`;

const StyledIframe = styled.iframe<{ $decryptionFailed: boolean }>`
  height: 100%;
  width: 100%;
  border: none;
  ${({ $decryptionFailed }) =>
    $decryptionFailed &&
    css`
      display: none;
    `}
`;

const useGetIframeStyles = () => {
  const { palette, typography } = useTheme();
  return `
    #decrypted-span-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
    #decrypted-span {
      height: fit-content;
      width: fit-content;
      font-family: ${typography.fontFamily};
      font-size: 14px;
      font-weight: ${typography.fontWeightRegular};
      line-height: ${typography.body2.lineHeight};
      color: ${palette.text.primary};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;
};
