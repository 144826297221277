import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

// Components
import { IconArrowRight } from '@u21/tabler-icons';

import {
  U21Section,
  U21TitleCountLabel,
  U21Table,
  U21TableColumn,
} from 'app/shared/u21-ui/components';

// Selectors
import {
  selectAlertLoading,
  selectAlertEntities,
} from 'app/modules/alerts/selectors';
import {
  selectAgent,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';

// Models
import {
  AssociatedObject,
  AssociatedObjectType,
} from 'app/modules/alerts/models';
import {
  AssociatedAlert,
  AssociatedCase,
  AssociatedSAR,
} from 'app/modules/alerts/types';

// Utils
import styled from 'styled-components';
import { isQueueAccessible } from 'app/modules/queues/utils';
import {
  useToggleAlertSidebar,
  useToggleCaseSidebar,
  useToggleFinCENSARSidebar,
} from 'app/modules/sidebar/hooks';
import {
  selectSidebarAlertID,
  selectSidebarCaseID,
  selectSidebarFinCENSARID,
} from 'app/modules/sidebar/selectors';

interface OwnProps {
  // The type of associated article this table should be rendering
  category: AssociatedObjectType;
  update?: (payload, tableConfig) => void;
  articles: AssociatedObject[];
  // The lumos route with `:id` that should get formatted when we want to navigate to its details page
  detailsRoute: string;
  config:
    | U21TableColumn<AssociatedAlert>[]
    | U21TableColumn<AssociatedCase>[]
    | U21TableColumn<AssociatedSAR>[];
}

const CATEGORY_LABEL: Record<AssociatedObjectType, string> = {
  alert: 'Alerts',
  case: 'Cases',
  sar: 'SARs',
};

export const PriorArticlesTable = ({
  category,
  update = () => {},
  articles,
  detailsRoute,
  config,
}: OwnProps) => {
  const loading = useSelector(selectAlertLoading);

  const [collapsed, setCollapsed] = useState(false);
  const handleToggleCollapse = () => setCollapsed(!collapsed);

  const classifiedQueuesEnabled = useSelector(selectClassifiedQueuesEnabled);
  const hasReadAssignmentsPermission = useSelector(
    selectHasReadAssignmentsPermissions,
  );
  const sessionAgent = useSelector(selectAgent);
  const alertEntities = useSelector(selectAlertEntities);
  const sidebarAlertID = useSelector(selectSidebarAlertID);
  const sidebarCaseID = useSelector(selectSidebarCaseID);
  const sidebarFinCENSARID = useSelector(selectSidebarFinCENSARID);

  const relevantEntitiesTitle = useMemo(() => {
    // A list of unique entity IDs that are associated among the provided articles
    const relevantEntities = uniq(
      articles.reduce(
        (collectedEntities, article) => [
          ...collectedEntities,
          ...article.entity_ids,
        ],
        [],
      ),
    );

    // No relevant entities to give information about
    if (relevantEntities.length === 0) {
      return '';
    }

    // If the articles are related through one entity, try to find it
    if (relevantEntities.length === 1) {
      const relevantEntity = alertEntities.find(
        (entity) => entity.id === relevantEntities[0],
      )?.name_readable;
      return relevantEntity ? `(${relevantEntity})` : '';
    }

    return `(${relevantEntities.length} entities)`;
  }, [alertEntities, articles]);

  const highlighted = useMemo(() => {
    switch (category) {
      case 'alert':
        return sidebarAlertID ? [sidebarAlertID] : undefined;
      case 'case':
        return sidebarCaseID ? [sidebarCaseID] : undefined;
      case 'sar':
        return sidebarFinCENSARID ? [sidebarFinCENSARID] : undefined;
      default:
        return undefined;
    }
  }, [category, sidebarAlertID, sidebarCaseID, sidebarFinCENSARID]);

  const toggleAlertSidebar = useToggleAlertSidebar();
  const toggleCaseSidebar = useToggleCaseSidebar();
  const toggleFinCENSARSidebar = useToggleFinCENSARSidebar();

  const handleRowClick = (
    id: number,
    data: AssociatedObject,
    e: KeyboardEvent,
  ) => {
    switch (category) {
      case 'alert':
        toggleAlertSidebar(id, e);
        break;
      case 'case':
        toggleCaseSidebar(id, e);
        break;
      case 'sar':
        toggleFinCENSARSidebar(id, e);
        break;
      default:
        break;
    }
  };

  const goToColumn:
    | U21TableColumn<AssociatedAlert>
    | U21TableColumn<AssociatedCase>
    | U21TableColumn<AssociatedSAR> = {
    id: 'goto',
    type: 'button',
    cellProps: (row) => ({
      children: <IconArrowRight />,
      to: detailsRoute.replace(':id', String(row.id)),
      hidden:
        classifiedQueuesEnabled &&
        !isQueueAccessible(
          hasReadAssignmentsPermission,
          sessionAgent.accessible_queues,
          row.queue_id,
          row.queue_access_type,
        ),
    }),
    disableSortBy: true,
  };

  const columns = [...config, goToColumn] as U21TableColumn<AssociatedObject>[];

  return (
    <U21Section
      title={
        <StyledU21TitleCountLabel
          count={articles.length}
          label={category}
        >{`Prior ${CATEGORY_LABEL[category]} ${relevantEntitiesTitle}`}</StyledU21TitleCountLabel>
      }
      collapsible={Boolean(articles.length)}
      collapsed={collapsed}
      onToggleCollapse={handleToggleCollapse}
    >
      {!loading && articles.length > 0 && (
        <U21Table
          data={loading ? [] : articles}
          columns={columns}
          onStateChange={update}
          count={loading ? 0 : articles.length}
          highlighted={highlighted}
          isRowClickable={(data: AssociatedObject) =>
            !classifiedQueuesEnabled ||
            isQueueAccessible(
              hasReadAssignmentsPermission,
              sessionAgent.accessible_queues,
              data.queue_id,
              data.queue_access_type,
            )
          }
          onRowClick={handleRowClick}
          defaultPageSize={5}
          noDataComponent={null}
        />
      )}
    </U21Section>
  );
};

const StyledU21TitleCountLabel = styled(U21TitleCountLabel)`
  // title includes entity name which is customer data so casing should be preserved
  text-transform: none;
`;
