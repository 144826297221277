import { isCmdCtrlClick } from 'app/shared/utils/keyboard';
import { openInNewTab, ROUTES_MAP } from 'app/shared/utils/routes';
import {
  SidebarComponentTypes,
  SidebarDefinition,
} from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch } from 'react-redux';
import { SyntheticEvent, useCallback } from 'react';

export const useToggleSidebar = () => {
  const dispatch = useDispatch();
  return useCallback(
    (
      route: string,
      sidebarDefinition: SidebarDefinition,
      e: KeyboardEvent | MouseEvent | SyntheticEvent,
    ) => {
      // todo SC-82804: refactor KeyboardEvent to MouseEvent
      if (isCmdCtrlClick(e as KeyboardEvent) && route) {
        openInNewTab(route);
      } else {
        dispatch(
          toggleSidebar({
            ...sidebarDefinition,
            coordinates: {
              // todo SC-82804: refactor KeyboardEvent to MouseEvent
              x: (e as MouseEvent).clientX,
              y: (e as MouseEvent).clientY,
            },
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useToggleEntitySidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number | string, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.entitiesId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.ENTITY,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleInstrumentSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number | string, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.instrumentsId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.INSTRUMENT,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleTransactionSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number | string, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.dataExplorerTransactionsId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.TRANSACTION,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleActionEventSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number | string, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.dataExplorerActionEventsId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.ACTION_EVENT,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleAlertSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.alertsId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.ALERT,
          data: { alert: { id } },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleCaseSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.casesId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.CASE,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};

export const useToggleFinCENSARSidebar = () => {
  const toggleSidebarCallback = useToggleSidebar();
  return useCallback(
    (id: number, e: KeyboardEvent | MouseEvent | SyntheticEvent) =>
      toggleSidebarCallback(
        ROUTES_MAP.filingsSarId.path.replace(':id', String(id)),
        {
          type: SidebarComponentTypes.SAR,
          data: { id },
        },
        e,
      ),
    [toggleSidebarCallback],
  );
};
