import pluralize from 'pluralize';
import {
  U21Badge,
  U21Button,
  U21ButtonGroup,
  U21Chip,
  U21FormLabel,
  U21Section,
  U21Spacer,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  EMPTY_ENTITY_FILTERS,
  NODE_OR_CONNECTION_TYPE_TO_ICON,
  NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME,
  TRANSACTION_SENDING_RECEIVING_OPTIONS,
} from 'app/modules/networkAnalysisRefresh/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { IconX } from '@u21/tabler-icons';
import { isEmpty, isEqual } from 'lodash';
import {
  BaseObjectType,
  EntityNetworkAnalysisFilters,
  InstrumentNetworkAnalysisFilters,
  LinkTypeFilters,
  EntityNetworkAnalysisFilters as NetworkAnalysisFilters,
} from 'app/modules/networkAnalysisRefresh/types';
import { getFilterOptions } from 'app/modules/networkAnalysisRefresh/helpers';
import styled from 'styled-components';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';

const FilterSection = ({
  title,
  type,
  options,
  stats,
}: {
  title?: string;
  type: keyof Omit<NetworkAnalysisFilters, 'transactionFilters'>;
  options: Array<string>;
  stats?: Record<LinkTypeFilters, number>;
}) => {
  const { filters, setFilters } = useNetworkAnalysisContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const filter = new Set(filters[type]);

  return (
    <U21Spacer>
      {title && <U21Typography variant="subtitle2">{title}:</U21Typography>}
      <U21Spacer horizontal wrap>
        {options.map((v) => (
          <U21Badge
            key={v}
            content={stats?.[v]}
            color={filter.has(v) ? 'primary' : 'default'}
          >
            <StyledChip
              icon={
                filter.has(v) ? <IconX /> : NODE_OR_CONNECTION_TYPE_TO_ICON[v]
              }
              variant={filter.has(v) ? 'ghost' : 'outlined'}
              color={filter.has(v) ? 'primary' : undefined}
              onClick={() => {
                if (filter.has(v)) {
                  filter.delete(v);
                } else {
                  filter.add(v);
                }
                setFilters((prev) => ({
                  ...prev,
                  [type]: Array.from(filter),
                }));
                history.replace(pathname);
              }}
              rounded
            >
              {(() => {
                let label = v;
                if (type === 'linkType') {
                  label = NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME[v];
                }
                if (stats?.[v]) {
                  label = pluralize(label, stats[v]);
                }
                return label;
              })()}
            </StyledChip>
          </U21Badge>
        ))}
      </U21Spacer>
    </U21Spacer>
  );
};

const StyledChip = styled(U21Chip)`
  margin-bottom: 8px;
`;

const EMPTY_INSTRUMENT_FILTER_OPTIONS: Record<
  keyof Omit<InstrumentNetworkAnalysisFilters, 'transactionFilters'>,
  Array<any>
> = {
  linkType: [],
};

const EMPTY_ENTITY_FILTER_OPTIONS: Record<
  keyof Omit<EntityNetworkAnalysisFilters, 'transactionFilters'>,
  Array<any>
> = {
  ...EMPTY_INSTRUMENT_FILTER_OPTIONS,
  entityType: [],
  entitySubtype: [],
  entityStatus: [],
};

export const Filters = () => {
  const {
    data,
    totals: { totalTransactions },
    baseObjectType,
    filters,
    setFilters,
    clearFilters,
  } = useNetworkAnalysisContext();

  const filterOptions = useMemo(() => {
    if (!data) {
      return EMPTY_ENTITY_FILTER_OPTIONS;
    }
    return getFilterOptions(data, baseObjectType);
  }, [data, baseObjectType]);

  // clear filters on dismount
  useEffect(() => clearFilters, [clearFilters]);

  return (
    <U21Section
      title={<U21Typography variant="h6">Filters</U21Typography>}
      action={
        <U21Button
          size="small"
          startIcon={<IconX />}
          onClick={clearFilters}
          variant="contained"
          disabled={isEqual(filters, EMPTY_ENTITY_FILTERS)}
        >
          Clear all
        </U21Button>
      }
    >
      <U21Spacer spacing={3}>
        <U21Spacer>
          <U21Typography variant="subtitle2">Select connections:</U21Typography>
          <FilterSection
            type="linkType"
            options={filterOptions.linkType}
            stats={data?.link_counts}
          />
        </U21Spacer>
        {totalTransactions > 0 && (
          <U21Spacer horizontal wrap>
            <U21Spacer>
              <U21FormLabel>Transaction direction:</U21FormLabel>
              <U21ButtonGroup
                value={filters.transactionFilters.direction}
                buttons={TRANSACTION_SENDING_RECEIVING_OPTIONS}
                onClick={(value) => {
                  setFilters((prev) => ({
                    ...prev,
                    transactionFilters: {
                      ...prev.transactionFilters,
                      direction: value,
                    },
                  }));
                }}
              />
            </U21Spacer>
            <U21Spacer>
              <U21FormLabel>Transaction minimum:</U21FormLabel>
              <U21TextField
                value={filters.transactionFilters?.min}
                placeholder="Enter an amount..."
                onChange={(min: number) => {
                  setFilters((prev) => ({
                    ...prev,
                    transactionFilters: {
                      ...prev.transactionFilters,
                      min: min ?? 0,
                    },
                  }));
                }}
                type="number"
              />
            </U21Spacer>
          </U21Spacer>
        )}
        {baseObjectType === BaseObjectType.ENTITY && (
          <U21Spacer>
            {!isEmpty(filterOptions.entityType) && (
              <FilterSection
                title="Entity type"
                type="entityType"
                options={filterOptions.entityType}
              />
            )}
            {!isEmpty(filterOptions.entitySubtype) && (
              <FilterSection
                title="Entity subtype"
                type="entitySubtype"
                options={filterOptions.entitySubtype}
              />
            )}
            {!isEmpty(filterOptions.entityStatus) && (
              <FilterSection
                title="Entity status"
                type="entityStatus"
                options={filterOptions.entityStatus}
              />
            )}
          </U21Spacer>
        )}
      </U21Spacer>
    </U21Section>
  );
};
