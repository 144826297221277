import { useMemo } from 'react';
import { debounce } from 'lodash';
import { FormSpy } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import { useU21Form } from 'app/shared/u21-ui/components/form/hooks';
import { U21FormAPI } from 'app/shared/u21-ui/components/form/models';

interface OwnProps<FormValues> {
  delay?: number; // in milliseconds
  onSubmit: (
    values: FormValues,
    form: U21FormAPI<FormValues>,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  skipAutoSaveValidation?: boolean;
}
export const U21FormAutoSave = <FormValues,>({
  delay = 2000,
  onSubmit,
  skipAutoSaveValidation,
}: OwnProps<FormValues>) => {
  const form = useU21Form<FormValues>();

  const handleChange = useMemo(() => {
    return debounce(({ valid, dirty, values }) => {
      if (skipAutoSaveValidation && onSubmit && dirty) {
        onSubmit(values, form);
      } else if (valid && dirty) {
        form.submit();
      }
    }, delay);
  }, [delay, form, skipAutoSaveValidation, onSubmit]);

  return (
    <FormSpy
      onChange={handleChange}
      subscription={{
        values: true,
        valid: true,
        dirty: true,
      }}
    />
  );
};
