import UNIT21LOGO from 'app/images/logos/unit21.png';

import { formatDatetime } from 'app/shared/utils/date';
import { getRouteName, NOT_ALLOWED_BACK_ROUTES } from 'app/shared/utils/routes';
import { selectAgentOrgDisplayName } from 'app/modules/session/selectors';
import { selectRoutingHistory } from 'app/modules/navigator/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IconArrowLeft, IconDownload } from '@u21/tabler-icons';
import {
  U21Button,
  U21ButtonProps,
  U21Page as U21PageOld,
  U21PageNew,
  U21PageProps,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';

export interface U21DashboardPageProps extends U21PageProps {
  backButtonProps?: Omit<U21ButtonProps, 'as'>;
  backRoute: string;
  ignoredRoutes?: (route: string) => boolean;
  newDesign?: boolean;
  printProps?: { label: string };
}

const defaultIgnoredRoutes = () => false;

export const U21DashboardPage = (props: U21DashboardPageProps) => {
  const {
    action,
    backButtonProps,
    backRoute,
    beforeTitleContent,
    children,
    ignoredRoutes = defaultIgnoredRoutes,
    newDesign,
    printProps,
    ...rest
  } = props;

  const { go, push } = useHistory();
  const { pathname, search } = useLocation();

  const orgName = useSelector(selectAgentOrgDisplayName);
  const routingHistory = useSelector(selectRoutingHistory);

  const { historyIndex, historyBackRoute } = useMemo(() => {
    const index = routingHistory.findIndex(
      (i) =>
        !NOT_ALLOWED_BACK_ROUTES[i.route] &&
        !ignoredRoutes(i.route) &&
        i.route !== `${pathname}${search}`,
    );
    if (index > -1) {
      return {
        historyIndex: index,
        historyBackRoute: routingHistory[index].route,
      };
    }
    return {};
  }, [ignoredRoutes, pathname, routingHistory, search]);

  const actualBackRoute = historyBackRoute || backRoute;

  const backRouteName = useMemo(
    () => (actualBackRoute ? getRouteName(actualBackRoute) : ''),
    [actualBackRoute],
  );

  const U21Page = newDesign ? U21PageNew : U21PageOld;

  return (
    <U21Page
      action={
        printProps ? (
          <U21Spacer horizontal>
            {action}
            <StyledU21Button
              color="primary"
              onClick={() => {
                // normally window.print should work - however there's a
                // Safari launchdarkly issue that's preventing window.print
                // https://github.com/launchdarkly/js-client-sdk/issues/187
                const supported = document.execCommand('print');
                if (!supported) {
                  window.print();
                }
              }}
              startIcon={<IconDownload />}
              variant="contained"
            >
              Download
            </StyledU21Button>
          </U21Spacer>
        ) : (
          action
        )
      }
      beforeTitleContent={
        <>
          <StyledU21Button
            // eslint-disable-next-line react/no-children-prop
            children={backRouteName ? `Back to ${backRouteName}` : 'Back'}
            startIcon={<IconArrowLeft />}
            // go back in history if found, otherwise go to the default back route
            onClick={() => (historyIndex ? go(-historyIndex) : push(backRoute))}
            {...backButtonProps}
          />
          {beforeTitleContent}
        </>
      }
      {...rest}
    >
      {Boolean(printProps) && (
        <StyledU21Spacer horizontal>
          <StyledImg alt="Unit21 Logo" src={UNIT21LOGO} />
          <U21Spacer align="end" spacing={0}>
            <U21Typography variant="subtitle1">{orgName}</U21Typography>
            <U21Typography variant="subtitle1">
              {printProps?.label}
            </U21Typography>
            <U21Typography variant="subtitle1">
              {formatDatetime(new Date(), undefined, { showTimezone: true })}
            </U21Typography>
          </U21Spacer>
        </StyledU21Spacer>
      )}
      {children}
    </U21Page>
  );
};

const StyledU21Button = styled(U21Button)`
  text-transform: none;
  @media print {
    display: none;
  }
`;

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledImg = styled.img`
  height: 72px;
`;
