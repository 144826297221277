import {
  U21Button,
  U21Tooltip,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconLink, IconCopy } from '@u21/tabler-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useState } from 'react';
import { StyledIconCircleCheck } from 'app/modules/networkAnalysis/components/LinkSection';
import { useHistory, useLocation } from 'react-router-dom';

export const CopyLinkSectionHash = ({ hash }: { hash: string }) => {
  const [copied, setCopied] = useState(false);
  const { pathname, search } = useLocation();
  const history = useHistory();

  return (
    <U21Tooltip
      tooltip={
        copied ? (
          <U21Typography
            color="inherit"
            icon={<StyledIconCircleCheck />}
            variant="inherit"
          >
            Copied!
          </U21Typography>
        ) : (
          <U21Typography color="inherit" icon={<IconCopy />} variant="inherit">
            Copy section URL
          </U21Typography>
        )
      }
    >
      <span>
        <CopyToClipboard
          onCopy={() => {
            history.replace(`${pathname}${search}#${hash}`);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
          text={`${window.location.origin}${pathname}${search}#${hash}`}
        >
          <U21Button
            icon={<IconLink />}
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-label="Copy link"
          />
        </CopyToClipboard>
      </span>
    </U21Tooltip>
  );
};
