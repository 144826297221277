import { NODE_TYPE_TO_BG_IMG } from 'app/modules/networkAnalysisRefresh/constants';
import {
  NodeType,
  NetworkAnalysisGroups,
  FilteredNetworkAnalysisData,
  GraphResult,
  NetworkAnalysisLinkNodeData,
  EntityNodeData,
  NetworkAnalysisNodeData,
  EntityNetworkAnalysisFilters,
  NetworkAnalysisRefreshResponse,
  ConnectionType,
  TransactionEdgeData,
  EntityEntityRelationshipEdgeData,
  BaseObjectType,
  InstrumentNodeData,
  InstrumentInstrumentRelationshipEdgeData,
  ObjectCounts,
  InstrumentNetworkAnalysisFilters,
  NetworkAnalysisFilters,
} from 'app/modules/networkAnalysisRefresh/types';
import { SelectFormatAmountPayload } from 'app/modules/orgSettings/models';
import {
  BASE_CYTOSCAPE_OPTIONS,
  BASE_EDGE_STYLES,
  BASE_LABEL_STYLES,
  BASE_NODE_STYLES,
} from 'app/shared/components/Graphs/constants';
import { getMaxDegree } from 'app/shared/components/Graphs/utils';
import { CytoscapeOptions, EdgeSingular, NodeSingular } from 'cytoscape';
import palette from 'vendor/material-minimal/palette';

const isEntityNode = (
  node: NetworkAnalysisNodeData,
): node is EntityNodeData => {
  return node.node_type === NodeType.ENTITY;
};

const isInstrumentNode = (
  node: NetworkAnalysisNodeData,
): node is InstrumentNodeData => {
  return node.node_type === NodeType.INSTRUMENT;
};

const isLinkNode = (
  node: NetworkAnalysisNodeData,
  baseObjectType: BaseObjectType,
): node is NetworkAnalysisLinkNodeData => {
  return baseObjectType === BaseObjectType.ENTITY
    ? node.node_type !== NodeType.ENTITY
    : node.node_type !== NodeType.INSTRUMENT;
};

const doEntityFiltersMatch = (
  node: EntityNodeData,
  { entityType, entityStatus, entitySubtype }: EntityNetworkAnalysisFilters,
): boolean => {
  return (
    node.is_base_node ||
    ((!entityType.length || entityType.includes(node.entity_type ?? '')) &&
      (!entitySubtype.length ||
        entitySubtype.includes(node.internal_entity_type ?? '')) &&
      (!entityStatus.length || entityStatus.includes(node.status ?? '')))
  );
};

const doLinkFiltersMatch = (
  node: NetworkAnalysisLinkNodeData,
  { linkType }: NetworkAnalysisFilters,
): boolean => {
  return !linkType.length || linkType.includes(node.node_type);
};

const doTransactionFiltersMatch = (
  edge: TransactionEdgeData,
  { linkType, transactionFilters: transactionData }: NetworkAnalysisFilters,
): boolean => {
  if (linkType.length && !linkType.includes(ConnectionType.TRANSACTION)) {
    return false;
  }
  if (edge.transaction_data.amount < transactionData.min) {
    return false;
  }
  if (
    transactionData.direction === 'ANY' ||
    edge.transaction_data.flow === transactionData.direction
  ) {
    return true;
  }
  return false;
};

const doEntityEntityRelationshipFiltersMatch = (
  edge: EntityEntityRelationshipEdgeData,
  { linkType }: NetworkAnalysisFilters,
): boolean => {
  return !linkType.length || linkType.includes(edge.edge_type);
};

const doInstrumentInstrumentRelationshipFiltersMatch = (
  edge: InstrumentInstrumentRelationshipEdgeData,
  { linkType }: NetworkAnalysisFilters,
): boolean => {
  return !linkType.length || linkType.includes(edge.edge_type);
};

/**
 * Filters the network analysis data based on the provided filters and returns sets of IDs
 * that should be included in both the network graph visualization and network tables.
 *
 * The filtering logic works as follows:
 * - If no filters are applied (empty filter arrays), all sets contain all IDs
 * - For links: Only includes links whose type matches the linkType filter
 * - For entity objects: Only includes entities that match ALL of:
 *   - entityType filter (e.g. INDIVIDUAL, BUSINESS)
 *   - entityStatus filter (e.g. ACTIVE, CLOSED)
 *   - entitySubtype filter (e.g. specific business types)
 * - For instrument objects: Filters not implemented yet
 * - For transactions: Only includes transactions that match the transaction filters:
 *   - Minimum amount threshold
 *   - Transaction direction (SENDING/RECEIVING/BOTH)
 *
 * @param data - The raw network analysis graph data containing nodes and edges
 * @param filters - Object containing filter criteria for links, entities and transactions
 * @returns FilteredNetworkAnalysisData containing Sets of IDs to include in visualization/tables
 */
export const filterData = (
  { nodes, edges }: GraphResult,
  {
    filters,
    baseObjectType,
  }:
    | {
        filters: EntityNetworkAnalysisFilters;
        baseObjectType: BaseObjectType.ENTITY;
      }
    | {
        filters: InstrumentNetworkAnalysisFilters;
        baseObjectType: BaseObjectType.INSTRUMENT;
      },
): FilteredNetworkAnalysisData => {
  const sets: FilteredNetworkAnalysisData = {
    objects: new Set(),
    links: new Set(),
    transactions: new Set(),
    entityRelationships: new Set(),
    instrumentRelationships: new Set(),
  };
  for (const edge of Object.values(edges)) {
    let shouldAddSource = false;
    let shouldAddTarget = false;
    let shouldAddEdgeToTransactions: boolean | undefined;
    let shouldAddEdgeToEntityRelationships: boolean | undefined;
    let shouldAddEdgeToInstrumentRelationships: boolean | undefined;

    const { source, target } = edge;

    const sourceNode = nodes[source];
    const targetNode = nodes[target];

    // check transaction filters
    if (edge.edge_type === ConnectionType.TRANSACTION) {
      shouldAddEdgeToTransactions = doTransactionFiltersMatch(edge, filters);
      if (!shouldAddEdgeToTransactions) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // check base entity entity's relationship filters
    } else if (
      edge.edge_type === ConnectionType.ENTITY_RELATIONSHIP &&
      baseObjectType === BaseObjectType.ENTITY
    ) {
      shouldAddEdgeToEntityRelationships =
        doEntityEntityRelationshipFiltersMatch(edge, filters);
      if (!shouldAddEdgeToEntityRelationships) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // check base instrumnent's instrument relationship filters
    } else if (
      edge.edge_type === ConnectionType.INSTRUMENT_RELATIONSHIP &&
      baseObjectType === BaseObjectType.INSTRUMENT
    ) {
      shouldAddEdgeToInstrumentRelationships =
        doInstrumentInstrumentRelationshipFiltersMatch(edge, filters);
      if (!shouldAddEdgeToInstrumentRelationships) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // check inter-object relationship (e.g. entity's instrument relationships or vice versa)
    } else if (
      (baseObjectType === BaseObjectType.ENTITY &&
        edge.edge_type === NodeType.INSTRUMENT) ||
      (baseObjectType === BaseObjectType.INSTRUMENT &&
        edge.edge_type === NodeType.ENTITY)
    ) {
      if (
        filters.linkType.length &&
        ((!filters.linkType.includes(NodeType.INSTRUMENT) &&
          baseObjectType === BaseObjectType.ENTITY) ||
          (!filters.linkType.includes(NodeType.ENTITY) &&
            baseObjectType === BaseObjectType.INSTRUMENT))
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (baseObjectType === BaseObjectType.ENTITY) {
        shouldAddEdgeToInstrumentRelationships = true;
      } else if (baseObjectType === BaseObjectType.INSTRUMENT) {
        shouldAddEdgeToEntityRelationships = true;
      }
    }
    // check source node
    if (
      baseObjectType === BaseObjectType.ENTITY &&
      isEntityNode(sourceNode) &&
      doEntityFiltersMatch(sourceNode, filters)
    ) {
      shouldAddSource = true;
    } else if (
      baseObjectType === BaseObjectType.INSTRUMENT &&
      isInstrumentNode(sourceNode)
    ) {
      shouldAddSource = true;
    } else if (
      isLinkNode(sourceNode, baseObjectType) &&
      doLinkFiltersMatch(sourceNode, filters)
    ) {
      shouldAddSource = true;
    }

    // check target node
    if (
      baseObjectType === BaseObjectType.ENTITY &&
      isEntityNode(targetNode) &&
      doEntityFiltersMatch(targetNode, filters)
    ) {
      shouldAddTarget = true;
    } else if (
      baseObjectType === BaseObjectType.INSTRUMENT &&
      isInstrumentNode(targetNode)
    ) {
      shouldAddTarget = true;
    } else if (
      isLinkNode(targetNode, baseObjectType) &&
      doLinkFiltersMatch(targetNode, filters)
    ) {
      shouldAddTarget = true;
    }

    // add both nodes if both should be added
    if (
      shouldAddSource &&
      shouldAddTarget &&
      // if it's undefined, it means the edge type is not a transaction
      // so still fine to add source and target
      shouldAddEdgeToTransactions !== false &&
      shouldAddEdgeToEntityRelationships !== false &&
      shouldAddEdgeToInstrumentRelationships !== false
    ) {
      if (
        (baseObjectType === BaseObjectType.ENTITY &&
          isEntityNode(sourceNode)) ||
        (baseObjectType === BaseObjectType.INSTRUMENT &&
          isInstrumentNode(sourceNode))
      ) {
        sets.objects.add(source);
      } else if (isLinkNode(sourceNode, baseObjectType)) {
        sets.links.add(source);
      }
      if (
        (baseObjectType === BaseObjectType.ENTITY &&
          isEntityNode(targetNode)) ||
        (baseObjectType === BaseObjectType.INSTRUMENT &&
          isInstrumentNode(targetNode))
      ) {
        sets.objects.add(target);
      } else if (isLinkNode(targetNode, baseObjectType)) {
        sets.links.add(target);
      }
      if (shouldAddEdgeToTransactions) {
        sets.transactions.add(edge.id);
      } else if (shouldAddEdgeToEntityRelationships) {
        sets.entityRelationships.add(edge.id);
      } else if (shouldAddEdgeToInstrumentRelationships) {
        sets.instrumentRelationships.add(edge.id);
      }
    }
  }
  return sets;
};

/**
 * Filters the graph elements and adds degree information for layout purposes.
 * The base entity gets the highest degree, link nodes get second highest degree,
 * and linked entity nodes get staggered lower degrees so the graph is more readable.
 *
 * @param data - The graph data containing nodes and edges
 * @param filteredData - Sets of filtered node/edge IDs to include
 * @returns The filtered elements with degree information added
 */
export const getElements = (
  data: GraphResult,
  baseObjectType: BaseObjectType,
  {
    objects,
    links,
    transactions,
    entityRelationships,
    instrumentRelationships,
  }: FilteredNetworkAnalysisData,
  formatAmount: (
    payload: Omit<SelectFormatAmountPayload, 'precision'>,
  ) => string,
) => {
  const elements = {
    nodes: {},
    edges: {},
  };

  const nodes = Object.values(data.nodes);
  const maxDegree = getMaxDegree(objects.size, 3);
  const baseNodeStyleOptions = {
    opaque: true,
    selected: false,
  };

  let degreeStaggerer = 0;
  for (const node of nodes) {
    if (
      (node.node_type === NodeType.ENTITY &&
        baseObjectType === BaseObjectType.ENTITY) ||
      (node.node_type === NodeType.INSTRUMENT &&
        baseObjectType === BaseObjectType.INSTRUMENT)
    ) {
      // base object is highest degree
      // base object should always be added to the graph
      if (node.is_base_node) {
        elements.nodes[node.id] = {
          data: {
            ...node,
            ...baseNodeStyleOptions,
            degree: maxDegree,
          },
        };
      } else if (objects.has(node.id)) {
        // linked object nodes are staggered lower degrees
        elements.nodes[node.id] = {
          data: {
            ...node,
            ...baseNodeStyleOptions,
            degree: degreeStaggerer % (maxDegree - 2),
          },
        };
        degreeStaggerer += 1;
      }
    } else if (links.has(node.id)) {
      // link nodes are second highest degree
      elements.nodes[node.id] = {
        data: {
          ...node,
          ...baseNodeStyleOptions,
          degree: maxDegree - 1,
        },
      };
    }
  }

  for (const edge of Object.values(data.edges)) {
    const { source: sourceID, target: targetID } = edge;
    const sourceNode = data.nodes[sourceID];
    const targetNode = data.nodes[targetID];
    // only add edges if both nodes are in the graph
    if (
      (links.has(sourceID) ||
        (((baseObjectType === BaseObjectType.ENTITY &&
          sourceNode.node_type === NodeType.ENTITY) ||
          (baseObjectType === BaseObjectType.INSTRUMENT &&
            sourceNode.node_type === NodeType.INSTRUMENT)) &&
          objects.has(sourceID))) &&
      (links.has(targetID) ||
        (((baseObjectType === BaseObjectType.ENTITY &&
          targetNode.node_type === NodeType.ENTITY) ||
          (baseObjectType === BaseObjectType.INSTRUMENT &&
            targetNode.node_type === NodeType.INSTRUMENT)) &&
          objects.has(targetID))) &&
      (edge.edge_type !== ConnectionType.TRANSACTION ||
        transactions.has(edge.id)) &&
      (edge.edge_type !== ConnectionType.ENTITY_RELATIONSHIP ||
        entityRelationships.has(edge.id)) &&
      (edge.edge_type !== ConnectionType.INSTRUMENT_RELATIONSHIP ||
        instrumentRelationships.has(edge.id))
    ) {
      elements.edges[edge.id] = {
        data: {
          ...edge,
          selected: false,
          ...(edge.edge_type === ConnectionType.TRANSACTION && {
            label: formatAmount({
              amount: edge.transaction_data.amount,
              currencyCodeProps: edge.transaction_data.currency ?? undefined,
            }),
          }),
        },
      };
    }
  }

  return elements;
};

/**
 * Converts graph data into grouped collections of entities and links for display in link tables
 *
 * Takes the raw graph data containing nodes and edges and organizes it into two collections:
 * 1. Links grouped with their connected entities
 * 2. Entities grouped with their connected links
 *
 * This transformation enables efficient lookup and display of:
 * - All entities connected to a specific link type (e.g. all entities sharing an email address)
 * - All links connected to a specific entity (e.g. all phone numbers belonging to an entity)
 *
 * @param data - The raw network analysis graph data containing nodes and edges
 * @param filteredData - Sets of filtered node/edge IDs to include
 * @returns NetworkAnalysisGroups containing the grouped links and entities
 */
export const convertDataToNetworkAnalysisGroups = (
  { nodes, edges }: GraphResult,
  {
    links: linksToInclude,
    objects: entitiesToInclude,
    transactions: transactionsToInclude,
    entityRelationships: entityRelationshipsToInclude,
    instrumentRelationships: instrumentRelationshipsToInclude,
  }: FilteredNetworkAnalysisData,
  baseObjectType: BaseObjectType,
): NetworkAnalysisGroups => {
  return Object.values(edges).reduce<NetworkAnalysisGroups>(
    (acc, edge) => {
      const { source: sourceID, target: targetID } = edge;
      const source = nodes[sourceID];
      const target = nodes[targetID];

      // shouldn't be possible... but just in case
      if (!source || !target) {
        return acc;
      }

      const {
        links,
        entities,
        transactions,
        entityRelationships,
        instrumentRelationships,
      } = acc;

      // entity relationships
      if (edge.edge_type === ConnectionType.ENTITY_RELATIONSHIP) {
        if (!entityRelationshipsToInclude.has(edge.id)) {
          return acc;
        }
        if (
          entitiesToInclude.has(source.id) &&
          entitiesToInclude.has(target.id)
        ) {
          entityRelationships[edge.id] = edge;
          if (
            entitiesToInclude.has(source.id) &&
            !entities[source.id] &&
            isEntityNode(source)
          ) {
            entities[source.id] = { ...source, links: [] };
          }
          if (
            entitiesToInclude.has(target.id) &&
            !entities[target.id] &&
            isEntityNode(target)
          ) {
            entities[target.id] = { ...target, links: [] };
          }
        }
        return acc;
      }

      // transactions
      if (edge.edge_type === ConnectionType.TRANSACTION) {
        if (!transactionsToInclude.has(edge.id)) {
          return acc;
        }
        transactions[edge.id] = edge;
        if (
          entitiesToInclude.has(source.id) &&
          !entities[source.id] &&
          isEntityNode(source)
        ) {
          entities[source.id] = { ...source, links: [] };
        }
        if (
          entitiesToInclude.has(target.id) &&
          !entities[target.id] &&
          isEntityNode(target)
        ) {
          entities[target.id] = { ...target, links: [] };
        }
        return acc;
      }

      // instrument relationships
      if (
        edge.edge_type === NodeType.INSTRUMENT &&
        instrumentRelationshipsToInclude.has(edge.id)
      ) {
        // only add instrument relationships if the source or target is the base entity
        instrumentRelationships[edge.id] = edge;
      }

      // all other link types
      let entity: EntityNodeData;
      let link: NetworkAnalysisLinkNodeData;
      if (isEntityNode(source)) {
        entity = source;
      } else if (isEntityNode(target)) {
        entity = target;
      } else {
        throw new Error('Invalid node type');
      }

      if (isLinkNode(source, baseObjectType)) {
        link = source;
      } else if (isLinkNode(target, baseObjectType)) {
        link = target;
      } else {
        throw new Error('Invalid node type');
      }

      if (entitiesToInclude.has(entity.id)) {
        // add entity to map if it doesn't exist
        if (!entities[entity.id]) {
          entities[entity.id] = { ...entity, links: [] };
        }
        // add link id to entity
        entities[entity.id].links.push(link.id);
      }

      if (linksToInclude.has(link.id)) {
        // add link to link map if it doesn't exist
        if (!links[link.id]) {
          links[link.id] = { ...link, entities: [] };
        }
        if (entitiesToInclude.has(entity.id)) {
          // add entity id to link
          links[link.id].entities.push(entity.id);
        }
      }

      return acc;
    },
    {
      links: {},
      entities: {},
      transactions: {},
      entityRelationships: {},
      instrumentRelationships: {},
    },
  );
};

const updateEntityFilterSets = (
  baseObjectType: BaseObjectType,
  node: NetworkAnalysisNodeData,
  filterSets: Record<
    keyof Omit<EntityNetworkAnalysisFilters, 'transactionFilters'>,
    Set<string>
  >,
) => {
  if (isEntityNode(node) && baseObjectType === BaseObjectType.ENTITY) {
    if (node.entity_type) {
      filterSets.entityType.add(node.entity_type);
    }
    if (node.internal_entity_type) {
      filterSets.entitySubtype.add(node.internal_entity_type);
    }
    if (node.status) {
      filterSets.entityStatus.add(node.status);
    }
  } else if (isLinkNode(node, baseObjectType)) {
    filterSets.linkType.add(node.node_type);
  }
};

export const getFilterOptions = (
  { graph_result: { nodes, edges } }: NetworkAnalysisRefreshResponse,
  baseObjectType: BaseObjectType,
): Omit<EntityNetworkAnalysisFilters, 'transactionFilters'> => {
  const filterSets = {
    linkType: new Set<NetworkAnalysisFilters['linkType'][number]>(),
    entityType: new Set<EntityNetworkAnalysisFilters['entityType'][number]>(),
    entitySubtype: new Set<
      EntityNetworkAnalysisFilters['entitySubtype'][number]
    >(),
    entityStatus: new Set<
      EntityNetworkAnalysisFilters['entityStatus'][number]
    >(),
  };

  for (const { source, target, edge_type: edgeType } of Object.values(edges)) {
    const sourceNode = nodes[source];
    const targetNode = nodes[target];
    if (edgeType === ConnectionType.TRANSACTION) {
      filterSets.linkType.add(ConnectionType.TRANSACTION);
    } else if (edgeType === ConnectionType.ENTITY_RELATIONSHIP) {
      filterSets.linkType.add(ConnectionType.ENTITY_RELATIONSHIP);
    } else if (edgeType === ConnectionType.INSTRUMENT_RELATIONSHIP) {
      filterSets.linkType.add(ConnectionType.INSTRUMENT_RELATIONSHIP);
    }
    updateEntityFilterSets(baseObjectType, sourceNode, filterSets);
    updateEntityFilterSets(baseObjectType, targetNode, filterSets);
  }

  return {
    linkType: Array.from(filterSets.linkType).toSorted(),
    entityType: Array.from(filterSets.entityType),
    entitySubtype: Array.from(filterSets.entitySubtype),
    entityStatus: Array.from(filterSets.entityStatus),
  };
};

export const getEdgeColor = (
  edge: EdgeSingular,
  contrastText: boolean = false,
): string => {
  if (edge.data('edge_type') === ConnectionType.TRANSACTION) {
    if (edge.data('transaction_data.flow') === 'OUTBOUND') {
      return contrastText
        ? palette.light.error.contrastText
        : palette.light.error.light;
    } else if (edge.data('transaction_data.flow') === 'INBOUND') {
      return contrastText
        ? palette.light.success.contrastText
        : palette.light.success.light;
    } else if (edge.data('transaction_data.flow') === 'OTHER') {
      return contrastText
        ? palette.light.colors.yellow.contrastText
        : palette.light.colors.yellow.main;
    }
  }
  if (edge.data('edge_type') === ConnectionType.ENTITY_RELATIONSHIP) {
    return contrastText
      ? palette.light.colors.purple.contrastText
      : palette.light.colors.purple.main;
  }
  // no need for contrast text here bc we're not dealing with an edge with a label
  if (edge.data('selected')) {
    return contrastText
      ? palette.light.primary.contrastText
      : palette.light.primary.light;
  }
  return contrastText ? palette.light.common.white : palette.light.grey[300];
};

export const selectNodes = (
  clickedNode: NodeSingular,
  baseEntityId: string,
) => {
  for (const edge of clickedNode.connectedEdges()) {
    // highlight node's connected edges
    edge.data('selected', true);
    // highlight node's edges' connected nodes
    for (const node of edge.connectedNodes()) {
      node.data('selected', true);
      // if node is a link, make sure that edges connected to base entity are also selected
      if (node.data('node_type') !== NodeType.ENTITY) {
        node.connectedEdges().forEach((edge2) => {
          if (
            edge2.data('source') === baseEntityId ||
            edge2.data('target') === baseEntityId
          ) {
            edge2.data('selected', true);
          }
        });
      }
    }
  }
};

export const getBaseNodeId = (
  data?: NetworkAnalysisRefreshResponse,
): string => {
  if (!data) {
    return '';
  }
  return (
    Object.values(data.graph_result.nodes).find(
      (node) =>
        (isEntityNode(node) || isInstrumentNode(node)) && node.is_base_node,
    )?.id ?? ''
  );
};

export const getObjectCounts = (
  baseObjectType: BaseObjectType,
  graphResult?: GraphResult,
): ObjectCounts => {
  const counts = {
    totalObjects: 0,
    totalLinks: 0,
    totalTransactions: 0,
    totalEntityRelationships: 0,
    totalInstrumentRelationships: 0,
  };
  if (!graphResult) {
    return counts;
  }
  const { nodes, edges } = graphResult;
  for (const node of Object.values(nodes)) {
    if (
      (node.node_type === NodeType.ENTITY &&
        baseObjectType === BaseObjectType.ENTITY) ||
      (node.node_type === NodeType.INSTRUMENT &&
        baseObjectType === BaseObjectType.INSTRUMENT &&
        !node.is_base_node)
    ) {
      counts.totalObjects += 1;
    } else counts.totalLinks += 1;
  }
  for (const edge of Object.values(edges)) {
    if (edge.edge_type === ConnectionType.TRANSACTION) {
      counts.totalTransactions += 1;
    } else if (edge.edge_type === ConnectionType.ENTITY_RELATIONSHIP) {
      counts.totalEntityRelationships += 1;
    } else if (edge.edge_type === ConnectionType.INSTRUMENT_RELATIONSHIP) {
      counts.totalInstrumentRelationships += 1;
    } else if (
      edge.edge_type === NodeType.INSTRUMENT &&
      baseObjectType === BaseObjectType.ENTITY
    ) {
      counts.totalInstrumentRelationships += 1;
    } else if (
      edge.edge_type === NodeType.ENTITY &&
      baseObjectType === BaseObjectType.INSTRUMENT
    ) {
      counts.totalEntityRelationships += 1;
    }
  }
  return counts;
};

export const getCytoscapeOptions = (
  baseObjectType: BaseObjectType,
): CytoscapeOptions => {
  const baseNodeType =
    baseObjectType === BaseObjectType.ENTITY
      ? NodeType.ENTITY
      : NodeType.INSTRUMENT;
  return {
    ...BASE_CYTOSCAPE_OPTIONS,
    style: [
      {
        selector: 'node',
        style: {
          ...BASE_NODE_STYLES,
          'font-size': '10px',
          'background-color': (node) => {
            if (node.data('node_type') === baseNodeType) {
              return palette.light[node.data('is_base_node') ? 'error' : 'info']
                .main;
            }
            return palette.light.primary.main;
          },
          'border-color': (node) => {
            if (
              node.data('selected') ||
              node.data('node_type') !== baseNodeType
            ) {
              return palette.light.primary.light;
            }
            return palette.light[node.data('is_base_node') ? 'error' : 'info']
              .light;
          },
          'background-image': (node) =>
            NODE_TYPE_TO_BG_IMG[node.data('node_type')] ?? 'none',
          opacity: (node) =>
            node.data('selected') ||
            node.data('opaque') ||
            node.data('is_base_node')
              ? 1
              : 0.35,
          content: (node) => node.data('label') || '',
        },
      },
      {
        selector: 'edge',
        style: {
          ...BASE_EDGE_STYLES,
          opacity: (edge: EdgeSingular) => (edge.data('selected') ? 1 : 0.35),
          'line-style': (edge: EdgeSingular) =>
            edge.data('is_soft_match') ? 'dashed' : 'solid',
          'line-color': getEdgeColor,
          'target-arrow-color': getEdgeColor,
          'target-arrow-shape': (edge) => {
            if (edge.data('is_soft_match')) {
              return 'none';
            }
            if (edge.data('edge_type') === ConnectionType.TRANSACTION) {
              const flow = edge.data('transaction_data')?.flow;
              if (flow === 'INBOUND' || flow === 'OUTBOUND') {
                return 'triangle';
              }
            }
            if (edge.data('edge_type') === ConnectionType.ENTITY_RELATIONSHIP) {
              return 'triangle';
            }
            return 'none';
          },
          'arrow-scale': 0.75,
          label: (edge: EdgeSingular) => edge.data('label') ?? '',
          'curve-style': 'bezier',
        },
      },
      {
        selector: 'node[label]',
        style: {
          ...BASE_LABEL_STYLES,
        },
      },
      {
        selector: 'edge[label]',
        style: {
          ...BASE_LABEL_STYLES,
          'text-background-color': getEdgeColor,
          color: (edge: EdgeSingular) => getEdgeColor(edge, true),
        },
      },
    ],
  };
};
