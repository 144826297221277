import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SidePanelOptionProps } from 'app/shared/components/SidePanel/model';

import {
  RESIZER_WIDTH,
  SIDE_PANEL_DEFAULT_WIDTH,
  SIDE_PANEL_MENU_WIDTH,
  SIDE_PANEL_MIN_WIDTH,
} from 'app/shared/components/SidePanel/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

import { debounce } from 'lodash';
import { selectSidebarOpen } from 'app/modules/sidebar/selectors';
import { setPanelWidths } from 'app/shared/components/SidePanel/sliceSidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import styled, { css } from 'styled-components';

import { IconChevronsLeft, IconChevronsRight } from '@u21/tabler-icons';
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelProps,
  PanelResizeHandle,
} from 'react-resizable-panels';
import { List, Paper } from '@mui/material';
import { SidePanelMenuItem } from 'app/shared/components/SidePanel/SidePanelMenuItem';
import { U21Button, U21Typography } from 'app/shared/u21-ui/components';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const SIDEPANEL_SEARCH_PARAM_KEY = 'sidepanel';

interface SidePanelInstance {
  expand: () => void;
  collapse: () => void;
}

export const SidePanelInstanceContext = createContext<SidePanelInstance | null>(
  null,
);

type CustomizablePanelProps = Partial<
  Omit<PanelProps, 'children' | 'minSize' | 'order'>
>;

interface Props {
  children: ReactNode;
  list: SidePanelOptionProps[];
  localStorageKey: LocalStorageKeys;
  mainPanelProps?: CustomizablePanelProps;
  sidePanelProps?: CustomizablePanelProps;
  ignoreSidebar?: boolean;
}

export const SidePanelLayout = (props: Props) => {
  const {
    children,
    ignoreSidebar,
    list,
    localStorageKey,
    mainPanelProps,
    sidePanelProps,
  } = props;

  const history = useHistory();
  const { search } = useLocation();

  const dispatch = useDispatch();
  const sidebarOpenState = useSelector(selectSidebarOpen);
  const sidebarOpen = ignoreSidebar ? false : sidebarOpenState;

  const parsed = queryString.parse(search);
  const selectedKey = parsed[SIDEPANEL_SEARCH_PARAM_KEY];

  const [dragging, setDragging] = useState(false);
  const [sidePanelCollapsed, setSidePanelCollapsed] = useState(false);

  const selected = useMemo(
    () => list.find((i) => i.label === selectedKey) ?? list[0],
    [list, selectedKey],
  );

  const sidePanelRef = useRef<ImperativePanelHandle>(null);

  const { ref: containerRef } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 300,
  });

  // calculate width as a percentage
  // use ref offsetWidth over width since the debounce causes initialization to be delayed
  const containerWidth = containerRef.current?.offsetWidth || Infinity;

  // resizer is not included in the percentage so account for half of resizer in percentages
  const sidePanelMinSize = Math.min(
    ((SIDE_PANEL_MIN_WIDTH + RESIZER_WIDTH / 2) / containerWidth) * 100,
    50,
  );
  const sidePanelDefaultSize = Math.min(
    ((SIDE_PANEL_DEFAULT_WIDTH + RESIZER_WIDTH / 2) / containerWidth) * 100,
    50,
  );
  const sidePanelCollapsedSize = (SIDE_PANEL_MENU_WIDTH / containerWidth) * 100;

  // no longer expandable if container width is less than 2x of SIDE_PANEL_MIN_WIDTH
  const expandable = containerWidth - RESIZER_WIDTH > 2 * SIDE_PANEL_MIN_WIDTH;

  useEffect(() => {
    if (list.length > 0) {
      // if no url param or param not valid, set to first element in list
      if (!selectedKey || !list.find((i) => i.label === selectedKey)) {
        history.replace({
          search: `?${queryString.stringify({ ...parsed, [SIDEPANEL_SEARCH_PARAM_KEY]: list[0].label })}`,
        });
      }
    }
  }, [history, list, parsed, selectedKey]);

  const expandableRef = useRef(expandable);
  expandableRef.current = expandable;
  const sidePanelInstance = useMemo<SidePanelInstance>(
    () => ({
      collapse: () => sidePanelRef.current?.collapse(),
      expand: () => {
        if (expandableRef.current) {
          sidePanelRef.current?.expand();
        }
      },
    }),
    [],
  );

  // workaround to force collapse sidepanel
  // this is a workaround due to a bug of the underlying library where
  // it does not collapse the sidepanel if smaller than minSizePixels
  useEffect(() => {
    if (!expandable && !sidePanelCollapsed) {
      sidePanelRef.current?.collapse();
    }
  }, [expandable, sidePanelCollapsed]);

  // calculate the height of the space available for the sidepanel so it doesn't scroll
  // browser height - everything above the sidepanel - everything below the sidepanel
  // everything above the sidepanel = offsetTop
  // everything below the sidepanel = 24px padding bottom
  const height = containerRef.current
    ? `${window.innerHeight - containerRef.current.offsetTop - 24}px`
    : '75vh';

  return (
    <SidePanelInstanceContext.Provider value={sidePanelInstance}>
      <Container ref={containerRef}>
        {/* render only when containerRef is defined since the width calculations need to be ready first */}
        {/* don't care about this in tests because it is not collapsible */}
        {(containerRef.current || process.env.NODE_ENV === 'test') && (
          <PanelGroup
            autoSaveId={localStorageKey}
            direction="horizontal"
            // overwrite overflow inline style so sticky works
            style={{ overflow: 'visible' }}
            onLayout={debounce(
              ([mainPanelPercentage, sidePanelPercentage]: number[]) => {
                const mainPanelWidth = Math.round(
                  (mainPanelPercentage / 100) * containerWidth -
                    RESIZER_WIDTH / 2,
                );
                const sidePanelWidth = Math.round(
                  (sidePanelPercentage / 100) * containerWidth -
                    RESIZER_WIDTH / 2,
                );
                dispatch(setPanelWidths({ mainPanelWidth, sidePanelWidth }));
              },
              300,
            )}
          >
            <Panel id="main-panel" minSize={50} order={1} {...mainPanelProps}>
              {children}
            </Panel>
            {list.length > 0 && (
              <>
                {/* PanelResizeHandle causes the UI to not be clickable so don't render in tests */}
                {!sidebarOpen && process.env.NODE_ENV !== 'test' && (
                  <>
                    {expandable ? (
                      <StyledPanelResizeHandle
                        $dragging={dragging}
                        onDragging={setDragging}
                      >
                        <Resizer />
                      </StyledPanelResizeHandle>
                    ) : (
                      // render spacing if resizer is not rendered
                      <HiddenPanelResizeHandle />
                    )}
                  </>
                )}
                <StickyPanel
                  $height={height}
                  $sidebarOpen={sidebarOpen}
                  // tests are collapsing the panel by default so turn off as a workaround
                  collapsible={process.env.NODE_ENV !== 'test'}
                  collapsedSize={sidePanelCollapsedSize}
                  defaultSize={sidePanelDefaultSize}
                  id="side-panel"
                  minSize={sidePanelMinSize}
                  order={2}
                  ref={sidePanelRef}
                  {...sidePanelProps}
                  onExpand={() => {
                    setSidePanelCollapsed(false);
                    sidePanelProps?.onExpand?.();
                  }}
                  onCollapse={() => {
                    setSidePanelCollapsed(true);
                    sidePanelProps?.onCollapse?.();
                  }}
                >
                  <SidePanelContainer square $sidebarOpen={sidebarOpen}>
                    <SidePanelHeader $expandable={expandable}>
                      <CollapseButtonContainer>
                        {expandable && (
                          <>
                            {sidePanelCollapsed ? (
                              <U21Button
                                aria-label="Expand"
                                icon={<IconChevronsLeft />}
                                onClick={() => sidePanelRef.current?.expand()}
                              />
                            ) : (
                              <U21Button
                                aria-label="Collapse"
                                icon={<IconChevronsRight />}
                                onClick={() => sidePanelRef.current?.collapse()}
                              />
                            )}
                          </>
                        )}
                      </CollapseButtonContainer>
                      {!sidePanelCollapsed && (
                        <SidePanelHeaderContent>
                          {selected.header || (
                            <U21Typography variant="subtitle1">
                              {selected.label}
                            </U21Typography>
                          )}
                          <SidePanelActionContainer>
                            {selected.action}
                          </SidePanelActionContainer>
                        </SidePanelHeaderContent>
                      )}
                    </SidePanelHeader>
                    <SidePanelContent>
                      <SidePanelMenu $expandable={expandable}>
                        {list.map((Elm) => {
                          return (
                            <SidePanelMenuItem
                              key={Elm.label}
                              Elm={Elm}
                              expandable={expandable}
                              selected={selected}
                              setSelected={() => {
                                history.push({
                                  search: `?${queryString.stringify({ ...parsed, [SIDEPANEL_SEARCH_PARAM_KEY]: Elm.label })}`,
                                });
                                if (sidePanelCollapsed && expandable) {
                                  sidePanelRef.current?.expand();
                                }
                              }}
                            />
                          );
                        })}
                      </SidePanelMenu>
                      <SidePanelMainContentContainer>
                        {selected.content}
                      </SidePanelMainContentContainer>
                    </SidePanelContent>
                  </SidePanelContainer>
                </StickyPanel>
              </>
            )}
          </PanelGroup>
        )}
      </Container>
    </SidePanelInstanceContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const StickyPanel = styled(Panel)<{
  $height?: string;
  $sidebarOpen?: boolean;
}>`
  position: sticky;
  top: 0;
  height: ${(props) => props.$height};
  display: flex;
  max-width: ${(props) => (props.$sidebarOpen ? 0 : '100%')};
`;

const Resizer = styled.div`
  width: 2px;
  height: 100%;
  background-color: transparent;
`;

const StyledPanelResizeHandle = styled(PanelResizeHandle)<{
  $dragging?: boolean;
}>`
  padding: 0 3px;

  // respect panel border-radius
  margin: ${(props) => props.theme.shape.borderRadiusMd}px 0;

  ${(props) =>
    props.$dragging &&
    css`
      ${Resizer} {
        background-color: ${props.theme.palette.grey[500_48]};
      }
    `}

  :hover {
    ${Resizer} {
      background-color: ${(props) => props.theme.palette.grey[500_48]};
    }
  }
`;

const HiddenPanelResizeHandle = styled(PanelResizeHandle)`
  width: ${RESIZER_WIDTH}px;
`;

const SidePanelContainer = styled(Paper)<{
  $sidebarOpen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: ${(props) => props.theme.transitions.create(['all'])};
  border-radius: ${(props) => props.theme.shape.borderRadiusMd}px;
  ${({ $sidebarOpen }) => {
    if ($sidebarOpen) {
      return css`
        width: 0;
        overflow: hidden;
      `;
    }
    return css`
      border: 1px solid ${(props) => props.theme.palette.grey[50024]};
    `;
  }}
`;

const SidePanelHeader = styled.div<{ $expandable: boolean }>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.$expandable ? 60 : 16)}px;
`;

const CollapseButtonContainer = styled.div`
  width: ${SIDE_PANEL_MENU_WIDTH}px;
  display: flex;
  justify-content: center;
`;

const SidePanelHeaderContent = styled.div`
  display: flex;
  flex: 1;
  padding: 0 16px;
`;

const SidePanelActionContainer = styled.div`
  margin-left: auto;
`;

const SidePanelContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const SidePanelMenu = styled(List)<{ $expandable?: boolean }>`
  height: 100%;
  width: ${SIDE_PANEL_MENU_WIDTH}px;
  ${(props) =>
    props.$expandable
      ? css`
          border-top: 1px solid ${props.theme.palette.grey[500_24]};
        `
      : css``}
  border-right: 1px solid ${(props) => props.theme.palette.grey[500_24]};
  background-color: ${(props) => props.theme.palette.grey[500_4]};
  padding: 0;
`;

const SidePanelMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  border-top: 1px solid ${(props) => props.theme.palette.grey[500_24]};
`;
