import {
  U21FormLabel,
  U21Modal,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { U21DataLabelSelect } from 'app/shared/u21-ui/components/dashboard';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDataConfigDeltaLake } from 'app/modules/orgSettings/selectors';

interface BulkEditDataLabelsModalProps {
  editObjectTags: (
    tagIdsToAdd: (string | number)[],
    tagIdsToRemove: (string | number)[],
  ) => Promise<void>;
  isEditLoading: boolean;
  title: ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const BulkEditDataLabelsModal = ({
  editObjectTags,
  isEditLoading,
  title,
  open,
  handleClose,
}: BulkEditDataLabelsModalProps) => {
  const isDataConfigDeltalake = useSelector(selectIsDataConfigDeltaLake);
  const [tagIdsAdd, setTagIdAdd] = useState<(number | string)[]>([]);
  const [tagIdsRemove, setTagIdRemove] = useState<(number | string)[]>([]);

  const handleAddTagChange = (tagIds: (string | number)[]) => {
    setTagIdAdd(tagIds);
    setTagIdRemove((prevTagIdsRemove) =>
      prevTagIdsRemove.filter((tagId) => !tagIds.includes(tagId)),
    );
  };

  const handleRemoveTagChange = (tagIds: (string | number)[]) => {
    setTagIdRemove(tagIds);
    setTagIdAdd((prevTagIdsAdd) =>
      prevTagIdsAdd.filter((tagId) => !tagIds.includes(tagId)),
    );
  };

  return (
    <U21Modal
      actionButtonProps={{
        loading: isEditLoading,
      }}
      open={open}
      title={title}
      onClose={() => {
        handleClose();
      }}
      onExited={() => {
        setTagIdAdd([]);
        setTagIdRemove([]);
      }}
      onAction={async () => {
        try {
          await editObjectTags(tagIdsAdd, tagIdsRemove);
          handleClose();
        } catch {
          // No-op because the error has already been handled.
        }
      }}
    >
      <U21Spacer spacing={2}>
        <U21Typography color="text.primary">
          Add or remove tags using the dropdowns below. This will apply to all
          checked instruments.
        </U21Typography>

        <div>
          <U21FormLabel help="If the instrument(s) already contain the tag(s) you're trying to add, they will be ignored.">
            Tags to add
          </U21FormLabel>
          <U21DataLabelSelect
            label={undefined}
            onChange={handleAddTagChange}
            value={tagIdsAdd}
          />
        </div>

        <div>
          <U21FormLabel help="If the instrument(s) don't contain the tag(s) you're trying to remove, they will be ignored.">
            Tags to remove
          </U21FormLabel>
          <U21DataLabelSelect
            label={undefined}
            onChange={handleRemoveTagChange}
            value={tagIdsRemove}
          />
        </div>

        {isDataConfigDeltalake && (
          <U21Typography color="text.secondary">
            Note: It will take several minutes for this change to take effect,
            and you will not be able to edit the tags for this object while the
            update is in progress.
          </U21Typography>
        )}
      </U21Spacer>
    </U21Modal>
  );
};
