import { FC, HTMLProps, ReactNode, SyntheticEvent } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { CheckboxProps, FormGroup } from '@mui/material';
import {
  U21Checkbox,
  U21CheckboxProps,
} from 'app/shared/u21-ui/components/input/U21Checkbox';
import {
  GRID_STYLE,
  GridStyleProps,
} from 'app/shared/u21-ui/components/layout/U21Grid';

export type U21CheckboxGroupOptionValue = string | number;

export interface U21CheckboxGroupOptionProps
  extends Omit<U21CheckboxProps, 'checked' | 'onChange'> {
  label: ReactNode;
  value: U21CheckboxGroupOptionValue;
}

export interface U21CheckboxGroupProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  columnMinWidth?: number;
  columns?: number;
  disabled?: boolean;
  horizontal?: boolean;
  inputProps?: CheckboxProps['inputProps'];
  onChange: (value: U21CheckboxGroupOptionValue[], e: SyntheticEvent) => void;
  options: U21CheckboxGroupOptionProps[];
  value?: U21CheckboxGroupOptionValue[];
}

export const U21CheckboxGroup: FC<U21CheckboxGroupProps> = ({
  columnMinWidth = 200,
  columns = 1,
  disabled,
  horizontal,
  inputProps = {},
  onChange,
  options,
  value = [],
  ...rest
}) => {
  return (
    <StyledFormGroup
      $columns={columns}
      $minWidth={columnMinWidth}
      // U21Checkbox already includes spacing so not needed
      $spacing={0}
      row={horizontal}
      {...getDOMProps(rest)}
    >
      {options.map((i) => {
        const {
          disabled: optionDisabled,
          inputProps: optionInputProps,
          value: optionValue,
          ...restOption
        } = i;
        const checked = value.includes(optionValue);
        return (
          <U21Checkbox
            key={optionValue}
            checked={checked}
            disabled={disabled || optionDisabled}
            inputProps={{ ...inputProps, ...optionInputProps }}
            onChange={(newChecked, e) => {
              if (newChecked) {
                onChange([...value, optionValue], e);
              } else {
                onChange(
                  value.filter((j) => j !== optionValue),
                  e,
                );
              }
            }}
            {...restOption}
          />
        );
      })}
    </StyledFormGroup>
  );
};

const StyledFormGroup = styled(FormGroup)<GridStyleProps>`
  ${(props) => (props.$columns > 1 ? GRID_STYLE : css``)}
`;
