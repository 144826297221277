// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import {
  TransactionsState,
  TransactionsActionTypes,
} from 'app/modules/transactions/models';

// Actions
import TransactionsActions from 'app/modules/transactions/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import { initialActionEvent } from 'app/modules/transactions/constants';

const initialState: Readonly<TransactionsState> = {
  actionEvent: initialActionEvent,
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<TransactionsState> = (
  state = initialState,
  action: TransactionsActions,
) => {
  return produce(state, (draft: Draft<TransactionsState>) => {
    switch (action.type) {
      case TransactionsActionTypes.RETRIEVE_ACTION_EVENT_SUCCESS:
        draft.actionEvent = action.payload;
        return;
    }
  });
};

export { reducer as transactionsReducer, initialState };
