import produce, { Draft } from 'immer';
import { unionBy } from 'lodash';

// Models
import {
  PaginatedAlerts,
  RulesActionTypes,
  RulesState,
} from 'app/modules/rules/models';

// Constants
import {
  INITIAL_METADATA,
  INITIAL_FILTERS,
  initialCurrentRule,
} from 'app/modules/rules/constants';

// Actions
import { RulesActions } from 'app/modules/rules/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import { FullRuleResponse } from 'app/modules/rules/types';

const initialState: RulesState = {
  paginatedRules: {
    rules: [],
    count: 0,
  },
  validationRules: {
    rules: [],
    count: 0,
  },
  componentRules: {
    rules: [],
    count: 0,
  },
  alertsById: {},
  paginatedAlerts: {
    alerts: [],
    count: 0,
  },
  editingRule: {
    metadata: INITIAL_METADATA,
    tags: [],
    filters: INITIAL_FILTERS,
    scenarioName: null,
    parameters: {},
    isValidated: false,
    duplicateRule: false,
    execution_frequency: '30min',
    embeddedFilters: {},
    queue: undefined,
    runs_on_org_id: undefined,
    visible_to_child_org: false,
  },

  currentRule: {
    rule: { ...initialCurrentRule } as FullRuleResponse, // "as" required or components will anticipate undefined
    paginated_alerts: {
      alerts: [],
      count: 0,
    } as PaginatedAlerts,
    paginated_whitelisted_entities: {
      whitelisted_entities: [],
      whitelisted_entity_expirations: {},
      count: 0,
    },
    paginated_global_whitelisted_entities: {
      whitelisted_entities: [],
      count: 0,
    },
  },
  blacklists: [],
  validationRuleFileExports: { file_exports: [], count: 0 },
};
// to ensure initialState is readonly
deepFreeze(initialState);

const immerRulesReducer = (state = initialState, action: RulesActions) =>
  produce(state, (draft: Draft<RulesState>) => {
    switch (action.type) {
      case RulesActionTypes.RETRIEVE_TRIGGERED_ALERTS_SUCCESS:
        draft.currentRule.paginated_alerts = action.payload;
        return;

      case RulesActionTypes.RETRIEVE_WHITELISTED_ENTITIES_SUCCESS:
        draft.currentRule.paginated_whitelisted_entities = action.payload;
        return;

      case RulesActionTypes.RETRIEVE_RULES_SUCCESS:
        draft.paginatedRules.rules = unionBy(
          draft.paginatedRules.rules,
          action.payload.rules,
          'id',
        );
        draft.paginatedRules.count = action.payload.count;
        return;

      case RulesActionTypes.RETRIEVE_COMPONENT_RULES_SUCCESS:
        draft.componentRules.rules = unionBy(
          draft.componentRules.rules,
          action.payload.rules,
          'id',
        );
        draft.componentRules.count = action.payload.count;
        return;

      case RulesActionTypes.CLEAR_RULES:
        draft.paginatedRules = initialState.paginatedRules;
        return;

      case RulesActionTypes.DELETE_VALIDATION_RULE_SUCCESS:
        draft.validationRules.count -= 1;
        draft.validationRules.rules.splice(
          draft.validationRules.rules.findIndex(
            (rule) => rule.id === action.payload,
          ),
          1,
        );
        return;

      case RulesActionTypes.RETRIEVE_GLOBALWHITELIST_ENTITIES_SUCCESS:
        draft.currentRule.paginated_global_whitelisted_entities =
          action.payload;
        return;

      case RulesActionTypes.SET_EDITING_RULE:
        draft.editingRule = {
          ...action.payload,
          isValidated:
            typeof action.payload.isValidated === 'undefined'
              ? false
              : action.payload.isValidated,
          duplicateRule: false,
        };
        return;

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_SUCCESS: {
        const oldAlertById = draft.alertsById[action.payload.id] || {};
        draft.alertsById = {
          ...draft.alertsById,
          [action.payload.id]: { ...oldAlertById, ...action.payload },
        };
        return;
      }

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS_SUCCESS: {
        const newTxnState = draft.alertsById[action.payload.id] || {};
        newTxnState.events = action.payload.events;
        draft.alertsById[action.payload.id] = newTxnState;
        return;
      }

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES_SUCCESS: {
        const newEntityState = draft.alertsById[action.payload.id] || {};
        newEntityState.entities = action.payload.entities;
        draft.alertsById[action.payload.id] = newEntityState;
        return;
      }

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ACTION_EVENTS_SUCCESS: {
        const newActionEventsState = draft.alertsById[action.payload.id] || {};
        newActionEventsState.action_events = {
          events: action.payload.events,
          count: action.payload.count,
        };
        draft.alertsById[action.payload.id] = newActionEventsState;
        return;
      }

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_INSTRUMENTS_SUCCESS: {
        const newInstrumentState = draft.alertsById[action.payload.id] || {};
        newInstrumentState.instruments = action.payload.instruments;
        draft.alertsById[action.payload.id] = newInstrumentState;
        return;
      }

      case RulesActionTypes.RETRIEVE_PAGINATED_ALERTS_SUCCESS:
        draft.paginatedAlerts = action.payload;
        return;

      case RulesActionTypes.RETRIEVE_VALIDATION_RULES_SUCCESS:
        draft.validationRules = action.payload;
        return;

      case RulesActionTypes.SET_DYNAMIC_COLUMN_DEFINITION_ID:
        draft.editingRule.parameters.dynamic_column_definition_id =
          action.payload;
        return;

      case RulesActionTypes.SET_ADDITIONAL_FIELDS_IN_RULE_PARAMETERS:
        draft.editingRule.parameters.additional_fields = action.payload;
        return;

      case RulesActionTypes.RETRIEVE_RULE_VALIDATION_FILE_EXPORTS_SUCCESS:
        draft.validationRuleFileExports = action.payload;
    }
  });

export { immerRulesReducer, initialState };
