import { RESERVED_PRIMARY_OBJECT_INTERNAL_NAME } from 'app/modules/dataMapping/constants';
import { ObjectErrorsSection } from 'app/modules/pullBasedDataFiles/components/sidebars/ObjectErrorsSection';
import { AnnotationTransformationExceptionMap } from 'app/modules/pullBasedDataFiles/responses';
import {
  selectSelectedDatafile,
  selectSelectedStream,
} from 'app/modules/pullBasedDataFiles/selectors';
import {
  U21Button,
  U21Chip,
  U21Divider,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import pluralize from 'pluralize';
import { UnknownErrorsSection } from 'app/modules/pullBasedDataFiles/components/sidebars/UnknownErrorsSection';
import { isNotLegacyErrors } from 'app/modules/pullBasedDataFiles/helpers';
import { useDebugFile } from 'app/modules/pullBasedDataFiles/queries/useDebugFile';
import { IconBug } from '@u21/tabler-icons';
import { formatDatetime } from 'app/shared/utils/date';
import React, { ReactNode, useState } from 'react';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { DebugFileConsentModal } from 'app/modules/pullBasedDataFiles/components/modals/DebugFileConsentModal';
import { setModalState } from 'app/modules/pullBasedDataFiles/slicePullBasedDataFiles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ExecutionInfo = ({
  label,
  value,
  labelWidth,
}: {
  label: string;
  value?: ReactNode;
  labelWidth?: number;
}) => (
  <U21Spacer horizontal align="start">
    <StyledTypographyLabel
      variant="body2"
      color="text.secondary"
      $labelWidth={labelWidth}
    >
      {label}:
    </StyledTypographyLabel>
    <StyledTypographyValue variant="body2">{value}</StyledTypographyValue>
  </U21Spacer>
);

export const ProcessingErrorsSidebar = () => {
  const stream = useSelector(selectSelectedStream);
  const file = useSelector(selectSelectedDatafile);
  const {
    mutate: debugFile,
    isPending: debugLoading,
    data: debugKey,
    reset: resetDebugKey,
  } = useDebugFile();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState<boolean>(false);

  if (
    !(
      stream &&
      file?.latest_execution?.errors &&
      isNotLegacyErrors(file.latest_execution.errors)
    )
  ) {
    return null;
  }
  const { latest_execution: execution } = file;
  const {
    success_count: success,
    failure_count: fail,
    skip_count: skip,
    total_count: total,
  } = execution;
  const { total_count: totalErrorCount, ...errors } =
    file.latest_execution.errors;
  const knownExceptions = Object.entries(
    errors.annotation_transformation_exceptions,
    // we want to make sure the primary object (default name: 'self') is at the top of the list
  ).reduce<[string, AnnotationTransformationExceptionMap][]>(
    (acc, [name, e]) => {
      if (name === RESERVED_PRIMARY_OBJECT_INTERNAL_NAME) {
        acc.unshift([name, e]);
      } else {
        acc.push([name, e]);
      }
      return acc;
    },
    [],
  );
  const { unhandled_exceptions: unknownExceptions } = errors;

  return (
    <>
      <U21SideMenu
        title={
          <U21Spacer horizontal>
            <U21Typography variant="h4">Error Log</U21Typography>
            <U21Chip color="warning" variant="ghost">
              {totalErrorCount} errors
            </U21Chip>
          </U21Spacer>
        }
        onClose={() => {
          resetDebugKey();
        }}
        actionButtonProps={{
          children: 'Download JSON',
          href: `data:application/json;charset=utf-8;,${encodeURIComponent(
            JSON.stringify(file.latest_execution),
          )}`,
          download: `${file.file_name} processing errors.json`,
          target: '_blank',
        }}
        noPadding
      >
        <StyledU21Spacer>
          <ExecutionInfo label="Stream" value={stream.source_object_name} />
          <ExecutionInfo label="File" value={file.file_name} />
          <ExecutionInfo
            label="Queued at"
            value={formatDatetime(file.latest_execution?.queued_at)}
          />
          <ExecutionInfo
            label="Finished at"
            value={formatDatetime(file.latest_execution?.finished_at)}
          />
          <U21Typography variant="body2">
            <b>{pluralize('row', total, true)}</b> total.{' '}
            <b>
              {success || 'No'} {pluralize('row', success)}
            </b>{' '}
            {file.latest_execution?.is_dry_run ? 'validated' : 'ingested'}{' '}
            successfully,{' '}
            <b>
              {fail || 'no'} {pluralize('row', fail)}
            </b>{' '}
            failed,{' '}
            <b>
              {skip || 'no'} {pluralize('row', skip)}
            </b>{' '}
            skipped.
          </U21Typography>
          {!debugKey ? (
            <div>
              <U21Button
                onClick={() =>
                  dispatch(
                    setModalState({
                      modalState: { modalStatus: 'DEBUG_CONSENT' },
                    }),
                  )
                }
                color="warning"
                size="small"
                loading={debugLoading}
                startIcon={<IconBug />}
              >
                Debug File
              </U21Button>
            </div>
          ) : (
            <ExecutionInfo
              label="Debug key"
              value={
                <CopyToClipboard
                  text={debugKey.ddo_debugger_key}
                  onCopy={() => setCopied(true)}
                >
                  <U21Button
                    variant="text"
                    color="primary"
                    tooltip={copied ? 'Copied!' : 'Copy to clipboard'}
                  >
                    {debugKey.ddo_debugger_key}
                  </U21Button>
                </CopyToClipboard>
              }
            />
          )}
        </StyledU21Spacer>
        <StyledDivider horizontal />
        {knownExceptions.map(([name, obj], i) => (
          <React.Fragment key={name}>
            <ObjectErrorsSection name={name} errorObject={obj} />
            {(i < knownExceptions.length - 1 || !!unknownExceptions.count) && (
              <StyledDivider horizontal />
            )}
          </React.Fragment>
        ))}
        {!!unknownExceptions.count && (
          <UnknownErrorsSection
            errors={unknownExceptions.errors}
            count={unknownExceptions.count}
            defaultCollapsed={!!knownExceptions.length}
          />
        )}
      </U21SideMenu>
      <DebugFileConsentModal debugFile={() => debugFile(file.id)} />
    </>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  padding: 0 16px;
`;

const StyledTypographyLabel = styled(U21Typography)<{ $labelWidth?: number }>`
  ${({ $labelWidth }) => css`
    min-width: ${$labelWidth ?? 72}px;
    max-width: ${$labelWidth ?? 72}px;
  `}
`;

const StyledTypographyValue = styled(U21Typography)`
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
`;

const StyledDivider = styled(U21Divider)`
  margin: 16px;
`;
