import { addHours } from 'date-fns';

import { ShortEntityResponse } from 'app/modules/entities/types';

// Constants
import palette from 'vendor/material-minimal/palette';
import { truncate } from 'lodash';
import { RuleGraphMatches } from 'app/modules/detectionModels/models';
import { EntityLinkAnalysisReport } from 'app/modules/entities/models';
import { U21NetworkGraphElements } from 'app/shared/components/Graphs/U21NetworkGraph';

const MAX_VALUE_LENGTH = 20;

export const graphifyMatchData = ({
  matches,
  entities,
  matchesLimit,
  entityLimit,
}: {
  matches: RuleGraphMatches[];
  entities?: ShortEntityResponse[];
  matchesLimit?: number;
  entityLimit?: number;
}): U21NetworkGraphElements | null => {
  const elements: U21NetworkGraphElements = { nodes: {}, edges: {} };

  if (!matches || matches.length === 0) {
    return null;
  }

  let curNumMatches = 0;
  const seenNodesAndEdges = new Set();
  for (const matchesInfo of matches) {
    curNumMatches += 1;
    // Break when we hit the limit for performance reasons
    if (matchesLimit && curNumMatches > matchesLimit) {
      break;
    }
    const { entity_external_ids: entityExternalIds } = matchesInfo;
    let { field } = matchesInfo;
    if (!entityExternalIds || !field) {
      return null;
    }

    if (!Array.isArray(field)) {
      field = [field];
    }

    for (const { value, type } of field) {
      if (value && type) {
        const linkId = `node:${value}`;
        if (!seenNodesAndEdges.has(linkId)) {
          seenNodesAndEdges.add(linkId);
          const linkLabel = truncate(value, { length: MAX_VALUE_LENGTH });
          elements.nodes[linkId] = {
            data: {
              id: linkId,
              nodeType: 'link',
              label: linkLabel,
              color: palette.light.grey[600],
              linkType: type,
              ...(type === 'instrument' && {
                instrumentId: value,
              }),
            },
          };
        }

        let numEntities = 0;
        for (const entityExternalId of entityExternalIds) {
          numEntities += 1;
          // Break when we hit limit of entities. Performance and stuff
          if (entityLimit && numEntities > entityLimit) {
            break;
          }
          const entityNodeId = `entity:${entityExternalId}`;
          if (!seenNodesAndEdges.has(entityNodeId)) {
            seenNodesAndEdges.add(entityExternalId);
            const entity = entities?.find(
              (ent) => ent.external_id === entityExternalId,
            );
            const entityLabel = entity
              ? `${entity.name_readable}\n(${entityExternalId})`
              : `(${entityExternalId})`;
            elements.nodes[entityNodeId] = {
              data: {
                id: entityNodeId,
                nodeType: 'entity',
                label: entityLabel,
                color: palette.light.colors.red.main,
                entityId: entityExternalId,
              },
            };
          }

          const edgeId = `${entityNodeId}___${linkId}`;
          if (!seenNodesAndEdges.has(edgeId)) {
            seenNodesAndEdges.add(edgeId);
            elements.edges[edgeId] = {
              data: {
                id: edgeId,
                target: entityNodeId,
                source: linkId,
              },
            };
          }
        }
      }
    }
  }
  return elements;
};

export const isLinkAnalysisPending = (
  linkAnalysis: EntityLinkAnalysisReport,
): boolean => {
  const { created_at: createdAt } = linkAnalysis;
  const createdAtDate = createdAt && new Date(createdAt);
  const createdMoreThan1Hour =
    !!createdAtDate && addHours(createdAtDate, 1).getTime() < Date.now();
  return (
    !createdMoreThan1Hour &&
    (linkAnalysis.status === 'PENDING' || linkAnalysis.status === 'STARTED')
  );
};
