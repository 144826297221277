import { ExternalSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/ExternalSourceChip';
import { InternalSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/InternalSourceChip';
import {
  AiInvestigationExternalSource,
  AiInvestigationInternalSource,
} from 'app/modules/investigations/types/responses';

interface OwnProps {
  sourceNumberToExternalSourceMap: Map<number, AiInvestigationExternalSource>;
  sourceNumberToInternalSourceMap: Map<number, AiInvestigationInternalSource>;
  currentSources: number[];
  setCurrentSources: (sourceNumbers: number[]) => void;
  setIsSourcesHovered: (isHovered: boolean) => void;
}

export const AiInvestigationSources = ({
  sourceNumberToExternalSourceMap,
  sourceNumberToInternalSourceMap,
  currentSources,
  setCurrentSources,
  setIsSourcesHovered,
}: OwnProps) => {
  return [
    ...[...sourceNumberToInternalSourceMap].map(([key, value]) => (
      <InternalSourceChip
        key={key}
        sourceNumber={key}
        sourceData={value}
        isHovering={currentSources.includes(key)}
        setIsHovering={(isCurrentlyHovering) => {
          setIsSourcesHovered(isCurrentlyHovering);
          setCurrentSources(isCurrentlyHovering ? [key] : []);
        }}
      />
    )),
    ...[...sourceNumberToExternalSourceMap].map(([key, value]) => (
      <ExternalSourceChip
        key={key}
        sourceNumber={key}
        sourceData={value}
        isHovering={currentSources.includes(key)}
        setIsHovering={(isCurrentlyHovering) => {
          setIsSourcesHovered(isCurrentlyHovering);
          setCurrentSources(isCurrentlyHovering ? [key] : []);
        }}
      />
    )),
  ];
};
