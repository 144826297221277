import { FC, HTMLProps, ReactNode } from 'react';
import { FormFieldError } from 'app/shared/models/form';

import { getDOMProps } from 'app/shared/utils/react';

import { FormHelperText } from '@mui/material';
import {
  motion,
  AnimatePresence,
  MotionProps,
  AnimationProps,
} from 'framer-motion';

const animationSettings: MotionProps & AnimationProps = {
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  transition: { duration: 0.3 },
};

export interface U21FormErrorProps extends HTMLProps<HTMLParagraphElement> {
  error?: FormFieldError;
}

export const U21FormError: FC<U21FormErrorProps> = ({ error, ...rest }) => {
  let node: ReactNode = null;
  if (Array.isArray(error) && error.length) {
    node = (
      <>
        {error.map((i) => {
          if (!i) {
            return null;
          }
          return (
            <FormHelperText key={i} error>
              {i}
            </FormHelperText>
          );
        })}
      </>
    );
  } else if (error && error.length) {
    node = <FormHelperText error>{error}</FormHelperText>;
  }

  return (
    <AnimatePresence>
      {node && (
        <motion.div {...animationSettings} {...getDOMProps(rest)}>
          {node}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
