import {
  U21Button,
  U21Chip,
  U21Section,
  U21Spacer,
  U21Tooltip,
  U21Typography,
  U21TypographyIcon,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LinkedEntitiesTable } from 'app/modules/networkAnalysis/components/LinkedEntitiesTable';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { EntityLink } from 'app/modules/networkAnalysis/types';
import {
  LINK_ANALYSIS_LINK_TYPES,
  LINK_SECTION_MOUNTED,
  LINK_SECTION_UNMOUNTED,
  LINK_TYPE_TO_ICON,
} from 'app/modules/networkAnalysis/constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconCircleCheck, IconCopy, IconLink } from '@u21/tabler-icons';
import { truncate } from 'lodash';
import { NetworkLinkProps } from 'app/modules/networkAnalysis/components/NetworkLinks';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';

interface LinkSectionProps extends NetworkLinkProps {
  link: EntityLink;
}

export const LinkSection = ({
  link,
  data,
  filteredData,
  dispatch,
  expandedSections,
  setExpandedSections,
}: LinkSectionProps) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch({
      type: LINK_SECTION_MOUNTED,
      payload: { [link.id]: sectionRef },
    });
    return () => {
      dispatch({
        type: LINK_SECTION_UNMOUNTED,
        payload: { sectionHash: link.id },
      });
    };
  }, [dispatch, link]);

  const linkedEntitiesLength = useMemo(
    () =>
      link.entities.reduce<number>(
        (acc, e) => (filteredData.entities.has(e) ? acc + 1 : acc),
        0,
      ),
    [link, filteredData],
  );

  const reduxDispatch = useDispatch();

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Spacer horizontal align="center">
            <U21TypographyIcon variant="h4">
              {LINK_TYPE_TO_ICON[link.type]}
            </U21TypographyIcon>
            <span>{link.type}</span>:{' '}
            <StyledTypography variant="h4">
              {truncate(link.value.toString(), { length: 60 })}
            </StyledTypography>
            <U21Chip
              color={linkedEntitiesLength > 5 ? 'error' : 'warning'}
              variant="ghost"
            >
              {pluralize('Entity', linkedEntitiesLength, true)}
            </U21Chip>
          </U21Spacer>
        }
        collapsible
        collapsed={!expandedSections.has(link.id)}
        onToggleCollapse={(c) =>
          setExpandedSections((prev) => {
            const newSet = new Set(prev);
            newSet[c ? 'delete' : 'add'](link.id);
            return newSet;
          })
        }
        action={
          link.type === LINK_ANALYSIS_LINK_TYPES.INSTRUMENT ? (
            <U21Spacer horizontal wrap>
              <U21Button
                startIcon={LINK_TYPE_TO_ICON[link.type]}
                onClick={() =>
                  reduxDispatch(
                    toggleSidebar({
                      type: SidebarComponentTypes.INSTRUMENT,
                      data: { id: link.value },
                    }),
                  )
                }
              >
                View Summary
              </U21Button>
              <CopyLinkHashButton linkId={link.id} />
            </U21Spacer>
          ) : (
            <CopyLinkHashButton linkId={link.id} />
          )
        }
      >
        <LinkedEntitiesTable
          entities={link.entities}
          filteredData={filteredData}
          data={data}
        />
      </U21Section>
    </div>
  );
};

const CopyLinkHashButton = ({ linkId }: { linkId: string }) => {
  const [copied, setCopied] = useState(false);
  const { pathname, search } = useLocation();
  const history = useHistory();
  return (
    <U21Tooltip
      tooltip={
        copied ? (
          <U21Typography
            color="inherit"
            icon={<StyledIconCircleCheck />}
            variant="inherit"
          >
            Copied!
          </U21Typography>
        ) : (
          <U21Typography color="inherit" icon={<IconCopy />} variant="inherit">
            Copy section URL
          </U21Typography>
        )
      }
    >
      <span>
        <CopyToClipboard
          onCopy={() => {
            history.replace(`${pathname}${search}#${linkId}`);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
          text={`${window.location.origin}${pathname}${search}#${linkId}`}
        >
          <U21Button
            icon={<IconLink />}
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-label="Copy link"
          />
        </CopyToClipboard>
      </span>
    </U21Tooltip>
  );
};

export const StyledTypography = styled(U21Typography)`
  text-transform: none;
`;

export const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${(props) => props.theme.palette.success.main};
`;
