import { IconWorld } from '@u21/tabler-icons';
import { CoreSourceAnnotation } from 'app/modules/investigations/components/AiInvestigation/Annotation/CoreSourceAnnotation';
import { AiInvestigationExternalSource } from 'app/modules/investigations/types/responses';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationExternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const ExternalSourceAnnotation = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  return (
    <CoreSourceAnnotation
      sourceNumber={sourceNumber}
      label={sourceData.header ? sourceData.header : 'Web search'}
      icon={<IconWorld />}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
    />
  );
};
