import { FormFieldSchema } from 'app/shared/models/form';
import { GoogleDriveDoc } from 'app/modules/uploads/models';
import { U21FormFieldTypes } from 'app/shared/u21-ui/components';

export interface CaseCreateAttachmentFormValue {
  s3Docs: File[];
  gdriveDocs: File[];
  gdrivePickedDocs: GoogleDriveDoc[];
}

// for create view
export const CASE_S3_DOCUMENTS: FormFieldSchema<File[]> = {
  name: 's3Docs' satisfies keyof CaseCreateAttachmentFormValue,
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER,
};

export const CASE_GDRIVE_DOCUMENTS: FormFieldSchema<File[]> = {
  name: 'gdriveDocs' satisfies keyof CaseCreateAttachmentFormValue,
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER, // not used but needed for typing
};

export const CASE_GDRIVE_PICKED_DOCUMENTS: FormFieldSchema<GoogleDriveDoc[]> = {
  name: 'gdrivePickedDocs' satisfies keyof CaseCreateAttachmentFormValue,
  default: [],
  type: U21FormFieldTypes.FILE_UPLOADER, // not used but needed for typing
};
