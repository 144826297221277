import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

// Models
import {
  ActionEventResponse,
  EventActionTypes,
  EventEditRequest,
  RetrieveActionEventsPayload,
} from 'app/shared/events/models';

export const editEvent = (payload: EventEditRequest) => {
  return createAction(EventActionTypes.EDIT_EVENT, payload);
};

export const editEventSuccess = (payload) => {
  return createAction(EventActionTypes.EDIT_EVENT_SUCCESS, payload);
};

export const retrieveActionEvents = (payload: RetrieveActionEventsPayload) => {
  return createAction(EventActionTypes.RETRIEVE_ACTION_EVENTS, payload);
};

export const retrieveActionEventsSuccess = (payload: ActionEventResponse) => {
  return createAction(EventActionTypes.RETRIEVE_ACTION_EVENTS_SUCCESS, payload);
};

const EventsActionsList = {
  editEvent,
  editEventSuccess,
  retrieveActionEventsSuccess,
};

export type EventsActions = ActionsUnion<typeof EventsActionsList>;
