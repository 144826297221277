import {
  DateRangeValue,
  U21Alert,
  U21Checkbox,
  U21FormField,
  U21FormFieldCustom,
  U21FormFieldTypes,
  U21MultiSelect,
  U21Select,
  U21Spacer,
  U21Subsection,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  CustomChecklistDefinition,
  CustomChecklistUpdateItemPayload,
  CustomChecklistValidationResult,
} from 'app/shared/customChecklist/models';
import { AiInvestigationResultSection } from 'app/modules/investigations/types/responses';
import { Dispatch, SetStateAction } from 'react';
import { CustomChecklistAIFindings } from 'app/shared/customChecklist/components/CustomChecklistAIFindings';
import { IconAlertCircle } from '@u21/tabler-icons';
import styled from 'styled-components';
import { getIn } from 'final-form';
import { Field } from 'react-final-form';
import { dateRangeFieldValueTransformation } from 'app/shared/customChecklist/helpers';

interface Props {
  aiInvestigationResultItems: AiInvestigationResultSection | undefined;
  checklistData: Record<string, any>;
  disabled: boolean;
  formFieldPrefix?: string;
  isExport?: boolean;
  isOpenKey: string;
  item: CustomChecklistDefinition;
  onChange?: (payload: CustomChecklistUpdateItemPayload) => void;
  path: string[];
  previouslySaved: boolean;
  setFieldValue: (key: string, value: any) => void;
  setIsOpenKey: Dispatch<SetStateAction<string>>;
  validationResults: CustomChecklistValidationResult | null;
}

export const CustomChecklistField = (props: Props) => {
  const {
    aiInvestigationResultItems,
    checklistData,
    disabled,
    formFieldPrefix,
    isExport,
    isOpenKey,
    item,
    onChange,
    path,
    previouslySaved,
    setFieldValue,
    setIsOpenKey,
    validationResults,
  } = props;
  const {
    key,
    label,
    type,
    required,
    enabled_by: enabledBy,
    options,
    placeholder,
  } = item;

  const itemPath = [...path, key];
  const itemKey = itemPath.join('.');
  const messages = validationResults
    ? Object.keys(validationResults)
        .filter((i) => i.startsWith(itemKey))
        .map((i) => validationResults[i])
        .flat()
    : [];

  const commonFieldProps = {
    name: [formFieldPrefix, itemKey].filter((_) => _).join('.'),
    required,
    disabled,
  };

  const aiFindings = (
    <CustomChecklistAIFindings
      aiInvestigationResultItems={aiInvestigationResultItems}
      disabled={disabled}
      isOpenKey={isOpenKey}
      isSidePanel
      itemKey={itemKey}
      label={label}
      setFieldValue={setFieldValue}
      setIsOpenKey={setIsOpenKey}
      type={type}
    />
  );

  switch (type) {
    case 'dropdown':
      return (
        <U21FormFieldCustom<string | undefined>
          label={label}
          {...commonFieldProps}
        >
          {(fieldProps) => (
            <U21Spacer horizontal useFlexGap>
              <U21Select
                options={options ?? []}
                placeholder={placeholder}
                {...fieldProps}
                onChange={(value) => {
                  onChange?.({ key: itemKey, value });
                  fieldProps.onChange(value);
                }}
              />
              {aiFindings}
            </U21Spacer>
          )}
        </U21FormFieldCustom>
      );

    case 'multi_select':
      return (
        <U21FormFieldCustom<string[]> label={label} {...commonFieldProps}>
          {(fieldProps) => (
            <U21Spacer horizontal useFlexGap>
              <U21MultiSelect
                options={options ?? []}
                placeholder={placeholder}
                {...fieldProps}
                onChange={(value) => {
                  onChange?.({ key: itemKey, value });
                  fieldProps.onChange(value);
                }}
              />
              {aiFindings}
            </U21Spacer>
          )}
        </U21FormFieldCustom>
      );

    case 'checkbox':
      return (
        <U21FormFieldCustom<boolean | undefined> {...commonFieldProps}>
          {(fieldProps) => {
            const { value: checked, ...restFieldProps } = fieldProps;
            return (
              <U21Spacer horizontal useFlexGap>
                <CheckboxContainer>
                  <U21Checkbox
                    label={label}
                    {...restFieldProps}
                    checked={checked}
                    onChange={(value) => {
                      onChange?.({ key: itemKey, value });
                      fieldProps.onChange(value);
                    }}
                  />
                </CheckboxContainer>
                {aiFindings}
              </U21Spacer>
            );
          }}
        </U21FormFieldCustom>
      );

    case 'input':
      return (
        <U21FormFieldCustom<string | undefined>
          label={label}
          {...commonFieldProps}
        >
          {(fieldProps) => (
            <U21Spacer horizontal useFlexGap>
              <U21TextField
                placeholder={placeholder}
                {...fieldProps}
                onChange={(value: string | undefined) => {
                  onChange?.({ key: itemKey, value });
                  fieldProps.onChange(value);
                }}
              />
              {aiFindings}
            </U21Spacer>
          )}
        </U21FormFieldCustom>
      );

    case 'label':
      return (
        <U21Alert key={itemKey} severity="warning">
          {label}
        </U21Alert>
      );

    case 'textarea':
      if (
        !enabledBy ||
        getIn(checklistData || {}, [...path, enabledBy].join('.'))
      ) {
        if (isExport) {
          return (
            <Field<string>
              key={itemKey}
              name={commonFieldProps.name}
              subscription={{ value: true }}
            >
              {({ input: { value } }) => {
                return <StyledU21Typography>{value}</StyledU21Typography>;
              }}
            </Field>
          );
        }
        return (
          <U21FormFieldCustom<string | undefined>
            label={label}
            {...commonFieldProps}
          >
            {(fieldProps) => (
              <U21Spacer horizontal useFlexGap>
                <U21TextField
                  placeholder={placeholder}
                  {...fieldProps}
                  onChange={(value: string | undefined) => {
                    onChange?.({ key: itemKey, value });
                    fieldProps.onChange(value);
                  }}
                  type="textarea"
                />
                {aiFindings}
              </U21Spacer>
            )}
          </U21FormFieldCustom>
        );
      }
      return null;

    case 'date_range':
      return (
        <U21FormField
          key={key}
          {...commonFieldProps}
          fieldProps={{
            onChange: (value: DateRangeValue) =>
              onChange?.({ key: itemKey, value }),
            placeholder,
            valueTransform: dateRangeFieldValueTransformation,
          }}
          label={label}
          type={U21FormFieldTypes.DATE_RANGE_PICKER}
        />
      );

    default: {
      const content = item.items?.map((i) => (
        <CustomChecklistField
          key={i.key}
          aiInvestigationResultItems={aiInvestigationResultItems}
          checklistData={checklistData}
          disabled={disabled}
          formFieldPrefix={formFieldPrefix}
          isExport={isExport}
          isOpenKey={isOpenKey}
          item={i}
          onChange={onChange}
          path={itemPath}
          previouslySaved={previouslySaved}
          setFieldValue={setFieldValue}
          setIsOpenKey={setIsOpenKey}
          validationResults={validationResults}
        />
      ));

      if (item.section === 'div') {
        return <HorizontalSection key={key}>{content}</HorizontalSection>;
      }
      return (
        <U21Subsection
          key={itemKey}
          collapsible
          title={label}
          titleIcon={
            validationResults !== null && !!messages?.length ? (
              <IconAlertCircle color="red" />
            ) : undefined
          }
        >
          <VerticalSection>
            {previouslySaved && messages?.length > 0 && (
              <U21Alert severity="error">
                {messages.map((message: string) => (
                  <p key={message}>{message}</p>
                ))}
              </U21Alert>
            )}
            {content}
          </VerticalSection>
        </U21Subsection>
      );
    }
  }
};

const CheckboxContainer = styled.div`
  flex: 1;
`;

const StyledU21Typography = styled(U21Typography)`
  white-space: pre-wrap;
`;

const HorizontalSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const VerticalSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
