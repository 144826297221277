import { IconProps } from 'app/shared/components/Icons/models';

export const IconArrowArrowRightInCircle = ({
  size = 20,
  color = 'currentColor',
  stroke = 1.5,
  ...rest
}: IconProps) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
      stroke="currentcolor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12L12 8"
      stroke="currentcolor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12L8 12"
      stroke="currentcolor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16L16 12"
      stroke="currentcolor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
