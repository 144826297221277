/**
 * Do not use/add functions in this file.
 * To use localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */

// Utils
import { subDays } from 'date-fns';

import { LocalStorageKeys } from 'app/shared/constants/localStorage';

const NOTES_CACHE = 'NOTES_CACHE';

const get = (prop: string) => localStorage.getItem(prop);
/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const set = (prop: string, value: string) =>
  localStorage.setItem(prop, value);
const remove = (prop: string) => localStorage.removeItem(prop);

const validJSON = (strInput) => {
  try {
    return JSON.parse(strInput);
  } catch (e) {
    return null;
  }
};

export const getLocalStorageJSON = (key: LocalStorageKeys): unknown => {
  const item = localStorage.getItem(key);
  return validJSON(item);
};

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const getNoteStateCache = (noteId) =>
  validJSON(get(`${noteId}_${NOTES_CACHE}`));

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const isDate30DaysAgo = (dateToCompare) => {
  const days30Past = subDays(new Date(), 30);
  return dateToCompare < days30Past;
};

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const cleanUpLocalStorage = () => {
  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach((key) => {
    if (key.indexOf(NOTES_CACHE) !== -1) {
      try {
        const cacheObject = JSON.parse(localStorage.getItem(key) || '{}');
        const lastUpdated = cacheObject.lastUpdated
          ? Date.parse(cacheObject.lastUpdated)
          : new Date();
        if (isDate30DaysAgo(lastUpdated)) {
          localStorage.removeItem(key);
        }
      } catch {
        // If we were unable to read from the local storage, it has been corrupted/modified
        // Clean it up and continue
        localStorage.removeItem(key);
      }
    }
  });
};

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const removeAllNotesCache = () => {
  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach((key) => {
    if (key.indexOf(NOTES_CACHE) !== -1) {
      localStorage.removeItem(key);
    }
  });
};

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const setNoteStateCache = (noteId, noteValue) => {
  try {
    cleanUpLocalStorage();
    set(
      `${noteId}_${NOTES_CACHE}`,
      JSON.stringify({
        ...noteValue,
        lastUpdated: new Date().toISOString(),
      }),
    );
  } catch (QuotaExceededError) {
    // Failsafe, if we have a full local storage, remove all the NOTES cache
    removeAllNotesCache();
    set(
      `${noteId}_${NOTES_CACHE}`,
      JSON.stringify({
        ...noteValue,
        lastUpdated: new Date().toISOString(),
      }),
    );
  }
};

/**
 * @deprecated For localStorage use hooks from `src/app/shared/hooks/useLocalStorage`.
 */
export const removeNoteStateCache = (noteId) =>
  remove(`${noteId}_${NOTES_CACHE}`);
