import { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';
import styled from 'styled-components';

// Models
import {
  CustomChecklistContent as CustomChecklistContentModel,
  CustomChecklistDefinition,
  CustomChecklistUpdateItemPayload,
  CustomChecklistValidationResult,
} from 'app/shared/customChecklist/models';
import {
  AiInvestigationResultItem,
  AiInvestigationResultSection,
  AiInvestigationResultSectionItem,
} from 'app/modules/investigations/types/responses';

// Components
import { Field, useForm } from 'react-final-form';
import { AiInvestigationResults } from 'app/modules/investigations/components/InvestigationDetails/AiInvestigationResults';
import {
  U21Alert,
  U21FormField,
  U21Spacer,
  U21FormFieldTypes,
  U21Typography,
  U21Subsection,
  U21Grid,
} from 'app/shared/u21-ui/components';
import { IconAlertCircle } from '@u21/tabler-icons';

// Helpers
import {
  dateRangeFieldValueTransformation,
  getChecklistFields,
  prepareInitialValues,
} from 'app/shared/customChecklist/helpers';

// Utils
import { generateFormFieldProps } from 'app/shared/utils/form';
import { getIn } from 'final-form';
import { get } from 'lodash';
import { CustomChecklistAIFindings } from 'app/shared/customChecklist/components/CustomChecklistAIFindings';
import { CustomChecklistField } from 'app/shared/customChecklist/components/CustomChecklistField';

export interface OwnProps {
  articleId?: number;
  content: CustomChecklistContentModel;
  definition: CustomChecklistDefinition[] | undefined;
  editable: boolean;
  formFieldPrefix?: string;
  historicalSubmissionId?: number;
  isExport?: boolean;
  onChange?: (payload: CustomChecklistUpdateItemPayload) => void;
  previouslySaved: boolean;
  validationResults: CustomChecklistValidationResult | null;
  aiInvestigationResultItems?: AiInvestigationResultSection;
  isSidePanel?: boolean;
}

type AllProps = OwnProps;

function CustomChecklistContent({
  articleId,
  content,
  definition,
  editable,
  formFieldPrefix,
  historicalSubmissionId,
  isExport,
  onChange,
  previouslySaved,
  validationResults,
  aiInvestigationResultItems,
  isSidePanel,
}: AllProps) {
  const {
    mutators: { setFieldValue },
  } = useForm();
  const [isOpenKey, setIsOpenKey] = useState<string>('');

  const flatChecklistDefinition = useMemo(
    () => getChecklistFields(definition || []),
    [definition],
  );

  const values = useMemo(() => {
    if (!flatChecklistDefinition) return {};
    return prepareInitialValues(content, flatChecklistDefinition);
  }, [flatChecklistDefinition, content]);

  return (
    <U21Spacer>
      {articleId && historicalSubmissionId && (
        <AiInvestigationResults
          id={articleId}
          customChecklistSubmissionId={historicalSubmissionId}
          isHistorical
        />
      )}
      <U21Spacer dividers spacing={0.5}>
        {itemsToComponents(
          definition,
          values,
          [],
          validationResults,
          aiInvestigationResultItems,
          previouslySaved,
          !editable,
          isOpenKey,
          setIsOpenKey,
          setFieldValue,
          onChange,
          formFieldPrefix,
          isExport,
          isSidePanel,
        )}
      </U21Spacer>
    </U21Spacer>
  );
}

const itemsToComponents = (
  items: CustomChecklistDefinition[] | undefined,
  checklistData: Record<string, any>,
  path: string[],
  validationResults: CustomChecklistValidationResult | null,
  aiInvestigationResultItems: AiInvestigationResultSection | undefined,
  previouslySaved: boolean,
  disabled: boolean,
  isOpenKey: string,
  setIsOpenKey: Dispatch<SetStateAction<string>>,
  setFieldValue: (key: string, value: any) => void,
  onChange?: (payload: CustomChecklistUpdateItemPayload) => void,
  formFieldPrefix?: string,
  isExport?: boolean,
  isSidePanel?: boolean,
) => {
  return (items || []).map((item) => {
    if (isSidePanel) {
      return (
        <CustomChecklistField
          key={item.key}
          aiInvestigationResultItems={aiInvestigationResultItems}
          checklistData={checklistData}
          disabled={disabled}
          formFieldPrefix={formFieldPrefix}
          isExport={isExport}
          isOpenKey={isOpenKey}
          item={item}
          onChange={onChange}
          path={path}
          previouslySaved={previouslySaved}
          setFieldValue={setFieldValue}
          setIsOpenKey={setIsOpenKey}
          validationResults={validationResults}
        />
      );
    }

    const {
      key,
      label,
      type,
      required,
      enabled_by: enabledBy,
      options,
      placeholder,
    } = item;
    const itemPath = [...path, key];
    const itemKey = itemPath.join('.');
    const messages = validationResults
      ? Object.keys(validationResults)
          .filter((i) => i.startsWith(itemKey))
          .map((i) => validationResults[i])
          .flat()
      : [];

    const fieldProps = {
      label,
      options,
      placeholder,
      onChange: onChange
        ? (value: any) => onChange({ key: itemKey, value })
        : undefined,
    };

    const formFieldProps = generateFormFieldProps({
      name: [formFieldPrefix, itemKey].filter((_) => _).join('.'),
      required,
      disabled,
      fieldProps,
    });

    const formField = () => {
      switch (type) {
        case 'dropdown':
          return (
            <StyledU21FormField
              key={key}
              {...formFieldProps}
              type={U21FormFieldTypes.SELECT}
            />
          );

        case 'multi_select':
          return (
            <StyledU21FormField
              key={key}
              {...formFieldProps}
              type={U21FormFieldTypes.MULTISELECT}
            />
          );

        case 'checkbox':
          return (
            <StyledU21FormField
              key={key}
              {...formFieldProps}
              type={U21FormFieldTypes.CHECKBOX}
            />
          );

        case 'input':
          return (
            <StyledU21FormField
              key={key}
              {...formFieldProps}
              type={U21FormFieldTypes.TEXT}
            />
          );

        case 'label':
          return (
            <U21Alert key={itemKey} severity="warning">
              {label}
            </U21Alert>
          );

        case 'textarea':
          return (
            (!enabledBy ||
              getIn(checklistData || {}, [...path, enabledBy].join('.'))) && (
              <Fragment key={itemKey}>
                {isExport ? (
                  <Field<string>
                    name={formFieldProps.name}
                    subscription={{ value: true }}
                  >
                    {({ input: { value } }) => {
                      return <StyledU21Typography>{value}</StyledU21Typography>;
                    }}
                  </Field>
                ) : (
                  <StyledU21FormField
                    {...formFieldProps}
                    type={U21FormFieldTypes.TEXTAREA}
                  />
                )}
              </Fragment>
            )
          );

        case 'date_range':
          return (
            <StyledU21FormField
              key={key}
              {...formFieldProps}
              fieldProps={{
                ...fieldProps,
                valueTransform: dateRangeFieldValueTransformation,
              }}
              type={U21FormFieldTypes.DATE_RANGE_PICKER}
            />
          );

        default:
          if (item.section === 'div') {
            return (
              <HorizontalSection key={key}>
                {itemsToComponents(
                  item.items,
                  checklistData,
                  itemPath,
                  validationResults,
                  aiInvestigationResultItems,
                  previouslySaved,
                  disabled,
                  isOpenKey,
                  setIsOpenKey,
                  setFieldValue,
                  onChange,
                  formFieldPrefix,
                  isExport,
                  isSidePanel,
                )}
              </HorizontalSection>
            );
          }
          return (
            <U21Subsection
              key={itemKey}
              collapsible
              title={label}
              titleIcon={
                validationResults !== null && !!messages?.length ? (
                  <IconAlertCircle color="red" />
                ) : undefined
              }
            >
              <VerticalSection>
                {previouslySaved && messages?.length > 0 && (
                  <U21Alert severity="error">
                    {messages.map((message: string) => (
                      <p key={message}>{message}</p>
                    ))}
                  </U21Alert>
                )}
                {itemsToComponents(
                  item.items,
                  checklistData,
                  itemPath,
                  validationResults,
                  aiInvestigationResultItems,
                  previouslySaved,
                  disabled,
                  isOpenKey,
                  setIsOpenKey,
                  setFieldValue,
                  onChange,
                  formFieldPrefix,
                  isExport,
                  isSidePanel,
                )}
              </VerticalSection>
            </U21Subsection>
          );
      }
    };

    const resultItem:
      | AiInvestigationResultSectionItem
      | AiInvestigationResultItem
      | undefined = get(aiInvestigationResultItems, itemKey);
    if (
      aiInvestigationResultItems &&
      typeof resultItem?.result === 'string' &&
      typeof resultItem?.system_output === 'string'
    ) {
      const field = formField();

      if (!field) {
        return null;
      }

      return (
        <U21Grid key={itemKey}>
          {field}
          <CustomChecklistAIFindings
            aiInvestigationResultItems={aiInvestigationResultItems}
            disabled={disabled}
            isOpenKey={isOpenKey}
            itemKey={itemKey}
            label={label}
            setFieldValue={setFieldValue}
            setIsOpenKey={setIsOpenKey}
            type={type}
          />
        </U21Grid>
      );
    }

    return formField();
  });
};

export default CustomChecklistContent;

const HorizontalSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const VerticalSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledU21Typography = styled(U21Typography)`
  white-space: pre-wrap;
`;

const StyledU21FormField = styled(U21FormField)`
  flex: 1;
`;
