import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';

const NETWORK_ANALYSIS_BASE_QUERY_KEY = ['networkAnalysis'];

export const NETWORK_ANALYSIS_QUERY_KEYS = {
  getUseNetworkAnalysisKey: (
    entityId: string | undefined,
    entityExternalId: string,
    deltaLakeEnabled: boolean,
  ) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'networkAnalysis',
      entityId,
      entityExternalId,
      deltaLakeEnabled,
    ] as const,
  getNetworkAnalysisKey: (externalId: string, baseObjectType: BaseObjectType) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'entity',
      externalId,
      baseObjectType,
    ] as const,
  getNetworkAnalysisStatusKey: (
    externalId: string,
    baseObjectType: BaseObjectType,
  ) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'status',
      externalId,
      baseObjectType,
    ] as const,
};
