import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip } from 'app/shared/u21-ui/components';
import { useToggleInstrumentSidebar } from 'app/modules/sidebar/hooks';

export interface U21InstrumentChipProps {
  id: number | string;
  name: string;
}

export const U21InstrumentChip = (props: U21InstrumentChipProps) => {
  const { id, name } = props;
  const hasReadInstrumentsPermission = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const toggleInstrumentSidebar = useToggleInstrumentSidebar();
  return (
    <U21Chip
      onClick={
        hasReadInstrumentsPermission
          ? (e) => {
              e.stopPropagation();
              toggleInstrumentSidebar(id, e);
            }
          : undefined
      }
    >
      {name}
    </U21Chip>
  );
};
