import {
  CSSProperties,
  HTMLProps,
  isValidElement,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { U21Themes } from 'vendor/material-minimal/models';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import {
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconDots,
  IconDotsVertical,
  IconEdit,
} from '@u21/tabler-icons';
import { Link } from 'react-router-dom';
import { Tab, TabProps, Tabs } from '@mui/material';
import { U21Button } from 'app/shared/u21-ui/components/input/U21Button';
import {
  U21MenuButton,
  U21MenuButtonItems,
} from 'app/shared/u21-ui/components/display/U21MenuButton';

export type TabValue = string | number;

export interface U21TabItem<TValue extends TabValue = TabValue>
  extends Omit<TabProps, 'component' | 'label' | 'value'> {
  hidden?: boolean;
  link?: boolean;
  search?: string;
  value: TValue;
  label: ReactElement | string;
  style?: CSSProperties;
}

export interface U21TabsProps<TValue extends TabValue = TabValue>
  extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  action?: ReactNode;
  defaultValue?: TValue;
  onTabChange?: (value: TValue, e?: SyntheticEvent) => void;
  onTabsCustomize?: () => void;
  tabs: (U21TabItem<TValue> | ReactElement)[];
  value: TValue;
}

export const U21Tabs = <TValue extends TabValue>(
  props: U21TabsProps<TValue>,
) => {
  const {
    action,
    defaultValue,
    onTabChange,
    onTabsCustomize,
    tabs,
    value,
    ...rest
  } = props;

  // filter out non-tabs (ReactElement) and disabled tabs
  const validTabs = useMemo(
    () =>
      tabs.filter((i): i is U21TabItem<TValue> => {
        if (isValidElement<any>(i)) {
          return false;
        }
        return !i.disabled;
      }),
    [tabs],
  );

  const currentTab = useMemo(
    () => validTabs.find((i) => i.value === value),
    [validTabs, value],
  );

  const validShownTabs = useMemo(
    () => validTabs.filter((i) => !i.hidden),
    [validTabs],
  );

  // if no default route, find first visible enabled tab
  // if no visible enabled tab, find first hidden enabled tab
  const defaultTab =
    defaultValue ?? validShownTabs[0]?.value ?? validTabs[0]?.value;

  const onTabChangeRef = useRef(onTabChange);
  onTabChangeRef.current = onTabChange;
  const onTabsCustomizeRef = useRef(onTabsCustomize);
  onTabsCustomizeRef.current = onTabsCustomize;

  useEffect(() => {
    if (onTabChangeRef.current && !currentTab?.value && defaultTab) {
      onTabChangeRef.current(defaultTab);
    }
  }, [currentTab?.value, defaultTab]);

  const hiddenMenuButtonTabs = useMemo<U21MenuButtonItems>(() => {
    const hiddenTabs = validTabs
      .filter((i) => i.hidden)
      .map((i) => ({
        key: i.value,
        text: i.label,
        onClick: (e) => onTabChangeRef.current?.(i.value, e),
        to: i.link ? `${i.value}${i.search ?? ''}` : undefined,
        rightIcon:
          currentTab?.value === i.value ? <StyledIconCheck /> : undefined,
      }));

    return [
      ...hiddenTabs,
      ...(hiddenTabs.length && onTabsCustomizeRef.current
        ? [<U21MenuButton.Divider key="divider" />]
        : []),
      ...(onTabsCustomizeRef.current
        ? [
            {
              text: 'Customize tabs',
              onClick: onTabsCustomizeRef.current,
              icon: <IconEdit />,
            },
          ]
        : []),
    ];
  }, [currentTab?.value, validTabs]);

  return (
    <TabsContainer {...getDOMProps(rest)}>
      <Tabs
        onChange={(e, newValue) => onTabChange?.(newValue, e)}
        ScrollButtonComponent={({ direction, disabled, onClick }) => {
          return (
            <ScrollButtonContainer>
              <U21Button
                aria-label={`scroll ${direction}`}
                disabled={disabled}
                icon={
                  direction === 'left' ? (
                    <IconChevronLeft />
                  ) : (
                    <IconChevronRight />
                  )
                }
                onClick={onClick}
              />
            </ScrollButtonContainer>
          );
        }}
        value={
          currentTab?.hidden || !validShownTabs.length
            ? false
            : (currentTab?.value ?? false)
        }
        variant="scrollable"
      >
        {tabs.map((tab) => {
          if (isValidElement<any>(tab)) {
            return tab;
          }

          const {
            disabled,
            hidden,
            icon,
            link,
            search,
            style,
            value: tabValue,
            ...restTab
          } = tab;

          if (hidden) {
            return null;
          }

          return (
            <StyledTab
              $style={style}
              key={tabValue}
              disabled={disabled}
              icon={icon && <IconContainer>{icon}</IconContainer>}
              iconPosition="start"
              value={tabValue}
              {...restTab}
              {...(link && !disabled
                ? { LinkComponent: Link, to: `${tabValue}${search ?? ''}` }
                : {})}
            />
          );
        })}
        {Boolean(hiddenMenuButtonTabs.length) && (
          <StyledU21MenuButton
            alignRight
            items={hiddenMenuButtonTabs}
            buttonProps={{
              size: 'small',
              startIcon: currentTab?.hidden ? <IconDotsVertical /> : undefined,
            }}
            $tabChosen={Boolean(currentTab?.hidden)}
          >
            {currentTab?.hidden ? <>{currentTab?.label}</> : <IconDots />}
          </StyledU21MenuButton>
        )}
      </Tabs>
      {action && <ActionContainer key="actions">{action}</ActionContainer>}
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const ScrollButtonContainer = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const StyledIconCheck = styled(IconCheck)`
  ${(props) => css`
    color: ${props.theme.palette.primary.main};
  `}
`;

const StyledTab = styled(Tab)<{ $style?: CSSProperties }>`
  padding: 0 12px;
  border-radius: 8px;

  &&& {
    margin: 0;
    pointer-events: auto;
  }

  // spread to get around a typing issue
  ${(props) => ({ ...props.$style })}

  // override hover styles for anchor tag
    ${(props) =>
    !props.disabled &&
    css`
      :hover {
        color: ${props.theme.palette.mode === U21Themes.LIGHT
          ? props.theme.palette.grey[600]
          : props.theme.palette.grey[500]};
      }

      &.Mui-selected:hover {
        color: ${props.theme.palette.mode === U21Themes.LIGHT
          ? props.theme.palette.grey[800]
          : props.theme.palette.common.white};
      }
    `}
`;

export const IconContainer = styled.span`
  display: flex;
  margin-right: 8px;

  &&& {
    margin-bottom: 0;
  }
`;

const StyledU21MenuButton = styled(U21MenuButton)<{ $tabChosen: boolean }>`
  // 12px is consistent the tab button padding
  margin-left: 12px;
  margin-top: 8px;

  ${(props) =>
    props.$tabChosen
      ? css`
          border: 1px solid ${props.theme.palette.primary.main};
        `
      : css``}}
`;

const ActionContainer = styled.div`
  margin-left: auto;
`;
