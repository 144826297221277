import { ENTITY_RELATIONSHIPS_SECTION_HASH } from 'app/modules/networkAnalysis/constants';
import {
  U21Badge,
  U21Section,
  U21Table,
  U21TableBodyCellProps,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConnectionType,
  EntityGroupItem,
  NetworkAnalysisGroups,
} from 'app/modules/networkAnalysisRefresh/types';
import {
  getColumns,
  getLinksColumn,
} from 'app/modules/networkAnalysisRefresh/components/LinkedEntitiesTable';
import { NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME } from 'app/modules/networkAnalysisRefresh/constants';
import { RelatedEntityDirectedness } from 'app/modules/relationships/models';
import { DIRECTEDNESS_TO_LABEL_MAP } from 'app/modules/relationships/columns';
import { selectEntityToEntityRelationshipConfigsByExternalId } from 'app/modules/relationships/selectors';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { CopyLinkSectionHash } from 'app/modules/networkAnalysisRefresh/components/CopyLinkSectionHash';

interface EntityRelationshipLinkTableRow extends EntityGroupItem {
  rowId: string;
  relationship_config_external_id: string;
  directedness: RelatedEntityDirectedness;
}

export const EntityRelationshipsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(
      ENTITY_RELATIONSHIPS_SECTION_HASH,
      sectionRef,
    );
  }, [handleLinkSectionMounted]);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const linkedRelationshipsLength = useMemo(
    () => Object.keys(networkGroups.entityRelationships).length,
    [networkGroups.entityRelationships],
  );

  const tableData = useMemo(
    () => makeEntityRelationshipTableData(networkGroups),
    [networkGroups],
  );

  const reduxDispatch = useDispatch();

  const relationshipConfigsByExternalId = useSelector(
    selectEntityToEntityRelationshipConfigsByExternalId,
  );

  const columns = useMemo(() => {
    const cols = [
      {
        id: 'relationship_config',
        accessor: 'relationship_config_external_id',
        Header: 'Relationship',
        Cell: ({
          value,
        }: U21TableBodyCellProps<EntityRelationshipLinkTableRow, string>) => {
          return relationshipConfigsByExternalId[value]?.name ?? value;
        },
        disableSortBy: true,
      },
      {
        id: 'directedness',
        accessor: 'directedness',
        Header: 'Direction',
        Cell: ({
          value,
        }: U21TableBodyCellProps<
          EntityRelationshipLinkTableRow,
          RelatedEntityDirectedness
        >) => {
          return DIRECTEDNESS_TO_LABEL_MAP[value] ?? null;
        },
        disableSortBy: true,
      },
      ...getColumns<EntityRelationshipLinkTableRow>(),
    ];
    if (data) {
      cols.push(
        getLinksColumn(
          data.graph_result.nodes,
          networkGroups,
          history,
          pathname,
          search,
        ),
      );
    }
    return cols;
  }, [
    data,
    networkGroups,
    history,
    pathname,
    search,
    relationshipConfigsByExternalId,
  ]);

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedRelationshipsLength}>
            <span>
              {`${
                NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME[
                  ConnectionType.ENTITY_RELATIONSHIP
                ]
              }s`}
            </span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(ENTITY_RELATIONSHIPS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(
          ENTITY_RELATIONSHIPS_SECTION_HASH,
        )}
        action={
          <CopyLinkSectionHash hash={ENTITY_RELATIONSHIPS_SECTION_HASH} />
        }
      >
        <U21Table<EntityRelationshipLinkTableRow>
          data={tableData}
          columns={columns}
          onRowClick={(_, { external_id: extId }) =>
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id: 0,
                  externalId: extId,
                },
              }),
            )
          }
          getRowID={({ rowId }: EntityRelationshipLinkTableRow) => rowId}
        />
      </U21Section>
    </div>
  );
};

const makeEntityRelationshipTableData = ({
  entityRelationships,
  entities,
}: NetworkAnalysisGroups) =>
  Object.values(entityRelationships).reduce<EntityRelationshipLinkTableRow[]>(
    (acc, entityRelationshipEdge) => {
      const { id, source, target, label } = entityRelationshipEdge;
      const sourceEntity = entities[source];
      const targetEntity = entities[target];

      acc.push({
        ...(sourceEntity.is_base_node ? targetEntity : sourceEntity),
        rowId: id,
        relationship_config_external_id: label,
        directedness: sourceEntity.is_base_node
          ? RelatedEntityDirectedness.TO
          : RelatedEntityDirectedness.FROM,
      });
      return acc;
    },
    [],
  );
