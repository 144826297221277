import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { FC, HTMLProps, useCallback, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { endOfDay, format } from 'date-fns';
import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';

import { BackdropProps, InputAdornment, TextFieldProps } from '@mui/material';
import { ClearButton } from 'app/shared/u21-ui/components/input/text-field/styles';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import {
  IconArrowRight,
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from '@u21/tabler-icons';
import { StyledBackdrop } from 'app/shared/u21-ui/components/input/select/styles';
import { U21InputGroup } from 'app/shared/u21-ui/components/display/U21InputGroup';
import { U21TextField } from 'app/shared/u21-ui/components/input/text-field/U21TextField';
import { U21TextFieldProps } from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export type OptionalDate = Date | null | undefined;
export type DateRangeValue = [OptionalDate, OptionalDate];

export interface U21DateRangePickerProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
  autoFocus?: boolean;
  backdrop?: boolean;
  backdropProps?: Omit<BackdropProps, 'open'>;
  clearable?: boolean;
  disabled?: boolean;
  endPlaceholder?: string;
  error?: boolean;
  id?: string;
  label?: string;
  maxDate?: Date;
  maxDateInputProps?: TextFieldProps['inputProps'];
  minDate?: Date;
  minDateInputProps?: TextFieldProps['inputProps'];
  onChange: (value: DateRangeValue) => void;
  required?: boolean;
  startPlaceholder?: string;
  value: DateRangeValue;
  valueTransform?: (value: DateRangeValue) => DateRangeValue;
}

function defaultValueTransform(value: DateRangeValue): DateRangeValue {
  const [start, end] = value;
  return [start, end ? endOfDay(end) : undefined];
}

const DATE_FORMAT = 'PP';

// defined based on MUI's default min / max
// github.com/mui/mui-x/blob/f074f2735552161fd86d07cca5154e2bcabb263f/packages/x-date-pickers/src/LocalizationProvider/LocalizationProvider.tsx#L126-L127
const DEFAULT_MIN_DATE = new Date('1900-01-01T00:00:00.000Z');
const DEFAULT_MAX_DATE = new Date('2099-12-31T00:00:00.000Z');

export const U21DateRangePicker: FC<U21DateRangePickerProps> = (props) => {
  const {
    autoFocus,
    backdrop,
    backdropProps,
    clearable = true,
    disabled,
    endPlaceholder = 'End date',
    error,
    id,
    label = '',
    maxDate = DEFAULT_MAX_DATE,
    maxDateInputProps = {},
    minDate = DEFAULT_MIN_DATE,
    minDateInputProps = {},
    name,
    onChange,
    valueTransform = defaultValueTransform,
    required,
    startPlaceholder = 'Start date',
    value,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [start = null, end = null] = value;
  const hasClearIcon =
    clearable && !disabled && (Boolean(start) || Boolean(end));

  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const onChangeWrapper = (rawValue: DateRangeValue) => {
    const newValue = valueTransform(rawValue);
    trackEvent(
      AnalyticsEvents.U21DATEPICKER_ON_CHANGE,
      props,
      { open },
      newValue,
    );
    onChange(newValue);
  };

  const isDateValid = useCallback(
    (maybe: Date | string): boolean => {
      const date = new Date(maybe);
      if (isNaN(date.getTime())) {
        return false;
      }
      return date < maxDate && date >= minDate;
    },
    [maxDate, minDate],
  );

  const [startInputValue, setStartInputValue] = useState<string>('');
  const [endInputValue, setEndInputValue] = useState<string>('');
  useEffect(() => {
    if (start) {
      if (isDateValid(start)) {
        // temporary fix since there are instances where value is string, SC-58419 for cleanup
        setStartInputValue(format(new Date(start), DATE_FORMAT));
      }
    } else {
      // if value is gone, and previous value was valid, clear
      // don't clear otherwise to show red error to user
      setStartInputValue((prevInputValue) => {
        if (isDateValid(prevInputValue)) {
          return '';
        }
        return prevInputValue;
      });
    }

    if (end) {
      if (isDateValid(end)) {
        // temporary fix since there are instances where value is string, SC-58419 for cleanup
        setEndInputValue(format(new Date(end), DATE_FORMAT));
      }
    } else {
      // if value is gone, and previous value was valid, clear
      // don't clear otherwise to show red error to user
      setEndInputValue((prevInputValue) => {
        if (isDateValid(prevInputValue)) {
          return '';
        }
        return prevInputValue;
      });
    }
  }, [isDateValid, end, start]);

  return (
    <>
      {backdrop &&
        createPortal(
          <StyledBackdrop invisible {...backdropProps} open={open} />,
          document.body,
        )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          components={{
            LeftArrowIcon: IconChevronLeft,
            RightArrowIcon: IconChevronRight,
          }}
          disabled={disabled}
          disableMaskedInput
          endText=""
          inputFormat={DATE_FORMAT}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChangeWrapper}
          onClose={() => setOpen(false)}
          open={open}
          PopperProps={{
            // must be greater than backdrop
            // number chosen based on https://mui.com/material-ui/customization/z-index
            ...(backdrop ? { style: { zIndex: 1400 } } : {}),
          }}
          renderInput={(startProps, endProps) => {
            const {
              error: startError,
              inputProps: startInputProps,
              ...restStartProps
            } = startProps;
            const {
              error: endError,
              inputProps: endInputProps,
              InputProps: endMInputProps,
              ...restEndProps
            } = endProps;

            const startValid = startInputValue
              ? isDateValid(startInputValue)
              : true;
            const endValid = endInputValue ? isDateValid(endInputValue) : true;

            const combinedStartInputProps = {
              ...minDateInputProps,
              ...startInputProps,
            };
            const combinedEndInputProps = {
              ...maxDateInputProps,
              ...endInputProps,
            };

            return (
              <StyledU21InputGroup
                borders={false}
                disabled={disabled}
                error={error}
                {...getDOMProps(rest)}
              >
                <StyledU21TextField
                  $open={open}
                  {...(restStartProps as Omit<
                    U21TextFieldProps,
                    'as' | 'onChange'
                  >)}
                  autoFocus={autoFocus}
                  error={error || startError || !startValid}
                  id={id}
                  inputProps={{
                    ...combinedStartInputProps,
                    onBlur: (e) => {
                      combinedStartInputProps?.onBlur?.(e);
                      // use e.target.value instead of inputValue because inputValue is delayed by 300ms
                      const targetValue = e.target.value;
                      if (targetValue) {
                        if (isDateValid(targetValue)) {
                          if (end && new Date(targetValue) > new Date(end)) {
                            // clear end value if range is invalid
                            onChangeWrapper([
                              isDateValid(targetValue)
                                ? new Date(targetValue)
                                : undefined,
                              undefined,
                            ]);
                            endInputRef.current?.focus();
                          } else {
                            onChangeWrapper([
                              isDateValid(targetValue)
                                ? new Date(targetValue)
                                : undefined,
                              end,
                            ]);
                          }
                        } else {
                          onChangeWrapper([start, undefined]);
                          endInputRef.current?.focus();
                        }
                      }
                    },
                    onChange: () => {},
                    placeholder: startPlaceholder,
                    value: startInputValue,
                  }}
                  inputRef={startInputRef}
                  name={name ? `${name}-start` : undefined}
                  onClick={disabled ? undefined : () => setOpen(true)}
                  onChange={(newValue: string = '') => {
                    setStartInputValue(newValue);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      startInputRef.current?.blur();
                      if (!end) {
                        endInputRef.current?.focus();
                      }
                    }
                  }}
                  required={required}
                  startIcon={<IconCalendar />}
                />
                <StyledIconArrowRight />
                <StyledU21TextField
                  $open={open}
                  $right
                  {...(restEndProps as Omit<
                    U21TextFieldProps,
                    'as' | 'onChange'
                  >)}
                  error={error || endError || !endValid}
                  inputProps={{
                    ...combinedEndInputProps,
                    onBlur: (e) => {
                      combinedEndInputProps?.onBlur?.(e);
                      // use e.target.value instead of inputValue because inputValue is delayed by 300ms
                      const targetValue = e.target.value;
                      if (targetValue) {
                        if (isDateValid(targetValue)) {
                          if (
                            start &&
                            new Date(targetValue) < new Date(start)
                          ) {
                            // clear start value if range is invalid
                            onChangeWrapper([
                              undefined,
                              isDateValid(targetValue)
                                ? new Date(targetValue)
                                : undefined,
                            ]);
                            startInputRef.current?.focus();
                          } else {
                            onChangeWrapper([
                              start,
                              isDateValid(targetValue)
                                ? new Date(targetValue)
                                : undefined,
                            ]);
                          }
                        } else {
                          onChangeWrapper([start, undefined]);
                        }
                      }
                    },
                    onChange: () => {},
                    placeholder: endPlaceholder,
                    value: endInputValue,
                  }}
                  inputRef={endInputRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      endInputRef.current?.blur();
                      if (!start) {
                        startInputRef.current?.focus();
                      }
                    }
                  }}
                  InputProps={{
                    ...endMInputProps,
                    endAdornment: hasClearIcon && (
                      <InputAdornment position="end">
                        <ClearButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeWrapper([undefined, undefined]);
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name={name ? `${name}-end` : undefined}
                  onChange={(newValue: string = '') => {
                    setEndInputValue(newValue);
                  }}
                  onClick={disabled ? undefined : () => setOpen(true)}
                />
              </StyledU21InputGroup>
            );
          }}
          startText={label}
          value={[start, end]}
        />
      </LocalizationProvider>
    </>
  );
};

const StyledU21InputGroup = styled(U21InputGroup)`
  position: relative;
  width: 100%;

  :hover {
    .MuiTextField-clearIndicator {
      visibility: visible;
    }
  }
`;

const StyledU21TextField = styled(U21TextField)<{
  $open?: boolean;
  $right?: boolean;
}>`
  .MuiInputBase-input {
    text-align: center;
    ${(props) =>
      props.$right
        ? css`
            padding-left: 6px;
          `
        : css`
            padding-right: 6px;
          `}
  }

  ${(props) =>
    !props.disabled &&
    css`
      .MuiInputBase-root {
        cursor: pointer;

        .MuiInputBase-input {
          cursor: pointer;
        }
      }
    `}

  ${(props) =>
    props.$open &&
    css`
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-color: ${props.theme.palette.primary.main};
      }
    `}
`;

const StyledIconArrowRight = styled(IconArrowRight)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.palette.grey[600]};
`;
