import { createSelector } from 'reselect';

// Models
import { RelationshipConfigType } from 'app/modules/relationships/models';

// Thunks
import { RELATIONSHIPS_SLICE_NAME } from 'app/modules/relationships/sliceRelationships';
import { EntityToEntityRelationshipConfigResponse } from 'app/modules/relationships/types';
import { EntityToInstrumentRelationshipConfigResponse } from 'app/modules/relationships/types/relationshipConfigs';

export const selectRelationshipConfig = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].relationshipConfig;

export const selectEntityToEntityRelationshipConfigs = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityToEntityRelationshipConfigs;

export const selectEntityToEntityRelationshipConfigsByExternalId =
  createSelector(selectEntityToEntityRelationshipConfigs, (configs) =>
    configs.reduce<Record<string, EntityToEntityRelationshipConfigResponse>>(
      (acc, config) => {
        acc[config.external_id] = config;
        return acc;
      },
      {},
    ),
  );

export const selectEntityToInstrumentRelationshipConfigs = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityToInstrumentRelationshipConfigs;

export const selectEntityToInstrumentRelationshipConfigsByExternalId =
  createSelector(selectEntityToInstrumentRelationshipConfigs, (configs) =>
    configs.reduce<
      Record<string, EntityToInstrumentRelationshipConfigResponse>
    >((acc, config) => {
      acc[config.external_id] = config;
      return acc;
    }, {}),
  );

export const selectRelationshipConfigs = (configType: RelationshipConfigType) =>
  createSelector(
    selectEntityToEntityRelationshipConfigs,
    selectEntityToInstrumentRelationshipConfigs,
    (
      entityToEntityRelationshipConfigs,
      entityToInstrumentRelationshipConfigs,
    ) =>
      configType === RelationshipConfigType.ENTITY_TO_ENTITY
        ? entityToEntityRelationshipConfigs
        : entityToInstrumentRelationshipConfigs,
  );

export const selectEntityToEntityRelationshipCount = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityToEntityRelationshipCount;

export const selectEntityToInstrumentRelationshipCount = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityToInstrumentRelationshipCount;

export const selectRelationshipCount = (configType: RelationshipConfigType) =>
  createSelector(
    selectEntityToEntityRelationshipCount,
    selectEntityToInstrumentRelationshipCount,
    (entityToEntityRelationshipCount, entityToInstrumentRelationshipCount) =>
      configType === RelationshipConfigType.ENTITY_TO_ENTITY
        ? entityToEntityRelationshipCount
        : entityToInstrumentRelationshipCount,
  );

export const selectAllRelationshipConfigs = createSelector(
  selectEntityToEntityRelationshipConfigs,
  selectEntityToInstrumentRelationshipConfigs,
  (
    entityToEntityRelationshipConfigs,
    entityToInstrumentRelationshipConfigs,
  ) => [
    ...entityToEntityRelationshipConfigs,
    ...entityToInstrumentRelationshipConfigs,
  ],
);

export const selectRelationshipConfigsLoading = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].loadingRetrieveRelationshipConfigs;

export const selectRelationshipConfigLoading = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].loadingRetrieveRelationshipConfig;

export const selectEditRelationshipConfigLoading = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].loadingEditRelationshipConfig;

export const selectCreateRelationshipConfigLoading = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].loadingCreateRelationshipConfig;

export const selectEntityRelatedEntitiesPaginationPayload = (
  state: RootState,
) => state[RELATIONSHIPS_SLICE_NAME].entityRelatedEntitiesPaginationPayload;

export const selectEntityRelatedInstrumentsPaginationPayload = (
  state: RootState,
) => state[RELATIONSHIPS_SLICE_NAME].entityRelatedInstrumentsPaginationPayload;

export const selectInstrumentRelatedEntitiesPaginationPayload = (
  state: RootState,
) => state[RELATIONSHIPS_SLICE_NAME].instrumentRelatedEntitiesPaginationPayload;

export const selectEntityRelatedEntitiesFilters = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityRelatedEntitiesFilters;

export const selectEntityRelatedInstrumentsFilters = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].entityRelatedInstrumentsFilters;

export const selectInstrumentRelatedEntitiesFilters = (state: RootState) =>
  state[RELATIONSHIPS_SLICE_NAME].instrumentRelatedEntitiesFilters;

export const selectEntityRelatedEntitiesPayload = createSelector(
  selectEntityRelatedEntitiesPaginationPayload,
  selectEntityRelatedEntitiesFilters,
  selectEntityToEntityRelationshipConfigs,
  (basePayload, initialFilters, configs) => {
    let filters: string[];
    if (initialFilters.direction === 'either') {
      filters = initialFilters.selectedConfigs.length
        ? initialFilters.selectedConfigs
        : configs.map((c) => c.external_id);
    } else {
      filters = configs.reduce<string[]>((acc, c) => {
        if (initialFilters?.direction === 'directed' && c.directed) {
          acc.push(c.external_id);
        } else if (initialFilters?.direction === 'undirected' && !c.directed) {
          acc.push(c.external_id);
        }
        return acc;
      }, []);
    }
    return {
      ...basePayload,
      filters,
    };
  },
);

export const selectEntityRelatedInstrumentsPayload = createSelector(
  selectEntityRelatedInstrumentsPaginationPayload,
  selectEntityRelatedInstrumentsFilters,
  selectEntityToInstrumentRelationshipConfigs,
  (basePayload, filters, configs) => ({
    ...basePayload,
    filters: filters.selectedConfigs.length
      ? filters.selectedConfigs
      : configs.map((c) => c.external_id),
  }),
);

export const selectInstrumentRelatedEntitiesPayload = createSelector(
  selectInstrumentRelatedEntitiesPaginationPayload,
  selectInstrumentRelatedEntitiesFilters,
  selectEntityToInstrumentRelationshipConfigs,
  (basePayload, filters, configs) => ({
    ...basePayload,
    filters: filters.selectedConfigs.length
      ? filters.selectedConfigs
      : configs.map((c) => c.external_id),
  }),
);
