// Components
import { IconUser } from '@u21/tabler-icons';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { EntitySummary } from 'app/modules/entities/models';

// Helpers
import { useMemo } from 'react';
import { formatDatetime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { useSelector } from 'react-redux';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

interface Props {
  count: number | null;
  entities: EntitySummary[];
}

export const SidebarAssociatedEntities = ({ count, entities }: Props) => {
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const list = useMemo(
    () =>
      entities.map<DataProps>((i) => ({
        label: formatDatetime(i.created_at),
        value: i.external_id,
        id: i.id,
        route: hasReadEntitiesPermission
          ? ROUTES_MAP.entitiesId.path.replace(':id', String(i.id))
          : undefined,
      })),
    [entities, hasReadEntitiesPermission],
  );
  return (
    <U21Subsection
      aria-label="Flagged Entities"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Flagged Entities
        </U21TitleCountLabel>
      }
      titleIcon={<IconUser />}
    >
      {entities.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No flagged entities
        </U21Typography>
      )}
    </U21Subsection>
  );
};
