import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';

interface InstrumentActionStatusPayload {
  action_status: string;
  instrument_external_ids: string[];
  action_reason?: string;
  action_note?: string;
}

const actionInstruments = (
  payload: InstrumentActionStatusPayload,
): Promise<InstrumentActionStatusPayload> =>
  post(`/instruments/action`, payload);

export const useActionInstruments = (instrumentUnit21Id?: string | number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: InstrumentActionStatusPayload) =>
      actionInstruments(payload),
    onError: (_, payload) => {
      const {
        action_status: actionStatus,
        instrument_external_ids: instrumentIds,
      } = payload;
      dispatch(
        sendErrorToast(
          `Failed to set ${instrumentIds.length > 1 ? `${instrumentIds.length} instruments` : `Instrument ${instrumentIds[0]}`} to ${actionStatus.toLowerCase()}`,
        ),
      );
    },
    onSuccess: (_, payload) => {
      const {
        action_status: actionStatus,
        instrument_external_ids: instrumentIds,
        action_note: actionNote,
      } = payload;
      dispatch(
        sendSuccessToast(
          `${instrumentIds.length > 1 ? `${instrumentIds.length} instruments` : `Instrument ${instrumentIds[0]}`} ${actionStatus.toLowerCase()}.`,
        ),
      );
      if (instrumentUnit21Id !== undefined) {
        queryClient.invalidateQueries({
          queryKey:
            INSTRUMENT_QUERY_KEYS.getInstrumentDetails(instrumentUnit21Id),
        });

        // invalidate the notes query if there is a note
        if (actionNote) {
          for (const instrumentId of instrumentIds) {
            queryClient.invalidateQueries({
              queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentNotes(instrumentId),
            });
          }
        }
      }
    },
  });
};
